import React, { useEffect, useState } from "react";
import { Row, Col, Spinner, Form, FloatingLabel } from "react-bootstrap";
import Layout from "../../components/Layout";
import ShowModal from "../../subcom/ShowModal";
import OffcanvasCom from "../../subcom/OffcanvasCom";
import { Link } from "react-router-dom";
import * as coaActions from "../../action/coaAction";
import * as ledgerActions from "../../action/ledgerAction";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import StartAndEndDate from "../../components/general/StartAndEndDate";
import GLWithDetailsTable from "../../components/generalLedgerWithDetails/GLWithDetailsTable";
import "../../Css/glStyle.css";

import TableHeading from "../../subcom/tableHeading";
import { getPostDatedCheque } from "../../action/postDatedCheque";
import PostDatedChequeTable from "../../components/postDatedCheque/PostDatedChequeTable";
const GeneralLegder = (props) => {
  let location = props.location;

  const partyId = props.match.params.party;
  const sd = props.match.params.sd;
  const ed = props.match.params.ed;
  const dispatch = useDispatch();
  const ledgerRed = useSelector((state) => state.ledger);
  const coa = useSelector((state) => state.coa);
  const globalRed = useSelector((state) => state.global);
  const [ledgerList, setLedgerList] = useState([]);
  const [loadingCustomer, setLoadingCustomers] = useState(false);
  const [loadingLeger, setLoadingLegder] = useState(false);
  const [sortByDate, setSortByDate] = useState(false);
  const [sortByDebit, setSortByDebit] = useState(false);
  const [sortByCredit, setSortByCredit] = useState(false);

  const [sortByVoucherNo, setSortByVoucherNo] = useState(false);
  const [customers, setListCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [openingBalance, setOpeningBalance] = useState(0);
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [showPostDateCheque, setShowPostDateCheque] = useState(false);
  const [selectedCustomerName, setSelectedCustomerName] = useState("");
  const [postDate, setPostDate] = useState("");
  const [rDate, setRDate] = useState({
    start: "",
    end: "",
  });

  useEffect(() => {
    setLedgerList([]);
    setSelectedCustomer("");
    setSelectedCustomerName("");
  }, [location]);
  useEffect(() => {
    document.title = "General Ledger";
    dispatch(coaActions.getAllLevel());
  }, []);
  const currentUserRed = useSelector((state) => state.user);
  const [reportRight, setReportRight] = useState({});
  const [reportPostDatedCheque, setReportPostDatedCheque] = useState([]);

  useEffect(() => {
    setReportRight(props.rights);
  }, []);
  useEffect(() => {
    if (partyId && sd && ed) {
      if (customers.length > 0) {
        const getCus = customers.find((f) => f.docId === partyId);
        setSelectedCustomerName(getCus.title);
        setRDate({ start: sd, end: ed });
      }
      dispatch(
        ledgerActions.generalLedgerGet2(partyId, new Date(sd), new Date(ed))
      );
    } else {
      if (globalRed.date !== "") {
        let endDate = new Date().toISOString().slice(0, 10);
        let endFormat = moment(endDate).format("YYYY-MM-DD");
        let todayDate = moment(new Date()).format("YYYY-MM-DD");
        setRDate({ start: globalRed.date, end: endFormat });
        setPostDate(todayDate);
      }
    }
  }, [partyId, sd, ed, customers, globalRed]);
  useEffect(() => {
    setLoadingLegder(ledgerRed.loading);
    // const first = ledgerRed.data;
    // const sliceData = first.slice(pageNo.start, pageNo.end);
    setLedgerList(ledgerRed.data);
    setOpeningBalance(ledgerRed.openingBalance);
  }, [ledgerRed]);

  useEffect(() => {
    if (sortByDate) {
      sortDataByDateInAsc();
    } else {
      sortDataByDateInDesc();
    }
  }, [sortByDate]);
  useEffect(() => {
    if (sortByCredit) {
      sortDataByCreditInDesc();
    } else {
      sortDataByCreditInAsc();
    }
  }, [sortByCredit]);
  useEffect(() => {
    if (sortByVoucherNo) {
      sortByVoucherNoInDesc();
    } else {
      sortByVoucherNoInAsc();
    }
  }, [sortByVoucherNo]);
  useEffect(() => {
    if (sortByDebit) {
      sortDataByDebitInDesc();
    } else {
      sortDataByDebitInAsc();
    }
  }, [sortByDebit]);
  useEffect(() => {
    let level = [];
    coa.allLevels.map((level1) => {
      if (level1.children.length > 0) {
        level1.children.map((level2) => {
          if (level2.children.length > 0) {
            level2.children.map((level3) => {
              level.push(level3);
            });
          }
        });
      }
    });
    if (level.length > 0) {
      setListCustomers(level);
    }
    setLoadingCustomers(coa.loading);
  }, [coa]);
  const loadData = async () => {
    if (rDate.start !== "" && rDate.end !== "" && selectedCustomer !== "") {
      dispatch(
        ledgerActions.generalLedgerGet2(
          selectedCustomer,
          rDate.start,
          rDate.end
        )
      );
      if (showPostDateCheque) {
        handleReportChangeType();
      }
    } else {
      alert("please enter all parameters");
      return;
    }
    setShowOffCanvas(!showOffCanvas);
  };
  const handleReportChangeType = async (val) => {
    const data = await getPostDatedCheque("received", postDate);
    setReportPostDatedCheque(data);
  };
  const selectedItemData = (id) => {
    const getCus = customers.find((f) => f.docId === id);
    setSelectedCustomer(getCus.docId);
    setSelectedCustomerName(getCus.title);
  };
  const handleStartDate = (e) => {
    const val = e.target.value;

    setRDate({ start: val, end: "" });
  };
  const handleEndDate = (e) => {
    const val = e.target.value;
    setRDate({ start: rDate.start, end: val });
  };
  const sortDataByCreditInDesc = async () => {
    setLoadingLegder(true);
    const oldList = [...ledgerList];
    setLedgerList([]);
    const sortedList = await oldList.sort(function (a, b) {
      return parseInt(b.accCredit) - parseInt(a.accCredit);
    });
    setLedgerList(sortedList);
    setLoadingLegder(false);
  };
  const sortDataByCreditInAsc = async () => {
    setLoadingLegder(true);
    const oldList = [...ledgerList];
    setLedgerList([]);
    const sortedList = await oldList.sort(function (a, b) {
      return parseInt(a.accCredit) - parseInt(b.accCredit);
    });
    setLedgerList(sortedList);
    setLoadingLegder(false);
  };
  const sortByVoucherNoInDesc = async () => {
    setLoadingLegder(true);
    const oldList = [...ledgerList];
    setLedgerList([]);
    const sortedList = await oldList.sort(function (a, b) {
      const aId = a.id ? a.id.split("-")[3] : a.invoiceId.split("-")[3];
      const bId = b.id ? b.id.split("-")[3] : b.invoiceId.split("-")[3];
      return parseInt(aId) - parseInt(bId);
    });
    setLedgerList(sortedList);
    setLoadingLegder(false);
  };
  const sortByVoucherNoInAsc = async () => {
    setLoadingLegder(true);
    const oldList = [...ledgerList];
    setLedgerList([]);
    const sortedList = await oldList.sort(function (a, b) {
      const aId = a.id ? a.id.split("-")[3] : a.invoiceId.split("-")[3];
      const bId = b.id ? b.id.split("-")[3] : b.invoiceId.split("-")[3];

      return parseInt(bId) - parseInt(aId);
    });
    setLedgerList(sortedList);
    setLoadingLegder(false);
  };

  const sortDataByDebitInDesc = async () => {
    setLoadingLegder(true);
    const oldList = [...ledgerList];
    setLedgerList([]);
    const sortedList = await oldList.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      //.date.toDate().toLocaleDateString()
      return parseInt(b.accDebit) - parseInt(a.accDebit);
    });
    setLedgerList(sortedList);
    setLoadingLegder(false);
  };
  const sortDataByDebitInAsc = async () => {
    setLoadingLegder(true);
    const oldList = [...ledgerList];
    setLedgerList([]);
    const sortedList = await oldList.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      //.date.toDate().toLocaleDateString()
      return parseInt(a.accDebit) - parseInt(b.accDebit);
    });
    setLedgerList(sortedList);
    setLoadingLegder(false);
  };
  const sortDataByDateInAsc = async () => {
    setLoadingLegder(true);
    const oldList = [...ledgerList];
    setLedgerList([]);
    const sortedList = await oldList.sort(function (a, b) {
      let aDate = a.date.toDate().toLocaleDateString();
      let bDate = b.date.toDate().toLocaleDateString();
      return new Date(aDate) - new Date(bDate);
    });
    setLedgerList(sortedList);
    setLoadingLegder(false);
  };
  const sortDataByDateInDesc = async () => {
    setLoadingLegder(true);
    const oldList = [...ledgerList];
    setLedgerList([]);
    const sortedList = await oldList.sort(function (a, b) {
      let aDate = a.date;
      let bDate = b.date;
      return new Date(bDate) - new Date(aDate);
    });
    setLedgerList(sortedList);
    setLoadingLegder(false);
  };
  const sortByDateHandler = () => {
    setSortByDate(!sortByDate);
  };
  const sortByDebitHandler = () => {
    setSortByDebit(!sortByDebit);
  };

  const sortByCreditHandler = () => {
    setSortByCredit(!sortByCredit);
  };
  const sortByVoucherNoHandler = () => {
    setSortByVoucherNo(!sortByVoucherNo);
  };
  const handlePrintOptions = () => {
    localStorage.setItem(
      "printGenerLedger",
      JSON.stringify({
        customer: selectedCustomerName,
        rDate,
        ledgerList,
        openingBalance,
        pathname: location.pathname,
      })
    );
  };
  if (!loadingCustomer) {
    return (
      <Layout>
        {loadingLeger && (
          <ShowModal show={loadingLeger}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner animation="grow" />
            </div>
          </ShowModal>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "5px",
            width: "30vw",
          }}
        >
          <OffcanvasCom
            show={showOffCanvas}
            handleOffcanvas={() => setShowOffCanvas(!showOffCanvas)}
          >
            <FloatingLabel label="Post Cheque Start Date">
              <Form.Control
                name="startDate"
                type="date"
                onChange={(e) => setPostDate(e.target.value)}
                placeholder="please select date"
                value={postDate}
              />
            </FloatingLabel>
            <Form.Check
              type="checkbox"
              label="Show with Post Date Cheque Report "
              value={showPostDateCheque}
              name="reportType"
              checked={showPostDateCheque}
              onChange={(e) => setShowPostDateCheque(!showPostDateCheque)}
            />
            <StartAndEndDate
              rDate={rDate}
              selectedCustomer={selectedCustomer}
              selectedCustomerName={selectedCustomerName}
              handleStartDate={handleStartDate}
              handleEndDate={handleEndDate}
              loadData={loadData}
              selectedItemData={selectedItemData}
              customers={customers}
            />
          </OffcanvasCom>
          <h4>Ladger</h4>
        </div>
        <div className="reportParamInfo">
          <div className="reportParamInfoChild">
            <span>Name</span>
            <span>{selectedCustomerName}</span>
          </div>
          <div className="reportParamInfoChild">
            <span>Start Date</span>
            <span>{rDate.start}</span>
          </div>
          <div className="reportParamInfoChild">
            <span>End Date</span>
            <span>{rDate.end}</span>
          </div>
        </div>
        <div>
          {ledgerList.length > 0 && (
            <GLWithDetailsTable
              ledgerList={ledgerList}
              openingBalance={openingBalance}
              pathname={location.pathname}
            >
              <thead>
                <tr>
                  <th style={{ width: "100px" }}>
                    <TableHeading
                      clickHandler={sortByDateHandler}
                      heading={"Date"}
                    />
                  </th>
                  <th style={{ width: "100px" }}>
                    <TableHeading
                      clickHandler={sortByVoucherNoHandler}
                      heading={"Voucher#"}
                    />
                  </th>
                  <th>Narration</th>
                  {location.pathname === "/gldetails" && (
                    <>
                      <th>QTY</th>
                      <th>Rate</th>
                      <th>Amount</th>
                    </>
                  )}

                  <th style={{ width: "auto" }}>
                    <TableHeading
                      clickHandler={sortByDebitHandler}
                      heading={"Debit"}
                    />
                  </th>
                  <th style={{ width: "auto" }}>
                    <TableHeading
                      clickHandler={sortByCreditHandler}
                      heading={"Credit"}
                    />
                  </th>
                  <th style={{ width: "auto" }}>Balance</th>
                </tr>
              </thead>
            </GLWithDetailsTable>
          )}
        </div>
        {showPostDateCheque &&
          reportPostDatedCheque &&
          reportPostDatedCheque.length && (
            <PostDatedChequeTable
              report={reportPostDatedCheque}
            ></PostDatedChequeTable>
          )}
        {ledgerList.length > 0 && (
          <Row>
            <Col>
              {reportRight && reportRight.print ? (
                <Link
                  onClick={handlePrintOptions}
                  target="_blank"
                  to={`/print/${"ledger"}/${selectedCustomerName}/${
                    rDate.start
                  }/${rDate.end}/${selectedCustomer}`}
                >
                  Print
                </Link>
              ) : null}
            </Col>
            {/* <Col>
                        <Button onClick={backPage}>Back Page</Button>
                        <Button onClick={nextPage}>Next Page</Button>
                    </Col> */}
          </Row>
        )}
      </Layout>
    );
  } else {
    return (
      <ShowModal show={loadingCustomer}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" role="status" />
        </div>
      </ShowModal>
    );
  }
};

export default GeneralLegder;
