import React from "react";

const ChartOfAccountTable = (props) => {
  const { list } = props;
  return (
    <table className="table table-striped table-hover table-bordered">
      <thead>
        <tr>
          <th>ID</th>
          <th>Title</th>
          <th>Level No</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        {list.length > 0 &&
          list.map((level1) => {
            if (level1.children.length > 0) {
              return (
                <>
                  <tr>
                    <td>{level1.id}</td>
                    <td>{level1.title}</td>
                    <td style={{textAlign:'center'}}>{"1"}</td>
                    <td style={{textAlign:'center'}}>{level1.type.toUpperCase()}</td>
                  </tr>
                  {level1.children.map((level2) => {
                    if (level2.children.length > 0) {
                      return (
                        <>
                          <tr>
                            <td>
                              {level1.id}
                              {level2.id}
                            </td>
                            <td>
                              <span style={{ marginLeft: "20px" }}>
                                {level2.title}
                              </span>
                            </td>
                            <td style={{textAlign:'center'}}>{"2"}</td>
                            <td style={{ textAlign: "center" }}>
                              {level1.type.toUpperCase()}
                            </td>
                          </tr>
                          {level2.children.map((level3) => {
                            return (
                              <tr>
                                <td>
                                  {level1.id}
                                  {level2.id}
                                  {level3.id}
                                </td>
                                <td >
                                  <span style={{ marginLeft: "40px" }}>
                                    {level3.title}
                                  </span>
                                </td>

                                <td style={{textAlign:'center'}}>{"3"}</td>
                                <td style={{textAlign:'center'}}>{level1.type.toUpperCase()}</td>
                              </tr>
                            );
                          })}
                        </>
                      );
                    }
                  })}
                </>
              );
            }
          })}
      </tbody>
    </table>
  );
};

export default ChartOfAccountTable;
