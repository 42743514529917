
import React from "react";
import { Table } from "react-bootstrap";
import BottomTable from "../InvoiceCom/BottomTable";
import POBottomTable from "./POBottomTable";
const PrintInvoice = (props) => {
  const { data, showType, invoiceCreator, type } = props;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "95%",
        }}
      >
        <Table striped bordered hover>
          <thead>
            <tr>
              <th style={{ width: "50px" }}>No</th>
              <th>Name</th>
              <th>UOM</th>
              {showType === "d" ? null : (
                <th style={{ width: "50px" }}>Rate</th>
              )}
              <th style={{ width: "50px" }}>Quantity</th>
              {type === "Delivery Challan" ||
              type === "Good Receipt Note" ? null : (
                <>
                  {" "}
                  {showType === "d" ? null : (
                    <th style={{ width: "50px" }}>Discount</th>
                  )}
                  {showType === "d" ? null : (
                    <th style={{ width: "50px" }}>Total</th>
                  )}
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {data.items &&
              data.items.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td style={{ textAlign: "start" }}>{item.name}</td>
                  <td>{item.uom}</td>
                  {showType === "d" ? null : <td>{item.rate}</td>}

                  <td>{item.quantity}</td>
                  {type === "Delivery Challan" ||
                  type === "Good Receipt Note" ? null : (
                    <>
                      {showType === "d" ? null : <td>{item.dicount}</td>}
                      {showType === "d" ? null : <td>{item.total}</td>}
                    </>
                  )}
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      {type === "Delivery Challan" || type === "Good Receipt Note" ? null : (
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "95%",
        }}>
          {showType === "d" ? null : (
            <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}>
              {type === "Purchase Order" ? (
                <POBottomTable
                  invoiceCreator={invoiceCreator}
                  newEditInvoice={data}
                  showType={showType}
                />
              ) : (
                <BottomTable
                  newEditInvoice={data}
                  showType={showType}
                  invoiceCreator={invoiceCreator}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PrintInvoice;
