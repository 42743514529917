import React from "react";
import { Table, InputGroup, Form } from "react-bootstrap";
import SelectCurrency from "react-select-currency";
const CurrencyCom = (props) => {
  const { onChange, value, label } = props;

  return (
    <InputGroup className="w-100">
      <InputGroup.Text style={{width:'20%'}}>{label}</InputGroup.Text>
      <div style={{width:'80%'}}>
        <SelectCurrency value={value} style={{width:'100%',height:'100%'}} onChange={onChange} />
      </div>
    </InputGroup>
  );
};

export default CurrencyCom;
