import React, { useEffect, useState } from "react";
import SlipHeader from "../../components/SlipHeader.js/SlipHeader";
import { loadcompanyData } from "../../action/generalMethod";
const PrintItemLadger = (props) => {
  const [reportData, setReportData] = useState();
  const [companyData, setCompanyData] = useState({});
  useEffect(async() => {
    let data = localStorage.getItem("printItemLadgerReport")
      ? localStorage.getItem("printItemLadgerReport")
      : null;
    if (data) {
      const parseData = JSON.parse(data);
      setReportData(parseData);
    }
    const cData = await loadcompanyData();
    setCompanyData(cData);
  }, []);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  useEffect(async () => {
    if (reportData) {
      await delay(4000);
      window.print();
    }
  }, [reportData]);

  if (reportData) {
    const { itemLedger, rDate, totalData } = reportData;

    return (
      <div>
        <SlipHeader
          companyData={companyData}
          data={""}
          vNo={""}
          slipType={"Item Ledger"}
        />
        {itemLedger.length > 0 && (
          <table className="table table-striped table-hover table-bordered">
            <thead>
              <tr>
                <th style={{ width: "100px" }}>Date</th>
                <th style={{ width: "130px" }}>Voucher No</th>
                <th>Particular</th>
                <th>Received Quantity</th>
                <th>Issued Quantity</th>
                <th>Balance in Stock</th>
                {/* <th>Operation</th> */}
              </tr>
            </thead>
            <tbody>
              {itemLedger.map((st, index) => {
                if (st.date) {
                  let date = new Date(st.date.seconds * 1000)
                    .toISOString()
                    .slice(0, 10);
                  return (
                    <tr key={index}>
                      <td> {date}</td>
                      <td>{st.invoiceId}</td>
                      <td>{st.narration}</td>
                      <td>{st.receivedQty > 0 && st.receivedQty}</td>
                      <td>{st.issuedQty > 0 && st.issuedQty}</td>
                      <td>{st.balanceInStock}</td>
                      {/* <td onClick={() => showStockModalHandler(st)}>edit</td> */}
                    </tr>
                  );
                }
              })}
              <tr>
                <td></td>
                <td></td>
                <th>Total</th>
                <th> {totalData.totalRecieved}</th>
                <th> {totalData.totalIssured}</th>
                <th>{itemLedger[itemLedger.length - 1].balanceInStock}</th>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    );
  } else {
    return <div>loading</div>;
  }
};

export default PrintItemLadger;
