import React, { useState } from "react";
import { Offcanvas, Button } from "react-bootstrap";
import { FaBars } from "react-icons/fa";

function OffcanvasCom(props) {
  return (
    <>
      <FaBars
        variant="primary"
        onClick={props.handleOffcanvas}
        style={{ width: "30px", height: "30px" }}
      >
        Launch
      </FaBars>

      <Offcanvas show={props.show} onHide={props.handleOffcanvas}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{props.children}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OffcanvasCom;
