import React from "react";
import { Form, FloatingLabel } from "react-bootstrap";
const Input = (props) => {
  return (
    <FloatingLabel label={props.label}>
      <Form.Control
        required={props.required}
        type={props.type}
        name={props.name}
        onChange={props.onChange}
        value={props.value}
        disabled={props.disabled}
        placeholder={props.label}
        style={props.style}
      />
      <Form.Control.Feedback type="invalid">
        Please choose a username.
      </Form.Control.Feedback>
    </FloatingLabel>
  );
};

export default Input;
