import React from "react";
import { Table } from "react-bootstrap";
import Input2 from "../../subcom/Input2";
import { BsSearch } from "react-icons/bs";
import { BiReset } from "react-icons/bi";
import { Dropdown, Form } from "react-bootstrap";
const SearchBar = (props) => {
  return (
    <Table striped bordered hover responsive style={{ textAlign: "center" }}>
      <thead>
        <tr>
          <th style={tableStyle}>Voucher ID</th>
          {props.phone && <th style={tableStyle}>Phone Number</th>}
          <th style={tableStyle}>Party Name</th>
          <th style={tableStyle}>date</th>
          <th style={tableStyle}>Search/Reset</th>
          <th style={tableStyle}>status</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Input2
              name="voucherId"
              type="text"
              onChange={props.handleVoucherId}
              value={props.vId}
            />
          </td>
          {props.phone && (
            <td>
              <Input2
                name="phoneNumber"
                type="text"
                onChange={props.handlePhoneNumber}
                value={props.pn}
              />
            </td>
          )}
          <td>
            <Input2
              name="PartyName"
              type="text"
              onChange={props.handlePartyName}
              value={props.pName}
            />
          </td>
          <td
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Form.Control
              name="date"
              type="date"
              onChange={props.handlePartyDate}
              value={props.date}
              style={{ width: "180px" }}
            />
          </td>

          <td>
            <BsSearch
              size={32}
              color="blue"
              style={{ margin: "3px" }}
              onClick={props.handleSearchInvoice}
            ></BsSearch>
            <BiReset
              size={32}
              color="red"
              style={{ margin: "3px" }}
              onClick={props.handleReset}
            ></BiReset>
          </td>
          <td>
            <Dropdown>
              <Dropdown.Toggle>
                {props.selectedStatus === ""
                  ? "STATUS"
                  : props.selectedStatus.toUpperCase()}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {props.statusList.map((s, index) => (
                  <>
                    <Dropdown.Item
                      key={index}
                      onClick={() => props.handleStatusSearch(s)}
                    >
                      {s.toUpperCase()}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default SearchBar;
const tableStyle = {
  width: "120px",
  fontSize: "12px",
};
