import React, { useEffect, useState } from "react";
import SlipHeader from "../../components/SlipHeader.js/SlipHeader";
import { loadcompanyData } from "../../action/generalMethod";
const PrintItemLedgerWithAvg = (props) => {
  const [reportData, setReportData] = useState();
  const [companyData, setCompanyData] = useState({});
  useEffect(async () => {
    let data = localStorage.getItem("printItemLadgerWithAvgReport")
      ? localStorage.getItem("printItemLadgerWithAvgReport")
      : null;
    if (data) {
      const parseData = JSON.parse(data);
      setReportData(parseData);
    }
    const cData = await loadcompanyData();
    setCompanyData(cData);
  }, []);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  useEffect(async () => {
    if (reportData) {
      await delay(4000);
      window.print();
    }
  }, [reportData]);

  if (reportData) {
    const { itemLedger, rDate, totalValues } = reportData;
    console.log({ itemLedger });
    return (
      <div>
        <SlipHeader
          companyData={companyData}
          data={""}
          vNo={""}
          slipType={"Item Ledger With Average"}
        />
        {itemLedger.length > 0 && (
          <table className="table table-striped table-hover table-bordered">
            <thead>
              <tr>
                <th></th>

                <th></th>
                <th></th>
                <th></th>
                <th colSpan={3}>Received Data</th>

                <th colSpan={3}>Issue Data</th>

                <th colSpan={3}>Balance in Stock</th>
                {/* <th>Operation</th> */}
              </tr>
              <tr>
                <th>Date</th>

                <th>Voucher No</th>
                <th>Voucher Type</th>
                <th>Particular</th>

                <th>QTY</th>

                <th>Rate</th>
                <th>Total</th>

                <th>QTY</th>

                <th>Rate</th>
                <th>Total</th>

                <th>QTY </th>
                <th>Rate </th>
                <th>Total </th>
                {/* <th>Operation</th> */}
              </tr>
            </thead>
            <tbody>
              {itemLedger.map((st, index) => {
                if (st.date) {
                  let date = new Date(st.date.seconds * 1000)
                    .toISOString()
                    .slice(0, 10);
                  return (
                    <tr key={index}>
                      <td> {date}</td>

                      <td>{st.invoiceId}</td>
                      <td>{st.type}</td>
                      <td>{st.narration}</td>

                      <td>{st.receivedQty > 0 && st.receivedQty}</td>
                      <td>{st.receivedQty > 0 && st.rRate}</td>
                      <td>{st.rTotal > 0 && st.rTotal}</td>

                      <td>{st.issuedQty > 0 && st.issuedQty}</td>
                      <td>{st.issuedQty > 0 && st.issuedQty}</td>
                      <td>{st.iTotal > 0 && st.iTotal}</td>
                      <td>{st.avgQty}</td>

                      <td>{st.avgRate.toFixed(2)}</td>

                      <td>{st.avgTotal.toFixed(2)}</td>
                      {/* <td onClick={() => showStockModalHandler(st)}>edit</td> */}
                    </tr>
                  );
                }
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <th>Total</th>
                <th>{totalValues.receive}</th>
                <th></th>
                <th>{totalValues.receivedSum}</th>
                <th>{totalValues.issue}</th>
                <th></th>
                <th>{totalValues.issueSum}</th>
                <th></th>
                <th></th>

                <th>{itemLedger[itemLedger.length - 1].avgTotal.toFixed(2)}</th>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    );
  } else {
    return <div>loading</div>;
  }
};

export default PrintItemLedgerWithAvg;
