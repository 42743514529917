import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import CustomDropDown from "../../subcom/CustomDropDown";
const ItemLedgerSearch = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {props.itemList.length > 0 && (
        <div style={{ width: "100%" ,margin:'3px'}}>
          <CustomDropDown
            list={props.itemList}
            label={props.label}
            selectedItem={(id) => props.selectedItem(id)}
          />
        </div>
      )}

      <Form.Control
      style={{ width: "100%" ,margin:'3px'}}
        name="startDate"
        type="date"
        onChange={props.handleStartDate}
        value={props.start}
      />
      <Form.Control
      style={{ width: "100%" ,margin:'3px'}}
        name="endDate"
        type="date"
        onChange={props.handleEndDate}
        placeholder="please select date"
        value={props.end}
      />

      {props.check !== "hide" && (
        <Button
          style={{
            width: "100%",
            fontSize: "20px",
            fontWeight: "bold",
            padding: "5px",
            margin:'3px'
          }}
          onClick={props.loadData}
        >
          Search
        </Button>
      )}
    </div>
  );
};

export default ItemLedgerSearch;
