import React from "react";
import { Table, InputGroup, Form } from "react-bootstrap";
import Input2 from "../../subcom/Input2";
import "../../Css/BottomTable.css";
const POBottomTable = (props) => {
  const {
    gross,
    freight,
    tax,
    discount,
    grand,
    validity,
    shipping,
    invoiceOwner,
    afterDiscount,
    terms,payment
  } = props.newEditInvoice;
  const { edit } = props;
  const { showType, invoiceCreator } = props;
  // console.log({ invoice: props.newEditInvoice });
  return (
    <div className="printlayout">
    <div className="container">
      <InputHandler label={"Gross"} value={gross} onChange={() => {}} />

      <InputGroup className="inputgroup">
        <InputGroup.Text>Disc Bef</InputGroup.Text>
        <Form.Control
          value={discount > 0 ? discount : ""}
          onChange={props.handleDiscountChange}
        />
        <Form.Control
          value={(gross * discount) / 100}
          onChange={() => {}}
        />
      </InputGroup>
      <InputHandler
        label={"Net Amount"}
        value={gross - discount}
        onChange={() => {}}
      />
      <InputGroup className="inputgroup">
        <InputGroup.Text>Tax</InputGroup.Text>
        <Form.Control value={tax} onChange={props.handleTaxChange} />
        <Form.Control value={(gross * tax) / 100} onChange={() => {}} />
      </InputGroup>
    </div>
    <div className="container">
      <InputHandler
        label={"Total Amount"}
        value={gross}
        onChange={() => {}}
      />
      <InputGroup className="inputgroup">
        <InputGroup.Text>Disc Aft</InputGroup.Text>
        <Form.Control
          value={afterDiscount > 0 ? afterDiscount : ""}
          onChange={props.handleDiscountAfterChange}
        />
        <Form.Control
          value={(gross * afterDiscount) / 100}
          onChange={() => {}}
        />
      </InputGroup>

      <InputHandler
        label={"Freight"}
        onChange={props.handleFrightChange}
        value={freight > 0 ? freight : ""}
      />
      <InputHandler label={"Grand"} value={grand} onChange={() => {}} />
    </div>
    <div className="container">
      <div style={{ width: "70%", height: "100%" }}>
        <InputHandler label={"Payment"} value={payment} onChange={props.handlepaymentChange} />
      </div>
      <div style={{ width: "30%", height: "100%" }}>
        <InputHandler
          label={"validity Period"}
          value={validity}
          onChange={props.handleValidityChange}
        />
      </div>
    </div>

    <div className="container">
      <InputGroup className="inputgroup">
        <InputGroup.Text id="basic-addon1">{"Terms"}</InputGroup.Text>
        <Form.Control
          as={"textarea"}
          value={terms}
          onChange={props.handletermChange}
        />
      </InputGroup>
    </div>
    <div className="container">
      <InputHandler
        label={"Shipping"}
        value={shipping}
        onChange={props.handleShippingChange}
      />
    </div>
    <h6 style={{ display: "flex", flexDirection: "row-reverse" }}>
      created by:{invoiceOwner && invoiceOwner}
    </h6>
  </div>
  );
};

export default POBottomTable;
const InputHandler = (props) => {
  const { label, value, type } = props;
  return (
    <InputGroup className="input-group">
      <InputGroup.Text id="basic-addon1">{label}</InputGroup.Text>
      <Form.Control
        type={type ? type : "text"}
        value={value}
        onChange={props.onChange}
      />
    </InputGroup>
  );
};
