import React from "react";
import { Table } from "react-bootstrap";

const JvBottom = (props) => {
  const { totalJV, jvCreator, jvOwner } = props;
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          textAlign: "center",
        }}
      >
        <Table
          striped
          bordered
          hover
          size="sm"
          style={{
            width: "250px",
            backgroundColor: "white",
            color: "black",
          }}
        >
          <thead>
            <tr>
              <th>Total Debit</th>
              <th>Total Credit</th>
            </tr>
          </thead>
          <tbody>
            <tr
              style={{
                width: "250px",
                backgroundColor: "white",
                color: "black",
              }}
            >
              <td>{totalJV.totalDebit}</td>
              <td>{totalJV.totalCredit}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      {jvOwner && (
        <h6
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            margin: "10px",
          }}
        >
          created by:{jvOwner}
        </h6>
      )}
    </div>
  );
};

export default JvBottom;
