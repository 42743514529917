import React from 'react'
import { Button, Row, Col } from 'react-bootstrap';
const ActionButtons = (props) => {
    return (
        <Row>
            <Col sm={12}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: "center",

                    }}>
                    <Button
                        onClick={() => props.saveInfo()}
                        style={{ margin: "10px" }}
                        variant='secondary'
                        type='submit'
                        >
                        {props.saveBtn?props.saveBtn:"Save"}
                    </Button>
                    <Button
                        onClick={() => props.cancelInfo()}
                        style={{ margin: "10px" }}
                        variant='danger'>
                        Cancel
                    </Button>
                </div>
            </Col>
        </Row>

    )
}

export default ActionButtons
