import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
const InvoiceHeader = (props) => {
  const { vType } = props;
  const [hDate, setHDate] = useState("");
  const dateProp = props.date;
  useEffect(() => {
    let date;

    if (dateProp) {
      if (dateProp === "") {
        date = new Date();
      }
      if (dateProp !== "") {
        date = new Date(dateProp);
      }
      if (dateProp instanceof Date) {
        date = new Date(dateProp);
      } else if (!(dateProp instanceof Date) && dateProp instanceof Object) {
        date = new Date(dateProp.toDate());
      }
    } else {
      date = new Date();
    }

    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    var today = year + "-" + month + "-" + day;

    setHDate(today);
  }, [props]);

  const handleDateChange = (e) => {
    if (props.edit) {
      if (props.editInvoice) {
        let date = e.target.value;
        let month = new Date(date).getMonth();
        let dbMonth = props.editInvoice.split("-");
        console.log("month is changing", { dbMonth });
        if (dbMonth[1] === (month + 1).toString()) {
          setHDate(date);
          props.changeDate(date);
        } else {
          alert("month cannot be changed");
        }
      } else {
        let date = e.target.value;
        setHDate(date);
        props.changeDate(date);
      }
    } else {
      alert("edit first to make change");
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "90%",
        margin: "10px",
      }}
    >
      <span style={{ borderBottom: "1px solid #eee" }}>
        <Form.Control type="date" value={hDate} onChange={handleDateChange} />
      </span>
     
      <span style={{ borderBottom: "1px solid #eee" }}>
        Voucher NO.{"\xa0\xa0\xa0\xa0\xa0\xa0\xa0"}
        {props.vNo ? props.vNo.toString().toUpperCase() : ""}
      </span>
    </div>
  );
};

export default InvoiceHeader;
