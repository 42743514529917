import React from "react";
import { Table } from "react-bootstrap";
import CustomDropDown from "../../subcom/CustomDropDown";
import { Link } from "react-router-dom";
import '../../Css/table.css'
const PartyTable = (props) => {
  const { contact, party, customerData, edit, invoiceType } = props;
  return (
    <Table striped bordered hover className='mytable2' style={{ textAlign: "center" }}>
      <thead>
        <tr>
          <th>No</th>

          <th>
            Party Name
            {invoiceType === "Purchase" ? (
              <Link to="/chartofaccount/supplier">+</Link>
            ) : (
              <Link to="/chartofaccount/client">+</Link>
            )}
          </th>
          <th>Contact Number</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ width: "80px" }}>No</td>
          <td
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              width: "100%",
            }}
          >
            {!edit ? (
              party
            ) : (
              <div>
                {customerData.length > 0 && (
                  <CustomDropDown
                    list={customerData}
                    label={party}
                    selectedItem={props.selectedCustomer}
                  />
                )}
              </div>
            )}
          </td>
          <td>{contact}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default PartyTable;
