import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ItemTable from "./InvoiceCom/ItemTable";
import { useSelector, useDispatch } from "react-redux";
import * as itemActions from "../action/itemAction";
import * as coaActions from "../action/coaAction";
import * as itemStockActions from "../action/itemStockAction";
import ActionBar from "./general/ActionBar";
import moment from "moment";
import ProductionHeader from "./production/ProductionHeader";
import ProductionBottom from "./production/ProductionBottom";
import {
  addProductionNote,
  approveReceipyProductionNote,
  cancelReceipyProductionNote,
  checkReceipyProductionNote,
  deleteReceipyProductionNote,
  editProductionNote,
} from "../action/productionCom";
import CopyProductionNote from "./production/CopyProductionNote";
import ReverseActionBtn from "../subcom/ReverseActionBtn";
import ShowModal from "../subcom/ShowModal";
const InvoiceTable = (props) => {
  const history = useHistory();
  const { invoiceType } = props;
  const { noteId, userRights } = props;
  const dispatch = useDispatch();
  const globalRed = useSelector((state) => state.global);
  const itemRed = useSelector((state) => state.item);
  const stockRed = useSelector((state) => state.stock);
  const noteRed = useSelector((state) => state.note);
  const [delV, setDelV] = useState(false);
  const [receipyData, setReceipyData] = useState({
    date: "",
    id: "",
    receipyItemId: "",
    receipyItemName: "",
    forQuantity: 0,
    caption: "",
    items: [],
    gross: 0,
    grand: 0,
    totalCost: 0,
    miscExpenses: 0,
    miscPurchaseExpenses: 0,
    miscComplainExpenses: 0,
    hiddenExpenses: 0,
    laborExpenses: 0,
    totalExpenses: 0,
    totalGrandExpenses: 0,
    vNo: "",
    cvId: "",
    type: "",
    check: false,
    cancel: false,
    approve: false,
  });

  const [voucherType, setVoucherType] = useState("");
  const [edit, setEdit] = useState(false);
  const [checkNegativeQty, setCheckNegativeQty] = useState(false);
  const [itemData, setItemData] = useState([]);
  const [productionNoteList, setProductionNoteList] = useState([]);
  const [receipyNoteList, setReceipyNoteList] = useState([]);
  const [voucherCopied, setCopiedVoucher] = useState({});

  const selectedItemData = (docId, index) => {
    if (voucherCopied.id) {
      alert("Item Cannot be change");
    } else {
      const getItem = itemData.find((f) => f.docId === docId);

      let itemQty = 0;
      if (invoiceType === "Sell") {
        if (checkNegativeQty) {
          itemQty = loadData(docId);
        } else {
          itemQty = 1;
        }
      } else {
        itemQty = 1;
      }

      if (itemQty > 0) {
        let items = [...receipyData.items];
        let gross = 0;
        items[index] = {
          id: getItem.docId,
          name: getItem.name,
          quantity: 1,
          discount: 0,
          rate: parseFloat(getItem.saleRate ? getItem.saleRate : 0),
          uom: getItem.uom,
          total: parseFloat(getItem.saleRate ? getItem.saleRate : 0),
        };
        items.forEach((item) => {
          gross = gross + item.total;
        });

        let overAllTotal = gross - receipyData.totalExpenses;
        setReceipyData({
          ...receipyData,
          items: items,
          gross: gross,
          grand: overAllTotal,
        });
      } else {
        alert("Item is out of stock");
      }
    }
  };
  const loadData = (docId) => {
    // let enDate;
    // if (invoiceHeader.date !== "") {
    //   enDate = invoiceHeader.date;
    // } else {
    //   enDate = newEditInvoice.date;
    // }
    // if (enDate) {
    //   if (!(enDate instanceof Date) && enDate instanceof Object) {
    //     enDate = new Date(enDate.toDate());
    //     enDate = enDate.toISOString().slice(0, 10);
    //   }
    // }
    // let endFormat = moment(enDate).format("YYYY-MM-DD");
    // let rDate = { start: globalRed.date, end: endFormat };
    // let sItem = { id: docId };
    // let invoices = invoiceRed.invoices;
    // if (rDate.start !== "" && rDate.end !== "" && sItem !== "") {
    //   let s = new Date(rDate.start).toISOString().slice(0, 10);
    //   let e = new Date(rDate.end).toISOString().slice(0, 10);
    //   if (globalRed.stockWithApprove) {
    //     invoices = invoices.filter((f) => f.approve === true);
    //   }
    //   if (globalRed.stockWithCheck) {
    //     invoices = invoices.filter((f) => f.check === true);
    //   }
    //   let balanceInStock = 0;
    //   const fin = stockRed.stock.find((f) => f.docId === sItem.id);
    //   if (fin && fin.balanceInStock) {
    //     balanceInStock = balanceInStock + parseInt(fin.balanceInStock);
    //   }
    //   invoices.map((f) => {
    //     let dbDate = new Date(f.date.toDate());
    //     let check = new Date(dbDate).toISOString().slice(0, 10);
    //     if (check < s) {
    //       if (f.type === "Sell") {
    //         f.items.map((item) => {
    //           if (item.id === sItem.id) {
    //             balanceInStock = balanceInStock - parseInt(item.quantity);
    //           }
    //         });
    //       } else if (f.type === "Purchase") {
    //         f.items.map((item) => {
    //           if (item.id === sItem.id) {
    //             balanceInStock = balanceInStock + parseInt(item.quantity);
    //           }
    //         });
    //       }
    //     }
    //     if (check >= s && check <= e) {
    //       if (f.type === "Sell") {
    //         f.items.map((item) => {
    //           if (item.id === sItem.id) {
    //             balanceInStock = balanceInStock - parseInt(item.quantity);
    //           }
    //         });
    //       } else if (f.type === "Purchase") {
    //         f.items.map((item) => {
    //           if (item.id === sItem.id) {
    //             balanceInStock = balanceInStock + parseInt(item.quantity);
    //           }
    //         });
    //       }
    //     }
    //   });
    //   return balanceInStock;
    // } else {
    //   alert("please enter all parameters");
    //   return;
    // }
  };
  const handleQuantityChange = (e, index) => {
    if (voucherCopied.id) {
      alert("cannot make changes");
    } else {
      let items = [...receipyData.items];
      let val = e.target.value;
      if (val === "") {
        val = 0;
      } else {
        val = parseInt(val);
      }
      let gross = 0;
      items[index] = {
        id: items[index].id,
        name: items[index].name,
        quantity: parseFloat(val),
        discount: 0,
        rate: items[index].rate,
        uom: items[index].uom,
        total: parseFloat(items[index].rate) * parseFloat(val),
      };
      items.forEach((item) => {
        gross = gross + item.total;
      });
      let grand = gross + receipyData.totalExpenses;
      setReceipyData({
        ...receipyData,
        gross,
        grand,
        items: items,
      });
    }
  };
  const handleItemRateChange = (e, index) => {
    let val = e.target.value;
    let items = [...receipyData.items];
    if (val === "") {
      val = 0;
    }

    if (items[index].name !== "") {
      items[index] = {
        id: items[index].id,
        name: items[index].name,
        quantity: items[index].quantity,
        discount: 0,
        rate: val,
        uom: items[index].uom,
        total: parseFloat(val) * items[index].quantity,
      };
      let gross = 0;
      items.forEach((item) => {
        gross = gross + item.total;
      });
      const grand = gross + receipyData.totalExpenses;
      setReceipyData({
        ...receipyData,
        gross,
        grand,
        items: items,
      });
    } else {
      alert("please item rate cannot be zero");
    }
  };

  const selectedItemForReceipy = (docId) => {
    if (voucherCopied.id) {
      alert("Supplier Cannot be changed");
    } else {
      const findItem = itemData.find((f) => f.docId === docId);

      setReceipyData({
        ...receipyData,
        receipyItemId: findItem.docId,
        receipyItemName: findItem.name,
      });
    }
  };

  const handleIncrease = () => {
    if (voucherCopied.id) {
      alert("Voucher items cannot be increased");
    } else {
      let id = receipyData.items.length;
      let newItem = {
        id: id,
        name: "",
        uom: "",
        quantity: 0,
        rate: 0,
        discount: 0,
        total: 0,
      };
      let itemList = [...receipyData.items, newItem];

      setReceipyData({
        ...receipyData,
        items: itemList,
      });
    }
  };
  const handleDecrease = (id) => {
    if (voucherCopied.id) {
      alert("cannot remove items");
    } else {
      var myArray = [...receipyData.items];
      myArray = myArray.filter((f, index) => index !== id);

      let gross = 0;
      myArray.forEach((item) => {
        gross = gross + item.total;
      });

      let overAllTotal = gross - receipyData.totalExpenses;
      setReceipyData({
        ...receipyData,
        items: myArray,
        gross: gross,
        grand: overAllTotal,
      });
    }
  };

  const checkInvoice = () => {
    if (!edit) {
      let message = "can not checked Voucher because\n";
      if (receipyData && !receipyData.approve && !receipyData.cancel) {
        dispatch(checkReceipyProductionNote(noteId, receipyData.type));
      } else {
        if (receipyData.approve) {
          message = message + "Voucher is approved";
        }
        if (receipyData.cancel) {
          message = message + "Voucher is canceld ";
        }
        alert(message);
      }
    } else {
      alert("Checked Voucher can not be Checked during edit");
    }
  };
  const cancelInvoice = () => {
    if (!edit) {
      let message = "can not checked Voucher because\n";
      if (receipyData && !receipyData.approve && !receipyData.check) {
        dispatch(cancelReceipyProductionNote(noteId, receipyData.type));
      } else {
        if (receipyData.approve) {
          message = message + "Voucher is approved";
        }
        if (receipyData.cancel) {
          message = message + "Voucher is canceld ";
        }
        alert(message);
      }
    } else {
      alert("Checked Voucher can not be Checked during edit");
    }
  };

  const approveInvoice = () => {
    if (!edit) {
      let message = "can not approve inovice\n";

      if (receipyData && receipyData.check && !receipyData.cancel) {
        dispatch(approveReceipyProductionNote(noteId, receipyData.type));
      } else {
        if (!receipyData.check) {
          message = message + "Voucher is not checked";
        }
        if (receipyData.cancel) {
          message = message + "Voucher is is canceld ";
        }
        alert(message);
      }
    } else {
      alert("can not approve during edit");
    }
  };
  const deleteInvoice = () => {
    if (!edit) {
      let message = "can not approve inovice\n";

      if (receipyData && !receipyData.check && !receipyData.cancel) {
        setDelV(true);
      } else {
        if (!receipyData.check) {
          message = message + "Voucher is not checked";
        }
        if (receipyData.cancel) {
          message = message + "Voucher is is canceld ";
        }
        alert(message);
      }
    } else {
      alert("can not approve during edit");
    }
  };

  const saveInvoice = () => {
    let items = [...receipyData.items];
    let error = items.filter((f) => f.rate === "");
    let error2 = [];
    if (noteId) {
      dispatch(editProductionNote(receipyData));
      moveToInvoice();
    } else {
      dispatch(addProductionNote(receipyData));
      moveToInvoice();
    }
  };
  const moveToInvoice = () => {
    setEdit(false);

    history.push({
      pathname: `/productionnote/${receipyData.id}`,
      state: {
        response: "",
      },
    });
  };
  const changeDateHandler = (date) => {
    setReceipyData({ ...receipyData, date: date.target.value });
  };
  const handleForQtyChange = (Qty) => {
    if (voucherCopied.id) {
      alert("cannot make changes");
    } else {
      let forQuantity;
      if (Qty.target.value === "") {
        forQuantity = 0;
      } else {
        forQuantity = parseInt(Qty.target.value);
      }
      setReceipyData({ ...receipyData, forQuantity });
    }
  };
  const handleForCaptionChange = (caption) => {
    if (voucherCopied.id) {
      alert("cannot make changes");
    } else {
      setReceipyData({ ...receipyData, caption: caption.target.value });
    }
  };

  useEffect(() => {
    dispatch(itemActions.loadItems());
    dispatch(coaActions.getAllLevel());
    dispatch(itemStockActions.loadStockReport());
    setProductionNoteList(noteRed.productionNote);
    setReceipyNoteList(noteRed.receipyNote);
  }, [noteRed]);

  useEffect(() => {
    if (noteId) {
      let savedNotes;
      const splitId = noteId.split("-");
      const checkId = splitId[0];
      if (checkId === "pn") {
        setVoucherType("Production Note");
        savedNotes = productionNoteList.find((f) => f.id === noteId);
      } else {
        setVoucherType("Receipy Note");
        savedNotes = receipyNoteList.find((f) => f.id === noteId);
      }

      if (savedNotes) {
        setReceipyData(savedNotes);
      }
    } else {
      makeNewInvoiceHandler();
    }
  }, [receipyNoteList, productionNoteList, noteId, noteRed]);

  const makeNewInvoiceHandler = () => {
    let lastId,
      iType = "rn",
      sr,
      date;

    const d = new Date();
    date = d;
    let endFormat = moment(date).format("YYYY-MM-DD");

    const month = d.getMonth() + 1;
    const year = d.getFullYear().toString().slice(2);
    if (receipyNoteList.length > 0) {
      const totalChild = receipyNoteList.filter((f) => {
        if (f.docId.includes(`${iType}-${month}-${year}`)) {
          return f;
        }
      });

      if (totalChild.length > 0) {
        const sortedArray = totalChild.sort((a, b) => {
          return b.vNo - a.vNo;
        });

        lastId = parseFloat(sortedArray[0].vNo);
        lastId = lastId + 1;
        sr = lastId;
      } else {
        lastId = 1;
        sr = 1;
      }
    } else {
      lastId = 1;
      sr = 1;
    }

    const p1 = iType + "-" + month + "-" + year + "-" + lastId;

    clearInputData(endFormat, p1, sr, "Receipy Note");
    setEdit(true);
  };
  const clearInputData = (date, id, vNo, type) => {
    setEdit(true);
    setReceipyData({
      date,
      id,
      receipyItemId: "",
      receipyItemName: "",
      forQuantity: 0,
      caption: "",
      items: [],
      gross: 0,
      grand: 0,
      totalCost: 0,
      miscExpenses: 0,
      miscPurchaseExpenses: 0,
      miscComplainExpenses: 0,
      hiddenExpenses: 0,
      laborExpenses: 0,
      totalExpenses: 0,
      totalGrandExpenses: 0,
      vNo: vNo,
      cvId: "",
      type: type,
      check: false,
      cancel: false,
      approve: false,
    });
  };
  useEffect(() => {
    setItemData(itemRed.item);
    // setStockData(stockRed.stock);
    setCheckNegativeQty(globalRed.restrictNegativeQty);
  }, [itemRed, stockRed, globalRed]);
  const miscExpensesHandler = (e) => {
    if (voucherCopied.id) {
      alert("Voucher Copied cannot make changes");
    } else {
      let value = e.target.value;
      let miscExpenses;
      if (value !== "") {
        miscExpenses = parseFloat(value);
        const {
          hiddenExpenses,
          miscPurchaseExpenses,
          laborExpenses,
          miscComplainExpenses,
          gross,
        } = receipyData;
        const totalExpenses =
          miscComplainExpenses +
          hiddenExpenses +
          miscPurchaseExpenses +
          laborExpenses +
          miscExpenses;
        const grand = totalExpenses + gross;
        setReceipyData({ ...receipyData, miscExpenses, totalExpenses, grand });
      }
    }
  };
  const miscPurchaseExpensesHandler = (e) => {
    let value = e.target.value;
    let miscPurchaseExpenses;
    if (value !== "") {
      miscPurchaseExpenses = parseFloat(value);
      const {
        hiddenExpenses,
        miscExpenses,
        laborExpenses,
        miscComplainExpenses,
        gross,
      } = receipyData;
      const totalExpenses =
        miscComplainExpenses +
        hiddenExpenses +
        miscExpenses +
        laborExpenses +
        miscPurchaseExpenses;
      const grand = gross + totalExpenses;
      setReceipyData({
        ...receipyData,
        miscPurchaseExpenses,
        totalExpenses,
        grand,
      });
    }
  };
  const miscomplainExpensesHandler = (e) => {
    if (voucherCopied.id) {
      alert("Cannot make changes now");
    } else {
      let value = e.target.value;
      let miscComplainExpenses;
      if (value !== "") {
        miscComplainExpenses = parseFloat(value);
        const {
          hiddenExpenses,
          miscExpenses,
          miscPurchaseExpenses,
          laborExpenses,
          gross,
        } = receipyData;
        const totalExpenses =
          hiddenExpenses +
          miscExpenses +
          miscPurchaseExpenses +
          laborExpenses +
          miscComplainExpenses;
        const grand = totalExpenses + gross;
        setReceipyData({
          ...receipyData,
          miscComplainExpenses,
          totalExpenses,
          grand,
        });
      }
    }
  };
  const hiddenExpensesHandler = (e) => {
    if (voucherCopied.id) {
      alert("Cannot make changes now");
    } else {
      let value = e.target.value;
      let hiddenExpenses;
      if (value !== "") {
        hiddenExpenses = parseFloat(value);
        const {
          laborExpenses,
          miscExpenses,
          miscPurchaseExpenses,
          miscComplainExpenses,
          gross,
        } = receipyData;
        const totalExpenses =
          laborExpenses +
          miscExpenses +
          miscPurchaseExpenses +
          miscComplainExpenses +
          hiddenExpenses;
        const grand = totalExpenses + gross;
        setReceipyData({
          ...receipyData,
          hiddenExpenses,
          totalExpenses,
          grand,
        });
      }
    }
  };
  const laborExpensesHandler = (e) => {
    if (voucherCopied.id) {
      alert("Cannot make changes now");
    } else {
      let value = e.target.value;
      let laborExpenses;
      if (value !== "") {
        laborExpenses = parseFloat(value);
        const {
          hiddenExpenses,
          miscExpenses,
          miscPurchaseExpenses,
          miscComplainExpenses,
          gross,
        } = receipyData;
        const totalExpenses =
          hiddenExpenses +
          miscExpenses +
          miscPurchaseExpenses +
          miscComplainExpenses +
          laborExpenses;
        const grand = totalExpenses + gross;
        setReceipyData({ ...receipyData, laborExpenses, totalExpenses, grand });
      }
    }
  };

  const copyReceipyHandler = (data) => {
    let lastId,
      iType = "pn",
      sr,
      date,
      p1;
    if (voucherType === "") {
      const d = new Date();
      date = d;
      let endFormat = moment(date).format("YYYY-MM-DD");

      const month = d.getMonth() + 1;
      const year = d.getFullYear().toString().slice(2);
      if (productionNoteList.length > 0) {
        const totalChild = productionNoteList.filter((f) => {
          if (f.id.includes(`${iType}-${month}-${year}`)) {
            return f;
          }
        });

        if (totalChild.length > 0) {
          const sortedArray = totalChild.sort((a, b) => {
            return b.vNo - a.vNo;
          });

          lastId = parseFloat(sortedArray[0].vNo);
          lastId = lastId + 1;
          sr = lastId;
        } else {
          lastId = 1;
          sr = 1;
        }
      } else {
        lastId = 1;
        sr = 1;
      }
      p1 = iType + "-" + month + "-" + year + "-" + lastId;
    } else {
      p1 = noteId;
      sr = receipyData.vNo;
    }

    let findReceipy = receipyNoteList.find((f) => f.id === data.id);
    let items = [];
    let gross = 0;

    findReceipy.items.map((item) => {
      let total = item.quantity * data.forQuantity * item.rate;
      items.push({
        ...item,
        quantity: item.quantity * data.forQuantity,
        total,
      });
      gross = gross + total;
    });
    let grand = gross + findReceipy.totalExpenses;
    findReceipy = {
      ...findReceipy,
      caption: data.caption,
      forQuantity: data.forQuantity,
      items: items,
      gross,
      grand,
      cvId: findReceipy.id,
      id: p1,
      vNo: sr,
      type: "Production Note",
    };

    setReceipyData(findReceipy);
    setCopiedVoucher(findReceipy);
  };
  return (
    <div style={{ margin: "10px", textAlign: "center" }}>
      {delV ? (
        <ShowModal show={delV} size={"sm"}>
          <ReverseActionBtn
            link={`/productionnote`}
            message="Delete This Voucher"
            okAction={() => {
              dispatch(deleteReceipyProductionNote(noteId, receipyData.type));
            }}
            cancelAction={() => {
              setDelV(false);
            }}
          />
        </ShowModal>
      ) : null}
      <ActionBar
        makeNewVoucher={() => {}}
        handleCheckVoucher={checkInvoice}
        handleApproveVoucher={approveInvoice}
        handleCancelVoucher={cancelInvoice}
        handleSaveVoucher={saveInvoice}
        deleteVoucher={deleteInvoice}
        handleChangeVoucher={(option) => {}}
        edit={edit}
        editVoucher={() => setEdit(!edit)}
        invoiceId={receipyData.id}
        check="note"
        voucherType={voucherType}
        date={""}
        invoiceRights={userRights}
      />
      <div
        style={{
          diplay: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <CopyProductionNote
            copyReceipyHandler={copyReceipyHandler}
            receipyNoteList={receipyNoteList}
          />
        </div>
        <div>ID{receipyData.id}</div>
      </div>
      <div>
        <ProductionHeader
          party={receipyData.receipyItemName}
          date={receipyData.date}
          forQuantity={receipyData.forQuantity}
          caption={receipyData.caption}
          itemData={itemData}
          handleDateChange={changeDateHandler}
          handleForQtyChange={handleForQtyChange}
          handleForCaptionChange={handleForCaptionChange}
          selectedItemForReceipy={selectedItemForReceipy}
          edit={edit}
        />

        {receipyData.receipyItemId === "" ||
        receipyData.date === "" ||
        receipyData.forQuantity === "" ||
        receipyData.forQuantity === 0 ||
        receipyData.caption === "" ? null : (
          <div>
            <ItemTable
              items={receipyData.items}
              edit={edit}
              selectedItemData={selectedItemData}
              handleQuantityChange={handleQuantityChange}
              handleItemRateChange={handleItemRateChange}
              itemData={itemData}
              handleIncrease={handleIncrease}
              handleDecrease={handleDecrease}
              invoiceType={invoiceType}
              productionNote={true}
            />
            <ProductionBottom
              receipyData={receipyData}
              miscExpensesHandler={miscExpensesHandler}
              miscPurchaseExpensesHandler={miscPurchaseExpensesHandler}
              miscomplainExpensesHandler={miscomplainExpensesHandler}
              hiddenExpensesHandler={hiddenExpensesHandler}
              laborExpensesHandler={laborExpensesHandler}
              edit={edit}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoiceTable;
