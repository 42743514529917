import React, { useEffect, useState } from "react";
import PrintInvoice from "../components/InvoiceCom/PrintInvoice";
import firebase from "firebase";
import PrintJv from "../components/jvcom/PrintJv";
import JvBottom from "../components/jvcom/JvBottom";
import GLWithDetailsTable from "../components/generalLedgerWithDetails/GLWithDetailsTable";
import { useSelector } from "react-redux";
import SlipHeader from "../components/SlipHeader.js/SlipHeader";
import { loadcompanyData } from "../action/generalMethod";

const View = (props) => {
  const { type, vNo, pType } = props.match.params;
  const { name, start, end, id } = props.match.params;
  const [totalJV, setTotalJV] = useState({
    totalCredit: 0,
    totalDebit: 0,
  });

  const [data, setData] = useState();
  const [reportData, setReportData] = useState();
  const userRed = useSelector((state) => state.user);
  const globalRed = useSelector((state) => state.global);
  const [jvRights, setJvRights] = useState({});
  const [slipType, setSlipType] = useState("");
  const [companyData, setCompanyData] = useState({});
  const [showLogo, setShowLogo] = useState(false);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  useEffect(async () => {
    if (type !== "ledger") {
      await loadVouchers();
     
    } else {
      let data = localStorage.getItem("printGenerLedger")
        ? localStorage.getItem("printGenerLedger")
        : null;
      if (data) {
        const parseData = JSON.parse(data);
        setData(parseData.ledgerList);
        setReportData(parseData);
        let showOrNot;
        if (globalRed.showLogo && globalRed.showLogo.length > 0) {
          showOrNot = globalRed.showLogo;
          if (parseData.pathname === "/gldetails") {
            setShowLogo(showOrNot[2].children[0].children[2].show);
          } else {
            setShowLogo(showOrNot[2].children[0].children[1].show);
          }
        }
      }
    }
    const cData = await loadcompanyData();
    setCompanyData(cData);
  }, [globalRed]);
  useEffect(async () => {
    let newTotalCredit = 0;
    let newTotalDebit = 0;

    if (data) {
      if (type === "Voucher") {
        data.jvItems &&
          data.jvItems.map((item) => {
            newTotalCredit =
              parseInt(newTotalCredit) + parseInt(item.accCredit);
            newTotalDebit = parseInt(newTotalDebit) + parseInt(item.accDebit);
          });
        setTotalJV({ totalCredit: newTotalCredit, totalDebit: newTotalDebit });
      }
      await delay(4000);
      //  window.print();
    }
  }, [type, data]);
  async function loadVouchers() {
  
    let collection;
    let voucherIdSplit = vNo.split("-");
    let showOrNot;
   
   
      
      if (voucherIdSplit[0] === "si") {
        collection = `invoicesSell`;
        setSlipType("Invoice");
      
      }
      if (voucherIdSplit[0] === "pu") {
        collection = `invoicesPurchase`;
        setSlipType("Invoice");
      
      }
      if (voucherIdSplit[0] === "po") {
        collection = `invoicesPurchase Order`;
        setSlipType("Invoice");
      
        console.log({ showOrNot });
       }
      if (voucherIdSplit[0] === "sr") {
        collection = `invoicesSell Return`;
        setSlipType("Invoice");
       
      }
      if (voucherIdSplit[0] === "pr") {
        collection = `invoicesPurchase Return`;
        setSlipType("Invoice");
       
      }
      if (voucherIdSplit[0] === "gr") {
        collection = `invoicesGood Receipt Note`;
        setSlipType("Invoice");
        console.log({ show: showOrNot });
     
      }
      if (voucherIdSplit[0] === "dc") {
        collection = `invoicesDelivery Challan`;
        setSlipType("Invoice");
      
      }
      if (voucherIdSplit[0] === "cp") {
        collection = `Cash PaymentVoucher`;
        setSlipType("Cash Payment Voucher");
        
      }
      if (voucherIdSplit[0] === "bp") {
        collection = `Bank PaymentVoucher`;
        setSlipType("Bank Payment Voucher");
      
      }
      if (voucherIdSplit[0] === "cr") {
        collection = `Cash ReceiptVoucher`;
        setSlipType("Cash Receipt Voucher");
      
      }
      if (voucherIdSplit[0] === "br") {
        collection = `Bank ReceiptVoucher`;
        setSlipType("Bank Receipt Voucher");
     
      }
      if (voucherIdSplit[0] === "jv") {
        collection = `Journal VoucherVoucher`;
        setSlipType("Journal Voucher");
        console.log({ showOrNot });
      
      }
      loadcompanyData();
      const voucherRef = await firebase
        .firestore()
        .collection(collection)
        .doc(vNo)
        .get();

      const voucher = await voucherRef.data();
      console.log({voucher})
      setData(voucher);
     
  
  }
  
  const [jvCreator, setJvCreator] = useState({});

  useEffect(() => {
    if (userRed.user) {
      const user = userRed.user;
      setJvCreator(user);
    }
  }, [userRed]);
  if (data) {
    if (type === "ledger") {
      const { ledgerList, openingBalance, pathname } = reportData;
      let reportTitle = "";
      if (pathname) {
        if (pathname === "/gldetails") {
          reportTitle = "General Ledger With Details";
        } else {
          reportTitle = "General Ledger";
        }
      }
      return (
        <div>
          <SlipHeader
            companyData={companyData}
            data={data}
            vNo={vNo}
            slipType={reportTitle}
            showLogo={showLogo}
          />

          <GLWithDetailsTable
            ledgerList={ledgerList}
            openingBalance={openingBalance}
            pathname={pathname}
            print={"print"}
          >
            <thead>
              <tr>
                <th style={{ width: "100px" }}>Date</th>
                <th style={{ width: "100px" }}>Voucher</th>
                <th>Narration</th>
                {pathname === "/gldetails" && (
                  <>
                    <th>QTY</th>
                    <th>Rate</th>
                    <th>Amount</th>
                  </>
                )}

                <th style={{ width: "40px" }}>Debit</th>
                <th style={{ width: "40px" }}>Credit</th>
                <th>Balance</th>
              </tr>
            </thead>
          </GLWithDetailsTable>
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            margin: "20px",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <SlipHeader
            companyData={companyData}
            data={data}
            vNo={vNo}
            slipType={slipType}
            showLogo={showLogo}
            type={type}
          />

          <div style={{ width: "100%" }}>
            {(type === "Sell" ||
              type === "Purchase" ||
              type === "Sell Return" ||
              type === "Purchase Return" ||
              type === "Delivery Challan" ||
              type === "Purchase Order" ||
              type === "Good Receipt Note") && (
              <PrintInvoice
                data={data}
                showType={pType}
                invoiceCreator={jvCreator}
                type={type}
              />
            )}
          </div>
          {type === "Voucher" && (
            <div>
              <PrintJv jv={data.jvItems} vNo={vNo} />
              <JvBottom totalJV={totalJV} jvCreator={jvCreator} />
            </div>
          )}
        </div>
      );
    }
  } else {
    return <div>loading</div>;
  }
};

export default View;
