import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, CloseButton } from "react-bootstrap";
import Input2 from "../../subcom/Input2";
import ShowModal from "../../subcom/ShowModal";
import { loadPendingsInvoices } from "../../action/invoiceAction";
const CopyVoucherCom = (props) => {
  const { invoiceType } = props;
  const [showCopyVoucher, setShowCopyVoucher] = useState(false);
  const [currentVoucher, setCurrentVoucher] = useState("");
  const [listInvoices, setListInvoices] = useState([]);

  const handleCopyOtherInvoice = () => {
    setShowCopyVoucher(!showCopyVoucher);
  };
  useEffect(() => {
    if (showCopyVoucher) {
      loadPendingsInvoicesHandler();
    }
  }, [showCopyVoucher]);
  const loadPendingsInvoicesHandler = async () => {
    let invoices = await loadPendingsInvoices();
    if (invoiceType === "Delivery Challan") {
      invoices = invoices.filter((f) => f.type === "Client Order");
     
      setCurrentVoucher("Client Order");
    }
    if (invoiceType === "Client Order") {
      invoices = invoices.filter((f) => f.type === "Quotation");
      setCurrentVoucher("Quotation");
    }
    if (invoiceType === "Good Receipt Note") {
      invoices = invoices.filter((f) => f.type === "Purchase Order");
      setCurrentVoucher("Purchase Order");
    }
    if (invoiceType === "Purchase") {
      invoices = invoices.filter((f) => f.type === "Good Receipt Note");
      setCurrentVoucher("Good Receipt Note");
    }

    if (invoiceType === "Sell") {
      invoices = invoices.filter((f) => f.type === "Delivery Challan");
      setCurrentVoucher("Delivery Challan");
    }
    if (invoiceType === "Purchase Return") {
      invoices = invoices.filter((f) => f.type === "Purchase");
      setCurrentVoucher("Purchase");
    }
    if (invoiceType === "Sell Return") {
      invoices = invoices.filter((f) => f.type === "Sell");
      setCurrentVoucher("Sell");
    }

    setListInvoices(invoices);
  };

  const handlerVoucherAdj = (data, index) => {};
  const handleSelectedVoucher = (data) => {
    let changeDataItems = [];
    data.items.map((item) => {
      if (item.issue !== item.quantity) {
        changeDataItems.push({ ...item, quantity: item.quantity - item.issue });
      }
    });
    let copyVoucher = { ...data, items: changeDataItems };
    props.handleSelectedVoucher(copyVoucher);
    setShowCopyVoucher(false);
  };

  return (
    <div>
      <Button onClick={handleCopyOtherInvoice}>Copy voucher</Button>

      {showCopyVoucher ? (
        <ShowModal
          show={showCopyVoucher}
          size={"xl"}
          onHide={() => setShowCopyVoucher(false)}
          center
        >
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <span style={{ alignItems: "center" }}>
              <CloseButton
                variant="danger"
                onClick={() => setShowCopyVoucher(false)}
              />
            </span>
          </div>
          {listInvoices.length > 0 ? (
            <table className="table table-bordered text-center">
              <thead>
                <tr>
                  <th>date</th>
                  <th>VoucherId</th>
                  <th>Party</th>
                  <th>Item</th>
                  <th>{currentVoucher + " "}Qty</th>
                  <th>{invoiceType} Qty</th>
                  <th>Balance Qty</th>
                </tr>
                <tr>
                  <th>
                    <Input2
                      name="voucherId"
                      type="text"
                      onChange={props.handleVoucherId}
                      value={props.vId}
                    />
                  </th>
                  <th>
                    {" "}
                    <Input2
                      name="voucherId"
                      type="text"
                      onChange={props.handleVoucherId}
                      value={props.vId}
                    />
                  </th>
                  <th>
                    <Input2
                      name="voucherId"
                      type="text"
                      onChange={props.handleVoucherId}
                      value={props.vId}
                    />
                  </th>
                  <th>
                    {" "}
                    <Input2
                      name="voucherId"
                      type="text"
                      onChange={props.handleVoucherId}
                      value={props.vId}
                    />
                  </th>
                  <th>
                    {" "}
                    <Input2
                      name="voucherId"
                      type="text"
                      onChange={props.handleVoucherId}
                      value={props.vId}
                    />
                  </th>
                  <th>
                    {" "}
                    <Input2
                      name="voucherId"
                      type="text"
                      onChange={props.handleVoucherId}
                      value={props.vId}
                    />
                  </th>
                  <th>
                    {" "}
                    <Input2
                      name="voucherId"
                      type="text"
                      onChange={props.handleVoucherId}
                      value={props.vId}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {listInvoices.map((m) => {
                  {
                    return m.items.map((item, index) => {
                      if (item.issue !== item.quantity) {
                        return (
                          <tr
                            key={m.invoiceId + index}
                            onClick={() => handleSelectedVoucher(m)}
                          >
                            <td>
                              {m.date && m.date.toDate().toLocaleDateString()}
                            </td>
                            <td>{m.invoiceId}</td>
                            <td>{m.party}</td>
                            <td>{item.name}</td>
                            <td>{item.quantity}</td>

                            <td>{item.issue}</td>
                            <td>{item.quantity - item.issue}</td>
                          </tr>
                        );
                      }
                    });
                  }
                })}
              </tbody>
            </table>
          ) : (
            <div>loading...</div>
          )}
        </ShowModal>
      ) : null}
    </div>
  );
};

export default CopyVoucherCom;
