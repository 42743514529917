import React, { useEffect, useState } from "react";

import firebase from "firebase";
import Layout from "../../components/Layout";
import ShowModal from "../../subcom/ShowModal";
import * as itemStockActions from "../../action/itemStockAction";
import * as invoiceActions from "../../action/invoiceAction";
import * as itemActions from "../../action/itemAction";
import { Table, Spinner, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ItemLedgerSearch from "../../components/general/ItemLedgerSearch";
import moment from "moment";
import OffcanvasCom from "../../subcom/OffcanvasCom";
const ItemLedgerWithAvg = (props) => {
  const itemId = props.match.params.id;
  const sd = props.match.params.sd;
  const ed = props.match.params.ed;
  const dispatch = useDispatch();
  const stockRed = useSelector((state) => state.stock);
  const itemRed = useSelector((state) => state.item);
  const invoiceRed = useSelector((state) => state.invoice);
  const [itemList, setItemList] = useState([]);
  const [stockList, setStockList] = useState([]);
  const [itemLedger, setItemLedger] = useState([]);
  const [loading, setLoading] = useState(false);
  const globalRed = useSelector((state) => state.global);
  const [rDate, setRDate] = useState({
    start: "",
    end: "",
  });

  const [totalValues, setTotalValues] = useState({
    receive: 0,
    receivedSum: 0,
    issue: 0,
    issueSum: 0,
  });
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [sItem, setSItem] = useState({ id: "", name: "" });
  const currentUserRed = useSelector((state) => state.user);
  const [stockReportRight, setStockReportRight] = useState({});
  useEffect(() => {
    dispatch(itemStockActions.loadStockReport());
    dispatch(itemActions.loadItems());
    dispatch(invoiceActions.loadInvoiceAll());
  }, []);
  useEffect(() => {
    setStockReportRight(props.rights);
  }, []);

  useEffect(() => {
    setLoading(stockRed.loading);
    setStockList(stockRed.stock);
  }, [stockRed]);
  useEffect(() => {
    setLoading(itemRed.loading);
    setItemList(itemRed.item);
  }, [itemRed]);
  useEffect(() => {
    if (itemId && sd && ed) {
      setRDate({ start: sd, end: ed });
      if (itemList.length > 0 && stockList.length > 0) {
        onItemSelected(itemId);
        loadData();
      }
    } else {
      if (globalRed.date !== "") {
        let endDate = new Date().toISOString().slice(0, 10);
        let endFormat = moment(endDate).format("YYYY-MM-DD");
        setRDate({ start: globalRed.date, end: endFormat });
      }
    }
  }, [globalRed, itemId, sd, ed, itemList, stockList, invoiceRed]);
  const loadData = async () => {
    let data = [];
    let invoices = invoiceRed.invoices;
    if (globalRed.updateStockWithGRN || globalRed.updateStockWithDC) {
      invoices = invoices.filter((f) => {
        if (f.type !== "Sell" && f.type !== "Purchase") {
          return f;
        }
      });

      if (!globalRed.updateStockWithGRN) {
        invoices = invoices.filter((f) => {
          if (f.type === "Delivery Challan") {
            return f;
          }
        });
      }
      if (!globalRed.updateStockWithDC) {
        invoices = invoices.filter((f) => {
          if (f.type === "Good Delivery Note") {
            return f;
          }
        });
      }
    } else {
      invoices = invoices.filter((f) => {
        if (f.type !== "Delivery Challan" && f.type !== "Good Receipt Note") {
          return f;
        }
      });
    }
    if (globalRed.stockWithApprove) {
      invoices = invoices.filter((f) => f.approve === true);
    }
    if (globalRed.stockWithCheck) {
      invoices = invoices.filter((f) => f.check === true);
    }
    const sortByDate = invoices.sort((a, b) => {
      let dbDate = new Date(a.date.toDate());
      let dbDate2 = new Date(b.date.toDate());
      return dbDate - dbDate2;
    });

    if (rDate.start !== "" && rDate.end !== "" && sItem.id !== "") {
      const fin = stockList.find((f) => f.docId === sItem.id);
      if (fin) {
        let serverStart = firebase.firestore.Timestamp.fromDate(
          new Date(rDate.start)
        );

        let s = new Date(rDate.start).toISOString().slice(0, 10);
        let e = new Date(rDate.end).toISOString().slice(0, 10);
        let balanceInStock = 0;
        if (fin.balanceInStock) {
          balanceInStock = balanceInStock + parseInt(fin.balanceInStock);
        }
        const item = itemRed.item.find((f) => f.docId === sItem.id);
        let date,
          narration,
          avgQty = 0,
          avgRate = 0,
          rQty = 0,
          iQty = 0,
          rSum = 0,
          iSum = 0,
          avgTotal = 0,
          openingRate = 0,
          sum = 0,
          num = 0;
        avgQty = avgQty + parseInt(balanceInStock);
        let itemPurchaseRate = 0;
        if (item.purchaseRate !== "") {
          avgTotal = avgQty * parseInt(item.purchaseRate);
          itemPurchaseRate = itemPurchaseRate + parseInt(item.purchaseRate);
          avgRate = avgTotal / avgQty;
          openingRate = avgTotal / avgQty;
        }
        sum = sum + avgRate;
        let getOpening = defineAverageRate(s);
        let avgOpening = (getOpening.avg + itemPurchaseRate) / 2;

        data.push({
          receivedQty: 0,
          rRate: 0,
          rTotal: 0,
          issuedQty: 0,
          iRate: 0,
          iTotal: 0,
          avgQty: avgQty,
          avgTotal: avgOpening * avgQty,
          avgRate: avgOpening > 0 ? avgQty / avgOpening : avgRate,
          date: serverStart,
          narration: "opening balance",
          invoiceId: "",
          type: "",
        });
        if (rDate.start !== "" && rDate.end !== "" && sItem !== "") {
          sortByDate.map((f) => {
            let currentSum = 0;
            let dbDate = new Date(f.date.toDate());
            let check = new Date(dbDate).toISOString().slice(0, 10);
            const averageRate = defineAverageRate(check);

            currentSum = currentSum + averageRate.avg + openingRate;
            if (
              f.type === "Purchase" ||
              f.type === "Good Receipt Note" ||
              f.type === "Sell Return"
            ) {
              narration = `${f.type} to ${f.party}`;
              f.items.map((item) => {
                if (check >= s && check <= e) {
                  if (item.id === sItem.id) {
                    avgQty = avgQty + parseInt(item.quantity);
                    num = num + 1;
                    sum = sum + parseInt(item.rate);
                    avgTotal = parseInt(avgQty) * sum;
                    avgRate = avgTotal / avgQty;
                    rQty = rQty + parseInt(item.quantity);
                    rSum = rSum + parseInt(item.rate);
                    data.push({
                      receivedQty: item.quantity,
                      rRate: item.rate,
                      rTotal: item.total,
                      issuedQty: 0,
                      iRate: 0,
                      iTotal: 0,
                      avgQty: avgQty,
                      avgTotal: currentSum * avgQty,
                      avgRate: currentSum,
                      date: f.date,
                      narration: narration,
                      invoiceId: f.invoiceId,
                      type: f.type,
                    });
                  }
                }
              });
            } else if (
              f.type === "Sell" ||
              f.type === "Delivery Challan" ||
              f.type === "Purchase Return"
            ) {
              f.items.map((item) => {
                if (check < s) {
                  if (item.id === sItem.id) {
                    avgQty = parseInt(avgQty) - parseInt(item.quantity);
                  }
                }
                if (check >= s && check <= e) {
                  if (item.id === sItem.id) {
                    narration = `${f.type} to ${f.party}`;
                    avgQty = parseInt(avgQty) - parseInt(item.quantity);
                    iQty = iQty + parseInt(item.quantity);
                    iSum = iSum + parseInt(item.rate);
                    data.push({
                      receivedQty: 0,
                      rRate: 0,
                      rTotal: 0,
                      issuedQty: item.quantity,
                      iRate: item.rate,
                      iTotal: item.total,
                      avgQty: avgQty,
                      avgTotal: currentSum * avgQty,
                      avgRate: currentSum,
                      date: f.date,
                      narration: narration,
                      invoiceId: f.invoiceId,
                      type: f.type,
                    });
                  }
                }
              });
            }
          });
        }
        setItemLedger(data);
        setTotalValues({
          receive: rQty,
          receivedSum: rSum,
          issue: iQty,
          issueSum: iSum,
        });
      }
    }
  };

  const defineAverageRate = (s) => {
    let invoices = invoiceRed.invoices;
    invoices = invoices.filter((f) => {
      let dbDate = new Date(f.date.toDate());
      let check = new Date(dbDate).toISOString().slice(0, 10);
      if (f.type === "Purchase" && check <= s) {
        return f;
      }
    });
    let sum = 0,
      num = 0,
      qty = 0;
   
    invoices.map((invoice) => {
      if (invoice.type === "Purchase") {
        invoice.items.map((item) => {
          if (item.id === sItem.id) {
            num = num + 1;
            sum = sum + parseInt(item.rate);
            qty = qty + parseInt(item.quantity);
          }
        });
      }
    });
    let avg = 0;
    if (num > 0) {
      avg = sum / num;
    }

    return { avg, qty };
  };

  const onItemSelected = (id) => {
    const select = itemList.find((f) => f.docId === id);
    setSItem({ id: select.docId, name: select.name });

    setItemLedger([]);
  };
  const handleStartDate = (e) => {
    const val = e.target.value;
    setRDate({ start: val, end: "" });
  };
  const handleEndDate = (e) => {
    const val = e.target.value;
    setRDate({ start: rDate.start, end: val });
  };

  const resetAllValues = () => {
    setItemLedger([]);
    dispatch(itemStockActions.loadStockReport());
    dispatch(itemActions.loadItems());
    dispatch(invoiceActions.loadInvoiceAll());
    setSItem({ id: "", name: "" });
    setRDate({ start: "", end: "" });
  };
  const handlePrintOption = () => {
    localStorage.setItem(
      "printItemLadgerWithAvgReport",
      JSON.stringify({
        rDate,
        itemLedger,
        totalValues,
      })
    );
  };
  if (!loading) {
    return (
      <Layout>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "20px",
            padding: "3px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <OffcanvasCom
              show={showOffCanvas}
              handleOffcanvas={() => setShowOffCanvas(!showOffCanvas)}
            >
              <ItemLedgerSearch
                itemList={itemList}
                label={sItem.name}
                selectedItem={(id) => onItemSelected(id)}
                handleStartDate={handleStartDate}
                handleEndDate={handleEndDate}
                start={rDate.start}
                end={rDate.end}
                loadData={loadData}
              />
              {itemLedger.length > 0 &&
                Object.keys(stockReportRight).length > 0 &&
                stockReportRight.print && (
                  <Button onClick={handlePrintOption} style={{ width: "100%" }}>
                    <Link
                      to={`/printitemledger`}
                      target="_blank"
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      Print
                    </Link>
                  </Button>
                )}
            </OffcanvasCom>

            <h5 onClick={resetAllValues}>Item Ledger With Average</h5>
          </div>
        </div>
        {itemLedger.length > 0 ? (
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th></th>

                <th></th>
                <th></th>
                <th></th>
                <th colSpan={3}>Received Data</th>

                <th colSpan={3}>Issue Data</th>

                <th colSpan={3}>Balance in Stock</th>
                {/* <th>Operation</th> */}
              </tr>
              <tr>
                <th>Date</th>

                <th>Voucher No</th>
                <th>Voucher Type</th>
                <th>Particular</th>

                <th>QTY</th>

                <th>Rate</th>
                <th>Total</th>

                <th>QTY</th>

                <th>Rate</th>
                <th>Total</th>

                <th>QTY </th>
                <th>Rate </th>
                <th>Total </th>
                {/* <th>Operation</th> */}
              </tr>
            </thead>
            <tbody>
              {itemLedger.map((st, index) => {
                return (
                  <tr key={index}>
                    <td> {st.date && st.date.toDate().toLocaleDateString()}</td>

                    <td>
                      {
                        <Link
                          style={{ textDecoration: "none", color: "black" }}
                          to={`/invoice/Sell/${st.invoiceId}`}
                          target="_blank"
                        >
                          {st.invoiceId}{" "}
                        </Link>
                      }
                    </td>
                    <td>{st.type}</td>
                    <td style={{ textAlign: "start", color: "black" }}>
                      {st.narration}
                    </td>

                    <td>{st.receivedQty > 0 && st.receivedQty}</td>
                    <td>{st.receivedQty > 0 && st.rRate}</td>
                    <td>{st.rTotal > 0 && st.rTotal}</td>
                    {/* <td>{st.balanceInStock}</td> */}
                    <td>{st.issuedQty > 0 && st.issuedQty}</td>
                    <td>{st.issuedQty > 0 && st.issuedQty}</td>
                    <td>{st.iTotal > 0 && st.iTotal}</td>

                    <td>{st.avgQty}</td>

                    <td>{st.avgRate.toFixed(2)}</td>

                    <td>{st.avgTotal.toFixed(2)}</td>

                    {/* <td onClick={() => showStockModalHandler(st)}>edit</td> */}
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <th>Total</th>
                <th>{totalValues.receive}</th>
                <th></th>
                <th>{totalValues.receivedSum}</th>
                <th>{totalValues.issue}</th>
                <th></th>
                <th>{totalValues.issueSum}</th>
                <th></th>
                <th></th>

                <th>{itemLedger[itemLedger.length - 1].avgTotal.toFixed(2)}</th>

                {/* <td></td> */}
              </tr>
            </tbody>
          </Table>
        ) : null}
        {itemLedger.length > 0 &&
          Object.keys(stockReportRight).length > 0 &&
          stockReportRight.print && (
            <Button onClick={handlePrintOption}>
              <Link
                to={`/printitemledgerwithavg`}
                target="_blank"
                style={{ color: "white", textDecoration: "none" }}
              >
                Print
              </Link>
            </Button>
          )}
      </Layout>
    );
  } else {
    return (
      <Layout>
        {" "}
        {loading && (
          <ShowModal show={loading}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner animation="border" role="status" />
            </div>
          </ShowModal>
        )}
      </Layout>
    );
  }
};

export default ItemLedgerWithAvg;
