import moment from "moment";
import React, { useEffect, useState } from "react";
import { Dropdown, Form, Button } from "react-bootstrap";
import { getPostDatedCheque } from "../../action/postDatedCheque";
import LinkCom from "../../components/general/LinkCom";
import SearchBar from "../../components/postDatedCheque/SearchBar";
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PostDatedChequeTable from "../../components/postDatedCheque/PostDatedChequeTable";

const PostDatedCheque = (props) => {
  const [report, setReport] = useState([]);
  const [unfilter, setUnfilter] = useState([]);
  const [sDate, setSDate] = useState("");
  const [show, setShow] = useState("");
  const [fDate, setfDate] = useState("");
  const [fVNO, setfVNO] = useState("");
  const [fBank, setfBank] = useState("");
  const [fAccount, setfAccount] = useState("");
  const [fCNO, setfCNO] = useState("");
  const [fCDate, setfCDate] = useState("");
  const [fAmount, setfAmount] = useState("");

  useEffect(async () => {
    let endDate = new Date().toISOString().slice(0, 10);
    let endFormat = moment(endDate).format("YYYY-MM-DD");
    setSDate(endFormat);
  }, []);
 
  const [reportRight, setReportRight] = useState({});
  useEffect(() => {
    setReportRight(props.rights);
  }, []);
  const handleEndDate = (e) => {
    const val = e.target.value;
    setSDate(val);
  };
  const searchByDate = (e) => {
    const val = e.target.value;

    if (val === "") {
      setReport(unfilter);
    } else {
      setReport(
        unfilter.filter((f) => {
          let date = new Date(f.date.seconds * 1000).toISOString().slice(0, 10);
          if (date === val) {
            return f;
          }
        })
      );
    }

    setfDate(val);
  };
  const searchByAmountHander = (e) => {
    const val = e.target.value;
    setReport(
      unfilter.filter((f) => {
        if (f.amount.toString().includes(val)) {
          return f;
        }
      })
    );
    setfAmount(val);
  };
  const searchByVNo = (e) => {
    const val = e.target.value;
    setReport(
      unfilter.filter((f) => {
        if (f.vNo.includes(val)) {
          return f;
        }
      })
    );
    setfVNO(val);
  };
  const selectedCustomer = (id, type) => {
    if (id) {
      if (type === "bank") {
        setfBank(id);
        setReport(
          unfilter.filter((f) => {
            if (f.bankTitle.includes(id)) {
              return f;
            }
          })
        );
      } else {
        setReport(
          unfilter.filter((f) => {
            if (f.accountTitle.includes(id)) {
              return f;
            }
          })
        );
        setfAccount(id);
      }
    } else {
      if (type === "bank") {
        setfBank("All Customer");
      } else {
        setfAccount("All Customer");
      }

      setReport(unfilter);
    }
  };
  const searchByCNoHandler = (e) => {
    const val = e.target.value;
    setReport(
      unfilter.filter((f) => {
        if (f.checkNo.includes(val)) {
          return f;
        }
      })
    );
    setfCNO(val);
  };
  const searchByCheckDateHander = (e) => {
    const val = e.target.value;
    if (val === "") {
      setReport(unfilter);
    } else {
      setReport(
        unfilter.filter((f) => {
          if (f.checkDate === val) {
            return f;
          }
        })
      );
    }

    setfCDate(val);
  };
  const handleReportChangeType = async (val) => {
    setReport([]);
    setShow(val);
    if (val === "received") {
      const data = await getPostDatedCheque("received", sDate);
      setReport(data);
      setUnfilter(data);
    }
    if (val === "issued") {
      const data = await getPostDatedCheque("issued", sDate);
      setReport(data);
      setUnfilter(data);
    }
  };
  const handlePrintInfo = () => {
    localStorage.setItem(
      "printPostDatedCheck",
      JSON.stringify({
        report,
      })
    );
  };
  return (
    <Layout>
      <div className="d-flex flex-row justify-content-start align-items-center">
        <Form.Control
          style={{ height: "40px", width: "200px" }}
          name="endDate"
          type="date"
          onChange={handleEndDate}
          placeholder="please select date"
          value={sDate}
        />
        <Dropdown>
          <Dropdown.Toggle>
            {show === "" ? "Choose Type" : show.toUpperCase()}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={(e) => handleReportChangeType("received")}>
              Received
            </Dropdown.Item>
            <Dropdown.Item onClick={(e) => handleReportChangeType("issued")}>
              Issued
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <PostDatedChequeTable
        report={report}
        SearchBar={
          <SearchBar
            searchByDate={searchByDate}
            searchByVNo={searchByVNo}
            selectedCustomer={selectedCustomer}
            searchByCheckDateHander={searchByCheckDateHander}
            searchByCNoHandler={searchByCNoHandler}
            searchByCheckDateHander={searchByCheckDateHander}
            searchByAmountHander={searchByAmountHander}
            fDate={fDate}
            fVNO={fVNO}
            fBank={fBank}
            fAccount={fAccount}
            fCNO={fCNO}
            fCDate={fCDate}
            fAmount={fAmount}
          />
        }
      ></PostDatedChequeTable>
      {report.length > 0 && reportRight && reportRight.print ? (
        <Button onClick={handlePrintInfo}>
          <Link
            to={`/printpostdatedcheque`}
            target="_blank"
            style={{ color: "white", textDecoration: "none" }}
          >
            Print
          </Link>
        </Button>
      ) : null}
    </Layout>
  );
};

export default PostDatedCheque;
