import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap';
import * as coaActions from '../../action/coaAction';
import { useDispatch } from 'react-redux';
import NewLevel1 from './NewLevel1';
const NewLevel2 = (props) => {
    const { level2Array, level1Data, level1Array } = props;
    const dispatch = useDispatch();
    const [level1Id, setLevel1Id] = useState('')
    const [level2Id, setLevel2Id] = useState('')
    const [level2Title, setLevel2Title] = useState('')
    const [show, setShow] = useState(false);
    let id;
    let next;
    if (level2Array.length > 0 && level1Data) {
        next = level2Array.filter(f => f.parentId === level1Data.docId);
    }
   

    if (next && next.length > 0) {
        id = next[next.length - 1].id
        id = parseInt(id) + 1
        if (id.toString().length == 1) {
            id = `00${id}`
        } else if (id.toString().length == 2) {
            id = `0${id}`
        } else if (id.toString().length == 3) {
            id = `${id}`
        }

    } else {
        id = '001'

    }

    const saveLevel = () => {
        if (level2Title !== '') {
            dispatch(coaActions.defineLevel2(
                level1Data.id, level1Data.docId, level2Title, level2Id));
            setShow(false);
        }
        else {
            alert('please enter some values')
        }

    }
    const cancelSave = () => {
        setShow(false)
        setLevel1Id('');
        setLevel2Id('');
        setLevel2Title('');
    }
    const onLevel2TitleChangeHandler = (e) => {
        setLevel2Title(e.target.value)
    }
    const onLevel2IdChangeHandler = (e) => {
        setLevel2Id(e.target.value)
    }

    return (


        <div style={{
            display: 'flex',
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: 'column',
            height: '200px',
            width: '500px'
        }}>
            <h3>Define Level 2</h3>

            <Row style={{  width: '500px'}}>
                <Col md={11}>
                    <Form.Control
                        required="required"
                        type="number"
                        name="id1"
                        disabled
                        value={level1Data.id}

                    />
                </Col>
                <Col md={1}>
                    <NewLevel1
                     type={props.type}
                        nextId={level1Array.length > 0 &&
                            level1Array[level1Array.length - 1]}
                    />
                </Col>
            </Row>




            <Form.Control
                required="required"
                type="number"
                name="id2"
                onChange={onLevel2IdChangeHandler}
                value={level2Id === "" ? id : level2Id}

            />
            <Form.Control
                label="Level 2 Title"
                required="required"
                type="text"
                name="title2"
                onChange={onLevel2TitleChangeHandler}
                value={level2Title}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    width: '100%',
                    marginTop: '10px'

                }}
            >
                <Button onClick={saveLevel}> Save</Button>
                <Button variant='danger' onClick={cancelSave}> Cancel</Button>
            </div>
        </div>




    )
}

export default NewLevel2
