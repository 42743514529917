import {
    LOAD_STOCK_FAILURE,
    LOAD_STOCK_SUCCESS,
    LOAD_STOCK_REQUEST,
    EDIT_STOCK_REQUEST,
    EDIT_STOCK_SUCCESS,
    EDIT_STOCK_FAILURE,
    UPDATE_STOCK_REQUEST,
    UPDATE_STOCK_SUCCESS,
    UPDATE_STOCK_FAILURE
} from '../action/itemStockAction'
const initialState = {
    loading: false,
    stock: [],
    error: '',

};
const stockReducer = (state = initialState, action) => {

    switch (action.type) {
        case LOAD_STOCK_REQUEST: {
            state = {
                ...state,
                loading: true,
                stock: [],
                error: ''
            }
        }
            break;
        case LOAD_STOCK_SUCCESS: {
            state = {
                ...state,
                loading: false,
                stock: action.payload.stock,
                error: ''
            }
        }
            break;
        case LOAD_STOCK_FAILURE: {
            state = {
                ...state,
                loading: false,
                data: [],
                error: action.payload.error
            }
        }
            break;
        case EDIT_STOCK_REQUEST: {

            state = {
                ...state,
                loading: true,
            }
        }
            break;
        case EDIT_STOCK_SUCCESS: {

            state = {
                ...state,
                loading: false,
                stock: action.payload.stock
            }
        }
            break;
        case EDIT_STOCK_FAILURE: {

            state = {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
            break;
        case UPDATE_STOCK_REQUEST: {

            state = {
                ...state,
                loading: true,
            }
        }
            break;
        case UPDATE_STOCK_SUCCESS: {
            const updatedArray = action.payload.data;
            console.log(updatedArray)
            const newArray = [];
            state.stock.forEach(item => {
                let findItem = updatedArray.find(f => f.docId === item.docId);
                if (findItem) {
                    newArray.push(findItem)
                } else {
                    newArray.push(item)
                }
            })
            state = {
                ...state,
                loading: false,
                stock: newArray
            }
        }
            break;
        case UPDATE_STOCK_FAILURE: {

            state = {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }
            break;
    }

    return state;
};
export default stockReducer;