import firebase from "firebase";
export const NEW_JV_REQUEST = "NEW_JV_REQUEST";
export const NEW_JV_SUCCESS = "NEW_JV_SUCCESS";
export const NEW_JV_FAILURE = "NEW_JV_FAILURE";
 
export const EDIT_JV_REQUEST = "EDIT_JV_REQUEST";
export const EDIT_JV_SUCCESS = "EDIT_JV_SUCCESS";
export const EDIT_JV_FAILURE = "EDIT_JV_FAILURE";
export const DELETE_JV_REQUEST = "DELETE_JV_REQUEST";
export const DELETE_JV_SUCCESS = "DELETE_JV_SUCCESS";
export const DELETE_JV_FAILURE = "DELETE_JV_FAILURE";

export const GET_JV_REQUEST = "GET_JV_REQUEST";
export const GET_JV_SUCCESS = "GET_JV_SUCCESS";
export const GET_JV_FAILURE = "GET_JV_FAILURE";

export const LOAD_JV_REQUEST = "LOAD_JV_REQUEST";
export const LOAD_JV_SUCCESS = "LOAD_JV_SUCCESS";
export const LOAD_JV_FAILURE = "LOAD_JV_FAILURE";
export const LOAD_NEXT_JV = "LOAD_NEXT_JV";
export const LOAD_PREV_JV = "LOAD_PREV_JV";
export const LOAD_FIRST_JV = "LOAD_FIRST_JV";
export const LOAD_LAST_JV = "LOAD_LAST_JV";

export const OP_JV_REQUEST = "OP_JV_REQUEST";
export const OP_JV_SUCCESS = "OP_JV_SUCCESS";
export const OP_JV_FAILURE = "OP_JV_FAILURE";
export const CANCEL_JV_SAVING = "CANCEL_JV_SAVING";

export const addJournalVoucher = (data, jvCreator) => {
  return async (dispatch, getState) => {
    const { jvWithApprove, jvWithCheck } = getState().global;
    dispatch({
      type: NEW_JV_REQUEST,
    });
    console.log("new VOucher", data);
    let id = data.jvHeader.jvId;
    let type = data.jvHeader.jvType;
    let date = data.jvHeader.date;
    let serverDate = firebase.firestore.Timestamp.fromDate(date);

    await firebase
      .firestore()
      .collection(`${type}Voucher`)
      .doc(id)
      .set({
        jvItems: data.jv,
        date: serverDate,
        type: data.jvHeader.jvType,
        cancel: false,
        check: false,
        cancelReason: "",
        approve: false,
        vNo: data.jvHeader.vNo,
        jvOwner: jvCreator.name,
      })
      .then(async () => {
        if (!jvWithApprove) {
          if (!jvWithCheck) {
            await addLedger(data.jv, id, serverDate);
          }
        }
      })
      .then(() => {
        dispatch({
          type: NEW_JV_SUCCESS,
          payload: {
            jv: {
              jvItems: data.jv,
              date: serverDate,
              type: data.jvHeader.jvType,
              cancel: false,
              check: false,
              cancelReason: "",
              approve: false,
              id: id,
              vNo: data.jvHeader.vNo,
              jvOwner: jvCreator.name,
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: NEW_JV_FAILURE,
          payload: { error: "something is wrong" },
        });
        console.log(error);
      });
  };
};
export const EditJournalVoucher = (data, jvHeader, vNo) => {
  return async (dispatch, getState) => {
    const { jvWithApprove, jvWithCheck } = getState().global;
    dispatch({
      type: EDIT_JV_REQUEST,
    });
    console.log({ data, jvHeader, vNo });
    let date = jvHeader.date;
    if (date instanceof Date) {
      date = new Date(date);
    } else if (!(date instanceof Date) && date instanceof Object) {
      date = new Date(date.toDate());
    }

    let serverDate = firebase.firestore.Timestamp.fromDate(date);

    await firebase
      .firestore()
      .collection(`${jvHeader.jvType}Voucher`)
      .doc(vNo)
      .set({
        ...jvHeader,
        date: serverDate,
        jvItems: data,
        cancel: false,
        check: false,
        cancelReason: "",
        approve: false,
      })
      .then(async () => {
        if (!jvWithApprove) {
          if (!jvWithCheck) {
            await removeLedger(vNo);
          }
        }
      })
      .then(async () => {
        if (!jvWithApprove) {
          if (!jvWithCheck) {
            await addLedger(data, vNo, serverDate);
          }
        }
      })
      .then(() => {
        dispatch({
          type: EDIT_JV_SUCCESS,
          payload: {
            jv: {
              id: vNo,
              date: serverDate,
              jvItems: data,
              ...jvHeader,
            },
          },
        });
      })
      .catch((error) => console.log(error));
  };
};
export const cancelJvRequest = () => {
  return async (dispatch) => {
    dispatch({
      type: CANCEL_JV_SAVING,
    });
  };
};
export const saveJournalVoucher = (jv) => {
  return async (dispatch) => {
    if (jv === "new") {
    } else {
      dispatch({
        type: EDIT_JV_REQUEST,
      });
    }
  };
};
export const deleteJournalVoucher = (vNo, type) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_JV_REQUEST,
    });
    await firebase
      .firestore()
      .collection(`${type}Voucher`)
      .doc(vNo)
      .delete()
      .then((res) => {})
      .then(async () => {
        const idsRef = await firebase
          .firestore()
          .collection("generalLedger")
          .where("id", "==", vNo)
          .get();
        idsRef.docs.map(async (doc, index) => {
          await firebase
            .firestore()
            .collection("generalLedger")
            .doc(doc.id)
            .delete();
        });
      })
      .then(() => {
        dispatch({
          type: DELETE_JV_SUCCESS,
          payload: { id: vNo },
        });
      })
      .catch((err) => {
        dispatch({
          type: DELETE_JV_FAILURE,
          payload: { error: err },
        });
        console.log(err);
      });
  };
};
export const getRequiredJV = (type, vNo) => {
  return async (dispatch) => {
    dispatch({
      type: GET_JV_REQUEST,
    });

    const voucherRef = await firebase
      .firestore()
      .collection(type)
      .doc(vNo)
      .get();

    if (voucherRef.exists) {
      const voucher = await voucherRef.data();
      dispatch({
        type: GET_JV_SUCCESS,
        payload: { jv: voucher },
      });
    } else {
      dispatch({
        type: GET_JV_FAILURE,
        payload: { error: "something is wrong" },
      });
    }
  };
};
export const loadJournalVoucher = (type) => {
  return async (dispatch) => {
    dispatch({
      type: LOAD_JV_REQUEST,
    });

    await firebase
      .firestore()
      .collection(`${type}Voucher`)
      .get()
      .then((voucherRef) => {
        const vouchers = voucherRef.docs.map((d) => {
          return { id: d.id, ...d.data() };
        });
        dispatch({
          type: LOAD_JV_SUCCESS,
          payload: { jv: vouchers },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOAD_JV_FAILURE,
          payload: { error: "something is wrong" + err },
        });
      });
  };
};

export const approveJv = (id, type) => {
  return async (dispatch, getState) => {
    dispatch({
      type: OP_JV_REQUEST,
    });
    const { jvWithApprove } = getState().global;

    const query = firebase.firestore().collection(`${type}Voucher`).doc(id);
    const dbRef = await query.get();
    if (dbRef.exists) {
      const data = dbRef.data();
      await query.update({ approve: !data.approve }).then(async () => {
        let filterDoc = { ...data, approve: !data.approve };
        if (jvWithApprove) {
          if (filterDoc.approve) {
            await addLedger(data.jvItems, id, data.date);
          } else {
            removeLedger(id);
          }
        }
        dispatch({
          type: OP_JV_SUCCESS,
          payload: { jv: { ...filterDoc, id: id } },
        });
      });
    } else {
      dispatch({
        type: OP_JV_FAILURE,
        payload: { error: "something is wrong" },
      });
    }
  };
};
export const checkJv = (id, type) => {
  return async (dispatch, getState) => {
    dispatch({
      type: OP_JV_REQUEST,
    });
    const { jvWithApprove, jvWithCheck } = getState().global;

    const query = firebase.firestore().collection(`${type}Voucher`).doc(id);
    const dbRef = await query.get();
    if (dbRef.exists) {
      const data = dbRef.data();
      await query.update({ check: !data.check }).then(async () => {
        let filterDoc = { ...data, check: !data.check };
        if (!jvWithApprove) {
          if (jvWithCheck) {
            if (filterDoc.check) {
              await addLedger(data.jvItems, id, data.date);
            } else {
              removeLedger(id);
            }
          }
        }
        dispatch({
          type: OP_JV_SUCCESS,
          payload: { jv: { ...filterDoc, id: id } },
        });
      });
    } else {
      dispatch({
        type: OP_JV_FAILURE,
        payload: { error: "something is wrong" },
      });
    }
  };
};
export const cancelJv = (id, reason, type) => {
  return async (dispatch, getState) => {
    dispatch({
      type: OP_JV_REQUEST,
    });
    let filterDoc, status, error;
    const query = firebase.firestore().collection(`${type}Voucher`).doc(id);
    const dbRef = await query.get();
    if (dbRef.exists) {
      const data = dbRef.data();
      if (!data.cancel) {
        await query
          .update({ cancel: !data.cancel, cancelReason: reason })
          .then(() => {
            status = true;
          })
          .catch((err) => {
            error = err;
          });
        filterDoc = { ...data, cancel: !data.cancel, cancelReason: reason };
      } else {
        await query
          .update({ cancel: !data.cancel, cancelReason: reason })
          .then(() => {
            status = true;
          })
          .catch((err) => {
            error = err;
          });
        filterDoc = { ...data, cancel: !data.cancel, cancelReason: "" };
      }
      if (filterDoc.cancel) {
        await removeLedger(id);
      }
      if (filterDoc) {
        dispatch({
          type: OP_JV_SUCCESS,
          payload: { jv: { ...filterDoc, id: id } },
        });
      }
    } else {
      if (error) {
        dispatch({
          type: OP_JV_SUCCESS,
          payload: { error: error },
        });
      }
    }
  };
};

export const addLedger = async (data, id, serverDate) => {
  data.map(async (d) => {
    await firebase
      .firestore()
      .collection(`generalLedger`)
      .doc()
      .set({ ...d, id: id, date: serverDate });
  });
};
const removeLedger = async (vNo) => {
  const idsRef = await firebase
    .firestore()
    .collection("generalLedger")
    .where("id", "==", vNo)
    .get();
  idsRef.docs.map(async (doc, index) => {
    await firebase.firestore().collection("generalLedger").doc(doc.id).delete();
  });
};

export const loadnext = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: LOAD_NEXT_JV,
    });
  };
};
export const loadprev = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: LOAD_PREV_JV,
    });
  };
};
export const loadfirst = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: LOAD_FIRST_JV,
    });
  };
};
export const loadlast = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: LOAD_LAST_JV,
    });
  };
};
