import React, { useState, useEffect } from "react";
import { Navbar, Nav, NavDropdown, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { themeToggle } from "../action/globalAction";
import { logout } from "../action/signup";
const Header = (props) => {
  const [checked, setChecked] = useState(false);
  const [headerItem, setHeaderItems] = useState([]);
  const [theme, setTheme] = useState({});
  const [color, setColor] = useState("");
  const [role, setRole] = useState("");
  const themRed = useSelector((state) => state.global);
  const userRed = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const handleCheckedSwitch = () => {
    dispatch(themeToggle());
    setChecked(!checked);
  };

  useEffect(() => {
    setChecked(themRed.isDarkTheme);
    if (themRed.isDarkTheme) {
      setTheme(themRed.blackTheme);
      setColor(themRed.blackThemecolor);
    } else {
      setTheme(themRed.whiteTheme);
      setColor(themRed.whiteThemecolor);
    }
  }, [themRed]);
  useEffect(() => {
    loadHeaderComs();
  }, [userRed]);
  const loadHeaderComs = async () => {
    let rights = [];
    if (userRed.user && Object.keys(userRed.user).length > 0) {
      if (userRed.user.right) {
        let myRights = userRed.user.right;
        Object.keys(myRights).map((key) => {
          rights.push({ ...myRights[key], docId: key });
        });
        const unsort = createRouteHierarchy(rights);

        const sortList = unsort.sort(function (a, b) {
          return a.no - b.no;
        });

        setHeaderItems(sortList);
      }
      setRole(userRed.user.role);
    }
  };

  if (userRed.user && Object.keys(userRed.user).length > 0) {
    return (
      <Navbar
        variant={theme}
        bg={theme}
        expand="lg"
        className="justify-content-between"
      >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <Nav>
            {headerItem.length > 0 &&
              headerItem.map((item, p) => {
                let visible = item.right.visible;
                if (role === "superadmin") {
                  visible = true;
                }
                if (item.children.length > 0) {
                  if (visible) {
                    const sortChild = item.children.sort(function (a, b) {
                      return a.no - b.no;
                    });
                    return (
                      <NavDropdown
                        title={item.title && item.title.toUpperCase()}
                        disabled={
                          item.right.enable || role === "superadmin"
                            ? false
                            : true
                        }
                        menuVariant={theme}
                      >
                        {sortChild.map((child, f1) => {
                          if (child.children.length > 0) {
                            const sortgrand = child.children.sort(function (a, b) {
                              return a.no - b.no;
                            });
                            let chvisible = child.right.visible
                              ? child.right.visible
                              : false;
                            if (role === "superadmin") {
                              chvisible = true;
                            }
                            if (chvisible) {
                              return (
                                <>
                                  <NavDropdown
                                    title={
                                      child.title && child.title.toUpperCase()
                                    }
                                    drop={"end"}
                                    disabled={
                                      child.right.enable ||
                                      role === "superadmin"
                                        ? false
                                        : true
                                    }
                                    menuVariant={theme}
                                  >
                                    {sortgrand.map((grand, f2) => {
                                      let grandVisible = grand.right.visible;
                                      if (role === "superadmin") {
                                        grandVisible = true;
                                      }
                                      if (grandVisible) {
                                        return (
                                          <>
                                            <NavDropdown.Item
                                              as={Link}
                                              to={`${grand.href}`}
                                              disabled={
                                                grand.right.enable ||
                                                role === "superadmin"
                                                  ? false
                                                  : true
                                              }
                                            >
                                              <Nav.Item>
                                                {grand.title &&
                                                  grand.title.toUpperCase()}
                                              </Nav.Item>
                                            </NavDropdown.Item>
                                            {f2 ===
                                            child.children.length - 1 ? null : (
                                              <NavDropdown.Divider />
                                            )}
                                          </>
                                        );
                                      }
                                    })}
                                  </NavDropdown>
                                  {f1 === item.children.length - 1 ? null : (
                                    <NavDropdown.Divider />
                                  )}
                                </>
                              );
                            }
                          } else {
                            let childVisible = child.right.visible;
                            if (role === "superadmin") {
                              childVisible = true;
                            }
                            if (childVisible) {
                              return (
                                <>
                                  <NavDropdown.Item
                                    as={Link}
                                    to={`${child.href}`}
                                    disabled={
                                      child.right.enable ||
                                      role === "superadmin"
                                        ? false
                                        : true
                                    }
                                  >
                                    <Nav.Item>
                                      {child.title && child.title.toUpperCase()}
                                    </Nav.Item>
                                  </NavDropdown.Item>
                                  {f1 !== item.children.length - 1 ? (
                                    <NavDropdown.Divider />
                                  ) : null}
                                </>
                              );
                            }
                          }
                        })}
                      </NavDropdown>
                    );
                  }
                } else {
                  if (visible) {
                    return (
                      <Nav.Link
                        as={Link}
                        to={`${item.href}`}
                        disabled={
                          item.right.enable || role === "superadmin"
                            ? false
                            : true
                        }
                      >
                        <Nav.Item>
                          {item.title && item.title.toUpperCase()}
                        </Nav.Item>
                      </Nav.Link>
                    );
                  }
                }
              })}

            {userRed.auth && Object.keys(userRed.user).length > 0 && (
              <NavDropdown
                title={userRed.user.name && userRed.user.name.toUpperCase()}
                drop={"down"}
                menuVariant={theme}
              >
                {userRed.auth && (
                  <NavDropdown.Item
                    onClick={() => {
                      dispatch(logout());
                    }}
                    as={Link}
                    to="/login"
                  >
                    LOGOUT
                  </NavDropdown.Item>
                )}
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  } else {
    return (
      <Navbar variant={theme} bg={theme} className="justify-content-between">
        <Nav.Item>Loading</Nav.Item>
      </Navbar>
    );
  }
};

export default Header;
const linkValue = {
  textDecoration: "none",
  color: "black",
  padding: "3px",
  marginTop: "10px",
  marginLeft: "10px",
};
function createRouteHierarchy(allLevels, parentId = null) {
  const LevelList = [];
  let catgory;
  if (parentId === null) {
    catgory = allLevels.filter((f) => f.parentId == undefined);
  } else {
    catgory = allLevels.filter((f) => f.parentId == parentId);
  }
  for (let cat of catgory) {
    LevelList.push({
      ...cat,
      children: createRouteHierarchy(allLevels, cat.docId),
    });
  }
  return LevelList;
}
