import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as invoiceActions from "../action/invoiceAction";
import * as itemActions from "../action/itemAction";
import * as coaActions from "../action/coaAction";
import ItemLedgerSearch from "../components/general/ItemLedgerSearch";
import PartyWiseItemWise from "../components/SalesCom/PartyWiseItemWise";
import ItemWise from "../components/SalesCom/ItemWise";
import InvoiceWise from "../components/SalesCom/InvoiceWise";
import { Form, Button } from "react-bootstrap";
import CustomDropDown from "../subcom/CustomDropDown";
import PartyWise from "../components/SalesCom/PartyWise";
import GroupingOption from "../components/SalesCom/GroupingOption";
import moment from "moment";
import OffcanvasCom from "../subcom/OffcanvasCom";
const SalesReport = (props) => {
  const reportParam = props.match.params.type;
  const invoiceRed = useSelector((state) => state.invoice);
  const coaRed = useSelector((state) => state.coa);
  const itemRed = useSelector((state) => state.item);
  const catAndTypesRed = useSelector((state) => state.catAndTypes);
  const globalRed = useSelector((state) => state.global);
  const [salesReport1, setSaleReport1] = useState([]);
  const [salesReport2, setSaleReport2] = useState([]);
  const [salesReport3, setSaleReport3] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [partyWise, setPartyWise] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [show, setShow] = useState("");
  const [rDate, setRDate] = useState({
    start: "",
    end: "",
  });
  const [sType, setSType] = useState({ docId: 0, name: "All types" });
  const [itemList, setItemList] = useState([]);
  const [sItem, setSItem] = useState({ docId: 0, name: "" });
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [sCustomer, setSCustomer] = useState({ docId: 0, name: "" });
  const [sCategory, setSCategory] = useState({
    docId: 0,
    name: "All Categories",
  });
  const dispatch = useDispatch();
  const [options, setOptions] = useState({
    customer: true,
    type: true,
    category: true,
  });
  useEffect(() => {
    if (show !== "") {
      handleReportChangeType(show);
    }
  }, [sCategory, sCustomer, sItem, sType, rDate]);
  useEffect(() => {
    dispatch(coaActions.getAllLevel());
    dispatch(invoiceActions.loadInvoiceByType(reportParam));
    dispatch(itemActions.loadItems());
    dispatch(itemActions.loadTypesCategories());
    document.title = reportParam + "-" + "Report";
  }, [reportParam]);
  
  const [reportRight, setReportRight] = useState({});
  useEffect(() => {
    setReportRight(props.rights);
  }, []);
  useEffect(async () => {
    setItemList([{ docId: 0, name: "All Items" }, ...itemRed.item]);
    const customers = await loadCustomerOrSupplier();
    setCustomerData([{ docId: 0, name: "All Customer" }, ...customers]);
    setCategoryList([
      { docId: 0, name: "All Categories" },
      ...catAndTypesRed.categories,
    ]);
    setTypeList([{ docId: 0, name: "All Types" }, ...catAndTypesRed.types]);
    setShow("");
    setSaleReport1([]);
    setSaleReport2([]);
    setSaleReport3([]);
    setPartyWise([]);
    setOptions({
      customer: true,
      type: true,
      category: true,
    });
    setSCustomer({ docId: 0, name: "All Customer" });
    setSItem({ docId: 0, name: "All Items" });
    setSCategory({ docId: 0, name: "All Categories" });
    setSType({ docId: 0, name: "All Types" });
  }, [coaRed, itemRed, catAndTypesRed]);
  useEffect(() => {
    if (globalRed.date !== "") {
      let endDate = new Date().toISOString().slice(0, 10);
      let endFormat = moment(endDate).format("YYYY-MM-DD");
      setRDate({ start: globalRed.date, end: endFormat });
    }
  }, [globalRed]);
  const loadCustomerOrSupplier = () => {
    let data = [];
    let custOrSupp;
    if (reportParam === "Sell") {
      custOrSupp = coaRed.allLevels.filter((f) => f.type === "ct");
    } else {
      custOrSupp = coaRed.allLevels.filter((f) => f.type === "sp");
    }
    custOrSupp.map((level1) => {
      if (level1.children && level1.children.length > 0) {
        level1.children.map((level2) => {
          if (level2.children && level2.children.length > 0) {
            level2.children.map((level3) => {
              data.push(level3);
            });
          }
        });
      }
    });
    return data;
  };

  const loadData = () => {
    if (rDate.start !== "" && rDate.end !== "") {
      let categoryGroup = [];
      if (sCategory.docId === 0) {
        categoryGroup = [...catAndTypesRed.categories];
      } else {
        const filterCategories = catAndTypesRed.categories.filter(
          (f) => f.docId === sCategory.docId
        );
        categoryGroup = [...filterCategories];
      }
      if (sType.docId === 0) {
        categoryGroup.forEach((cat, index) => {
          const filteredTypes = typeList.filter(
            (f) => f.parentDocId === cat.docId
          );
          categoryGroup[index] = {
            ...categoryGroup[index],
            types: filteredTypes,
          };
        });
      } else {
        const filteredTypes = typeList.filter((f) => f.docId === sType.docId);
        categoryGroup.forEach((cat, index) => {
          if (filteredTypes[0].parentDocId === cat.docId) {
            categoryGroup[index] = {
              ...categoryGroup[index],
              types: filteredTypes,
            };
          }
        });
      }
      let customerWiseGrouping = [];
      if (sCustomer.docId === 0) {
        const customers = loadCustomerOrSupplier();

        customerWiseGrouping = [...customers];
      } else {
        const customers = loadCustomerOrSupplier();
        const filterCustomer = customers.filter(
          (f) => f.docId === sCustomer.docId
        );
        customerWiseGrouping = [...filterCustomer];
      }

      let invoices = invoiceRed.invoices;

      let s = new Date(rDate.start).toISOString().slice(0, 10);
      let e = new Date(rDate.end).toISOString().slice(0, 10);
      let data = [];
      customerWiseGrouping.map((cus) => {
        let category = [];
        invoices.forEach((f) => {
          let dbDate = new Date(f.date.toDate());
          let check = new Date(dbDate).toISOString().slice(0, 10);
          if (check >= s && check <= e) {
            if (f.ledgerId === cus.docId) {
              categoryGroup.map((cat, i1) => {
                let types = [];
                let sel;
                if (cat.types && cat.types.length > 0) {
                  cat.types.map((type, i2) => {
                    sel = type;
                    let items = [];
                    if (sItem.docId === 0) {
                      f.items.map((item) => {
                        const findItem = itemList.find(
                          (f) => f.docId === item.id
                        );
                        if (findItem) {
                          if (findItem.type === type.docId) {
                            items.push({
                              date: f.date,
                              invoiceId: f.invoiceId,
                              name: item.name,
                              quantity: item.quantity,
                              total: item.total,
                              rate: item.rate,
                            });
                          }
                        }
                      });
                    } else {
                      const findItem = itemList.find(
                        (f) => f.docId === sItem.docId
                      );
                      if (findItem) {
                        if (findItem.type === type.docId) {
                          f.items.map((item) => {
                            if (findItem.docId === item.id) {
                              items.push({
                                date: f.date,
                                invoiceId: f.invoiceId,
                                name: item.name,
                                quantity: item.quantity,
                                total: item.total,
                                rate: item.rate,
                              });
                            }
                          });
                        }
                      }
                    }

                    if (items.length > 0) {
                      types.push({
                        ...sel,
                        items,
                      });
                    }
                  });
                }
                if (types.length > 0) {
                  category.push({ ...cat, types });
                }
              });
            }
          }
        });
        if (category.length > 0) {
          data.push({
            name: cus.title,
            docId: cus.docId,
            category,
          });
        }
      });

      setSaleReport1(data);
    }
  };
  const handleStartDate = (e) => {
    const val = e.target.value;
    setRDate({ ...rDate, start: val });
  };
  const handleEndDate = (e) => {
    const val = e.target.value;
    setRDate({ ...rDate, end: val });
  };
  const selectedCustomer = (docId) => {
    if (docId === 0) {
      setSCustomer({ docId: 0, name: "All Customer" });
    } else {
      const select = customerData.find((f) => f.docId === docId);
      setSCustomer({ docId: select.docId, name: select.title });
    }
    if (show !== "") {
      handleReportChangeType(show);
    }
  };
  const onItemSelected = (docId) => {
    if (docId === 0) {
      setSItem({ docId: 0, name: "All Items" });
    } else {
      const select = itemRed.item.find((f) => f.docId === docId);
      setSItem({ docId: select.docId, name: select.name });
    }
  };
  const loadDataByItems = () => {
    if (rDate.start !== "" && rDate.end !== "") {
      let categoryGroup = [];
      if (sCategory.docId === 0) {
        categoryGroup = [...catAndTypesRed.categories];
      } else {
        const filterCategories = catAndTypesRed.categories.filter(
          (f) => f.docId === sCategory.docId
        );
        categoryGroup = [...filterCategories];
      }
      if (sType.docId === 0) {
        let cats = [];
        categoryGroup.forEach((cat, index) => {
          const filteredTypes = typeList.filter(
            (f) => f.parentDocId === cat.docId
          );
          if (filteredTypes.length > 0) {
            cats.push({
              ...cat,
              types: filteredTypes,
            });
          }
        });
        categoryGroup = cats;
      } else {
        const filteredTypes = typeList.filter((f) => f.docId === sType.docId);
        let cats = [];
        categoryGroup.forEach((cat, index) => {
          if (filteredTypes[0].parentDocId === cat.docId) {
            cats.push({
              ...cat,
              types: filteredTypes,
            });
          }
        });
        categoryGroup = cats;
      }
      if (sItem.docId === 0) {
        categoryGroup.forEach((cat, index) => {
          let types = [];
          if (cat.types && cat.types.length > 0) {
            cat.types.forEach((type, index2) => {
              let items = [];
              let sel;
              const filterItems = itemList.filter((f) => f.type === type.docId);
              if (filterItems.length > 0) {
                filterItems.map((item) => {
                  items.push({
                    name: item.name,
                    unit: item.uom,
                    quantity: 0,
                    amount: 0,
                    rate: 0,
                    total: 0,
                    docId: item.docId,
                  });
                });
              }
              sel = type;
              if (filterItems.length > 0) {
                types.push({
                  ...sel,
                  items,
                });
              }
            });
          }
          if (types.length > 0) {
            categoryGroup[index] = {
              ...cat,
              types,
            };
          }
        });
      } else {
        categoryGroup.forEach((cat, index) => {
          let types = [];
          if (cat.types && cat.types.length > 0) {
            cat.types.forEach((type, index2) => {
              let items = [];
              let sel;
              const filterItems = itemList.filter((f) => f.type === type.docId);
              if (filterItems.length > 0) {
                filterItems.map((item) => {
                  if (sItem.docId === item.docId) {
                    items.push({
                      name: item.name,
                      unit: item.unit,
                      quantity: 0,
                      amount: 0,
                      rate: 0,
                      total: 0,
                      docId: item.docId,
                    });
                  }
                });
              }
              sel = type;
              if (items.length > 0) {
                types.push({
                  ...sel,
                  items,
                });
              }
            });
          }
          if (types.length > 0) {
            categoryGroup[index] = {
              ...cat,
              types,
            };
          }
        });
      }

      console.log({ categoryGroup });
      let invoices = invoiceRed.invoices;

      let s = new Date(rDate.start).toISOString().slice(0, 10);
      let e = new Date(rDate.end).toISOString().slice(0, 10);

      invoices.forEach((f) => {
        let dbDate = new Date(f.date.toDate());
        let check = new Date(dbDate).toISOString().slice(0, 10);
        if (check >= s && check <= e) {
          f.items.map((item) => {
            categoryGroup.map((cat, i1) => {
              if (cat.types && cat.types.length > 0) {
                cat.types.map((type, i2) => {
                  if (type.items && type.items.length > 0) {
                    let index = type.items.findIndex(
                      (f) => f.docId === item.id
                    );

                    if (index !== -1) {
                      let quantity =
                        parseInt(type.items[index].quantity) +
                        parseInt(item.quantity);

                      let amount =
                        parseFloat(type.items[index].amount) +
                        parseFloat(item.rate);
                      let total =
                        parseFloat(type.items[index].total) +
                        parseFloat(item.total);

                      type.items[index] = {
                        name: type.items[index].name,
                        unit: type.items[index].unit,
                        quantity: quantity,
                        amount: amount,
                        rate: total / quantity,
                        total: total,
                        docId: type.items[index].docId,
                      };
                    }
                  }
                });
              }
            });
          });
        }
      });
      let filteredCats = [];
      categoryGroup.map((cat) => {
        let filterTypes = [];
        if (cat.types && cat.types.length > 0) {
          cat.types.map((type) => {
            let typeQuantity = 0;
            let items = [];
            if (type.items && type.items.length > 0) {
              type.items.map((item) => {
                if (parseInt(item.quantity) > 0) {
                  items.push(item);
                  typeQuantity = typeQuantity + parseInt(item.quantity);
                  console.log(typeQuantity);
                }
              });
            }
            if (typeQuantity > 0 && items.length > 0) {
              console.log({ type });
              filterTypes.push({ ...type });
            }
          });
        }
        if (filterTypes.length > 0) {
          filteredCats.push({ ...cat, types: filterTypes });
        }
      });
      console.log(filteredCats);

      setSaleReport2(filteredCats);
    }
  };
  const loadDataByPartyWise = () => {
    if (rDate.start !== "" && rDate.end !== "") {
      let data = [];

      let invoices = invoiceRed.invoices;

      let s = new Date(rDate.start).toISOString().slice(0, 10);
      let e = new Date(rDate.end).toISOString().slice(0, 10);
      if (sCustomer.docId === "" || sCustomer.docId === 0) {
        customerData.map((cus) => {
          const filterInvoice = invoices.filter(
            (f) => f.ledgerId === cus.docId
          );
          let gross = 0,
            tax = 0,
            discount = 0,
            grand = 0,
            other = 0,
            party = "",
            date = "";
          if (filterInvoice.length > 0) {
            filterInvoice.forEach((f) => {
              let dbDate = new Date(f.date.toDate());
              let check = new Date(dbDate).toISOString().slice(0, 10);
              if (check >= s && check <= e) {
                date = f.date;

                party = f.party;
                gross = gross + parseFloat(f.gross);
                tax = tax + parseFloat(f.tax);
                discount = discount + parseFloat(f.discount);
                grand = grand + parseFloat(f.grand);
                other = other + parseFloat(f.other);
              }
            });
            data.push({
              date: date,
              party: party,
              gross: gross,
              tax: tax,
              discount: discount,
              grand: grand,
              advance: other,
              balance: 0,
            });
          }
        });
      } else {
        const filterInvoice = invoices.filter((f) => {
          if (f.ledgerId === sCustomer.docId) {
            let dbDate = new Date(f.date.toDate());
            let check = new Date(dbDate).toISOString().slice(0, 10);
            if (check >= s && check <= e) {
              return f;
            }
          }
        });

        if (filterInvoice.length > 0) {
          let gross = 0,
            tax = 0,
            discount = 0,
            grand = 0,
            other = 0,
            party = "",
            date = "";
          filterInvoice.forEach((f) => {
            let dbDate = new Date(f.date.toDate());
            let check = new Date(dbDate).toISOString().slice(0, 10);
            if (check >= s && check <= e) {
              date = f.date;
              other = other + parseFloat(f.other);
              party = f.party;
              gross = gross + parseFloat(f.gross);
              tax = tax + parseFloat(f.tax);
              discount = discount + parseFloat(f.discount);
              grand = grand + parseFloat(f.grand);
            }
          });
          data.push({
            date: date,
            party: party,
            gross: gross,
            tax: tax,
            discount: discount,
            grand: grand,
            advance: other,
            balance: 0,
          });
        }
      }

      setPartyWise(data);

      //     setSaleReport3(data);
    }
  };
  const loadDataByInvoiceWise = () => {
    if (rDate.start !== "" && rDate.end !== "") {
      let data = [];

      let invoices = invoiceRed.invoices;

      let s = new Date(rDate.start).toISOString().slice(0, 10);
      let e = new Date(rDate.end).toISOString().slice(0, 10);
      if (sCustomer.docId === "" || sCustomer.docId === 0) {
        invoices.forEach((f) => {
          let dbDate = new Date(f.date.toDate());
          let check = new Date(dbDate).toISOString().slice(0, 10);
          if (check >= s && check <= e) {
            data.push({
              date: f.date,
              invoiceId: f.invoiceId,
              party: f.party,
              gross: f.gross,
              tax: f.tax,
              discount: f.discount,
              grand: f.grand,
              advance: f.other,
              balance: 0,
            });
          }
        });
      } else {
        invoices.forEach((f) => {
          let dbDate = new Date(f.date.toDate());
          let check = new Date(dbDate).toISOString().slice(0, 10);
          if (check >= s && check <= e) {
            if (f.ledgerId === sCustomer.docId) {
              data.push({
                date: f.date,
                invoiceId: f.invoiceId,
                party: f.party,
                gross: f.gross,
                tax: f.tax,
                discount: f.discount,
                grand: f.grand,
                advance: f.other,
                balance: 0,
              });
            }
          }
        });
      }

      setSaleReport3(data);
    }
  };
  const onCategorySelected = (cId) => {
    let name = categoryList.find((f) => f.docId === cId);
    setSCategory({ docId: cId, name: name.name });
    setSType({ docId: 0, name: "All Types" });
    setSItem({ docId: 0, name: "All Items" });
    if (cId === 0) {
      setTypeList([{ docId: 0, name: "All Types" }, ...catAndTypesRed.types]);
      setItemList([{ docId: 0, name: "All Items" }, ...itemRed.item]);
    } else {
      const filteredTypes = catAndTypesRed.types.filter(
        (f) => f.parentDocId === cId
      );
      setTypeList([{ docId: 0, name: "All Types" }, ...filteredTypes]);
      let items = [];
      filteredTypes.forEach((type) => {
        const filteredItems = itemRed.item.filter((f) => f.type === type.docId);
        if (filteredItems.length > 0) {
          items = [...items, ...filteredItems];
        }
      });
      setItemList([{ docId: 0, name: "All Items" }, ...items]);
    }
    if (show !== "") {
      handleReportChangeType(show);
    }
  };
  const onTypeSelected = (tId) => {
    let selectedType = typeList.find((f) => f.docId === tId);
    setSType({ docId: tId, name: selectedType.name });
    setSItem({ docId: 0, name: "All Items" });
    if (tId === 0) {
    } else {
      const filteredItems = itemRed.item.filter((f) => f.type === tId);

      setItemList([{ docId: 0, name: "All Items" }, ...filteredItems]);
    }

    if (show !== "") {
      handleReportChangeType(show);
    }
  };
  const handlePrintInfo = () => {
    let report = [];
    if (show === "itemWise") {
      report = salesReport2;
    }
    if (show === "partyWise") {
      report = partyWise;
    }
    if (show === "invoiceWise") {
      report = salesReport3;
    }
    if (show === "itemWisePartyWise") {
      report = salesReport1;
    }

    localStorage.setItem(
      "printOption",
      JSON.stringify({
        customer: sCustomer,
        rDate,
        reportType: show,
        report,
        options,
        reportParam,
      })
    );
  };
  const handleReportChangeType = (val) => {
    setOptions({
      customer: true,
      type: true,
      category: true,
    });
    setShow(val);
    if (val === "itemWise") {
      loadDataByItems();
    }
    if (val === "partyWise") {
      loadDataByPartyWise();
    }
    if (val === "invoiceWise") {
      loadDataByInvoiceWise();
    }
    if (val === "itemWisePartyWise") {
      loadData();
    }
  };
  const handleCheckValue = (e) => {
    const val = e.target.value;
    if (val === "customer") {
      setOptions({ ...options, customer: !options.customer });
    }
    if (val === "category") {
      setOptions({ ...options, category: !options.category });
    }
    if (val === "type") {
      setOptions({ ...options, type: !options.type });
    }
  };
  if (coaRed.loading || itemRed.loading || catAndTypesRed.loading) {
    return <div>loading</div>;
  } else {
    return (
      <Layout>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <OffcanvasCom
            show={showOffCanvas}
            handleOffcanvas={() => setShowOffCanvas(!showOffCanvas)}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ width: "100%", margin: "3px" }}>
                {customerData.length > 0 && (
                  <CustomDropDown
                    list={customerData}
                    label={sCustomer.name}
                    selectedItem={(id) => selectedCustomer(id)}
                  />
                )}
              </div>
              <div style={{ width: "100%", margin: "3px" }}>
                {categoryList.length > 0 && (
                  <CustomDropDown
                    list={categoryList}
                    label={sCategory.name}
                    selectedItem={(docId) => onCategorySelected(docId)}
                  />
                )}
              </div>
              <div style={{ width: "100%", margin: "3px" }}>
                {typeList.length > 0 && (
                  <CustomDropDown
                    list={typeList}
                    label={sType.name}
                    selectedItem={(docId) => onTypeSelected(docId)}
                  />
                )}
              </div>
              <div style={{ width: "100%", margin: "3px" }}>
                <ItemLedgerSearch
                  itemList={itemList}
                  label={sItem.name}
                  selectedItem={(id) => onItemSelected(id)}
                  handleStartDate={handleStartDate}
                  handleEndDate={handleEndDate}
                  start={rDate.start}
                  end={rDate.end}
                  check="hide"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Form.Check
                type="radio"
                label="Item Wise"
                value="itemWise"
                name="reportType"
                checked={show === "itemWise" ? true : false}
                onChange={(e) => handleReportChangeType(e.target.value)}
              />
              <Form.Check
                type="radio"
                label="Party Wise"
                value="partyWise"
                name="reportType"
                checked={show === "partyWise" ? true : false}
                onChange={(e) => handleReportChangeType(e.target.value)}
              />
              <Form.Check
                type="radio"
                label="Invoice Wise"
                value="invoiceWise"
                name="reportType"
                checked={show === "invoiceWise" ? true : false}
                onChange={(e) => handleReportChangeType(e.target.value)}
              />

              <Form.Check
                type="radio"
                label="Item Wise Party Wise"
                value="itemWisePartyWise"
                name="reportType"
                checked={show === "itemWisePartyWise" ? true : false}
                onChange={(e) => handleReportChangeType(e.target.value)}
              />
              {show !== "" &&
              rDate.start !== "" &&
              rDate.end !== "" &&
              reportRight.print ? (
                <Button onClick={handlePrintInfo}>
                  <Link
                    to={`/printreport/q`}
                    target="_blank"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Print
                  </Link>
                </Button>
              ) : null}
            </div>
          </OffcanvasCom>
          <h4>{reportParam} Report</h4>
        </div>

        {show === "itemWise" || show === "itemWisePartyWise" ? (
          <GroupingOption
            check={show}
            handleCheckValue={handleCheckValue}
            options={options}
          />
        ) : null}
        <div
          style={{
            boxShadow: "0 4px 4px 0 rgba(0,0,0,0.2)",
            marginTop: "20px",
            backgroundColor: "white",
            borderRadius: "10px",
          }}
        >
          {show === "itemWise" ? (
            <ItemWise
              party={sCustomer.name}
              reportName="Item Wise"
              date={rDate}
              salesReport={salesReport2}
              options={options}
            />
          ) : null}
          {show === "itemWisePartyWise" ? (
            <PartyWiseItemWise
              party={sCustomer.name}
              reportName="Item Wise Party Wise"
              salesReport={salesReport1}
              date={rDate}
              options={options}
            />
          ) : null}
          {show === "invoiceWise" ? (
            <InvoiceWise
              party={sCustomer.name}
              reportName="Invoice Wise"
              salesReport={salesReport3}
              date={rDate}
            />
          ) : null}
          {show === "partyWise" ? (
            <PartyWise
              party={sCustomer.name}
              reportName="Party Wise"
              salesReport={partyWise}
              date={rDate}
            />
          ) : null}
        </div>
      </Layout>
    );
  }
};

export default SalesReport;
