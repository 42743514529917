import React, { useState, useEffect } from "react";
import { Card, Tabs, Tab, Button } from "react-bootstrap";
import Layout from "../../components/Layout";
import Customer from "../../components/accountCart/Customer";
import IncomeTab from "../../components/accountCart/IncomeTab";
import LiabilityTab from "../../components/accountCart/LiabilityTab";
import OwnerEquity from "../../components/accountCart/OwnerEquity";
import AssestTab from "../../components/accountCart/AssestTab";
import Expenses from "../../components/accountCart/Expenses";
import { Link } from "react-router-dom";
import * as coaActions from "../../action/coaAction";
import { useDispatch, useSelector } from "react-redux";
import Supplier from "../../components/accountCart/SupplierTab";
import "../../Css/mediaStyle.css";
const COA = (props) => {
  const dispatch = useDispatch();
  const userRed = useSelector((state) => state.user);
  const [activeTab, setActiveTab] = useState();
  const { aTab } = props.match.params;
  const [rights, setRights] = useState({});
  useEffect(() => {
    dispatch(coaActions.getAllLevel());
    setRights(props.rights);
  }, []);
 
  useEffect(() => {
    document.title = "COA/" + aTab.toUpperCase();
    setActiveTab(aTab);
  }, [aTab]);


  return (
    <Layout>
      <div
        style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", marginTop: "20px" }}
      >
        <Card>
          <Tabs activeKey={activeTab} style={{ margin: "20px" }}>
            <Tab
              eventKey="ownequities"
              title={
                <LinkCom
                  link={"/chartofaccount/ownequities"}
                  title={"Owner Equity"}
                />
              }
            >
              {activeTab === "ownequities" && <OwnerEquity right={rights} />}
            </Tab>

            <Tab
              eventKey="liabilities"
              title={
                <LinkCom
                  link={"/chartofaccount/liabilities"}
                  title={"Liabilities"}
                />
              }
            >
              {activeTab === "liabilities" && <LiabilityTab right={rights} />}
            </Tab>
            <Tab
              eventKey="supplier"
              title={
                <LinkCom link={"/chartofaccount/supplier"} title={"Supplier"} />
              }
            >
              {activeTab === "supplier" && <Supplier right={rights} />}
            </Tab>
            <Tab
              eventKey="client"
              title={
                <LinkCom link={"/chartofaccount/client"} title={"Client"} />
              }
            >
              {activeTab === "client" && <Customer right={rights} />}
            </Tab>
            <Tab
              eventKey="assets"
              title={
                <LinkCom link={"/chartofaccount/assets"} title={"Assets"} />
              }
            >
              {activeTab === "assets" && <AssestTab right={rights} />}
            </Tab>
            <Tab
              eventKey="incomes"
              title={
                <LinkCom link={"/chartofaccount/incomes"} title={"Incomes"} />
              }
            >
              {activeTab === "incomes" && <IncomeTab right={rights} />}
            </Tab>
            <Tab
              eventKey="expenses"
              title={
                <LinkCom link={"/chartofaccount/expenses"} title={"Expenses"} />
              }
            >
              {activeTab === "expenses" && <Expenses right={rights} />}
            </Tab>
          </Tabs>
        </Card>
      </div>
    </Layout>
  );
};

export default COA;
const LinkCom = (props) => {
  return (
    <Link to={props.link} style={{ textDecoration: "none" }}>
      <Button className="btnclass gradient-button-4">{props.title}</Button>
    </Link>
  );
};
