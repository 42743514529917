import React from "react";
import "../../Css/homeStyle.css";
import { Card, Button, Badge } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { MdNotifications } from "react-icons/md";
import { BsPersonFill } from "react-icons/bs";
import PieChart from "../../components/charts/PieChart";
import BarChart from "../../components/charts/BarChart";
import DoughnutChart from "../charts/DoughnutChart";
import LineChart from "../charts/LineChart";
const HomeDetails = (props) => {
  return (
    <div>
      <div className="centerContainer">
        <div className="rightside1">
          <div>Dashbord</div>
          <div className="searchQuery">
            <span>
              <input placeholder="Search" className="searchInput" />
              <FiSearch />
            </span>

            <div className="position-relative">
              <MdNotifications
                className="badge-div"
                style={{ width: "25px", height: "25px" }}
              />
              <span
                style={{ fontSize: "10px" }}
                className="position-absolute top-0 start-100 translate-middle  bg-primary border border-light rounded-circle"
              >
                99+
                <span className="visually-hidden">unread messages</span>
              </span>
            </div>

            <span>
              <BsPersonFill></BsPersonFill>
            </span>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-3">
            <Card style={{ width: "100%" }}>
              <Card.Body>
                <Card.Title>Special title treatment</Card.Title>
                <Card.Text>
                  With supporting text below as a natural lead-in to additional
                  content.
                </Card.Text>
                <Button variant="primary">Go somewhere</Button>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-3">
            {" "}
            <Card>
              <Card.Body>
                <Card.Title>Special title treatment</Card.Title>
                <Card.Text>
                  With supporting text below as a natural lead-in to additional
                  content.
                </Card.Text>
                <Button variant="primary">Go somewhere</Button>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-3">
            {" "}
            <Card>
              <Card.Body>
                <Card.Title>Special title treatment</Card.Title>
                <Card.Text>
                  With supporting text below as a natural lead-in to additional
                  content.
                </Card.Text>
                <Button variant="primary">Go somewhere</Button>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-3">
            {" "}
            <Card>
              <Card.Body>
                <Card.Title>Special title treatment</Card.Title>
                <Card.Text>
                  With supporting text below as a natural lead-in to additional
                  content.
                </Card.Text>
                <Button variant="primary">Go somewhere</Button>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="adjust-chart-div">
          <Card className="card-div">
            <Card.Body className="card-body-div">
              <div className="adjust-chart">
                <PieChart />
              </div>
              <div style={{ height: "20%", width: "100%" }}>
                <Card.Title>Bar Chart</Card.Title>
                <Card.Text>Bar Chart Information</Card.Text>
              </div>
            </Card.Body>
          </Card>
          <Card className="card-div">
            <Card.Body className="card-body-div">
              <div className="adjust-chart">
                <BarChart />
              </div>
              <div style={{ height: "20%", width: "100%" }}>
                <Card.Title>Bar Chart</Card.Title>
                <Card.Text>Bar Chart Information</Card.Text>
              </div>
            </Card.Body>
          </Card>
          <Card className="card-div">
            <Card.Body className="card-body-div">
              <div className="adjust-chart">
                <LineChart />
              </div>
              <div style={{ height: "20%", width: "100%" }}>
                <Card.Title>Bar Chart</Card.Title>
                <Card.Text>Bar Chart Information</Card.Text>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default HomeDetails;
