import firebase from 'firebase';
export const LOAD_ITEM_REQUEST = 'LOAD_ITEM_REQUEST'
export const LOAD_ITEM_SUCCESS = 'LOAD_ITEM_SUCCESS'
export const LOAD_ITEM_FAILURE = 'LOAD_ITEM_FAILURE'

export const ADD_TYPE_REQUEST = 'ADD_TYPE_REQUEST'
export const ADD_TYPE_SUCCESS = 'ADD_TYPE_SUCCESS'
export const ADD_TYPE_FAILURE = 'ADD_TYPE_FAILURE'

export const EDIT_TYPE_REQUEST = 'EDIT_TYPE_REQUEST'
export const EDIT_TYPE_SUCCESS = 'EDIT_TYPE_SUCCESS'
export const EDIT_TYPE_FAILURE = 'EDIT_TYPE_FAILURE'

export const DELETE_TYPE_REQUEST = 'DELETE_TYPE_REQUEST'
export const DELETE_TYPE_SUCCESS = 'DELETE_TYPE_SUCCESS'
export const DELETE_TYPE_FAILURE = 'DELETE_TYPE_FAILURE'

export const ADD_CATEGORY_REQUEST = 'ADD_CATEGORY_REQUEST'
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS'
export const ADD_CATEGORY_FAILURE = 'ADD_CATEGORY_FAILURE'

export const EDIT_CATEGORY_REQUEST = 'EDIT_CATEGORY_REQUEST'
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS'
export const EDIT_CATEGORY_FAILURE = 'EDIT_CATEGORY_FAILURE'

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE'

export const cateTypeConstants = {
    LOAD_CATTYPES_REQUEST: 'LOAD_CATTYPES_REQUEST',
    LOAD_CATTYPES_SUCCESS: 'LOAD_CATTYPES_SUCCESS',
    LOAD_CATTYPES_FAILURE: 'LOAD_CATTYPES_FAILURE'
}

export const editCategory = (data) => {
    return async dispatch => {
        dispatch({
            type: EDIT_CATEGORY_REQUEST
        });

        let document = {
            id: data.id,
            name: data.category,
        }
        await firebase
            .firestore()
            .collection('categories')
            .doc(data.docId)
            .set({ ...document }).then(() => {
                dispatch({
                    type: EDIT_CATEGORY_SUCCESS,
                    payload: { data: { ...document, docId: data.docId } }
                })
            }).catch(error => {
                dispatch({
                    type: EDIT_CATEGORY_FAILURE,
                    payload: { error: error }
                })
            });





    }
}
export const DELETE_ITEM_REQUEST = 'DELETE_ITEM_REQUEST';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAILURE = 'DELETE_ITEM_FAILURE';
export const editItemConstants = {
    EDIT_ITEM_REQUEST: 'EDIT_ITEM_REQUEST',
    EDIT_ITEM_SUCCESS: 'EDIT_ITEM_SUCCESS',
    EDIT_ITEM_FAILURE: 'EDIT_ITEM_FAILURE'
}
export const addItemConstants = {
    ADD_ITEM_REQUEST: 'ADD_ITEM_REQUEST',
    ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
    ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE'
}
export const addStockConstants = {
    ADD_STOCK_REQUEST: 'ADD_STOCK_REQUEST',
    ADD_STOCK_SUCCESS: 'ADD_STOCK_SUCCESS',
    ADD_STOCK_FAILURE: 'ADD_STOCK_FAILURE'
}

export const addItem = (data) => {
    return async (dispatch, getState) => {
        dispatch({
            type: addItemConstants.ADD_ITEM_REQUEST
        });
        const catAndTypes = getState().catAndTypes;
        const cat = catAndTypes.categories.find(f => f.docId === data.category);
        const t = catAndTypes.types.find(f => f.docId === data.type);
        let docId;
        let stockDoc;
        const query = firebase.firestore()
            .collection('items');
        const typeRef = await query
            .where('type', '==', data.type)
            .where('category', '==', data.category)
            .get();
        let parseId;
        if (typeRef.size > 0) {
            const typeData = await typeRef.docs.map(doc => doc.data());
            const sortedData = typeData.sort((a, b) => {
                return b.id - a.id
            })
            let id;
            if (sortedData.length > 0) {

                id = sortedData[0].id
            } else {
                id = `0001`
            }
            parseId = parseInt(id);
            let checkId = parseInt(id) + 1;
            if (checkId.toString().length === 1) {
                parseId = `000${checkId}`
                console.log({ parseId })
            } else if (checkId.toString().length === 2) {
                parseId = `00${checkId}`
            } else if (checkId.toString().length === 3) {
                parseId = `0${checkId}`

            } else if (checkId.toString().length === 4) {
                parseId = `${checkId}`
            }

        } else {
            parseId = `0001`
        }
        const ItemDoc = {
            name: data.name,
            serial: data.serial,
            barcode: data.barcode,
            des: data.des,
            uom: data.uom,
            subunit: data.subunit,
            packSize: data.packSize,
            purchaseRate: data.purchaseRate,
            saleRate: data.saleRate,
            maxLevel: data.maxLevel,
            minLevel: data.minLevel,
            category: data.category,
            type: data.type,
            id: parseId,
            typeSr: data.typeSr,
            categorySr: data.categorySr,

        }
        await firebase
            .firestore()
            .collection('items')
            .add({ ...ItemDoc })
            .then(async docRef => {
                docId = docRef.id;
                stockDoc = {
                    name: data.name,
                    openingStock: data.openingStock,
                    receivedQty: 0,
                    issuedQty: 0,
                    balanceInStock: data.openingStock,
                    date: firebase.firestore.Timestamp.fromDate(new Date())
                }

                await firebase
                    .firestore()
                    .collection('StockReport')
                    .doc(docRef.id)
                    .set({ ...stockDoc }).then(() => {
                        dispatch({
                            type: addItemConstants.ADD_ITEM_SUCCESS,
                            payload: { data: { docId, ...ItemDoc, catName: cat.name, typeName: t.name } }
                        });
                        // dispatch({
                        //     type: addStockConstants.ADD_STOCK_SUCCESS,
                        //     payload: {
                        //         data: { docId, ...stockDoc }
                        //     }
                        // });
                    })


            }).catch((err) => {
                console.log(err)
                dispatch({
                    type: addStockConstants.ADD_STOCK_FAILURE,
                    payload: { error: 'something is wrong' + err }
                });
            })







    }
}
const uploadImageHandler = async (imgUrl, id) => {

    const storageRef = firebase.storage().ref();
    var metadata = {
        contentType: "image/jpeg",
    };
    const str = imgUrl.name;
    const change = str.split("").join("");
    const makeName = id + change;
    var uploadTask = storageRef
        .child("items/" + makeName)
        .put(imgUrl, metadata);
    uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(progress);
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    break;
            }
        },
        (error) => {
            switch (error.code) {
                case "storage/unauthorized":
                    break;
                case "storage/canceled":
                    break;

                // ...

                case "storage/unknown":
                    // Unknown error occurred, inspect error.serverResponse
                    break;
            }
        },
        () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {

                return await downloadURL;

            })
        }
    );
}
export const addCategory = (data) => {
    return async dispatch => {
        dispatch({ type: ADD_CATEGORY_REQUEST });
        console.log(data)
        let query = firebase
            .firestore()
            .collection('categories');
        let parseId;
        const getLastCategory = await query.orderBy('id', 'desc').limit(1).get()
        if (getLastCategory.size > 0) {
            let lastIdArray = getLastCategory.docs.map(d => d.data());
            let id = lastIdArray[0].id;
            parseId = parseInt(id);

            let checkId = parseInt(id) + 1;
            if (checkId.toString().length === 1) {

                parseId = `00${parseId + 1}`

            } else if (checkId.toString().length === 2) {

                parseId = `0${parseId + 1}`
            }
            else if (checkId.toString().length === 3) {

                parseId = `${parseId + 1}`
            }


        } else {
            parseId = `001`
        }


        if (parseId) {
            let document = {
                id: parseId,
                name: data
            }
            await query

                .add({ ...document })
                .then((docRef) => {

                    dispatch({ type: ADD_CATEGORY_SUCCESS, payload: { data: { ...document, docId: docRef.id } } });
                })
                .catch((err) => {
                    dispatch({ type: ADD_CATEGORY_FAILURE, payload: { error: err } });
                })
        } else {
            dispatch({ type: ADD_CATEGORY_FAILURE, payload: { error: "something is wrong" } });
        }






    }
}
export const loadItems = () => {
    return async dispatch => {
        dispatch({ type: LOAD_ITEM_REQUEST });
        const itemRef = await firebase.firestore().collection('items').get()
        const catRef = await firebase.firestore().collection('categories').get()
        const typeRef = await firebase.firestore().collection('types').get()
        const fet = await itemRef.docs.map(doc => {

            const data = doc.data()
            return {
                docId: doc.id,
                ...data,
            }
        });
        const typeData = await typeRef.docs.map(doc => {
            const data = doc.data();
            return {
                docId: doc.id,
                ...data,
            }
        });
        const catData = await catRef.docs.map(doc => {
            const data = doc.data();
            return {
                docId: doc.id,
                ...data,
            }
        });

        let dbData = [];
        if (fet) {
            fet.map(item => {

                let latestCat = "", latestType = "";
                catData.map(cat => {
                    if (cat.docId === item.category) {
                        latestCat = cat.name
                    }
                })
                typeData.map(type => {
                    if (type.docId === item.type) {
                        latestType = type.name
                    }
                })



               // dbData.push({ ...item, catName: latestCat, typeName: latestType });
                dbData.push({ ...item });
            })
            dispatch({
                type: LOAD_ITEM_SUCCESS,
                payload: dbData
            });
        } else {
            dispatch({
                type: LOAD_ITEM_FAILURE,
                payload: { error: "data not found" }
            });
        }




    }
}
export const editType = (data) => {
    return async dispatch => {
        dispatch({
            type: EDIT_TYPE_REQUEST
        });

        let document = {
            id: data.id,
            name: data.type,
            parentId: data.parentId
        }
        await firebase
            .firestore()
            .collection('types')
            .doc(data.docId)
            .set({ ...document }).then(() => {
                dispatch({
                    type: EDIT_TYPE_SUCCESS,
                    payload: { data: { ...document, docId: data.docId } }
                })
            }).catch(error => {
                dispatch({
                    type: EDIT_TYPE_FAILURE,
                    payload: { error: error }
                })
            });





    }
}
export const addType = (data) => {
    return async dispatch => {
        dispatch({
            type: ADD_TYPE_REQUEST
        });
        const query = firebase.firestore()
            .collection('types');
        const typeRef = await query.where('parentId', '==', data.categoryId).get();
        let parseId;
        if (typeRef.size > 0) {
            const typeData = await typeRef.docs.map(doc => doc.data());
            const sortedData = typeData.sort((a, b) => {
                return b.id - a.id
            })
            let id;
            if (sortedData.length > 0) {

                id = sortedData[0].id
            } else {
                id = `001`
            }

            parseId = parseInt(id);
            let checkId = parseInt(id) + 1;
            if (checkId.toString().length === 1) {
                parseId = `00${checkId}`
                console.log({ parseId })
            } else if (checkId.toString().length === 2) {
                parseId = `0${checkId}`
            } else if (checkId.toString().length === 3) {
                parseId = `${checkId}`
            }

        } else {
            parseId = `001`
        }
        console.log({ data });
        if (parseId) {
            let document = {
                id: parseId,
                name: data.type,
                parentId: data.categoryId,
                parentDocId: data.categoryDocId
            }
            await firebase
                .firestore()
                .collection('types')

                .add({ ...document }).then((docRef) => {
                    dispatch({
                        type: ADD_TYPE_SUCCESS,
                        payload: { data: { ...document, docId: docRef.id } }
                    })
                }).catch(error => {
                    dispatch({
                        type: ADD_TYPE_FAILURE,
                        payload: { error: error }
                    })
                });

        } else {
            dispatch({
                type: ADD_TYPE_FAILURE,
                payload: { error: "something is wrong" }
            })
        }



    }
}

export const loadTypesCategories = () => {
    return async dispatch => {
        dispatch({ type: cateTypeConstants.LOAD_CATTYPES_REQUEST });
        const catRef = await firebase.firestore().collection('categories').get()
        const typeRef = await firebase.firestore().collection('types').get()

        if (catRef && typeRef) {
            const typeData = await typeRef.docs.map(doc => {
                const data = doc.data();
                return {
                    docId: doc.id,
                    name: data.name,
                    id: data.id,
                    parentId: data.parentId,
                    parentDocId: data.parentDocId
                }
            });
            const catData = await catRef.docs.map(doc => {
                const data = doc.data()
                return {
                    id: data.id,
                    docId: doc.id,
                    name: data.name,
                }
            });
            dispatch({
                type: cateTypeConstants.LOAD_CATTYPES_SUCCESS,
                payload: { categories: catData, types: typeData }
            });
        } else {
            dispatch({
                type: cateTypeConstants.LOAD_CATTYPES_FAILURE,
                payload: { error: "categories and types not found" }
            });
        }
    }
}

export const EditItem = (data) => {
    return async (dispatch, getState) => {
        const catAndTypes = getState().catAndTypes;
        const cat = catAndTypes.categories.find(f => f.docId === data.category);
        const t = catAndTypes.types.find(f => f.docId === data.type);
        console.log({ data })
        dispatch({
            type: editItemConstants.EDIT_ITEM_REQUEST
        });
        let docId = data.docId;
        const img = data.imgUrl

        const updatedDoc = {
            name: data.name,
            serial: data.serial?data.serial:"",
            barcode: data.barcode,
            des: data.des,
            uom: data.uom,
            subunit: data.subunit,
            packSize: data.packSize,
            purchaseRate: data.purchaseRate,
            saleRate: data.saleRate,
            maxLevel: data.maxLevel,
            minLevel: data.minLevel,
            category: data.category,
            type: data.type,
            id: data.id,
            typeSr: data.typeSr,
            categorySr: data.categorySr,

        }
        if (docId) {
            await firebase
                .firestore()
                .collection('items')
                .doc(docId).set({ ...updatedDoc })
                .then(async () => {
                    await firebase
                        .firestore()
                        .collection('StockReport').doc(docId).update({ name: data.name })
                }).then(res => {
                    dispatch({
                        type: editItemConstants.EDIT_ITEM_SUCCESS,
                        payload: { data: { ...updatedDoc, docId, typeName: t.name, catName: cat.name } }

                    });
                }).catch((error) => {
                    dispatch({
                        type: editItemConstants.EDIT_ITEM_FAILURE,
                        error: 'something is wrong'

                    });
                })

        }




    }
}
export const DeleteItem = (docId, collection) => {
    return async dispatch => {
        dispatch({
            type: DELETE_ITEM_REQUEST
        });
        let error;

        if (docId) {
            const dataCheck = await firebase
                .firestore()
                .collection('invoicesPurchase').get();
            if (dataCheck.size > 0) {
                const invoices = dataCheck.docs.map(d => {
                    const data = d.data();
                    return data;
                });
                const filter = invoices.filter(f => {
                    let filterItem = f.items.find(findId => findId.id === docId);
                    if (filterItem) {
                        return f;
                    }
                })
                if (filter.length === 0) {
                    const dataCheck2 = await firebase
                        .firestore()
                        .collection('invoicesSell').get();
                    const invoicesSell = dataCheck2.docs.map(d => {
                        const data = d.data();
                        return data;
                    });
                    const filterSell = invoicesSell.filter(f => {
                        let filterItem = f.items.find(findId => findId.id === docId);
                        if (filterItem) {
                            return f;
                        }
                    });
                    if (filterSell.length > 0) {
                        error = 'item is connected with sell inovoices'
                        dispatch({
                            type: DELETE_ITEM_FAILURE,
                            payload: { error }

                        });
                    }
                } else {
                    error = 'item is connected with purchase inovoices'
                    dispatch({
                        type: DELETE_ITEM_FAILURE,
                        payload: { error }

                    });
                }

            }

            if (!error) {
                await firebase
                    .firestore()
                    .collection(collection)
                    .doc(docId)
                    .delete()
                    .then(async () => {
                        await firebase
                            .firestore()
                            .collection('StockReport')
                            .doc(docId)
                            .delete()

                    }).then(() => {
                        dispatch({
                            type: DELETE_ITEM_SUCCESS,
                            payload: { data: docId }

                        });
                    }).catch((error) => {
                        dispatch({
                            type: DELETE_ITEM_FAILURE,
                            payload: { error: 'something is wrong' + error }

                        });
                    })
            }
        }




    }
}
export const deleteCategories = (docId, collection) => {
    return async dispatch => {
        dispatch({
            type: DELETE_CATEGORY_REQUEST
        });


        if (docId) {
            const typeCheck = await firebase
                .firestore()
                .collection('types')
                .where('parentDocId', '==', docId)
                .get();
            if (typeCheck.size > 0) {
                dispatch({
                    type: DELETE_CATEGORY_FAILURE,
                    payload: { error: 'category is connected with types cannot be deleted' }

                });
            } else {
                await firebase
                    .firestore()
                    .collection(collection)
                    .doc(docId)
                    .delete()
                    .then(res => {
                        dispatch({
                            type: DELETE_CATEGORY_SUCCESS,
                            payload: { data: docId }

                        })
                    }).catch((error) => {
                        dispatch({
                            type: DELETE_CATEGORY_FAILURE,
                            payload: { error: 'something is wrong' + error }

                        });
                    })
            }


        }




    }
}
export const deleteType = (docId, collection) => {
    return async dispatch => {
        dispatch({
            type: DELETE_TYPE_REQUEST
        });


        if (docId) {
            const itemCheck = await firebase.firestore().collection('items').where('type', '==', docId).get();
            if (itemCheck.size > 0) {
                dispatch({
                    type: DELETE_TYPE_FAILURE,
                    payload: { error: 'type is connected with items cannot be deleted' }

                });
            } else {
                await firebase
                    .firestore()
                    .collection(collection)
                    .doc(docId)
                    .delete()
                    .then(res => {
                        dispatch({
                            type: DELETE_TYPE_SUCCESS,
                            payload: { data: docId }

                        })
                    }).catch((error) => {
                        dispatch({
                            type: DELETE_TYPE_FAILURE,

                            payload: { error: 'something is wrong' + error }

                        });
                    })
            }


        }




    }
}

