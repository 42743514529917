import React from "react";
import LinkCom from "../general/LinkCom";

const PostDatedChequeTable = (props) => {
  const { report } = props;
  
  return (
    <table className="table table-striped table-hover table-bordered text-center">
      <thead>
        <tr>
          <th>Date</th>
          <th>V-No </th>
          <th>Account Title</th>

          <th>Bank Title</th>

          <th>Cheque No</th>

          <th>Cheque Date</th>
          <th>Cheque Amount </th>
        </tr>
      </thead>
      <tbody>
        {props.SearchBar}

        {report &&
          report.length > 0 &&
          report.map((item) => {
            let date = new Date(item.date.seconds * 1000)
              .toISOString()
              .slice(0, 10);
            return (
              <tr>
                <td>{date}</td>
                <td>
                  <LinkCom vNo={item.vNo} />
                </td>
                <td>{item.accountTitle}</td>
                <td>{item.bankTitle}</td>
                <td>{item.checkNo}</td>
                <td>{item.checkDate}</td>
                <td>{item.amount}</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default PostDatedChequeTable;
