import React, { useState } from 'react'
import Input from '../../subcom/Input';
import ActionButtons from '../../subcom/ActionButtons';
import * as itemActions from '../../action/itemAction';
import { Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

const CategoryTab = (props) => {
    const dispatch = useDispatch();
    const editValue = props.editValue;
    const [category, setCategory] = useState(editValue ? editValue.name : '');
    const onCategoryChange = (e,) => {

        const val = e.target.value;
        setCategory(val);
    }

    const saveInfo = () => {
        if (editValue) {
           dispatch(itemActions.editCategory({ ...editValue, category }));
        } else {
            dispatch(itemActions.addCategory(category));
        }
        props.handleCatModal()
    }
    return (
        <div >
            <h1 style={
                { marginTop: '10px', marginLeft: '20px' }} > Add Category</h1>
            <Row>
                <Input
                    label='Category'
                    type='text'
                    name={"category"}
                    value={category}
                    onChange={(e) => onCategoryChange(e)}
                />
            </Row>
            <div style={{
                display: "flex",
                margin: '20px',
                justifyContent: "flex-end", alignItems: "center"
            }}>


                <div style={{ marginLeft: '30px' }}>
                    <ActionButtons saveInfo={saveInfo} cancelInfo={props.handleCatModal} /></div>
            </div>
        </div>
    )
}

export default CategoryTab
