import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
const PrintInvoiceOptions = (props) => {
  const { voucherType, invoiceId, disabled } = props;

  return (
    <Dropdown>
      <Dropdown.Toggle>Print</Dropdown.Toggle>
      <Dropdown.Menu className="d-content">
        <Dropdown.Item
          as={Link}
          target="_blank"
          className="dropdown-item"
          to={`/print/${voucherType}/${invoiceId}/s`}
        >
          Sell Invoice
        </Dropdown.Item>
        <Dropdown.Item
          as={Link}
          className="dropdown-item"
          target="_blank"
          to={`/print/${voucherType}/${invoiceId}/d`}
        >
          Delivery Invoice
        </Dropdown.Item>
        <Dropdown.Item
          as={Link}
          className="dropdown-item"
          target="_blank"
          to={`/print/${voucherType}/${invoiceId}/t`}
        >
          Tax Invoice
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default PrintInvoiceOptions;
