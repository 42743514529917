import {
    ADD_LEVEL_1_FAILURE,
    ADD_LEVEL_1_REQUEST,
    ADD_LEVEL_1_SUCCESS,
    ADD_LEVEL_2_FAILURE,
    ADD_LEVEL_2_REQUEST,
    ADD_LEVEL_2_SUCCESS,
    ADD_LEVEL_3_FAILURE,
    ADD_LEVEL_3_REQUEST,
    ADD_LEVEL_3_SUCCESS,
    ALL_LEVEL_LOAD_FAILURE,
    ALL_LEVEL_LOAD_REQUEST,
    ALL_LEVEL_LOAD_SUCCESS,
    UPDATE_LEVEL_3_FAILURE,
    UPDATE_LEVEL_3_REQUEST,
    UPDATE_LEVEL_3_SUCCESS,
    DELETE_LEVEL_3_FAILURE,
    DELETE_LEVEL_3_REQUEST,
    DELETE_LEVEL_3_SUCCESS,
    UPDATE_LEVEL_1_FAILURE,
    UPDATE_LEVEL_1_REQUEST,
    UPDATE_LEVEL_1_SUCCESS,
    DELETE_LEVEL_1_FAILURE,
    DELETE_LEVEL_1_REQUEST,
    DELETE_LEVEL_1_SUCCESS,
    UPDATE_LEVEL_2_FAILURE,
    UPDATE_LEVEL_2_REQUEST,
    UPDATE_LEVEL_2_SUCCESS,
    DELETE_LEVEL_2_FAILURE,
    DELETE_LEVEL_2_REQUEST,
    DELETE_LEVEL_2_SUCCESS,
    ADD_LEVEL3_CD_SUCCESS,
    ADD_LEVEL3_CD_REQUEST,

} from "../action/coaAction";

const initialState = {
    loading: false,
    level1: [],
    level2: [],
    level3: [],
    allLevels: [],
    error: ''

};
function createLevel(allLevels, parentId = null) {
    const LevelList = [];
    let catgory;
    if (parentId === null) {
        catgory = allLevels.filter((f) => f.parentId == undefined);
    } else {
        catgory = allLevels.filter((f) => f.parentId == parentId);
    }
    for (let cat of catgory) {
        LevelList.push({
            docId: cat.docId,
            id: cat.id,
            title: cat.title,
            parentId: cat.parentId,
            children: createLevel(allLevels, cat.docId),
            type: cat.type,
            credit: cat.credit ? cat.credit : 0,
            debit: cat.debit ? cat.debit : 0
        });
    }
    return LevelList;
}
const generalChartAccount = (state = initialState, action) => {
    switch (action.type) {
        case ALL_LEVEL_LOAD_REQUEST: {
            state = {
                ...state,
                loading: true
            }
        }
            break;
        case ALL_LEVEL_LOAD_SUCCESS: {
            const data = action.payload;
            state = {
                ...state,
                level1: data.level1,
                level2: data.level2,
                level3: data.level3,
                allLevels: createLevel([...data.level1, ...data.level2, ...data.level3]),
                loading: false
            }
        }
            break;
        case ALL_LEVEL_LOAD_FAILURE: {
            const data = action.payload;
            state = {
                ...state,
                error: data.error,
                loading: false
            }
        }
            break;
        case ADD_LEVEL_1_REQUEST: {
            state = {
                ...state,
                loading: true
            }
        }
            break;
        case ADD_LEVEL_1_SUCCESS: {
            const data = action.payload.data;
            const newLevel1 = [...state.level1, data]
            state = {
                ...state,
                level1: newLevel1,
                allLevels: createLevel([...newLevel1, ...state.level2, ...state.level3]),
                loading: false
            }
        }
            break;
        case ADD_LEVEL_1_FAILURE: {
            state = {
                ...state,
                error: action.payload.error,
                loading: false
            }
        }
            break;
        case ADD_LEVEL_2_REQUEST: {
            state = {
                ...state,
                loading: true
            }
        }
            break;
        case ADD_LEVEL_2_SUCCESS: {
            const data = action.payload.data;
            const newLevel2 = [...state.level2, data]
            state = {
                ...state,
                level2: newLevel2,
                allLevels: createLevel([...state.level1, ...newLevel2, ...state.level3]),
                loading: false
            }
        }
            break;
        case ADD_LEVEL_2_FAILURE: {
            state = {
                ...state,
                error: action.payload.error,
                loading: false
            }
        }
            break;
        case ADD_LEVEL_3_REQUEST: {
            state = {
                ...state,
                loading: true
            }
        }
            break;
        case ADD_LEVEL_3_SUCCESS: {
            const data = action.payload.data;
            const newLevel3 = [...state.level3, data]
            state = {
                ...state,
                level3: newLevel3,
                allLevels: createLevel([...state.level1, ...state.level2, ...newLevel3]),
                loading: false
            }
        }
            break;
        case ADD_LEVEL_3_FAILURE: {
            state = {
                ...state,
                error: action.payload.error,
                loading: false
            }
        }
            break;
        case UPDATE_LEVEL_1_REQUEST: {
            state = {
                ...state,
                loading: true
            }
        }
            break;
        case UPDATE_LEVEL_1_SUCCESS: {
            const data = action.payload.data;
            let newArray = []
            state.level1.map(f => {
                if (f.docId === data.docId) {
                    const newDoc = {
                        id: f.id,
                        title: data.title,
                        docId: data.docId,
                        type: f.type
                    }
                    newArray.push(newDoc);
                } else {
                    newArray.push(f);
                }
            });

            state = {
                ...state,
                level1: newArray,
                allLevels: createLevel([...newArray, ...state.level2, ...state.level3]),
                loading: false
            }
        }
            break;
        case UPDATE_LEVEL_1_FAILURE: {
            state = {
                ...state,
                error: action.payload.error,
                loading: false
            }
        }
            break;
        case UPDATE_LEVEL_2_REQUEST: {
            state = {
                ...state,
                loading: true
            }
        }
            break;
        case UPDATE_LEVEL_2_SUCCESS: {
            const data = action.payload.data;
            let newArray = []
            state.level2.map(f => {
                if (f.docId === data.docId) {
                    const newDoc = {
                        id: f.id,
                        title: data.title,
                        docId: data.docId,
                        parentId: f.parentId
                    }
                    newArray.push(newDoc);
                } else {
                    newArray.push(f);
                }
            });

            state = {
                ...state,
                level2: newArray,
                allLevels: createLevel([...state.level1, ...newArray, ...state.level3]),
                loading: false
            }
        }
            break;
        case UPDATE_LEVEL_2_FAILURE: {
            state = {
                ...state,
                error: action.payload.error,
                loading: false
            }
        }
            break;
        case UPDATE_LEVEL_3_REQUEST: {
            state = {
                ...state,
                loading: true
            }
        }
            break;
        case UPDATE_LEVEL_3_SUCCESS: {
            const data = action.payload.data;
            let newArray = []
            state.level3.map(f => {
                if (f.docId === data.docId) {
                    const newDoc = {
                        id: f.id,
                        title: data.title,
                        docId: data.docId,
                        parentId: f.parentId,
                        credit: data.credit,
                        debit: data.debit,
                    }
                    newArray.push(newDoc);
                } else {
                    newArray.push(f);
                }
            });

            state = {
                ...state,
                level3: newArray,
                allLevels: createLevel([...state.level1, ...state.level2, ...newArray]),
                loading: false
            }
        }
            break;
        case UPDATE_LEVEL_3_FAILURE: {
            state = {
                ...state,
                error: action.payload.error,
                loading: false
            }
        }
            break;
        case DELETE_LEVEL_1_REQUEST: {
            state = {
                ...state,
                loading: true
            }
        }
            break;
        case DELETE_LEVEL_1_SUCCESS: {
            const data = action.payload.data;
            let newArray = state.level1.filter(f => f.docId !== data);
            state = {
                ...state,
                level1: newArray,
                allLevels: createLevel([...newArray, ...state.level2, ...state.level3]),
                loading: false
            }
        }
            break;
        case DELETE_LEVEL_1_FAILURE: {
            state = {
                ...state,
                error: action.payload.error,
                loading: false
            }
        }
            break;
        case DELETE_LEVEL_2_REQUEST: {
            state = {
                ...state,
                loading: true
            }
        }
            break;
        case DELETE_LEVEL_2_SUCCESS: {
            const data = action.payload.data;
            let newArray = state.level2.filter(f => f.docId !== data);
            state = {
                ...state,
                level2: newArray,
                allLevels: createLevel([...state.level1, ...newArray, ...state.level3]),
                loading: false
            }
        }
            break;
        case DELETE_LEVEL_2_FAILURE: {
            state = {
                ...state,
                error: action.payload.error,
                loading: false
            }
        }
            break;
        case DELETE_LEVEL_3_REQUEST: {
            state = {
                ...state,
                loading: true
            }
        }
            break;
        case DELETE_LEVEL_3_SUCCESS: {
            const data = action.payload.data;
            let newArray = state.level3.filter(f => f.docId !== data);
            state = {
                ...state,
                level3: newArray,
                allLevels: createLevel([...state.level1, ...state.level2, ...newArray]),
                loading: false
            }
        }
            break;
        case DELETE_LEVEL_3_FAILURE: {
            state = {
                ...state,
                error: action.payload.error,
                loading: false
            }
        }
            break;
        case ADD_LEVEL3_CD_REQUEST: {
            state = {
                ...state,
                loading: true
            }
        }
            break;
        case ADD_LEVEL3_CD_SUCCESS: {
            const data = action.payload.data;
            let newArray = []
            state.level3.forEach(f => {
                let doc = data.find(s => s.docId === f.docId);
                if (doc) {
                    const newDoc = {
                        id: f.id,
                        title: doc.title,
                        docId: doc.docId,
                        parentId: f.parentId,
                        credit: doc.credit,
                        debit: doc.debit,
                    }
                    newArray.push(newDoc);
                } else {
                    newArray.push(f);
                }
            });

            state = {
                ...state,
                level3: newArray,
                allLevels: createLevel([...state.level1, ...state.level2, ...newArray]),
                loading: false
            }
        }
            break;

    }
    return state;
};
export default generalChartAccount;
