import React, { useState, useEffect } from 'react'
import Input from '../../subcom/Input';
import ActionButtons from '../../subcom/ActionButtons';
import * as itemActions from '../../action/itemAction';
import { Row, } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import CustomDropDown from '../../subcom/CustomDropDown';
const TypeTab = (props) => {
    const dispatch = useDispatch();

    const editValue = props.editValue;
    const [type, setType] = useState(editValue ? editValue.name : '');
    const [categoryDocId, setCategoryDocId] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [list, setList] = useState(props.list);
    const onTypeChange = (e,) => {
        const val = e.target.value;
        setType(val);
    }
    useEffect(() => {
        setList(props.list)
    }, [props.list])

    const onCategoryChange = (docId) => {

        const category = list.find(f => f.docId === docId);
        if (!editValue) {
            console.log({ docId: category.docId })
            setCategoryDocId(category.docId)
            setCategoryId(category.id)
        } else {
            alert('categoryId cannot be changes')
            return;
        }
    }

    const saveInfo = () => {
        if (editValue) {
            console.log(editValue)
            dispatch(itemActions.editType({ ...editValue, type }));
        } else {
            if ((categoryDocId !== '' && type !== '')) {

                dispatch(itemActions.addType({ type, categoryDocId, categoryId }));
            } else {
                alert('please select category first')
            }
        }
        if ((categoryDocId !== '' && type !== '')) { props.handleTypeModal() }
    }
    const createLabel = (array, docId) => {

        if (array.length > 0) {
            let find = array.find(f => f.docId === docId);
            let name;
            if (find) {

                name = find.name;
            } else {
                name = ''
            }
            return name;
        }
    }
    return (
        <div
        >
            <h1 style={
                { marginTop: '10px', marginLeft: '20px' }} > Add Type </h1>

            <Row>

                {(list && list.length > 0) && <CustomDropDown
                    list={list}
                    label={createLabel(list, categoryDocId)}
                    selectedItem={(docId) => onCategoryChange(docId)}
                />}
                <Input
                    label='Type'
                    type='text'
                    name={"type"}
                    value={type}
                    onChange={(e) => onTypeChange(e)}
                />

            </Row>
            <div style={{
                display: "flex",
                margin: '20px',
                justifyContent: "flex-end", alignItems: "center"
            }}>



                <div style={{ marginLeft: '30px' }}>
                    <ActionButtons saveInfo={saveInfo} cancelInfo={props.handleTypeModal} /></div>
            </div>
        </div>
    )
}

export default TypeTab
