import React, { useEffect, useState } from "react";
import { Collapse, ListGroup, Form } from "react-bootstrap";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { MdModeEdit, MdSave } from "react-icons/md";
import { useDispatch } from "react-redux";
import ViewRight from "./ViewRight";

import {
  updateNameAndRoleHandler,
  updateRightsHandler,
} from "../../action/superAdmin";
import "../../Css/generalStyle.css";

const imgReplace = require("../../icons/default-avatar.png");
const  SuperAdminOption=(props) =>{
  const { user } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [changeName, setChangeName] = useState(user.name);
  const [changeRole, setChangeRole] = useState(user.role);
  

  const ChangeRoleHandler = (e) => {
    setChangeRole(e.target.value);
  };
  const ChangeNameHandler = (e) => {
    setChangeName(e.target.value);
  };
  const toggleEditInfo = () => {
    setShowInput(!showInput);
  };
  const saveInfoHandler = (e) => {
    dispatch(updateNameAndRoleHandler(user.email, changeName, changeRole));
    setShowInput(false);
  };

  return (
    <div key={user.email}>
      <div className="userListSpace">
        <div className="userList">
          <img
            src={user.picture}
            style={{ width: "44px", height: "44px", borderRadius: "50px" }}
            onError={(event) =>
              event.target.setAttribute("src", imgReplace.default)
            }
          />

          {showInput ? (
            <Form.Control onChange={ChangeNameHandler} value={changeName} />
          ) : (
            <p className="textClass">{changeName}</p>
          )}
          {showInput ? (
            <Form.Select value={changeRole} onChange={ChangeRoleHandler}>
              <option value={"admin"}>Admin</option>
              <option value={"user"}>User</option>
            </Form.Select>
          ) : (
            <p className="textClass">{changeRole}</p>
          )}
        </div>
        <div className="btnSetting">
          <MdModeEdit
            onClick={toggleEditInfo}
            style={{
              width: "24px",
              height: "24px",
              borderRadius: "50px",
              margin: "10px",
            }}
          />
          <MdSave
            onClick={saveInfoHandler}
            style={{
              width: "24px",
              height: "24px",
              borderRadius: "50px",
              margin: "10px",
            }}
          />
          {open ? (
            <BsChevronUp color="black" onClick={() => setOpen(!open)} />
          ) : (
            <BsChevronDown color="black" onClick={() => setOpen(!open)} />
          )}
        </div>
      </div>
      <ViewRight
        show={open}
        name={changeName}
        email={user.email}
        handlViewRights={() => setOpen(false)}
      />
    </div>
  );
}
export default SuperAdminOption;
