import React from "react";
import { Table } from "react-bootstrap";
import { FiTrash, FiEdit2 } from "react-icons/fi";
const ItemListTable = (props) => {
  const loadedItems = props.loadedItems;
  const check = props.check;
  const rights = props.right;

  return (
    <Table striped bordered hover>
      <thead>
        <tr style={{ textAlign: "center" }}>
          <th>#</th>
          <th>Item Name</th>
          {check === "item" && <th>UOM</th>}
          {check === "item" && <th>category</th>}
          {check === "item" && <th>type</th>}
          {/* {check === 'item' && <th>Photo</th>} */}
          <th>Operation</th>
        </tr>
      </thead>
      <tbody>
        {loadedItems.length > 0 &&
          loadedItems.map((item, index) => (
            <tr style={{ textAlign: "center" }} key={item.docId}>
              {item.typeSr ? (
                <td>{`${item.categorySr}${item.typeSr}${item.id}`}</td>
              ) : (
                <td>
                  {item.parentId ? `${item.parentId}${item.id}` : item.id}
                </td>
              )}

              <td>{item.name}</td>
              {check === "item" && <td>{item.uom ? item.uom : ""}</td>}
              {check === "item" && <td>{item.category ? item.category : ""}</td>}
              {check === "item" && (
                <td>{item.type ? item.type : ""}</td>
              )}

              {/* {item.photoUrl && <td>
                            <img
                                alt={item.photoUrl ? item.photoUrl : "pictures"}
                                src={item.photoUrl}
                                width="64px"
                                height="64px" />
                        </td>} */}
              <td>
                <FiEdit2
                  style={{
                    margin: "4",
                    padding: "4",
                    border: "1px solid black",
                  }}
                  size={32}
                  color="blue"
                  onClick={() => {
                    if (rights.edit) {
                        
                      props.handleEditProduct(item);
                    } else {
                      alert("you do not have rights for current operation");
                    }
                  }}
                />
                <FiTrash
                  style={{
                    margin: "4",
                    padding: "4",
                    border: "1px solid black",
                  }}
                  size={32}
                  color="red"
                  onClick={() => {
                    if (rights.delete) {
                      props.handleDeleteProduct(item.docId);
                    } else {
                      alert("you do not have rights for current operation");
                    }
                  }}
                />
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default ItemListTable;
