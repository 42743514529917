import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
const ListReceipyProduction = (props) => {
  const history = useHistory();
  const noteRed = useSelector((state) => state.note);
  const [type, setType] = useState("receipy");
  const [note, setNote] = useState([]);
  useEffect(() => {
    if (type === "production") {
      setNote(noteRed.productionNote);
    } else {
      setNote(noteRed.receipyNote);
    }
  }, [type, noteRed]);
  const moveToInvoice = (id) => {
    history.push({
      pathname: `/productionnote/${id}`,
      state: {
        response: "",
      },
    });
  };
  const onNoteChange = (e) => {
    setType(e.target.value);
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          marginLeft: "20px",
        }}
      >
        <Form.Check
          type="checkbox"
          label="Production Note"
          value="production"
          checked={type === "production" ? true : false}
          onChange={onNoteChange}
          style={{ marginRight: "30px" }}
        />
        <Form.Check
          type="checkbox"
          label="Receipy Note"
          value="receipy"
          checked={type === "receipy" ? true : false}
          onChange={onNoteChange}
          style={{ marginRight: "30px" }}
        />
      </div>
      <div>
        {note.length > 0 && (
          <table
            className="table table-striped table-hover table-bordered"
            style={{ textAlign: "center" }}
          >
            <thead>
              <tr>
                <th>Type</th>
                <th>Date</th>
                <th>VNo</th>
                <th>Name</th>
                <th>Qty</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>
              {note.map((item) => {
                return (
                  <tr
                    key={item.id}
                    onDoubleClick={() => moveToInvoice(item.id)}
                  >
                    <td>{type && type !== "" && type.toUpperCase()}</td>
                    <td>{item.date}</td>
                    <td>{item.id && item.id.toUpperCase()}</td>
                    <td>{item.receipyItemName}</td>
                    <td>{item.forQuantity}</td>
                    <td>{item.grand}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ListReceipyProduction;
