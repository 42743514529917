import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import * as jvActions from '../../action/jvAction';
import SearchBar from '../general/SearchBar';
import { useDispatch } from 'react-redux';
const JvList = (props) => {
    const dispatch = useDispatch();
    const jvList = props.jvList;
    const { voucherType, check } = props;
    const [jvs, setJvs] = useState([]);
    const [statusList, setStatusList] = useState([
        "checked",
        "approved",
        "cancel",
        'unchecked',
        'unapproved',
    ]);
    const [vId, setVId] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [pn, setPN] = useState('');
    const [pName, setPName] = useState('');
    const [date, setDate] = useState('');
    useEffect(() => {
        setJvs(jvList)
    }, [jvList])
    const handleSearchJv = () => {
        let list = [...jvList];
        if (vId !== '') {
            list = list.filter(f => f.id === vId);
        }
        if (pn !== '') {
            list = list.filter(f => f.contact === pn);
        }
        if (pName !== '') {
            list = list.filter(f => {
                let name = f.jvItems.find(item =>
                    item.party.toLowerCase().includes(pName))
                if (name) {
                    return f;
                }
            });
        }
        if (date !== '') {
            list = list.filter(f => {
                const checkDate = new Date(f.date.toDate().toLocaleDateString())
                const inputDate = new Date(date)
                const cDate = checkDate.getDate() + "-" + (checkDate.getMonth() + 1) + "-" + checkDate.getFullYear();
                const cDate2 = (inputDate.getDate() + 1) + "-" + (inputDate.getMonth() + 1) + "-" + inputDate.getFullYear();

                if (cDate === cDate2) { return f; }
            });
        }


    }
    const handleStatusSearch = (status) => {
        let list = [...jvList];
        setSelectedStatus(status);
        if (status) {
            switch (status) {
                case 'checked': {
                    list = list.filter(f => f.check === true && f.approve === false);
                }
                    break;
                case 'unchecked': {
                    list = list.filter(f => f.check === false);
                }
                    break;
                case 'approved': {
                    list = list.filter(f => f.approve === true);
                }
                    break;
                case 'unapproved': {
                    list = list.filter(f => f.check === true && f.approve === false);
                }
                    break;
                case 'cancel': {
                    list = list.filter(f => f.cancel === true);
                }
                    break;

            }

        }
        setJvs(list);
    }
    const handleReset = () => {
        setJvs(jvList)
        setDate('');
        setPName('');
        setPN('');
        setVId('');
        setSelectedStatus('');
    }
    const handleJvOperation = (current, op) => {
        let message = `can not be ${op}`
        if (op === 'check') {
            if (current && !current.approve && !current.cancel) {
                console.log(current)
                dispatch(jvActions.checkJv(current.id,voucherType))
            } else {

                alert(message);
            }
        }
        if (op === 'approve') {
            if (current && current.check && !current.cancel) {
                dispatch(jvActions.approveJv(current.id,voucherType))
            } else {

                alert(message)
            }
        }
    }
    return (
        <div>
            <SearchBar
                handleVoucherId={(e) => {
                    setVId(e.target.value);
                    setJvs(jvList.filter(f => f.id.includes(e.target.value)))
                }}
                handlePartyName={(e) => {
                    setPName(e.target.value);
                    setJvs(jvList.filter(f => {
                        let name = f.jvItems.find(item =>
                            item.party.toLowerCase().includes(e.target.value))
                        if (name) {
                            return f;
                        }
                    }))
                }}
                handlePartyDate={(e) => {
                    let list = [...jvList];
                    setDate(e.target.value)
                    list = list.filter(f => {
                        var date = e.target.value;
                        var dbDate = new Date(f.date.toDate())
                        let today = new Date(date).toISOString().slice(0, 10)
                        let check = new Date(dbDate).toISOString().slice(0, 10)

                        if (today === check) { return f; }
                    });
                    setJvs(list);
                }}
                handleSearchInvoice={handleSearchJv}
                handleReset={handleReset}
                date={date}
                pName={pName}

                vId={vId}
                selectedStatus={selectedStatus}
                statusList={statusList}
                handleStatusSearch={handleStatusSearch}
            />

            <Table
                size='lg' hover bordered striped style={{ fontWeight: '8px', textAlign: 'center' }}
            >
                <thead>
                    <tr>
                        <th style={{ width: "40px", }}>
                            Date
                        </th>
                        <th style={{ width: "140px" }}>
                            Voucher
                        </th>
                        <th style={{ width: "140px" }}>
                            Party
                        </th>
                        <th style={{ width: "140px" }}>
                            Voucher Adj
                        </th>

                        <th style={{ width: "40px" }}>
                            Narration</th>
                        <th style={{ width: "40px" }}>
                            Debit
                        </th>
                        <th style={{ width: "40px" }}>
                            Credit
                        </th>
                        <th style={{ width: "40px" }}>
                            Status
                        </th>
                        <th style={{ width: "40px" }}>
                            Check
                        </th>
                        <th style={{ width: "40px" }}>
                            Approve
                        </th>
                        <th style={{ width: "40px" }}>
                            View
                        </th>

                    </tr>
                </thead>
                {

                    jvs.map((voucher, index) => {
                        return (
                            <tbody key={voucher.id} style={
                                { borderBottom: '4px solid black' }}>
                                {voucher.jvItems.map((item, no) => {

                                    return (<tr key={no}>
                                        <td style={{ width: "40px", }}>
                                            {voucher.date && new Date(voucher.date.toDate()).toISOString().slice(0, 10) }
                                        </td>
                                        <td style={{ width: "140px" }}>
                                            {voucher.id}
                                        </td>
                                        <td style={{ width: "140px" }}>
                                            {item.party && item.party}
                                        </td>
                                        <td style={{ width: "140px" }}>
                                            {item.voucherAdj && item.voucherAdj}
                                        </td>
                                        <td style={{ width: "40px" }}>
                                            {item.lineNarration && item.lineNarration}</td>
                                        <td style={{ width: "40px" }}>
                                            {item.accDebit && item.accDebit}
                                        </td>
                                        <td style={{ width: "40px" }}>
                                            {item.accCredit && item.accCredit}
                                        </td>
                                        <td>{(voucher.approve && voucher.check) &&
                                            <>{"approve"}</>}
                                            {(!voucher.approve && voucher.check) &&
                                                <>{"checked"}</>}



                                            {(!voucher.check) && "unchecked"}
                                        </td>
                                        <td onDoubleClick={() => handleJvOperation(voucher, 'check')} >
                                            {voucher.check ?
                                                "yes" : "no"}</td>
                                        <td onDoubleClick={() => handleJvOperation(voucher, 'approve')}>
                                            {(voucher.approve && voucher.check) ?
                                                "yes" : "no"}</td>
                                        <td>
                                            <Link
                                                onClick={() => props.changeTab()}
                                                to={`/${check}/${voucherType}/${voucher.id}`}>

                                                View
                                            </Link>
                                        </td>

                                    </tr>)
                                })}
                            </tbody>
                        )
                    })
                }
            </Table>
        </div>
    )
}

export default JvList
