import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { Card, Tab, Tabs } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner } from "react-bootstrap";
import ShowModal from "../subcom/ShowModal";
import * as jvActions from "../action/jvAction";
import { Link } from "react-router-dom";
import JournalVoucher from "../components/JournalVoucher";
import JvList from "../components/jvcom/JvList";
const JVPage = (props) => {
  const dispatch = useDispatch();
  const jvType = props.match.params.type;
  const jvId = props.match.params.id;
  const [tab, setTab] = useState("new");
  const jvRed = useSelector((state) => state.jv);
  const [voucherByType, setVoucherByType] = useState([]);

  useEffect(() => {
    dispatch(jvActions.loadJournalVoucher(jvType));
  }, [ jvType]);

  const userRed = useSelector((state) => state.user);
  const [jvRights, setJvRights] = useState({});
  const [jvCreator, setJvCreator] = useState({});
  useEffect(() => {
    setJvRights(props.rights);
  }, []);
  useEffect(() => {
    jvByTypeHandler();
  }, [jvRed, jvType]);
  const jvByTypeHandler = async () => {
    if (jvRed.jvList) {
      let filterVoucher = [];
      if (jvRed.jvList.length > 0) {
        if (jvRights.view) {
          filterVoucher = jvRed.jvList;
        } else {
          const user = userRed.user;
          if (user) {
            if (user.name) {
              const userName = user.name;
              filterVoucher = await jvRed.jvList.filter((f) => {
                if (f.jvOwner === userName) {
                  return f;
                }
              });
            }
          }
        }
      }

      setVoucherByType(filterVoucher);
    }
  };
  if (!jvRed.loading) {
    return (
      <Layout>
        <div>
          <Card style={{ display: "flex", width: "100%" }}>
            <Card.Body className="no-gutters p-0 m-0">
              <div className="mb-3 ml-2">
                <Tabs activeKey={tab} className="m-2">
                  <Tab
                    eventKey="new"
                    title={
                      <Button
                        className="btnclass gradient-button-4"
                      >
                        <Link
                          to={`/jvpage/${jvType}`}
                          onClick={() => {
                            setTab("new");
                          }}
                          style={{color:'white',textDecoration:'none'}}
                        >
                          New
                        </Link>
                      </Button>
                    }
                  >
                    {tab === "new" && (
                      <JournalVoucher
                        vNo={jvId}
                        jvType={jvType}
                        jvRights={jvRights}
                        jvCreator={jvCreator}
                      ></JournalVoucher>
                    )}
                  </Tab>

                  <Tab
                    eventKey="list"
                    title={
                      <Button
                        className="btnclass gradient-button-4"
                        style={buttonStyle}
                        onClick={() => {
                          setTab("list");
                        }}
                      >
                        List
                      </Button>
                    }
                  >
                    {voucherByType.length > 0 && (
                      <JvList
                        jvList={voucherByType}
                        voucherType={jvType}
                        check={"jvpage"}
                        changeTab={() => setTab("new")}
                      />
                    )}
                  </Tab>
                </Tabs>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Layout>
    );
  } else {
    return (
      <ShowModal show={jvRed.loading} onHide={() => {}}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="grow" />
        </div>
      </ShowModal>
    );
  }
};

const buttonStyle = {
  marginRight: "10px",
  fontWeight: "bold",
  fontSize: "12px",
};
export default JVPage;
