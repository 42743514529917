import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../../Css/sideBar.css";
import CustomDrop from "../../subcom/CustomDrop";
const SideBarCom = () => {
  const [showDropdown, setShowDropdown] = useState(null);
  const [showDropdownChild, setShowDropdownChild] = useState(null);
  const [headerItem, setHeaderItems] = useState([]);
  const userRed = useSelector((state) => state.user);
  useEffect(() => {
    loadHeaderComs();
  }, [userRed]);
  const toggleDropDown = (no) => {
    if (showDropdown === no) {
      setShowDropdown(null);
      setShowDropdownChild(null);
    } else {
      setShowDropdown(no);
      setShowDropdownChild(null);
    }
  };
  const toggleDropDownChild = (no) => {
    if (showDropdownChild === no) {
      setShowDropdownChild(null);
    } else {
      setShowDropdownChild(no);
    }
  };
  const loadHeaderComs = async () => {
    let rights = [];
    if (userRed.user && Object.keys(userRed.user).length > 0) {
      if (userRed.user.right) {
        let myRights = userRed.user.right;
        Object.keys(myRights).map((key) => {
          rights.push({ ...myRights[key], docId: key });
        });
        const unsort = createRouteHierarchy(rights);

        const sortList = unsort.sort(function (a, b) {
          return a.no - b.no;
        });

        setHeaderItems(sortList);
      }
    }
  };

  return (
    <div>
      {headerItem.length > 0 &&
        headerItem.map((item, index) => {
          if (item.children && item.children.length > 0) {
            return (
              <CustomDrop
                title={item.title}
                index={index}
                show={showDropdown === index ? true : false}
                toggleDropDown={toggleDropDown}
              >
                {item.children.map((child, cIndex) => {
                  if (child.children && child.children.length > 0) {
                    return (
                      <li className="list-group-item dropdown-list">
                        <CustomDrop
                          title={child.title}
                          index={cIndex}
                          show={showDropdownChild === cIndex ? true : false}
                          toggleDropDown={toggleDropDownChild}
                        >
                          {child.children.map((grand) => {
                            return (
                              <li className="list-group-item ">
                                <Link
                                  to={grand.href}
                                  style={{
                                    fontSize: "15px",
                                    color: "black",
                                    textDecoration: "none",
                                  }}
                                >
                                  {grand.title}
                                </Link>
                              </li>
                            );
                          })}
                        </CustomDrop>
                      </li>
                    );
                  } else {
                    return (
                      <li className="list-group-item">
                        <Link
                          to={child.href}
                          style={{
                            fontSize: "15px",
                            color: "black",
                            textDecoration: "none",
                          }}
                        >
                          {child.title}
                        </Link>
                      </li>
                    );
                  }
                })}
              </CustomDrop>
            );
          } else {
            return (
              <Link
                className="btn btn-secondary dropdown-btn "
                to={item.href}
                style={{
                  textDecoration: "none",
                }}
              >
                {item.title}
              </Link>
            );
          }
        })}
    </div>
  );
};

export default SideBarCom;
function createRouteHierarchy(allLevels, parentId = null) {
  const LevelList = [];
  let catgory;
  if (parentId === null) {
    catgory = allLevels.filter((f) => f.parentId == undefined);
  } else {
    catgory = allLevels.filter((f) => f.parentId == parentId);
  }
  for (let cat of catgory) {
    LevelList.push({
      ...cat,
      children: createRouteHierarchy(allLevels, cat.docId),
    });
  }
  return LevelList;
}
