import React, { useEffect, useState } from "react";

import * as coaActions from "../../action/coaAction";
import { useSelector, useDispatch } from "react-redux";
import { FiChevronsRight } from "react-icons/fi";
//import UpdateTitle from './UpdateTitle';

import NewLevel1 from "./NewLevel1";
import NewLevel2 from "./NewLevel2";
import NewLevel3 from "./NewLevel3";
import UpdateTitle from "./UpdateTitle";
import CustomerCom from "./CustomerTab";
import DefineNewLevels from "./NewLevel3Details";
const ListLevels = (props) => {
  const { right } = props;
  const coa = useSelector((state) => state.coa);
  const [edit, setEdit] = useState(false);
  const [level1Array, setLevel1Array] = useState([]);
  const [level2Array, setLevel2Array] = useState([]);
  const [level3Array, setLevel3Array] = useState([]);
  const [level, setLevel] = useState({ data: {}, type: "" });
  const [updatedTitle, setUpdatedTitle] = useState("");
  const dispatch = useDispatch();

  const handleOnClick = (data, type) => {
    setLevel({ data, type });
    setUpdatedTitle(data.title);
    setEdit(false);
  };
  const deleteLevel = async () => {
    if (edit && level.data.children.length === 0) {
      if (level.type === "level1") {
        dispatch(coaActions.deleteTitleLevel1(level.data.docId, props.type));
      }
      if (level.type === "level2") {
        dispatch(coaActions.deleteTitleLevel2(level.data.docId, props.type));
      }
      if (level.type === "level3") {
        dispatch(coaActions.deleteTitleLevel3(level.data.docId, props.type));
      }
      setEdit(false);
      setLevel({ data: {}, type: "" });
    } else {
      alert("can not delete");
    }
  };
  const updateTitle = () => {
    if (edit) {
      if (level.type === "level1") {
        dispatch(coaActions.updateTitleLevel1(updatedTitle, level.data.docId));
      }
      if (level.type === "level2") {
        dispatch(coaActions.updateTitleLevel2(updatedTitle, level.data.docId));
      }
      if (level.type === "level3") {
        dispatch(coaActions.updateTitleLevel3(updatedTitle, level.data.docId));
      }
    } else {
      alert("please edit first");
    }
  };
  useEffect(() => {
    setLevel1Array(coa.level1);
    setLevel2Array(coa.level2);
    setLevel3Array(coa.level3);
  }, [coa]);
  const handleEditLevel = () => {
    if (level.type !== "") {
      setEdit(!edit);
    } else {
      alert("select level first");
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <div
          className="overflow-auto p-3 mb-3 mb-md-0 mr-md-3 bg-light"
          style={{ maxHeight: "450px", maxWidth: "750px" }}
        >
          <table className="table table-striped table-hover table-bordered">
            <thead>
              <tr>
                <th>
                  <NewLevel1
                    type={props.type}
                    nextId={
                      level1Array.length > 0 &&
                      level1Array[level1Array.length - 1]
                    }
                  />
                </th>
                <th>ID</th>
                <th>Title</th>
                <th>Type</th>
              </tr>
            </thead>

            <tbody>
              {props.integration.map((level1) => (
                <>
                  <tr>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={handleOnClick.bind(this, level1, "level1")}
                    >
                      <FiChevronsRight size={22} color="black" />
                    </td>
                    <td> {level1.id}</td>
                    <td> {level1.title}</td>
                    <td> {level1.type.toUpperCase()}</td>
                  </tr>
                  {level1.children.length > 0 &&
                    level1.children.map((level2) => (
                      <>
                        <tr key={coaActions.updateTitleLevel2.docId}>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={handleOnClick.bind(this, level2, "level2")}
                          >
                            <FiChevronsRight size={22} color="black" />
                          </td>
                          <td>
                            {" "}
                            {level1.id}
                            {level2.id}
                          </td>
                          <td> {level2.title}</td>
                          <td> {level1.type.toUpperCase()}</td>
                        </tr>
                        {level2.children.length > 0 &&
                          level2.children.map((level3) => (
                            <>
                              <tr key={level3.docId}>
                                <td
                                  style={{ cursor: "pointer" }}
                                  onClick={handleOnClick.bind(
                                    this,
                                    level3,
                                    "level3"
                                  )}
                                >
                                  <FiChevronsRight size={22} color="black" />
                                </td>
                                <td>
                                  {" "}
                                  {level1.id}
                                  {level2.id}
                                  {level3.id}
                                </td>
                                <td>{level3.title}</td>
                                <td> {level1.type.toUpperCase()}</td>
                              </tr>
                            </>
                          ))}
                      </>
                    ))}
                </>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{ margin: "10px", width: "500px" }}>
          <div style={{ marginLeft: "20px", padding: "5px" }}>
            <button
              style={{ margin: "8px" }}
              className="btnclass gradient-button-4"
              onClick={handleEditLevel}
              disabled={!right.edit}
            >
              Edit
            </button>
            <button
              style={{ margin: "8px" }}
              onClick={updateTitle}
              className="btnclass gradient-button-4"
              disabled={!right.edit}
            >
              Update
            </button>
            <button
              style={{ margin: "8px" }}
              onClick={deleteLevel}
              className="btnclass gradient-button-4"
              disabled={!right.delete}
            >
              Delete
            </button>
          </div>
          {level.type === "level1" && edit === false && (
            <NewLevel2
              type={props.type}
              level1Array={level1Array}
              level2Array={level2Array}
              level1Data={level.data}
            />
          )}
          {level.type === "level2" && edit === false && (
            <NewLevel3
              type={props.type}
              level1Array={level1Array}
              level2Array={level2Array}
              level3Array={level3Array}
              level2Data={level.data}
            />
          )}
          {level.type === "level3" && edit === false && (
            <div>
              <DefineNewLevels level3Data={level.data} />
              {props.type === "ct" || props.type === "sp" ? (
                <CustomerCom type={props.type} level3Data={level.data} />
              ) : null}
            </div>
          )}
          {level.type !== "" && edit === true && (
            <UpdateTitle
              title={updatedTitle}
              id={level.data.id}
              updateTitle={(title) => setUpdatedTitle(title)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ListLevels;
// <UpdateTitle title={level.data ? level.data.title : ""} updateTitle={updateTitle} deleteLevel={deleteLevel} />
