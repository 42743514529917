import firebase from 'firebase';
export const LOAD_STOCK_REQUEST = 'LOAD_STOCK_REQUEST'
export const LOAD_STOCK_SUCCESS = 'LOAD_STOCK_SUCCESS'
export const LOAD_STOCK_FAILURE = 'LOAD_STOCK_FAILURE'

export const UPDATE_STOCK_REQUEST = 'UPDATE_STOCK_REQUEST'
export const UPDATE_STOCK_SUCCESS = 'UPDATE_STOCK_SUCCESS'
export const UPDATE_STOCK_FAILURE = 'UPDATE_STOCK_FAILURE'
export const EDIT_STOCK_REQUEST = 'EDIT_STOCK_REQUEST'
export const EDIT_STOCK_SUCCESS = 'EDIT_STOCK_SUCCESS'
export const EDIT_STOCK_FAILURE = 'EDIT_STOCK_FAILURE'
export const editStockItem = (data) => {
    return async (dispatch, getState) => {
        console.log(data);
        const stock = getState().stock.stock;
        const filterStock = stock.filter(f => f.rId !== data.rId);
        const newStock = [...filterStock, data];
        dispatch({
            type: EDIT_STOCK_REQUEST
        });

        await firebase
            .firestore()
            .collection('StockReport')
            .doc(data.rId).update(data)
            .then(() => {
                dispatch({
                    type: EDIT_STOCK_SUCCESS,
                    payload: { stock: newStock }
                });
            })
            .catch(error => {
                dispatch({
                    type: EDIT_STOCK_FAILURE,
                    payload: { error: error.message }
                });
                console.log(error.message)
            });



    }
}
export const loadStockReport = () => {
    return async dispatch => {
        dispatch({
            type: LOAD_STOCK_REQUEST
        });
        const itemsRef = await firebase
            .firestore()
            .collection('items').get()
        await firebase
            .firestore()
            .collection('StockReport')
            .get().then(res => {
                const items = itemsRef.docs.map(d => {
                    const data = d.data();
                    return {
                        docId: d.id,
                        ...data
                    }
                })
                const docs = res.docs.map(d => {
                    const itemData = items.find(f => f.docId === d.id);
                    const data = d.data()
                    let showId = itemData.categorySr + itemData.typeSr + itemData.id;
                    return {
                        docId: d.id,
                        categorySr: itemData ? itemData.categorySr : 0,
                        typeSr: itemData ? itemData.typeSr : 0,
                        id: itemData ? itemData.id : 0,
                        balanceInStock: data.balanceInStock,
                        openingStock: data.openingStock ? data.openingStock : 0,
                        issuedQty: data.issuedQty,
                        receivedQty: data.receivedQty,
                        date: data.date,
                        name: data.name,
                        showId: showId,
                        category: itemData.category,
                        type: itemData.type,
                    }


                });
                if (docs.length > 0) {
                    dispatch({
                        type: LOAD_STOCK_SUCCESS,
                        payload: { stock: docs }
                    });
                } else {
                    dispatch({
                        type: LOAD_STOCK_FAILURE,
                        payload: { error: "data not found" }
                    });
                }
            }).catch(error => {
                dispatch({
                    type: LOAD_STOCK_FAILURE,
                    payload: { error: error.message }
                });
                console.log(error.message)
            });



    }
}
export const updateOpeningQty = (data) => {
    return async dispatch => {
        dispatch({
            type: UPDATE_STOCK_REQUEST,
        });
        let updatedArray = [];
        var db = firebase.firestore();
        var batch = db.batch()
        data.forEach(doc => {
            let docToUpdate = {
                openingStock: doc.openingStock,
                issuedQty: doc.issuedQty,
                name: doc.name,
                date: doc.date,
                balanceInStock: doc.balanceInStock,
                receivedQty: doc.receivedQty,
            }
            updatedArray.push({
                ...doc
            });
            var docRef = firebase
                .firestore()
                .collection('StockReport').doc(doc.docId)
            batch.set(docRef, docToUpdate)

        })
        await batch.commit().then(() => {
            dispatch({
                type: UPDATE_STOCK_SUCCESS,
                payload: { data: updatedArray }
            });
        }).catch((err) => {
            dispatch({
                type: EDIT_STOCK_FAILURE,
                payload: { error: "something is wrong" + err }
            });
        })




    }
}