import firebase from "firebase";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN__SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";


export const signup = (data) => {
  return async (dispatch) => {
    dispatch({ type: SIGNUP_REQUEST });
    const { email, name, password, role, picture } = data;
    let status;
    let query = firebase.firestore().collection("user").doc(email);
    let rights = await firebase.firestore().collection("routes").get();
    let rights2 = {};
    if (rights.size > 0) {
      rights.docs.map((doc) => {
        rights2 = { ...rights2, [doc.id]: { ...doc.data() } };
      });
    }
    const check = await query.get();
    if (check.exists) {
      status = "user already exist";
      dispatch({ type: SIGNUP_FAILURE, payload: status });
    } else {
      if (picture === "") {
        await query
          .set({ ...data })
          .then(async () => {
            await firebase
              .firestore()
              .collection("userRight")
              .doc(email)
              .set({ ...rights2 });
          })
          .then(() => {
            dispatch({
              type: SIGNUP_SUCCESS,
              payload: { ...data },
            });
          })
          .catch((err) => {
            dispatch({ type: SIGNUP_FAILURE, payload: err });
          });
      } else {
        await uploadImageHandler(picture, email)
          .then(async (url) => {
            const doc = {
              email,
              picture: url,
              name,
              password,
              role,
            };
            await query
              .set(doc)
              .then(() => {
                dispatch({ type: SIGNUP_SUCCESS, payload: doc });
              })
              .then(async () => {
                await firebase
                  .firestore()
                  .collection("userRight")
                  .doc(email)
                  .set({ ...rights2 });
              })
              .catch((err) => {
                dispatch({ type: SIGNUP_FAILURE, payload: err });
              });
          })
          .catch((err) => {
            dispatch({ type: SIGNUP_FAILURE, payload: err });
          });
      }
    }
  };
};

const uploadImageHandler = async (imgUrl, id) => {
  const storageRef = firebase.storage().ref();
  var metadata = {
    contentType: "image/jpeg",
  };
  const str = imgUrl.name;
  const change = str.split("").join("");
  const makeName = id + change;

  var uploadTask = storageRef.child("items/" + makeName).put(imgUrl, metadata);

  return await uploadTask.snapshot.ref.getDownloadURL();
};
export const signin = (data) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });
    const { email, password } = data;
    let status;
    let query = firebase.firestore().collection("user").doc(email);
    const check = await query.get();
    if (!check.exists) {
      status = "user does not exist";
      dispatch({ type: LOGIN_FAILURE, payload: status });
    } else {
      status = check.data();
      if (email === status.email && password === status.password) {
        status = check.data();
        if (status.role === "superadmin") {
          let userRightsRef = await firebase
            .firestore()
            .collection("routes")
            .get();
          let superadmin = {};
          userRightsRef.docs.map((doc) => {
            superadmin = {
              ...superadmin,
              [doc.id]: {
                ...doc.data(),
              },
            };
          });

          localStorage.setItem(
            "user",
            JSON.stringify({ ...status, right: superadmin })
          );
          dispatch({
            type: LOGIN_SUCCESS,
            payload: { ...status, right: superadmin },
          });
        } else {
          let userRightsRef = await firebase
            .firestore()
            .collection("userRight")
            .doc(email)
            .get();
          const userRightsData = userRightsRef.data();

          localStorage.setItem(
            "user",
            JSON.stringify({ ...status, right: userRightsData })
          );
          dispatch({
            type: LOGIN_SUCCESS,
            payload: { ...status, right: userRightsData },
          });
        }
      } else {
        status = "Please check your email or password";
        dispatch({ type: LOGIN_FAILURE, payload: status });
      }
    }
  };
};
export const isUserLoggedIn = () => {
  return async (dispatch) => {
    const user = localStorage.getItem("user");
    const parseUser = JSON.parse(user);
    if (user) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: parseUser,
      });
    } else {
      dispatch({
        type: LOGIN_FAILURE,
        payload: "Failed to login",
      });
    }
  };
};
export const logout = () => {
  return async (dispatch) => {
    const logoutRequest = localStorage.removeItem("user");

    dispatch({
      type: LOGOUT_SUCCESS,
    });
  };
};
