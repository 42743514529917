import React, { useState } from 'react'
import { Dropdown, Button, FormControl } from 'react-bootstrap';

const DropdownForVoucherAdj = (props) => {

    const { value, ledgerId, listInvoices } = props;
    
    const filterList = listInvoices;
    return (
        <Dropdown className='no-gutters p-0 m-0'>
            <Dropdown.Toggle as={Toggle} >
                <div style={{
                    width: '80px',
                    fontSize: '10px',
                    fontWeight: 'bold',
                    padding: '5px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'

                }} >
                    {value ? value : "Select Invoice"}
                </div>

            </Dropdown.Toggle>
            <Dropdown.Menu as={Menu}>
                {
                    filterList.map(invoice => (
                        <Dropdown.Item key={invoice.invoiceId}
                            onClick={() => props.handlerVoucherAdj(invoice)}
                        >
                            {invoice.invoiceId}
                        </Dropdown.Item>
                    ))
                }



            </Dropdown.Menu>

        </Dropdown>
    )
}
const CustomToggle = ({ children, onClick }, ref) => {
    return (
        <Button ref={ref}
            style={{ width: 'auto', height: 'auto' }}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}  >
            {children}
        </Button>
    )
}
const Toggle = React.forwardRef(CustomToggle);
const CustomMenu = ({ children, style, className }, ref) => {
    const [value, setValue] = useState('');
    return (
        <div style={style} className={className} ref={ref}>
            <FormControl
                autoFocus
                className="mx-3 my-2 w-auto"
                placeholder="Type to filter..."
                onChange={(e) => setValue(e.target.value)}
                value={value}
            />
            <ul className="overflow-auto p-3 mb-3 mb-md-0 mr-md-3 bg-light" style={{ maxHeight: '250px' }}>
                {
                    React.Children.toArray(children).filter(f => {

                        return (
                            !value || f.invoiceId.toLowerCase().includes(value)
                        )
                    })
                }
            </ul>

        </div>
    )
}
const Menu = React.forwardRef(CustomMenu);
export default DropdownForVoucherAdj;