import React, { useEffect, useState } from "react";
import { InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { costCenterList, locationList, projectIDList } from "../data/data1";
import CurrencyCom from "../subcom/CurrencyList";
import CustomDropDown from "../subcom/CustomDropDown";

import InputGroupCom from "../subcom/InputGroup";
const CoAndQuotation = (props) => {
  const {
    vType,
    customerData,
    party,
    selectedCustomer,
    currency,
    onLocationChange,
    onCostCenterChange,
    onProjectIdChange,
    location,
    costCenter,
    pId,
  } = props;
  const [hDate, setHDate] = useState("");
  const [defineLink, setDefineLink] = useState("");
  const dateProp = props.date;
  useEffect(() => {
    let date;

    if (dateProp) {
      if (dateProp === "") {
        date = new Date();
      }
      if (dateProp !== "") {
        date = new Date(dateProp);
      }
      if (dateProp instanceof Date) {
        date = new Date(dateProp);
      } else if (!(dateProp instanceof Date) && dateProp instanceof Object) {
        date = new Date(dateProp.toDate());
      }
    } else {
      date = new Date();
    }

    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    var today = year + "-" + month + "-" + day;

    setHDate(today);
    if (vType === "Purchase Order") {
      setDefineLink("/chartofaccount/supplier");
    }
    if (vType === "Client Order") {
      setDefineLink("/chartofaccount/client");
    }
    if (vType === "Quotation") {
      setDefineLink("/chartofaccount/client");
    }
  }, [props]);

  const handleDateChange = (e) => {
    if (props.edit) {
      if (props.editInvoice) {
        let date = e.target.value;
        let month = new Date(date).getMonth();
        let dbMonth = props.editInvoice.split("-");
        console.log("month is changing", { dbMonth });
        if (dbMonth[1] === (month + 1).toString()) {
          setHDate(date);
          props.changeDate(date);
        } else {
          alert("month cannot be changed");
        }
      } else {
        let date = e.target.value;
        setHDate(date);
        props.changeDate(date);
      }
    } else {
      alert("edit first to make change");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        margin: "10px",
      }}
    >
      <div className="row">
        <div className="col-4">
          <div className="row g-0 p-0 m-0 w-100" >
            <div className="col-6">
              <InputGroupCom
                type="date"
                value={hDate}
                onChange={handleDateChange}
                label="Date"
              />
            </div>
            <div className="col-6">
              <div
                style={{ display: "flex", flexDirection: "row" }}
                className="g-0 p-0 m-0 w-100"
              >
                <InputGroup.Text>Location</InputGroup.Text>
                <div style={{ width: "90%" }}>
                  {locationList.length > 0 && (
                    <CustomDropDown
                      list={locationList}
                      label={location===''?'choose':location}
                      selectedItem={(id) => {
                        let findLocation = locationList.find(
                          (f) => f.docId === id
                        );
                        onLocationChange(findLocation.title);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            <InputGroupCom
              type="text"
              value={props.vNo ? props.vNo.toString().toUpperCase() : ""}
              onChange={handleDateChange}
              label="Voucher NO"
            />
            <InputGroupCom
              type="text"
              value={""}
              onChange={() => {}}
              label="Tender #"
            />

            <CurrencyCom
              label="Currency"
              value={currency}
              onChange={props.onCurrencyChange}
            />
            <InputGroupCom
              type="text"
              value={""}
              onChange={() => {}}
              label="Exch Rate"
            />
          </div>
        </div>
        <div className="col-8">
          <div className="row g-0 p-0 m-0 w-100">
            <div className="col-4">
              <div
                style={{ display: "flex", flexDirection: "row" }}
                className="g-0 p-0 m-0 w-100"
              >
                <InputGroup.Text style={{ width: "20%" }}>
                  <Link to={defineLink}>{"Client"}</Link>
                </InputGroup.Text>
                <div style={{ width: "80%" }}>
                  {customerData.length > 0 && (
                    <CustomDropDown
                      list={customerData}
                      label={party===''?"choose":party}
                      selectedItem={selectedCustomer}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div
                style={{ display: "flex", flexDirection: "row" }}
                className="g-0 p-0 m-0 w-100"
              >
                <InputGroup.Text style={{ width: "20%" }}>PID</InputGroup.Text>
                <div style={{ width: "80%" }}>
                  {projectIDList.length > 0 && (
                    <CustomDropDown
                      list={projectIDList}
                    
                      label={pId===''?"choose":pId}
                      selectedItem={(id) => {
                        let findLocation = projectIDList.find(
                          (f) => f.docId === id
                        );
                        onProjectIdChange(findLocation.title);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div
                style={{ display: "flex", flexDirection: "row" }}
                className="g-0 p-0 m-0 w-100"
              >
                <InputGroup.Text style={{width:'40%'}}>Cost Center</InputGroup.Text>
                <div style={{width:'60%'}}>
                  {costCenterList.length > 0 && (
                    <CustomDropDown
                      list={costCenterList}
                      
                      label={costCenter===''?"choose":costCenter}
                      selectedItem={(id) => {
                        let findLocation = costCenterList.find(
                          (f) => f.docId === id
                        );
                        onCostCenterChange(findLocation.title);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row g-0 p-0 m-0 w-100">
            <div className="col-4">
              <InputGroupCom
                type="text"
                value={""}
                onChange={() => {}}
                label="Attn Person"
              />
            </div>
            <div className="col-4">
              <InputGroupCom
                type="text"
                value={""}
                onChange={() => {}}
                label="Designation"
              />
            </div>
            <div className="col-4">
              <InputGroupCom
                type="text"
                value={""}
                onChange={() => {}}
                label="C Person"
              />
            </div>
          </div>
          <InputGroupCom
            type="text"
            value={""}
            onChange={() => {}}
            label="Subject"
          />
        </div>
      </div>
      {/* {vType === "Quotation" ||
      vType === "Client Order" ||
      vType === "Good Receipt Note" ||
      vType === "Delivery Challan" ? (
        <h5>{vType}</h5>
      ) : (
        <h5>{vType + "-" + "Invoice"}</h5>
      )} */}
    </div>
  );
};

export default CoAndQuotation;
