import firebase from "firebase";

export const ADD_LEVEL_1_REQUEST = " ADD_LEVEL_1_REQUEST";
export const ADD_LEVEL_1_SUCCESS = " ADD_LEVEL_1_SUCCESS";
export const ADD_LEVEL_1_FAILURE = " ADD_LEVEL_1_FAILURE";

export const UPDATE_LEVEL_1_REQUEST = " UPDATE_LEVEL_1_REQUEST";
export const UPDATE_LEVEL_1_SUCCESS = " UPDATE_LEVEL_1_SUCCESS";
export const UPDATE_LEVEL_1_FAILURE = " UPDATE_LEVEL_1_FAILURE";

export const DELETE_LEVEL_1_REQUEST = " DELETE_LEVEL_1_REQUEST";
export const DELETE_LEVEL_1_SUCCESS = " DELETE_LEVEL_1_SUCCESS";
export const DELETE_LEVEL_1_FAILURE = " DELETE_LEVEL_1_FAILURE";

export const ADD_LEVEL_2_REQUEST = " ADD_LEVEL_2_REQUEST";
export const ADD_LEVEL_2_SUCCESS = " ADD_LEVEL_2_SUCCESS";
export const ADD_LEVEL_2_FAILURE = " ADD_LEVEL_2_FAILURE";

export const UPDATE_LEVEL_2_REQUEST = " UPDATE_LEVEL_2_REQUEST";
export const UPDATE_LEVEL_2_SUCCESS = " UPDATE_LEVEL_2_SUCCESS";
export const UPDATE_LEVEL_2_FAILURE = " UPDATE_LEVEL_2_FAILURE";

export const DELETE_LEVEL_2_REQUEST = " DELETE_LEVEL_2_REQUEST";
export const DELETE_LEVEL_2_SUCCESS = " DELETE_LEVEL_2_SUCCESS";
export const DELETE_LEVEL_2_FAILURE = " DELETE_LEVEL_2_FAILURE";

export const ADD_LEVEL_3_REQUEST = " ADD_LEVEL_3_REQUEST";
export const ADD_LEVEL_3_SUCCESS = " ADD_LEVEL_3_SUCCESS";
export const ADD_LEVEL_3_FAILURE = " ADD_LEVEL_3_FAILURE";

export const UPDATE_LEVEL_3_REQUEST = " UPDATE_LEVEL_3_REQUEST";
export const UPDATE_LEVEL_3_SUCCESS = " UPDATE_LEVEL_3_SUCCESS";
export const UPDATE_LEVEL_3_FAILURE = " UPDATE_LEVEL_3_FAILURE";

export const DELETE_LEVEL_3_REQUEST = " DELETE_LEVEL_3_REQUEST";
export const DELETE_LEVEL_3_SUCCESS = " DELETE_LEVEL_3_SUCCESS";
export const DELETE_LEVEL_3_FAILURE = " DELETE_LEVEL_3_FAILURE";

export const ALL_LEVEL_LOAD_REQUEST = " ALL_LEVEL_LOAD_REQUEST";
export const ALL_LEVEL_LOAD_SUCCESS = " ALL_LEVEL_LOAD_SUCCESS";
export const ALL_LEVEL_LOAD_FAILURE = " ALL_LEVEL_LOAD_FAILURE";

export const ADD_LEVEL3_CD_REQUEST = " ADD_LEVEL3_CD_REQUEST";
export const ADD_LEVEL3_CD_SUCCESS = " ADD_LEVEL3_CD_SUCCESS";
export const ADD_LEVEL3_CD_FAILURE = " ADD_LEVEL3_CD_FAILURE";

export const defineLevel1 = (data) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_LEVEL_1_REQUEST,
    });
    const query = firebase.firestore().collection("level1");
    let newId, error;
    if (data.id) {
      const makeIdRef = await query.where("id", "==", data.id).get();
      if (makeIdRef.size > 0) {
        error = "document already exist";
        dispatch({
          type: ADD_LEVEL_1_FAILURE,
          payload: { error: error },
        });
      } else {
        newId = data.id;
      }
    } else {
      const makeIdRef = await query.orderBy("id", "desc").limit(1).get();
      if (makeIdRef.size > 0) {
        const nextIdArray = makeIdRef.docs.map((d) => d.data());
        const id = parseInt(nextIdArray[0].id);
        if (id.toString().length === 1) {
          newId = `0${id + 1}`;
        } else if (id.toString().length === 2) {
          newId = `${id + 1}`;
        }
      } else {
        newId = "01";
      }
    }

    if (newId) {
      await query
        .add({ id: newId, title: data.level1Title, type: data.type })
        .then((docRef) => {
          dispatch({
            type: ADD_LEVEL_1_SUCCESS,
            payload: {
              data: {
                docId: docRef.id,
                id: newId,
                title: data.level1Title,
                type: data.type,
              },
            },
          });
        })
        .catch((err) => {
          dispatch({
            type: ADD_LEVEL_1_FAILURE,
            payload: { error: err },
          });
        });
    }
  };
};
export const defineLevel2 = (level1Id, parentId, level2Title, level2Id) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_LEVEL_2_REQUEST,
    });

    const query = firebase.firestore().collection("level2");
    let newId;
    if (level2Id) {
      const makeIdRef = await query.where("parentId", "==", parentId).get();
      if (makeIdRef.size > 0) {
        const check = makeIdRef.docs.filter((doc) => {
          let docData = doc.data();
          if (docData.id === level2Id) {
            return docData;
          }
        });

        if (check.length > 0) {
          dispatch({
            type: ADD_LEVEL_2_FAILURE,
            payload: {
              error: check,
            },
          });
        } else {
          newId = level2Id;
        }
      } else {
        newId = level2Id;
      }
    } else {
      const makeIdRef = await query.where("parentId", "==", parentId).get();
      if (makeIdRef.size > 0) {
        const nextIdArrayUnsorted = makeIdRef.docs.map((d) => d.data());
        const nextIdArraySorted = nextIdArrayUnsorted.sort(
          (a, b) => a.id - b.id
        );
        const nextid = nextIdArraySorted[nextIdArraySorted.length - 1].id;
        console.log(nextid);
        const id = parseInt(nextid);
        if (id.toString().length === 1) {
          newId = `00${id + 1}`;
        } else if (id.toString().length === 2) {
          newId = `0${id + 1}`;
        } else if (id.toString().length === 3) {
          newId = `${id + 1}`;
        }
      } else {
        newId = "001";
      }
    }

    const data = {
      id: newId,
      title: level2Title,
      level1Id: level1Id,
      parentId: parentId,
    };
    if (newId) {
      await query
        .add(data)
        .then(async (docRef) => {
          dispatch({
            type: ADD_LEVEL_2_SUCCESS,
            payload: {
              data: {
                docId: docRef.id,
                ...data,
              },
            },
          });
        })
        .catch((err) => {
          dispatch({
            type: ADD_LEVEL_2_FAILURE,
            payload: {
              error: err,
            },
          });
        });
    }
  };
};
export const defineLevel3 = (
  level2Id,
  parentId,
  level3Title,
  level3Id,
  type
) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_LEVEL_3_REQUEST,
    });
    const query = firebase.firestore().collection("level3");
    let newId;
    if (level3Id) {
      const makeIdRef = await query.where("parentId", "==", parentId).get();
      if (makeIdRef.size > 0) {
        const check = makeIdRef.docs.filter((doc) => {
          let docData = doc.data();
          if (docData.id === level3Id) {
            return docData;
          }
        });
        if (check.length > 0) {
          dispatch({
            type: ADD_LEVEL_3_FAILURE,
            payload: {
              error: check,
            },
          });
        } else {
          newId = level3Id;
        }
      } else {
        newId = level3Id;
      }
    } else {
      const makeIdRef = await query.where("parentId", "==", parentId).get();
      if (makeIdRef.size > 0) {
        const nextIdArrayUnsorted = makeIdRef.docs.map((d) => d.data());
        const nextIdArraySorted = nextIdArrayUnsorted.sort(
          (a, b) => a.id - b.id
        );
        const nextid = nextIdArraySorted[nextIdArraySorted.length - 1].id;

        const id = parseInt(nextid);
        if (id.toString().length === 1) {
          newId = `000${id + 1}`;
        } else if (id.toString().length === 2) {
          newId = `00${id + 1}`;
        } else if (id.toString().length === 3) {
          newId = `0${id + 1}`;
        } else if (id.toString().length === 4) {
          newId = `${id + 1}`;
        }
      } else {
        newId = "0001";
      }
    }

    const data = {
      id: newId,
      title: level3Title,
      level1Id: level2Id,
      parentId: parentId,
    };
    if (newId) {
      await query
        .add(data)
        .then(async (docRef) => {
          dispatch({
            type: ADD_LEVEL_3_SUCCESS,
            payload: { data: { docId: docRef.id, ...data } },
          });
          if (type === "ct" || type === "sp") {
            let doc = {
              id: docRef.id,
              name: level3Title,
              phoneNumber: "",
              address: "",
              email: "",
              contactNumber: "",
              customerType: type,
              level3: docRef.id,
              imgUrl: "",
            };
            if (type === "ct") {
              await firebase
                .firestore()
                .collection("customer")
                .doc(docRef.id)
                .set(doc);
            } else {
              await firebase
                .firestore()
                .collection("supplier")
                .doc(docRef.id)
                .set(doc);
            }
          }
        })
        .catch((err) => {
          dispatch({
            type: ADD_LEVEL_3_FAILURE,
            payload: { error: err },
          });
        });
    }
  };
};
export const getAllLevel = () => {
  return async (dispatch) => {
    dispatch({
      type: ALL_LEVEL_LOAD_REQUEST,
    });
    let level1 = [],
      level2 = [],
      level3 = [],
      error;
    await firebase
      .firestore()
      .collection("level1")
      .orderBy("id", "asc")
      .get()
      .then((Level1Ref) => {
        if (Level1Ref.size > 0) {
          const nextIdArray = Level1Ref.docs.map((d) => {
            return { ...d.data(), docId: d.id };
          });
          level1 = [...nextIdArray];
        } else {
        }
      })
      .catch((err) => {
        error = err;
      });
    await firebase
      .firestore()
      .collection("level2")
      .orderBy("id", "asc")
      .get()
      .then((Level2Ref) => {
        if (Level2Ref.size > 0) {
          const nextIdArray = Level2Ref.docs.map((d) => {
            return { ...d.data(), docId: d.id };
          });
          level2 = [...nextIdArray];
        } else {
        }
      })
      .catch((err) => {
        error = err;
      });
    await firebase
      .firestore()
      .collection("level3")
      .orderBy("id", "asc")
      .get()
      .then((Level3Ref) => {
        if (Level3Ref.size > 0) {
          const nextIdArray = Level3Ref.docs.map((d) => {
            return {
              ...d.data(),
              docId: d.id,
            };
          });
          level3 = [...nextIdArray];
        } else {
        }
      })
      .catch((err) => {
        error = err;
      });

    if (!error) {
      dispatch({
        type: ALL_LEVEL_LOAD_SUCCESS,
        payload: { level1, level2, level3 },
      });
    } else {
      dispatch({
        type: ALL_LEVEL_LOAD_FAILURE,
        payload: { error },
      });
    }
  };
};
export const updateTitleLevel1 = (title, docId) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_LEVEL_1_REQUEST });
    await firebase
      .firestore()
      .collection("level1")
      .doc(docId)
      .update({ title })
      .then(() => {
        dispatch({
          type: UPDATE_LEVEL_1_SUCCESS,
          payload: { data: { docId, title } },
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_LEVEL_1_FAILURE,
          payload: { error: err },
        });
        console.log(err);
      });
  };
};
export const updateTitleLevel2 = (title, docId) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_LEVEL_2_REQUEST });
    await firebase
      .firestore()
      .collection("level2")
      .doc(docId)
      .update({ title })
      .then(() => {
        dispatch({
          type: UPDATE_LEVEL_2_SUCCESS,
          payload: { data: { docId, title } },
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_LEVEL_2_FAILURE,
          payload: { error: err },
        });
        console.log(err);
      });
  };
};
export const updateTitleLevel3 = (title, docId) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_LEVEL_3_REQUEST });
    await firebase
      .firestore()
      .collection("level3")
      .doc(docId)
      .update({ title })
      .then(() => {
        dispatch({
          type: UPDATE_LEVEL_3_SUCCESS,
          payload: { data: { docId, title } },
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_LEVEL_3_FAILURE,
          payload: { error: err },
        });
        console.log(err);
      });
  };
};
export const deleteTitleLevel1 = (docId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_LEVEL_1_REQUEST });
    await firebase
      .firestore()
      .collection("level1")
      .doc(docId)
      .delete()
      .then(() => {
        dispatch({
          type: DELETE_LEVEL_1_SUCCESS,
          payload: { data: docId },
        });
      })
      .catch((err) => {
        dispatch({
          type: DELETE_LEVEL_1_FAILURE,
          payload: { error: err },
        });
        console.log(err);
      });
  };
};
export const deleteTitleLevel2 = (docId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_LEVEL_2_REQUEST });
    await firebase
      .firestore()
      .collection("level2")
      .doc(docId)
      .delete()
      .then(() => {
        dispatch({
          type: DELETE_LEVEL_2_SUCCESS,
          payload: { data: docId },
        });
      })
      .catch((err) => {
        dispatch({
          type: DELETE_LEVEL_2_FAILURE,
          payload: { error: err },
        });
        console.log(err);
      });
  };
};
export const deleteTitleLevel3 = (docId, type) => {
  return async (dispatch) => {
    console.log({ type });
    let query = firebase.firestore();
    dispatch({ type: DELETE_LEVEL_3_REQUEST });
    let dataFound = false;
    const sellInvoiceRef = await query
      .collection("invoicesSell")
      .where("ledgerId", "==", docId)
      .get();
    if (sellInvoiceRef.size > 0) {
      dataFound = true;
    }
    if (!dataFound) {
      const purchaseInvoiceRef = await query
        .collection("invoicesPurchase")
        .where("ledgerId", "==", docId)
        .get();
      if (purchaseInvoiceRef.size > 0) {
        dataFound = true;
      }
    }
    if (!dataFound) {
      const bpVouchersRef = await query.collection("Bank PaymentVoucher").get();
      if (bpVouchersRef.size > 0) {
        bpVouchersRef.docs.map((doc) => {
          const docData = doc.data();
          docData.jvItems.map((item) => {
            if (item.ledgerId === docId) {
              dataFound = true;
            }
          });
        });
      }
    }
    if (!dataFound) {
      const cpVouchersRef = await query.collection("Cash PaymentVoucher").get();
      if (cpVouchersRef.size > 0) {
        cpVouchersRef.docs.map((doc) => {
          const docData = doc.data();
          docData.jvItems.map((item) => {
            if (item.ledgerId === docId) {
              dataFound = true;
            }
          });
        });
      }
    }
    if (!dataFound) {
      const brVouchersRef = await query.collection("Bank ReceiptVoucher").get();
      if (brVouchersRef.size > 0) {
        brVouchersRef.docs.map((doc) => {
          const docData = doc.data();
          docData.jvItems.map((item) => {
            if (item.ledgerId === docId) {
              dataFound = true;
            }
          });
        });
      }
    }
    if (!dataFound) {
      const crVouchersRef = await query.collection("Cash ReceiptVoucher").get();
      if (crVouchersRef.size > 0) {
        crVouchersRef.docs.map((doc) => {
          const docData = doc.data();
          docData.jvItems.map((item) => {
            if (item.ledgerId === docId) {
              dataFound = true;
            }
          });
        });
      }
    }
    if (!dataFound) {
      const jvVouchersRef = await query
        .collection("Journal VoucherVoucher")
        .get();
      if (jvVouchersRef.size > 0) {
        jvVouchersRef.docs.map((doc) => {
          const docData = doc.data();
          docData.jvItems.map((item) => {
            if (item.ledgerId === docId) {
              dataFound = true;
            }
          });
        });
      }
    }
    if (!dataFound) {
      await query
        .collection("level3")
        .doc(docId)
        .delete()
        .then(() => {
          dispatch({
            type: DELETE_LEVEL_3_SUCCESS,
            payload: { data: docId },
          });
        })
        .catch((err) => {
          dispatch({
            type: DELETE_LEVEL_3_FAILURE,
            payload: { error: err },
          });
          console.log(err);
        });
    } else {
      dispatch({
        type: DELETE_LEVEL_3_FAILURE,
        payload: { error: "some data found" },
      });
    }
    console.log({ dataFound });
  };
};

export const clearErrorMessage = () => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_LEVEL_2_FAILURE,
      payload: { error: "" },
    });
  };
};
export const addCreditDebitLevel3 = (data) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_LEVEL3_CD_REQUEST,
    });
    let updatedArray = [];
    var db = firebase.firestore();
    var batch = db.batch();
    data.forEach((doc) => {
      let docToUpdate = {
        id: doc.id,
        title: doc.title,
        parentId: doc.parentId,
        credit: doc.credit,
        debit: doc.debit,
      };
      updatedArray.push({
        docId: doc.docId,
        ...docToUpdate,
      });
      var docRef = firebase.firestore().collection("level3").doc(doc.docId);
      batch.set(docRef, docToUpdate);
    });
    await batch
      .commit()
      .then(() => {
        dispatch({
          type: ADD_LEVEL3_CD_SUCCESS,
          payload: { data: updatedArray },
        });
      })
      .catch((err) => {
        dispatch({
          type: ADD_LEVEL3_CD_FAILURE,
          payload: { error: "something is wrong" + err },
        });
      });
  };
};
