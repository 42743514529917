import React from "react";
import { Link } from "react-router-dom";
const LinkCom = (props) => {
  const vNo = props.vNo;
  let link;
  const subType = vNo.split("-")[0];
  if (
    subType === "si" ||
    subType === "pu" ||
    subType === "sr" ||
    subType === "pr"
  ) {
    if (subType === "si") {
      link = `/invoice/${"Sell"}/${vNo}`;
    } else if (subType === "sr") {
      link = `/invoice/${"Sell Return"}/${vNo}`;
    } else if (subType === "pu") {
      link = `/invoice/${"Purchase"}/${vNo}`;
    } else if (subType === "pr") {
      link = `/invoice/${"Purchase Return"}/${vNo}`;
    }
  } else {
    if (subType === "cp") {
      link = `/jvpage/${"Cash Payment"}/${vNo}`;
    }
    if (subType === "jv") {
      link = `/jvpage/${"Journal Voucher"}/${vNo}`;
    }
    if (subType === "bp") {
      link = `/jvpage/${"Bank Payment"}/${vNo}`;
    }
    if (subType === "br") {
      link = `/jvpage/${"Bank Receipt"}/${vNo}`;
    }
    if (subType === "cr") {
      link = `/jvpage/${"Cash Receipt"}/${vNo}`;
    }
  }

  return (
    <Link
      to={link}
      target="_blank"
      style={{ textDecoration: "none", color: "black" }}
    >
      {props.vNo ? props.vNo.toString().toUpperCase() : ""}
    </Link>
  );
};
export default LinkCom;
