import firebase from "firebase";
export function createRouteHierarchy(allLevels, parentId = null) {
  const LevelList = [];
  let catgory;
  if (parentId === null) {
    catgory = allLevels.filter((f) => f.parentId == undefined);
  } else {
    catgory = allLevels.filter((f) => f.parentId == parentId);
  }
  for (let cat of catgory) {
    LevelList.push({
      docId: cat.docId,
      title: cat.title,
      parentId: cat.parentId,
      href: cat.href,
      no: cat.no,
      children: createRouteHierarchy(allLevels, cat.docId),
    });
  }
  return LevelList;
}
export const addRoute = async (data, type) => {
  let newAddition = {
    title: data.routeName,
    href: data.routeLink,
    no: data.listNumber,
    right: data.applyRules,
  };
  let newLogoAdd = {
    title: data.routeName,
    href: data.routeLink,
    no: data.listNumber,
    show: false,
  };
  if (data.parentId && data.parentId !== "") {
    newAddition = { ...newAddition, parentId: data.parentId };
  }
  let query = firebase.firestore().collection("routes");
  let queryLogo = firebase
    .firestore()
    .collection("gobalsettings")
    .doc("showLogoSetting");
  if (data.updateId) {
    if (type === "reportAction") {
      const getLogoRef = await queryLogo.get();
      if (getLogoRef.exists) {
        await queryLogo.update({ [data.updateId]: newLogoAdd });
      } else {
        await queryLogo.set({ [data.updateId]: newLogoAdd });
      }
    }
    await query.doc(data.updateId).set(newAddition);
    await loadUserRoutesCollection().then((userRights) => {
      userRights.map(async (user) => {
        await firebase
          .firestore()
          .collection("userRight")
          .doc(user.docId)
          .update({ [data.updateId]: newAddition });
      });
    });
  } else {
    await query.add(newAddition).then(async (docRef) => {
      if (type === "reportAction") {
        const getLogoRef = await queryLogo.get();
        if (getLogoRef.exists) {
          await queryLogo.update({ [docRef.id]: newLogoAdd });
        } else {
          await queryLogo.set({ [docRef.id]: newLogoAdd });
        }
      }
      await loadUserRoutesCollection().then((userRights) => {
        userRights.map(async (user) => {
          await firebase
            .firestore()
            .collection("userRight")
            .doc(user.docId)
            .update({ [docRef.id]: newAddition });
        });
      });
    });
  }
};
export const loadRoutes = async () => {
  let docs = [];
  const loadRoutes = await firebase.firestore().collection("routes").get();
  if (loadRoutes.size > 0) {
    docs = loadRoutes.docs.map((doc) => {
      return {
        ...doc.data(),
        docId: doc.id,
      };
    });
  }
  return docs;
};
export const loadUserRoutesCollection = async () => {
  let docs = [];
  const loadRoutes = await firebase.firestore().collection("userRight").get();
  if (loadRoutes.size > 0) {
    docs = loadRoutes.docs.map((doc) => {
      return {
        docId: doc.id,
      };
    });
  }
  return docs;
};
export const deleteRoute = async (id, type) => {
  await firebase
    .firestore()
    .collection("routes")
    .doc(id)
    .delete()
    .then(async () => {
      const loadRoutes = await firebase
        .firestore()
        .collection("userRight")
        .get();
      if (loadRoutes.size > 0) {
        loadRoutes.docs.map(async (doc) => {
          let data = doc.data();
          delete data[id];
          await firebase
            .firestore()
            .collection("userRight")
            .doc(doc.id)
            .update({ ...data });
        });
      }
    })
    .then(async () => {
      let queryUpdate = firebase
        .firestore()
        .collection("gobalsettings")
        .doc("showLogoSetting");
      let newList = {};
      await queryUpdate.get().then(async (doc) => {
        if (doc.exists) {
          let listLogo = doc.data();

          Object.keys(listLogo).map((item) => {
            if (item !== id) {
              newList = { ...newList, [item]: listLogo[item] };
            }
          });
          await queryUpdate.set(newList);
        }
      });
    });
};
