import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import * as dsActions from "../../action/detailsSummay";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import StartAndEndDate from "../../components/general/StartAndEndDate";
import moment from "moment";
import * as coaActions from "../../action/coaAction";
import "../../Css/generalStyle.css";
import CustomDropDown from "../../subcom/CustomDropDown";
import { Link } from "react-router-dom";
import OffcanvasCom from "../../subcom/OffcanvasCom";
const ReceiptDS = (props) => {
  const reportParam = props.match.params.type;
  const dispatch = useDispatch();
  const coa = useSelector((state) => state.coa);
  const globalRed = useSelector((state) => state.global);
  const [report, setReport] = useState([]);
  const [customers, setListCustomers] = useState([]);
  const [show, setShow] = useState("details");
  const [selectedCustomerName, setSelectedCustomerName] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [listSpecificName, setListSpecificName] = useState("");
  const [listSpecific, setListSpecific] = useState("");
  const [rDate, setRDate] = useState({
    start: "",
    end: "",
  });
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [sortedByDate, setSortedByDate] = useState(false);
  let grand = 0;
  useEffect(async () => {
    dispatch(coaActions.getAllLevel());
    setReport([]);
  }, [reportParam]);
  useEffect(async () => {
    if (globalRed.date !== "") {
      let endDate = new Date().toISOString().slice(0, 10);
      let endFormat = moment(endDate).format("YYYY-MM-DD");
      setRDate({ start: globalRed.date, end: endFormat });
      if (reportParam === "payment") {
        console.log({reportParam})
        const data = await dsActions.paymentDetailsAndSummay(
          null,
          globalRed.date,
          endFormat,
          null
        );
        setReport(data);
      } else {
        const data = await dsActions.receiptDetailsAndSummay(
          null,
          globalRed.date,
          endFormat,
          null
        );
        setReport(data);
      }
    }
  }, [globalRed, reportParam]);
  const handleReportChangeType = (val) => {
    setShow(val);
  };

  
  const [reportRight, setReportRight] = useState({});
  useEffect(() => {
    setReportRight(props.rights);
  }, []);
  useEffect(() => {
    let level = [];
    coa.allLevels.map((level1) => {
      if (level1.children.length > 0) {
        level1.children.map((level2) => {
          if (level2.children.length > 0) {
            level2.children.map((level3) => {
              level.push(level3);
            });
          }
        });
      }
    });
    if (level.length > 0) {
      setListCustomers([{ docId: 0, title: "All Accounts" }, ...level]);
    }
  }, [coa]);
  const loadData = async () => {
    if (rDate.start !== "" && rDate.end !== "" && selectedCustomer !== "") {
      let listSpecificID;
      if (listSpecific === "" || listSpecific === 0) {
        listSpecificID = null;
      } else {
        listSpecificID = listSpecific;
      }
      if (reportParam === "payment") {
        const data = await dsActions.paymentDetailsAndSummay(
          selectedCustomer,
          rDate.start,
          rDate.end,
          listSpecificID
        );

        await setReport(data);
      } else {
        const data = await dsActions.receiptDetailsAndSummay(
          selectedCustomer,
          rDate.start,
          rDate.end,
          listSpecificID
        );

        await setReport(data);
      }
    } else {
      alert("please enter all parameters");
      return;
    }
    setShowOffCanvas(!showOffCanvas);
  };
  const sortByDate = async () => {
    let unsorted = [];

    if (sortedByDate) {
      unsorted = await report.sort(function (a, b) {
        let aDate = a.date && a.date.toDate().toLocaleDateString();
        let bDate = a.date && b.date.toDate().toLocaleDateString();
        return new Date(aDate) - new Date(bDate);
      });
    } else {
      unsorted = await report.sort(function (a, b) {
        let aDate = a.date && a.date.toDate().toLocaleDateString();
        let bDate = a.date && b.date.toDate().toLocaleDateString();
        return new Date(bDate) - new Date(aDate);
      });
    }

    setReport(unsorted);
    setSortedByDate(!sortedByDate);
  };
  const selectedItemData = (id) => {
    if (id === 0) {
      setSelectedCustomer(null);
      setSelectedCustomerName("All Accounts");
    } else {
      const getCus = customers.find((f) => f.docId === id);
      setSelectedCustomer(getCus.docId);
      setSelectedCustomerName(getCus.title);
    }
  };
  const selectedListSpecificName = (id) => {
    if (id === 0) {
      setListSpecific(null);
      setListSpecificName("All Accounts");
    } else {
      const getCus = customers.find((f) => f.docId === id);
      setListSpecific(getCus.docId);
      setListSpecificName(getCus.title);
    }
  };
  const handleStartDate = (e) => {
    const val = e.target.value;

    setRDate({ start: val, end: "" });
  };
  const handleEndDate = (e) => {
    const val = e.target.value;
    setRDate({ start: rDate.start, end: val });
  };
  const handlePrintOptions = () => {
    localStorage.setItem(
      "printpaymentreceipt",
      JSON.stringify({
        customer: selectedCustomerName,
        listName: listSpecificName,
        rDate,
        report,
        show,
        reportParam,
      })
    );
  };
  return (
    <Layout>
      <div className="rowClass">
        <OffcanvasCom
          show={showOffCanvas}
          handleOffcanvas={() => setShowOffCanvas(!showOffCanvas)}
        >
          <CustomDropDown
            list={customers}
            label={
              selectedCustomerName === ""
                ? "Cash/Bank Selection"
                : selectedCustomerName
            }
            selectedItem={(id) => selectedItemData(id)}
          />
          <StartAndEndDate
            rDate={rDate}
            selectedCustomer={listSpecific}
            selectedCustomerName={
              listSpecificName === "" ? "Acc Selection" : listSpecificName
            }
            handleStartDate={handleStartDate}
            handleEndDate={handleEndDate}
            loadData={loadData}
            selectedItemData={selectedListSpecificName}
            customers={customers}
          />
          <div>
            <Form.Check
              type="radio"
              label="Summary "
              value="summary"
              name="reportType"
              checked={show === "summary" ? true : false}
              onChange={(e) => handleReportChangeType(e.target.value)}
            />
            <Form.Check
              type="radio"
              label="Details"
              value="details"
              name="reportType"
              checked={show === "details" ? true : false}
              onChange={(e) => handleReportChangeType(e.target.value)}
            />
          </div>
        </OffcanvasCom>
        <h3>
          {reportParam === "receipt"
            ? " Receipt Details and Summary"
            : "Payment Details and Summary"}
        </h3>
      </div>

      {report.length > 0 ? (
        <table className="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              {show === "details" && (
                <>
                  <th onClick={sortByDate}>Dated</th>
                  <th>VNo</th>
                </>
              )}

              <th>Account Title</th>
              {show === "details" && (
                <>
                  <th>Remarks</th>
                  <th>Ref No</th>
                  <th>Ref Date</th>
                </>
              )}

              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {report.length > 0 &&
              report.map((item) => {
                let total = 0;
                if (item.data && item.data.length > 0) {
                  return (
                    <>
                      <>
                        {show === "details" && (
                          <tr>
                            <td
                              colSpan={7}
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                            >
                              {item.title}
                            </td>
                          </tr>
                        )}

                        {item.data.map((d) => {
                          if (d.items && d.items.length > 0) {
                            return (
                              <>
                                {d.items.map((jvItems) => {
                                  if (
                                    parseFloat(jvItems.accDebit) > 0 ||
                                    parseFloat(jvItems.accCredit) > 0
                                  ) {
                                    if (reportParam === "payment") {
                                      total =
                                        total + parseFloat(jvItems.accDebit);
                                      grand =
                                        grand + parseFloat(jvItems.accDebit);
                                    } else {
                                      total =
                                        total + parseFloat(jvItems.accCredit);
                                      grand =
                                        grand + parseFloat(jvItems.accCredit);
                                    }

                                    if (show === "details") {
                                      return (
                                        <tr>
                                          <td>
                                            {d.date &&
                                              d.date
                                                .toDate()
                                                .toLocaleDateString()}
                                          </td>
                                          <td>{d.id}</td>
                                          <td>{jvItems.party}</td>
                                          <td>{jvItems.lineNarration}</td>
                                          <td>{jvItems.checkDate}</td>
                                          <td>{jvItems.checkNo}</td>

                                          <td className="thRight">
                                            {reportParam === "payment"
                                              ? jvItems.accDebit
                                              : jvItems.accCredit}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  }
                                })}
                              </>
                            );
                          }
                        })}
                      </>

                      <tr>
                        {show === "details" && (
                          <>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </>
                        )}
                        <td>{show !== "details" ? item.title : "Sub Total"}</td>
                        <td className="thRight">{total}</td>
                      </tr>
                    </>
                  );
                }
              })}
            <tr>
              {show === "details" && (
                <>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </>
              )}
              <td></td>
              <td className="thRight">{grand}</td>
            </tr>
          </tbody>
        </table>
      ) : (
        <div>loading</div>
      )}
      {report.length > 0 && (
        <div className="row">
          <div className="col">
            {reportRight && reportRight.print ? (
              <Link
                onClick={handlePrintOptions}
                target="_blank"
                to={`/printpaymentreceipt`}
              >
                Print
              </Link>
            ) : null}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default ReceiptDS;
