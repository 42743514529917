import {
    LOAD_ITEM_REQUEST,
    LOAD_ITEM_SUCCESS,
    LOAD_ITEM_FAILURE,
    addItemConstants,
    DELETE_ITEM_FAILURE,
    DELETE_ITEM_REQUEST,
    DELETE_ITEM_SUCCESS,
    editItemConstants
} from '../action/itemAction';
const initialState = {
    loading: false,
    item: [],
    error: '',

};
const itemReducer = (state = initialState, action) => {
    switch (action.type) {
        case addItemConstants.ADD_ITEM_REQUEST:
            {
                state = {
                    ...state,
                    loading: true,
                }
            }
            break;
        case addItemConstants.ADD_ITEM_SUCCESS:
            {
                state = {
                    ...state,
                    item: [...state.item, action.payload.data],
                    loading: false,
                }

            }
            break;
        case addItemConstants.ADD_ITEM_FAILURE:
            {
                state = {
                    ...state,
                    error: action.payload.error,
                    loading: false,
                }

            }
            break;

        case editItemConstants.EDIT_ITEM_REQUEST:
            {
                state = {
                    ...state,
                    loading: true,
                }
            }
            break;
        case editItemConstants.EDIT_ITEM_SUCCESS:

            {
                const data = action.payload.data;
                let newArray = [];
                state.item.forEach(loop => {
                    if (loop.docId === data.docId) {
                        newArray.push(data);
                    } else {
                        newArray.push(loop)
                    }
                })

                state = {
                    ...state,
                    item: newArray,
                    loading: false,
                }

            }
            break;
        case editItemConstants.EDIT_ITEM_FAILURE:
            {
                state = {
                    ...state,
                    error: action.payload.error,
                    loading: false,
                }

            }
            break;
        case DELETE_ITEM_REQUEST:
            {
                state = {
                    ...state,
                    loading: true,
                }
            }
            break;
        case DELETE_ITEM_SUCCESS:
            const filterItem = state.item.filter(f => f.docId !== action.payload.data)
            {
                state = {
                    ...state,
                    item: filterItem,
                    loading: false,
                }

            }
            break;
        case DELETE_ITEM_FAILURE:
            {
                state = {
                    ...state,
                    error: action.payload.error,
                    loading: false,
                }

            }
            break;

        case LOAD_ITEM_REQUEST:
            {
                state = {
                    ...state,
                    loading: true,
                }
            }
            break;
        case LOAD_ITEM_SUCCESS:
            {
                state = {
                    ...state,
                    item: action.payload,
                    loading: false,
                }

            }
            break;
        case LOAD_ITEM_FAILURE:
            {
                state = {
                    ...state,
                    error: action.payload.error,
                    item: [],
                    loading: false,
                }

            }
            break;


    }

    return state;
};
export default itemReducer;