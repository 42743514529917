import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../../Css/invoiceStyle.css";
const SlipHeader = (props) => {
  const { companyData, data, vNo, slipType, showLogo, type } = props;
  const globalRed = useSelector((state) => state.global);
  const [showInfo, setShowInfo] = useState("");
  useEffect(() => {
    setShowInfo(globalRed.companyInfoShow);
  }, [globalRed]);
  return (
    <div>
      {showInfo ? (
        <div>
          <div className="center">
            <div className="slipDes">
              {showLogo && (
                <div className="imgstyleing">
                  <img src={companyData.logo} className="responsiveImage" />
                </div>
              )}
            </div>
            <div className="companyInfo">
              <p className="heading">
                {companyData.name && companyData.name.toUpperCase()}
              </p>

              {companyData.address && <span>{companyData.address}</span>}

              {companyData.mobileNo && (
                <span>Ph -{companyData.mobileNo.toUpperCase()}</span>
              )}

              {companyData.email && <span> Email- {companyData.email}</span>}

              {companyData.ntn && <p>PNTN- {companyData.ntn}</p>}
              <p className="smallheading">{type && type}</p>
            </div>
          </div>
          <div className="center">
            {data.party && (
              <div className="col-sm-4">
                <p>Buyer name {data.party}</p>
              </div>
            )}

            <div className="col-sm-4">
              {" "}
              {slipType === "Invoice" && "Invoice:"}
              {slipType === "Voucher" && "Voucher:"}
              {vNo ? vNo.toUpperCase() : vNo}
            </div>
            <div className="col-sm-4">
              {data.date && (
                <p>
                  <span> Date :</span>
                  {data.date.toDate().toLocaleDateString()}
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="companyContainer">
          <div className="center">
            {data.party && (
              <div className="col-sm-4">
                <p>Buyer name {data.party}</p>
              </div>
            )}

            <div className="col-sm-4">
              {" "}
              {type && type}
              {slipType === "Invoice" && "Invoice:"}
              {slipType === "Voucher" && "Voucher:"}
              {vNo ? vNo.toUpperCase() : vNo}
            </div>
            <div className="col-sm-4">
              {data.date && (
                <p>
                  <span> Date :</span>
                  {data.date.toDate().toLocaleDateString()}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SlipHeader;
