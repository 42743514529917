import firebase from "firebase";

export const receiptDetailsAndSummay = async (docId, start, end,listName) => {
  let report = [];

  if ((start, end)) {
    let serverDate = firebase.firestore.Timestamp.fromDate(new Date(start));
    let serverEnd = firebase.firestore.Timestamp.fromDate(new Date(end));

    let crRef = await firebase
      .firestore()
      .collection("Cash ReceiptVoucher")
      .where("date", ">=", serverDate)
      .where("date", "<=", serverEnd)
      .get();
    let brRef = await firebase
      .firestore()
      .collection("Bank ReceiptVoucher")
      .where("date", ">=", serverDate)
      .where("date", "<=", serverEnd)
      .get();
    let level3Ref = await firebase.firestore().collection("level3").get();

    let level3Data = level3Ref.docs.map((doc) => {
      let data = { ...doc.data(), docId: doc.id };
      return data;
    });
    let crData = crRef.docs.map((doc) => {
      let data = { ...doc.data(), docId: doc.id };
      return data;
    });
    let brData = brRef.docs.map((doc) => {
      let data = { ...doc.data(), docId: doc.id };
      return data;
    });
    const voucher = [...crData, ...brData];

    if (docId) {
      level3Data = level3Data.filter((doc) => doc.docId === docId);
    }

    await level3Data.map((level) => {
      let data = [];
      voucher.forEach((f) => {
        const AccFind = f.jvItems.find((item) => level.docId === item.ledgerId);
        if (AccFind) {
          let jvItems = f.jvItems.filter((item) => {
            if (
              level.docId !== item.ledgerId &&
              parseFloat(item.accCredit) > 0
            ) {
              if (listName) {
                if (listName === item.ledgerId) {
                  return item;
                }
              } else {
                return item;
              }
            }
          });
          if (jvItems.length > 0) {
            data.push({
              date: f.date,
              id: f.docId,
              items: jvItems,
            });
          }
        }
      });
      if (data.length > 0) {
        report.push({ ...level, data: data });
      }
    });
    return report;
  }
};
export const paymentDetailsAndSummay = async (docId, start, end, listName) => {
  let report = [];

  if ((start, end)) {
    let serverDate = firebase.firestore.Timestamp.fromDate(new Date(start));
    let serverEnd = firebase.firestore.Timestamp.fromDate(new Date(end));

    let bpRef = await firebase
      .firestore()
      .collection("Bank PaymentVoucher")
      .where("date", ">=", serverDate)
      .where("date", "<=", serverEnd)
      .get();
    let cpRef = await firebase
      .firestore()
      .collection("Cash PaymentVoucher")
      .where("date", ">=", serverDate)
      .where("date", "<=", serverEnd)
      .get();
    let level3Ref = await firebase.firestore().collection("level3").get();

    let level3Data = level3Ref.docs.map((doc) => {
      let data = { ...doc.data(), docId: doc.id };
      return data;
    });
    let cpData = cpRef.docs.map((doc) => {
      let data = { ...doc.data(), docId: doc.id };
      return data;
    });
    let bpData = bpRef.docs.map((doc) => {
      let data = { ...doc.data(), docId: doc.id };
      return data;
    });
    const voucher = [...cpData, ...bpData];

    if (docId) {
      level3Data = level3Data.filter((doc) => doc.docId === docId);
    }
console.log({voucher})
    await level3Data.map((level) => {
      let data = [];
      voucher.forEach((f) => {
        const AccFind = f.jvItems.find((item) => level.docId === item.ledgerId);
        if (AccFind) {
          let jvItems = f.jvItems.filter((item) => {
            if (
              level.docId !== item.ledgerId &&
              parseFloat(item.accDebit) > 0
            ) {
              if (listName) {
                if (listName === item.ledgerId) {
                  return item;
                }
              } else {
                return item;
              }
            }
          });
          if (jvItems.length > 0) {
            data.push({
              date: f.date,
              id: f.docId,
              items: jvItems,
            });
          }
        }
      });
      if (data.length > 0) {
        report.push({ ...level, data: data });
      }
    });
    return report;
  }
};
