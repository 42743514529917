import React, { useEffect, useState } from "react";
import { div, Navbar } from "react-bootstrap";
import "../../Css/btnStyle.css";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as invoiceActions from "../../action/invoiceAction";
import * as jvActions from "../../action/jvAction";
import * as noteActions from "../../action/productionCom";
import PrintInvoiceOptions from "../../subcom/PrintInvoiceOptions";
import { isMobile } from "react-device-detect";
const ActionBar = (props) => {
  const { voucherType, invoiceId, check, vNo, invoiceRights } = props;
  const invoiceRed = useSelector((state) => state.invoice);
  const noteRed = useSelector((state) => state.note);
  const jvRed = useSelector((state) => state.jv);
  const [current, setCurrent] = useState();
  const [firstLink, setFirstLink] = useState();
  const [lastLink, setLastLink] = useState();
  const [nextLink, setNextLink] = useState();
  const [prevLink, setPrevLink] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!invoiceRed.loading && check === "invoice") {
      if (invoiceRed.invoices && invoiceRed.invoices.length > 0) {
        let filterInvoice = [];
        setCurrent(invoiceRed.invoices.find((f) => f.invoiceId === invoiceId));
        filterInvoice = invoiceRed.invoices.filter(
          (f) => f.type === voucherType
        );

        if (filterInvoice.length > 0) {
          let link = `/${check}/${voucherType}/`;
          const first = filterInvoice[0]
            ? `${link}${filterInvoice[0].invoiceId}`
            : null;

          const last = filterInvoice[filterInvoice.length - 1]
            ? `${link}${filterInvoice[filterInvoice.length - 1].invoiceId}`
            : null;
          setFirstLink(first);
          setLastLink(last);
          if (invoiceRed.next < filterInvoice.length) {
            setNextLink(`${link}${filterInvoice[invoiceRed.next].invoiceId}`);
          }

          if (invoiceRed.prev < invoiceRed.invoices.length) {
            const min = filterInvoice.length - 1 - invoiceRed.prev;
            if (filterInvoice[min]) {
              if (filterInvoice[min].invoiceId) {
                setPrevLink(`${link}${filterInvoice[min].invoiceId}`);
              }
            }
          }
        }
      }
    }
    if (!jvRed.loading && check === "jvpage") {
      if (jvRed.jvList.length > 0) {
        setCurrent(jvRed.jvList.find((f) => f.id === vNo));
        let link = `/${check}/${voucherType}/`;
        let first = jvRed.jvList[jvRed.first]
          ? jvRed.jvList[jvRed.first].id
          : null;
        let last = jvRed.jvList[jvRed.last]
          ? jvRed.jvList[jvRed.last].id
          : null;
        setFirstLink(`${link}${first}`);
        setLastLink(`${link}${last}`);
        if (jvRed.next < jvRed.jvList.length) {
          setNextLink(`${link}${jvRed.jvList[jvRed.next].id}`);
        }
        if (jvRed.prev < jvRed.jvList.length) {
          const min = jvRed.jvList.length - 1 - jvRed.prev;
          setPrevLink(`${link}${jvRed.jvList[min].id}`);
        }
      }
    }
    if (check === "note" && !noteRed.loading) {
      handleReceipyOptions();
    }
  }, [invoiceRed, invoiceId, voucherType, vNo, jvRed, invoiceRights, noteRed]);
  const handleReceipyOptions = () => {
    if (noteRed.receipyNote && noteRed.receipyNote.length > 0) {
      let receipyNote;
      if (voucherType === "Production Note") {
        setCurrent(noteRed.productionNote.find((f) => f.id === invoiceId));

        receipyNote = noteRed.productionNote;
      } else {
        setCurrent(noteRed.receipyNote.find((f) => f.id === invoiceId));

        receipyNote = noteRed.receipyNote;
      }
      if (receipyNote) {
        let link = `/${"productionnote"}/`;
        const first = receipyNote[0] ? `${link}${receipyNote[0].id}` : null;

        const last = receipyNote[receipyNote.length - 1]
          ? `${link}${receipyNote[receipyNote.length - 1].id}`
          : null;
        setFirstLink(first);
        setLastLink(last);
        if (noteRed.next < receipyNote.length) {
          setNextLink(`${link}${receipyNote[noteRed.next].id}`);
        }

        if (noteRed.prev < receipyNote.length) {
          const min = receipyNote.length - 1 - noteRed.prev;
          if (receipyNote[min]) {
            if (receipyNote[min].id) {
              setPrevLink(`${link}${receipyNote[min].id}`);
            }
          }
        }
      }
    }
  };
  const handleChangeInvoice = async (option) => {
    if (option === "f") {
      if (check === "invoice") {
        dispatch(invoiceActions.loadfirst());
      }
      if (check === "jvpage") {
        dispatch(jvActions.loadfirst());
      }
      if (check === "note") {
        dispatch(noteActions.loadfirst());
      }
    }
    if (option === "l") {
      if (check === "invoice") {
        dispatch(invoiceActions.loadlast());
      }
      if (check === "jvpage") {
        dispatch(jvActions.loadlast());
      }
      if (check === "note") {
        dispatch(noteActions.loadlast());
      }
    }
    if (option === "p") {
      if (check === "invoice") {
        dispatch(invoiceActions.loadprev());
      }
      if (check === "jvpage") {
        dispatch(jvActions.loadprev());
      }
      if (check === "note") {
        dispatch(noteActions.loadprev());
      }
    }
    if (option === "n") {
      if (check === "invoice") {
        dispatch(invoiceActions.loadnext());
      }
      if (check === "jvpage") {
        dispatch(jvActions.loadnext());
      }
      if (check === "note") {
        dispatch(noteActions.loadnext());
      }
    }
  };
  const editVoucher = () => {
    if (current && !current.check && !current.cancel) {
      props.editVoucher();
    } else {
      alert("Voucher Can not be edited");
    }
  };

  const saveVoucher = () => {
    if (current) {
      if (!current.check && !current.cancel && !current.approve) {
        props.handleSaveVoucher();
      } else {
        alert("Voucher Can not be Saved");
      }
    } else {
      props.handleSaveVoucher();
    }
  };
  const deleteVoucher = () => {
    if (current) {
      if (!current.check && !current.cancel && !current.approve) {
        props.deleteVoucher();
      } else {
        alert("Voucher Can not be Deleted");
      }
    }
  };
  const handlePrintOption = () => {
  
    localStorage.setItem(
      "printOption",
      JSON.stringify({
        ...current,
      })
    );
  };
  console.log({invoiceRights})
  return (
    <Navbar expand="lg">
      <Navbar.Toggle />
      <Navbar.Collapse>
        <div className={isMobile ? "mobileActionBar" : "actionBarContainer"}>
          <div className="btnclass gradient-button-4" onClick={saveVoucher}>
            save
          </div>
          <button
            className="btnclass gradient-button-4"
            disabled={!invoiceRights.edit}
            onClick={editVoucher}
          >
            Edit
          </button>
          <button
            className="btnclass gradient-button-4"
            onClick={() => handleChangeInvoice("f")}
            disabled={!firstLink}
          >
            <Link
              to={firstLink}
              style={{ textDecoration: "none", color: "white" }}
            >
              First
            </Link>
          </button>
          <button
            className="btnclass gradient-button-4"
            onClick={() => handleChangeInvoice("n")}
            disabled={!nextLink}
          >
            <Link
              to={nextLink}
              style={{ textDecoration: "none", color: "white" }}
            >
              Next
            </Link>
          </button>
          <button
            className="btnclass gradient-button-4"
            onClick={() => handleChangeInvoice("p")}
            disabled={!prevLink}
          >
            <Link
              to={prevLink}
              style={{ textDecoration: "none", color: "white" }}
            >
              Previus
            </Link>
          </button>

          <button
            className="btnclass gradient-button-4"
            onClick={() => handleChangeInvoice("l")}
            disabled={!lastLink}
          >
            <Link
              to={lastLink}
              style={{ textDecoration: "none", color: "white" }}
            >
              Last
            </Link>
          </button>
          {current && (
            <>
              <button
                className="btnclass gradient-button-4"
                onClick={deleteVoucher}
                disabled={!invoiceRights.delete}
              >
                Delete
              </button>
              <button
                className="btnclass gradient-button-4"
                onClick={() => props.handleCheckVoucher()}
                disabled={!invoiceRights.check}
              >
                {current.check ? "Checked" : "Check"}
              </button>
              <button
                onClick={() => props.handleApproveVoucher()}
                className="btnclass gradient-button-4"
                disabled={!invoiceRights.approve}
              >
                {current.approve ? "Approved" : "Approve"}
              </button>
              <button
                onClick={() => props.handleCancelVoucher()}
                className="btnclass gradient-button-4"
                disabled={!invoiceRights.cancel}
              >
                {current.cancel ? "Canceled" : "Cancel"}
              </button>
              {check === "invoice" || check === "jvpage" ? (
                <>
                  {check === "invoice" ? (
                    <>
                      {voucherType === "Sell" ||
                      voucherType === "Sell Return" ? (
                        <PrintInvoiceOptions
                          type="invoices"
                          invoiceId={invoiceId}
                          voucherType={voucherType}
                          disabled={!invoiceRights.print}
                        />
                      ) : (
                        <Link
                          className="btnclass gradient-button-4"
                          target="_blank"
                          to={
                            invoiceRights.print === true
                              ? `/print/${voucherType}/${invoiceId}/t`
                              : null
                          }
                          onClick={(event) => {
                            if (!invoiceRights.print) {
                              event.preventDefault();
                            }
                          }}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          Print
                        </Link>
                      )}
                    </>
                  ) : (
                    <Link
                      target="_blank"
                      disabled={!invoiceRights.print}
                      className="btnclass gradient-button-4"
                      to={`/print/${"Voucher"}/${vNo}/t`}
                      onClick={(event) => {
                        if (!invoiceRights.print) {
                          event.preventDefault();
                        }
                      }}
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      Print
                    </Link>
                  )}
                </>
              ) : null}
              {check === "note" && (
                <>
                  <Link
                    target="_blank"
                    disabled={!invoiceRights.print}
                    className="btnclass gradient-button-4"
                    to={`/printnote`}
                    onClick={(event) => {
                      if (!invoiceRights.print) {
                        event.preventDefault();
                      } else {
                        handlePrintOption();
                      }
                    }}
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Print
                  </Link>
                </>
              )}
            </>
          )}
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};
export default ActionBar;
