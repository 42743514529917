import React, { useState, useEffect } from "react";
import { Table, Button, Badge } from "react-bootstrap";
import { BsCheckAll } from "react-icons/bs";

import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import * as invoiceActions from "../../action/invoiceAction";
import SearchBar from "../general/SearchBar";
const ListInvoices = (props) => {
  const dispatch = useDispatch();
  const listInvoices = props.list;
  const invoiceType = props.invoiceType;
  const {  check } = props;
  const [invoices, setInvoices] = useState([]);
  const statusList = [
    "checked",
    "approved",
    "cancel",
    "unchecked",
    "unapproved",
  ];

  const [vId, setVId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [pn, setPN] = useState("");

  const [pName, setPName] = useState("");
  const [date, setDate] = useState("");
  useEffect(() => {
    setInvoices(listInvoices);
  }, [listInvoices]);
  const handleInvoiceOperation = (current, op) => {
    let message = `can not be ${op}`;
    if (op === "check") {
      if (current && !current.approve && !current.cancel) {
        dispatch(invoiceActions.checkInvoice(current.invoiceId, invoiceType));
      } else {
        alert(message);
      }
    }
    if (op === "approve") {
      if (current && current.check && !current.cancel) {
        dispatch(invoiceActions.approveInvoice(current.invoiceId, invoiceType));
      } else {
        alert(message);
      }
    }
  };
  const handleSearchDate = (date) => {
    let invoice = [...listInvoices];
    setDate(date);
    if (date !== "") {
      invoice = invoice.filter((f) => {
        var dbDate = new Date(f.date.toDate());

        let today = new Date(date).toISOString().slice(0, 10);
        let check = new Date(dbDate).toISOString().slice(0, 10);
        if (check === today) {
          return f;
        }
      });
    }
    setInvoices(invoice);
  };
  const handleStatusSearch = (status) => {
    let invoice = [...listInvoices];
    setSelectedStatus(status);
    if (status) {
      switch (status) {
        case "checked":
          {
            invoice = invoice.filter(
              (f) => f.check === true && f.approve === false
            );
          }
          break;
        case "unchecked":
          {
            invoice = invoice.filter((f) => f.check === false);
          }
          break;
        case "approved": {
          invoice = invoice.filter((f) => f.approve === true);
        }
        case "unapproved":
          {
            invoice = invoice.filter(
              (f) => f.check === true && f.approve === false
            );
          }
          break;
        case "cancel": {
          invoice = invoice.filter((f) => f.cancel === true);
        }
      }
    }
    setInvoices(invoice);
  };
  const handleReset = () => {
    setInvoices(listInvoices);
    setDate("");
    setPName("");
    setPN("");
    setVId("");
    setSelectedStatus("");
  };
  return (
    <div>
      <div>
        <SearchBar
          handleVoucherId={(e) => {
            setVId(e.target.value);
            setInvoices(
              listInvoices.filter((f) => f.invoiceId.includes(e.target.value))
            );
          }}
          handlePhoneNumber={(e) => {
            setPN(e.target.value);
            setInvoices(
              listInvoices.filter((f) => f.contact.includes(e.target.value))
            );
          }}
          handlePartyName={(e) => {
            setInvoices(
              listInvoices.filter((f) =>
                f.party.toString().toLowerCase().includes(e.target.value)
              )
            );
            setPName(e.target.value);
          }}
          handlePartyDate={(e) => handleSearchDate(e.target.value)}
          handleReset={handleReset}
          date={date}
          pName={pName}
          pn={pn}
          phone={true}
          vId={vId}
          selectedStatus={selectedStatus}
          statusList={statusList}
          handleStatusSearch={handleStatusSearch}
        />
      </div>
      <div style={{ width: "100%", height: "500px", overflow: "auto" }}>
        <Table
          striped
          bordered
          hover
          responsive
          style={{ textAlign: "center" }}
        >
          <thead>
            <tr>
              <th>date</th>
              <th>Voucher ID</th>
              <th>Party Name</th>
              <th>Contact No.</th>
              {invoiceType === "Delivery Challan" ||
              invoiceType === "Good Receipt Note" ? null : (
                <th>Grand Total</th>
              )}

              <th>Status</th>
              <th>check</th>
              <th>approve</th>
              <th>View</th>
              <th>created</th>
            </tr>
          </thead>
          <tbody>
            {invoices &&
              invoices.map((m) => {
                return (
                  <tr key={m.invoiceId}>
                    <td>{m.date && m.date.toDate().toLocaleDateString()}</td>
                    <td>{m.invoiceId}</td>
                    <td style={{ textAlign: "start" }}>{m.party}</td>
                    {invoiceType === "Delivery Challan" ||
                    invoiceType === "Good Receipt Note" ? null : (
                      <td>{m.contact}</td>
                    )}
                    <td>{m.grand}</td>

                    <td>
                      {m.approve && m.check && (
                        <>
                          {"approve"}
                          <BsCheckAll size={15} />
                        </>
                      )}
                      {!m.approve && m.check && <>{"checked"}</>}

                      {!m.check && "unchecked"}
                    </td>

                    <td
                      onDoubleClick={() => handleInvoiceOperation(m, "check")}
                    >
                      {m.check ? "yes" : "no"}
                    </td>
                    <td
                      onDoubleClick={() => handleInvoiceOperation(m, "approve")}
                    >
                      {m.approve && m.check ? "yes" : "no"}
                    </td>
                    <td>
                      <Link
                        onClick={() => props.changeTab()}
                        to={`/${check}/${invoiceType}/${m.invoiceId}`}
                      >
                        View
                      </Link>
                    </td>
                    <td>{m.invoiceOwner}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ListInvoices;

const tableStyle = {
  width: "120px",
  fontSize: "12px",
};
