import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import * as stockActions from "../../action/itemStockAction";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Table } from "react-bootstrap";
import Level3Search from "../../components/accountCart/Level3Search";
import CustomDropDown from "../../subcom/CustomDropDown";
import * as itemActions from "../../action/itemAction";
const OpeningQuantity = (props) => {
  const dispatch = useDispatch();
  const [stockList, setStockList] = useState([]);
  const [list, setList] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);
  const [showId, setShowId] = useState("");
  const [showTitle, setShowTitle] = useState("");
  const [showType, setShowType] = useState("");
  const [showCategory, setShowCategory] = useState("");
  const [loadedCategories, setLoadedCategories] = useState([]);
  const [loadedType, setLoadedType] = useState([]);
  const others = useSelector((state) => state.catAndTypes);
  const stock = useSelector((state) => state.stock);
  const [openingQuantityRight, setOpeningQuantityRight] = useState({});

  useEffect(() => {
    dispatch(stockActions.loadStockReport());
    setOpeningQuantityRight(props.rights);
  }, []);
  useEffect(() => {
    dispatch(itemActions.loadTypesCategories());
  }, []);
  useEffect(() => {
    setLoadedCategories(others.categories);
    setLoadedType(others.types);
  }, [others]);
  useEffect(() => {
    setUpdatedList([]);
    setStockList(stock.stock);
    setList(stock.stock);
  }, [stock]);

  const makeUpdateList = (doc) => {
    
    const check = updatedList.find((f) => f.docId === doc.docId);
    let newUpdatedList = [...updatedList];
    if (check) {
      newUpdatedList = newUpdatedList.filter((f) => f.docId !== check.docId);
    }
    newUpdatedList.push(doc);
    setUpdatedList(newUpdatedList);
  };

  const handleQtyChange = (value, index) => {
    let oldArray = [...list];
    let check = oldArray[index];
    let inputVal;
    if (value === "") {
      inputVal = 0;
    } else {
      inputVal = value;
    }
    
    let updatedDoc = {
      ...check,
      balanceInStock: parseInt(inputVal),
    };
    oldArray[index] = updatedDoc;
    setList(oldArray);
    makeUpdateList(updatedDoc);
  };
  const handleUpdateLevel = () => {
   
    if (updatedList.length > 0) {
      dispatch(stockActions.updateOpeningQty(updatedList));
      setUpdatedList([]);
    }
  };
  const searchById = (val) => {
    let oldArray = [...stockList];
    setShowTitle("");
    setShowId(val);
    oldArray = oldArray.filter((f) => {
      if (f.showId.toLowerCase().startsWith(val)) {
        return f;
      }
    });
    setList(oldArray);
  };
  const searchByCategory = (val) => {
    let oldArray = [...stockList];
    setShowTitle("");
    setShowId("");
    setShowCategory(val);
    oldArray = oldArray.filter((f) => {
      if (f.category === val) {
        return f;
      }
    });
    setList(oldArray);
  };
  const searchByType = (val) => {
    let oldArray = [...stockList];
    setShowTitle("");
    setShowId("");
    setShowCategory("");
    setShowType(val);
    oldArray = oldArray.filter((f) => {
      if (f.type === val) {
        return f;
      }
    });
    setList(oldArray);
  };
  const searchByTitle = (val) => {
   
    let oldArray = [...stockList];
    setShowId("");
    setShowTitle(val);
    oldArray = oldArray.filter((f) => {
      if (f.name.toString().toLowerCase().includes(val)) {
        return f;
      }
    });
  
    setList(oldArray);
  };

  const handleReset = () => {
    setStockList(stock.stock);
    setList(stock.stock);
    setShowId("");
    setShowTitle("");
    setShowType("");
    setShowCategory("");
  };
  const createLabel = (array, docId) => {
    if (array.length > 0) {
      let find = array.find((f) => f.docId === docId);
      let name;
      if (find) {
        name = find.name;
      } else {
        name = "";
      }
      return name;
    }
  };
  if (!stock.loading) {
    return (
      <Layout>
        <div
          style={{
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            marginTop: "20px",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px",
            }}
          >
            <h4>Opening Balance</h4>
            <Button
              onClick={handleUpdateLevel}
              disabled={!openingQuantityRight.edit}
            >
              Update
            </Button>
          </div>

          <Level3Search
            searchById={searchById}
            showId={showId}
            searchByTitle={searchByTitle}
            showTitle={showTitle}
            handleReset={handleReset}
            check={"openingQty"}
          >
            <td>
              <div
                style={{
                  width: "200px",
                  height: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {loadedCategories.length > 0 && (
                  <CustomDropDown
                    list={loadedCategories}
                    label={createLabel(loadedCategories, showCategory)}
                    selectedItem={(docId) => searchByCategory(docId)}
                  />
                )}
              </div>
            </td>
            <td>
              <div
                style={{
                  width: "200px",
                  height: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {loadedType.length > 0 && (
                  <CustomDropDown
                    list={loadedType}
                    label={createLabel(loadedType, showType)}
                    selectedItem={(docId) => searchByType(docId)}
                  />
                )}
              </div>
            </td>
          </Level3Search>
          <div style={{ maxHeight: "500px", overflow: "auto" }}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>

                  <th style={{ width: "100px" }}>Opening QTY</th>
                </tr>
              </thead>
              <tbody>
                {list.length > 0 &&
                  list.map((item, index) => (
                    <tr key={item.docId}>
                      <td> {`${item.showId}`}</td>
                      <td> {item.name}</td>

                      <td>
                        <Form.Control
                          disabled={!openingQuantityRight.edit}
                          type="number"
                          value={item.balanceInStock}
                          onChange={(e) =>
                            handleQtyChange(e.target.value, index)
                          }
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Layout>
    );
  } else {
    return <div>loading</div>;
  }
};

export default OpeningQuantity;
