import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
const ReverseActionBtn = (props) => {
    return (
        <div>
            <div style={{
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                margin: '12px'
            }}>
                <p style={{ fontSize: "20px", fontWeight: 'bold', color: 'red' }}>

                    {
                        props.message
                    }
                </p>

            </div>
            <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>


                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: "center",

                    }}>
                    <Link
                        onClick={() => props.okAction()}
                        style={{ margin: "10px" }}
                      
                        to={props.link}
                    >
                       <Button   variant='danger'>YES</Button> 
                    </Link>
                    <Button
                        onClick={() => props.cancelAction()}
                        style={{ margin: "10px" }}
                        variant='secondary'
                    >
                        NO
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ReverseActionBtn
