import React, { useState } from "react";
import { Card } from "react-bootstrap";
const Accordion1 = (props) => {
  const [show, setShow] = useState("accordion-collapse collapse");
  const [btnClass, setBtnClass] = useState("accordion-button");
  const handleShow = () => {
    if (show === "accordion-collapse collapse") {
      setShow("accordion-collapse collapse show");
      setBtnClass("accordion-button collapsed");
    } else {
      setShow("accordion-collapse collapse");
      setBtnClass("accordion-button ");
    }
  };
  return (
    <div className="accordion accordion-flush mt-2 w-100">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button className={`${btnClass}`} onClick={handleShow} type="button">
            {props.title}
          </button>
        </h2>
        <div className={`${show}`}>
          <div className="accordion-body ">
            <Card>{props.children}</Card>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Accordion1;
