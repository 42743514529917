import { LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS } from "../action/signup";

const initialState = {
  user: {},
  loading: false,
  error: "",
  auth: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      {
        state = {
          ...initialState,
        };
      }
      break;
    case LOGIN_SUCCESS:
      {
        state = {
          loading: false,
          user: action.payload,
          auth: true,
          error: "",
        };
      }
      break;
    case LOGIN_FAILURE:
      {
        state = {
          user: {},
          loading: false,
          error: action.payload,
          auth: false,
        };
      }
      break;
  }

  return state;
};
export default userReducer;
