import firebase from "firebase";
export const loadcompanyData = async () => {
  const CompanyRef = await firebase
    .firestore()
    .collection("companycollection")
    .doc("mycompany")
    .get();

  return await CompanyRef.data();
};
