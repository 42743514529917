import firebase from "firebase";
export const getPostDatedCheque = async (type, start) => {
  let serverDate = firebase.firestore.Timestamp.fromDate(new Date(start));
  let collection;
  if (type === "received") {
    collection = "Bank ReceiptVoucher";
  }
  if (type === "issued") {
    collection = "Bank PaymentVoucher";
  }
  let brRef = await firebase.firestore().collection(collection).get();
  let brData = brRef.docs.map((doc) => {
    let data = { ...doc.data(), docId: doc.id };
    return data;
  });
  let data = [];
  brData.map((jv) => {
    jv.jvItems.forEach((item) => {
      if (item.checkDate >= start) {
        if (type === "issued") {
          jv.jvItems.forEach((f) => {
            if (
              f.checkNo === item.checkNo &&
              f.checkDate === item.checkDate &&
              f.ledgerId !== item.ledgerId &&
              f.accCredit > 0
            ) {
              data.push({
                date: jv.date,
                vNo: jv.docId,
                bankTitle: f.party,
                accountTitle: item.party,
                checkNo: f.checkNo,
                checkDate: f.checkDate,
                amount: f.accCredit,
              });
            }
          });
        } else {
          jv.jvItems.forEach((f) => {
            if (
              f.checkNo === item.checkNo &&
              f.checkDate === item.checkDate &&
              f.ledgerId !== item.ledgerId &&
              f.accDebit > 0
            ) {
              data.push({
                date: jv.date,
                vNo: jv.docId,
                bankTitle: f.party,
                accountTitle: item.party,
                checkNo: f.checkNo,
                checkDate: f.checkDate,
                amount: f.accDebit,
              });
            }
          });
        }
      }
    });
  });

  return data;
};
