import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap';
import ActionButtons from '../../subcom/ActionButtons';
import Input from '../../subcom/Input';
import { useDispatch, useSelector } from 'react-redux';
import * as  itemStockActions from '../../action/itemStockAction';
const EditStock = (props) => {
    const data = props.data;
    const dispatch = useDispatch();
    const [current, setCurrent] = useState({
        rId: data.rId,
        balanceInStock: (parseInt(data.openingStock) + parseInt(data.receivedQty) )- parseInt(data.issuedQty),
        issuedQty: data.issuedQty,
        openingStock: data.openingStock,
        receivedQty: data.receivedQty,
        name: data.name,
        date: data.date
    });

    const saveEditStock = () => {
        dispatch(itemStockActions.editStockItem(current))
        props.closeStockModal()

    }
    const onNameChange = (e) => {
        let val = e.target.value;
        setCurrent({ ...current, name: val })
    }
    const onBalanceInStockChange = (e) => {
        let val = e.target.value;
        setCurrent({ ...current, balanceInStock: val })
    }
    const onIssuedQtyChange = (e) => {
        let val = e.target.value;
        setCurrent({ ...current, issuedQty: val })
    }
    const onOpeningStockChange = (e) => {
        let val = e.target.value;
        setCurrent({ ...current, openingStock: val })
    }
    const onReceivedQtyChange = (e) => {
        let val = e.target.value;
        setCurrent({ ...current, receivedQty: val })
    }
    return (
        <div style={{

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }}>
            <Row style={{ width: "90%" }}>
                <Col sm={12}>
                    <Input
                        label='Name'
                        type='text'
                        name={"name"}
                        onChange={onNameChange}
                        value={current.name}
                        disabled={true}
                    />

                </Col>
                <Col sm={12}>
                    <Input
                        label='Balance In Stock'
                        type='text'
                        name={"balanceInStock"}
                        onChange={onBalanceInStockChange}
                        value={parseInt(current.openingStock) + parseInt(current.receivedQty)}
                        disabled={true}
                    />

                </Col>
                <Col sm={12}>
                    <Input
                        label='Issued Quantity'
                        type='text'
                        name={"issuedQty"}
                        onChange={onIssuedQtyChange}
                        value={current.issuedQty}
                    />
                </Col>
                <Col sm={12}>
                    <Input
                        label='Opening Stock'
                        type='text'
                        name={"openingStock"}
                        onChange={onOpeningStockChange}
                        value={current.openingStock}
                    />
                </Col>
                <Col sm={12}>
                    <Input
                        label='Received Quantity'
                        type='text'
                        name={"receivedQty"}
                        onChange={onReceivedQtyChange}
                        value={current.receivedQty}
                    />
                </Col>

            </Row>

            <ActionButtons
                saveInfo={saveEditStock}
                cancelInfo={props.closeStockModal}
            />
        </div>
    )
}

export default EditStock
