import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";

import { useSelector, useDispatch } from "react-redux";
import { Button, ListGroup, Form } from "react-bootstrap";
import Accordion from "../subcom/Accordion1";
import * as globalActions from "../action/globalAction";
import ListItem from "../subcom/ListItem";
import DataShow from "../components/superadmin/DataShow";
import { Link } from "react-router-dom";
import LogoShowCom from "../components/superadmin/LogoShowCom";

const GlobalSettings = () => {
  const globalRed = useSelector((state) => state.global);
  const stockRed = useSelector((state) => state.stock);
  const userRed = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [saveWithAprrove, setSaveWithApprove] = useState(false);
  const [saveWithCheck, setSaveWithCheck] = useState(false);

  const [backDateEntry, setBackDateEntry] = useState(false);

  const [darkTheme, setDarkTheme] = useState(false);
  const [enforceCategory, setEnforceCategory] = useState(false);
  const [companyInfoShow, setCompanyInfoShow] = useState(false);

  const [stockWithApprove, setStockWithApprove] = useState(false);
  const [stockWithCheck, setStockWithCheck] = useState(false);
  const [restrictNegativeQty, setRestrictNegativeQty] = useState(false);
  const [updateStockWithGRN, setUpdateStockWithGRN] = useState(false);
  const [updateStockWithDC, setUpdateStockWithDC] = useState(false);

  const [jvWithApprove, setJvWithApprove] = useState(false);
  const [jvWithCheck, setJvWithCheck] = useState(false);
  const [date, setDate] = useState("");
  const [showLogo, setShowLogo] = useState([]);

  const handleInvoiceSettings = () => {
    const perm = {
      saveWithAprrove,
      saveWithCheck,
      backDateEntry,
      darkTheme,
      stockWithApprove,
      stockWithCheck,
      updateStockWithGRN,
      updateStockWithDC,
      jvWithApprove,
      jvWithCheck,
      enforceCategory,
      date,
      restrictNegativeQty,
      companyInfoShow,
      showLogo,
    };

    dispatch(globalActions.saveSettings(perm));
  };

  useEffect(() => {
    
    setLoading(globalRed.loading);
    const {
      saveWithAprrove,
      saveWithCheck,
      backDateEntry,
      stockWithApprove,
      stockWithCheck,
      updateStockWithGRN,
      updateStockWithDC,
      jvWithApprove,
      jvWithCheck,
      enforceCategory,
      companyInfoShow,
      restrictNegativeQty,
      date,
      showLogo,
    } = globalRed;
    setSaveWithApprove(saveWithAprrove ? saveWithAprrove : false);
    setSaveWithCheck(saveWithCheck ? saveWithCheck : false);
    setBackDateEntry(backDateEntry ? backDateEntry : false);
    setDarkTheme(backDateEntry ? backDateEntry : false);
    setStockWithApprove(stockWithApprove ? stockWithApprove : false);
    setStockWithCheck(stockWithCheck ? stockWithCheck : false);
    setJvWithApprove(jvWithApprove ? jvWithApprove : false);
    setJvWithCheck(jvWithCheck ? jvWithCheck : false);
    setEnforceCategory(enforceCategory ? enforceCategory : true);
    setCompanyInfoShow(companyInfoShow ? companyInfoShow : false);
    setDate(date );
    setRestrictNegativeQty(restrictNegativeQty ? restrictNegativeQty : false);
    setUpdateStockWithGRN(updateStockWithGRN ? updateStockWithGRN : false);
    setUpdateStockWithDC(updateStockWithDC ? updateStockWithDC : false);
    setShowLogo(showLogo ? showLogo : []);
  }, [globalRed]);
  const handleDarkTheme = () => {
    setDarkTheme(!darkTheme);
  };
  const handleCompanyInfoShow = () => {
    setCompanyInfoShow(!companyInfoShow);
  };
  const handleBackDateEntry = () => {
    setBackDateEntry(!backDateEntry);
  };

  const handleSaveWithCheck = () => {
    if (!saveWithAprrove) {
      setSaveWithCheck(!saveWithCheck);
    } else {
      alert("please uncheck save with approve");
    }
  };

  const handleJvWithCheck = () => {
    if (!jvWithApprove) {
      setJvWithCheck(!jvWithCheck);
    } else {
      alert("please uncheck save with approve");
    }
  };
  const handleStockWithCheck = () => {
    if (!stockWithApprove) {
      setStockWithCheck(!stockWithCheck);
    } else {
      alert("please uncheck save with approve");
    }
  };

  const handleJvWithApprove = () => {
    if (jvWithCheck) {
      setJvWithApprove(!jvWithApprove);
    } else {
      alert("please check save with check");
    }
  };
  const handleSaveWithApprove = () => {
    if (saveWithCheck) {
      setSaveWithApprove(!saveWithAprrove);
    } else {
      alert("please check save with check");
    }
  };

  const handleStockWithApprove = () => {
    if (stockWithCheck) {
      setStockWithApprove(!stockWithApprove);
    } else {
      alert("please check save with check");
    }
  };
  const handleStockUpdateWithGRN = () => {
    setUpdateStockWithGRN(!updateStockWithGRN);
  };
  const handleStockUpdateWithDC = () => {
    setUpdateStockWithDC(!updateStockWithDC);
  };
  const handleEnforceCategory = () => {
    setEnforceCategory(!enforceCategory);
  };
  const handleStockWithNegativeQty = () => {
    let errors = [];
    if (globalRed.restrictNegativeQty === false) {
      stockRed.stock.forEach((findStock) => {
        let stockQty = 0;
        if (findStock.balanceInStock !== "") {
          stockQty = stockQty + parseInt(findStock.balanceInStock);
        }
        if (findStock.receivedQty !== "") {
          if (parseInt(findStock.receivedQty) > 0) {
            stockQty = stockQty + parseInt(findStock.receivedQty);
          }
        }
        if (findStock.issuedQty !== "") {
          if (parseInt(findStock.issuedQty) > 0) {
            stockQty = stockQty - parseInt(findStock.issuedQty);
          }
        }
        if (stockQty <= 0) {
          errors.push(findStock.name);
        }
      });
    }
    if (errors.length > 0) {
      let text = "";
      text = text + "," + errors.map((name) => name);
      alert(text + "are out of stock");
    } else {
      setRestrictNegativeQty(!restrictNegativeQty);
    }
  };
  const handleStartDate = (e) => {
    setDate(e.target.value);
  };
  const parentOptionChange = (parent, child, grand) => {
    let changeOption = showLogo;
    let changeShow;
    if (grand) {
      changeShow =
        changeOption[parseInt(parent.id) - 1].children[parseInt(child.id) - 1]
          .children[parseInt(grand.id) - 1];
      changeOption[parseInt(parent.id) - 1].children[
        parseInt(child.id) - 1
      ].children[parseInt(grand.id) - 1] = {
        ...changeShow,
        show: !changeShow.show,
      };
    } else {
      changeShow =
        changeOption[parseInt(parent.id) - 1].children[parseInt(child.id) - 1];
      changeOption[parseInt(parent.id) - 1].children[parseInt(child.id) - 1] = {
        ...changeShow,
        show: !changeShow.show,
      };
    }
    setShowLogo(changeOption);
    dispatch(globalActions.changeShowLogo(changeOption));
  };

  if (loading) {
    return <div>loading</div>;
  } else {
    return (
      <Layout>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-evenly",
            flexDirection: "column",
          }}
        >
          <Accordion title={"General Settings"}>
            <ListGroup variant="flush">
              <ListItem
                label="Restrict Back Date Entry"
                onChange={handleBackDateEntry}
                value={backDateEntry}
              />
              <ListItem
                label="Dark Theme"
                onChange={handleDarkTheme}
                value={darkTheme}
              />
              <ListItem
                label="Enforce Category"
                onChange={handleEnforceCategory}
                value={enforceCategory}
              />
              <ListItem
                label="Show Company Information on Slips"
                onChange={handleCompanyInfoShow}
                value={companyInfoShow}
              />
              <ListGroup.Item className="m-1">
                <div className="d-flex flex-row justify-content-between  ">
                  <Form.Label>{"Start Date"}</Form.Label>
                  <Form.Control
                    type="date"
                    value={date}
                    onChange={handleStartDate}
                  />
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Accordion>
          <Accordion title={"Journal Voucher Settings"}>
            <ListGroup variant="flush">
              <ListItem
                label="Post Journal Voucher After Approve"
                onChange={handleJvWithApprove}
                value={jvWithApprove}
                checked={jvWithApprove ? "checked" : ""}
              />
              <ListItem
                label="Post Journal Voucher After Check"
                onChange={handleJvWithCheck}
                value={jvWithCheck ? "checked" : ""}
              />
            </ListGroup>
          </Accordion>
          <Accordion title={"Invoice Settings"}>
            <ListGroup variant="flush">
              <ListItem
                label="Post Voucher After Approve"
                onChange={handleSaveWithApprove}
                value={saveWithAprrove}
                checked={saveWithAprrove ? "checked" : ""}
              />
              <ListItem
                label="Post Voucher After Check"
                onChange={handleSaveWithCheck}
                value={saveWithCheck}
              />
            </ListGroup>
          </Accordion>
          <Accordion title={"Stock Settings"}>
            <ListGroup variant="flush">
              <ListItem
                label="Post In Stock After Approve"
                onChange={handleStockWithApprove}
                value={stockWithApprove}
                checked={stockWithApprove ? "checked" : ""}
              />
              <ListItem
                label="Post In Stock After Check"
                onChange={handleStockWithCheck}
                value={stockWithCheck}
                checked={stockWithCheck ? "checked" : ""}
              />
              <ListItem
                label="Restrict Negative Value"
                onChange={handleStockWithNegativeQty}
                value={restrictNegativeQty}
                checked={restrictNegativeQty ? "checked" : ""}
              />
              <ListItem
                label="Update Stock With GRN"
                onChange={handleStockUpdateWithGRN}
                value={updateStockWithGRN}
                checked={updateStockWithGRN ? "checked" : ""}
              />
              <ListItem
                label="Update Stock With Delivery Challan"
                onChange={handleStockUpdateWithDC}
                value={updateStockWithDC}
                checked={updateStockWithDC ? "checked" : ""}
              />
            </ListGroup>
          </Accordion>
          {Object.keys(userRed.user).length > 0 &&
            userRed.user.role === "superadmin" && (
              <>
                <h3>Super Admin Settings</h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to="/companysetup"
                  >
                    Go to Company Setup
                  </Link>
                </div>

                <Accordion title={"Show Logo List"}>
                  <LogoShowCom
                    showLogo={showLogo}
                    parentOptionChange={parentOptionChange}
                  />
                </Accordion>
                <Accordion title={"User Rights Setting"}>
                  <DataShow />
                </Accordion>
              </>
            )}

          <div className="d-flex flex-row-reverse mr-3 mt-3 w-100">
            <Button onClick={handleInvoiceSettings}>Save</Button>
          </div>
        </div>
      </Layout>
    );
  }
};

export default GlobalSettings;
