import React, { useState } from 'react'
import Input from '../../subcom/Input2'
import ActionButtons from '../../subcom/ActionButtons'

const InvoiceCancel = (props) => {
    const [reason, setReason] = useState('');
    const status = props.cancel;
    const check = props.check;
  
    const handleCancelInvoice = () => {

        if (!check) {
            if (!status) {
                if (reason !== '') {
                    props.saveReasonModal(reason)
                } else {
                    alert('please enter reason')
                }
            } else {
                props.saveReasonModal(reason)
            }

        }else {
            alert('can not be canceled')
        }

    }
    return (

        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center"
        }}>
            <h5 style={{ color: 'red', fontWeight: 'bold' }}>Cancel Reason</h5>
            {
                !status && <Input
                    name="cancel"
                    type="text"
                    onChange={(e) => setReason(e.target.value)}
                    value={reason}
                />
            }
            <ActionButtons
                saveInfo={handleCancelInvoice}
                cancelInfo={() => props.cancelReasonModal()}
            />



        </div>
    )
}

export default InvoiceCancel
