import React from 'react'
import Header from './Header';

const Layout = (props) => {
    return (
        <div>
            <Header />
            <div style={{width:'98%',margin:'auto'}}>
                {
                    props.children
                }

            </div>
        </div>
    )
}

export default Layout
