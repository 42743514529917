import firebase from "firebase";
export const DELETE_RECEIPY_REQUEST = "DELETE_RECEIPY_REQUEST";
export const DELETE_RECEIPY_SUCCESS = "DELETE_RECEIPY_SUCCESS";
export const DELETE_RECEIPY_FAILURE = "DELETE_RECEIPY_FAILURE";
export const UPDATE_RECEIPY_REQUEST = "UPDATE_RECEIPY_REQUEST";
export const UPDATE_RECEIPY_SUCCESS = "UPDATE_RECEIPY_SUCCESS";
export const UPDATE_RECEIPY_FAILURE = "UPDATE_RECEIPY_FAILURE";
export const NEW_RECEIPY_REQUEST = "NEW_RECEIPY_REQUEST";
export const NEW_RECEIPY_SUCCESS = "NEW_RECEIPY_SUCCESS";
export const NEW_RECEIPY_FAILURE = "NEW_RECEIPY_FAILURE";
export const LOAD_RECEIPY_REQUEST = "LOAD_RECEIPY_REQUEST";
export const LOAD_RECEIPY_SUCCESS = "LOAD_RECEIPY_SUCCESS";
export const LOAD_RECEIPY_FAILURE = "LOAD_RECEIPY_FAILURE";
export const NOTE_OP_REQUEST = "NOTE_OP_REQUEST";
export const NOTE_OP_SUCCESS = "NOTE_OP_SUCCESS";
export const NOTE_OP_FAILURE = "NOTE_OP_FAILURE";
export const LOAD_LAST_NOTE = "LOAD_LAST_NOTE";
export const LOAD_NEXT_NOTE = "LOAD_NEXT_NOTE";
export const LOAD_PREV_NOTE = "LOAD_PREV_NOTE";
export const LOAD_FIRST_NOTE = "LOAD_FIRST_NOTE";
export const addProductionNote = (data) => {
  return async (dispatch) => {
    dispatch({ type: NEW_RECEIPY_REQUEST });
    let query;
    if (data.cvId !== "") {
      console.log({ cvId: data.cvId });
      query = firebase.firestore().collection("Production Note").doc(data.id);
    } else {
      query = firebase.firestore().collection("Receipy Note").doc(data.id);
    }
    if (query) {
      const checkDoc = await query.get();
      if (!checkDoc.exists) {
        await query
          .set(data)
          .then(() => {
            dispatch({ type: NEW_RECEIPY_SUCCESS, payload: data });
          })
          .catch((err) => {
            console.log(err);
            dispatch({ type: NEW_RECEIPY_FAILURE, payload: { error: err } });
          });
      } else {
        let error = "document already exists";
        console.log({ error });
        dispatch({ type: NEW_RECEIPY_FAILURE, payload: { error } });
      }
    }
  };
};
export const editProductionNote = (data) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_RECEIPY_REQUEST });
    let query;
    if (data.cvId !== "") {
      console.log({ cvId: data.cvId });
      query = firebase.firestore().collection("Production Note").doc(data.id);
    } else {
      query = firebase.firestore().collection("Receipy Note").doc(data.id);
    }
    if (query) {
      const checkDoc = await query.get();
      if (checkDoc.exists) {
        await query
          .update(data)
          .then(() => {
            dispatch({ type: UPDATE_RECEIPY_SUCCESS, payload: data });
          })
          .catch((err) => {
            console.log(err);
            dispatch({ type: UPDATE_RECEIPY_FAILURE, payload: { error: err } });
          });
      } else {
        let error = "document does not exists";
        console.log({ error });
        dispatch({ type: UPDATE_RECEIPY_FAILURE, payload: { error } });
      }
    }
  };
};
export const loadProductionNote = () => {
  return async (dispatch) => {
    dispatch({ type: LOAD_RECEIPY_REQUEST });
    let receipyNoteRef = firebase.firestore().collection("Receipy Note");
    let productionNoteRef = firebase.firestore().collection("Production Note");
    const receipyNoteDoc = await receipyNoteRef.get();
    const productionNoteRefDoc = await productionNoteRef.get();
    let receipyNoteData = [],
      productionNoteData = [];
    if (receipyNoteDoc.size > 0) {
      receipyNoteData = receipyNoteDoc.docs.map((doc) => {
        const docData = { ...doc.data(), docId: doc.id };
        return {
          ...docData,
        };
      });
    }
    if (productionNoteRefDoc.size > 0) {
      productionNoteData = productionNoteRefDoc.docs.map((doc) => {
        const docData = { ...doc.data(), docId: doc.id };
        return {
          ...docData,
        };
      });
    }
    dispatch({
      type: LOAD_RECEIPY_SUCCESS,
      payload: {
        productionNote: productionNoteData,
        receipyNote: receipyNoteData,
      },
    });
  };
};
export const loadnext = (type) => {
  return async (dispatch, getState) => {
    dispatch({
      type: LOAD_NEXT_NOTE,
      invoiceType: type,
    });
  };
};
export const loadprev = (type) => {
  return async (dispatch) => {
    dispatch({
      type: LOAD_PREV_NOTE,
      invoiceType: type,
    });
  };
};
export const loadfirst = (type) => {
  return async (dispatch) => {
    console.log({ type }, "action");
    dispatch({
      type: LOAD_FIRST_NOTE,
      payload: type,
    });
  };
};
export const loadlast = (type) => {
  return async (dispatch) => {
    dispatch({
      type: LOAD_LAST_NOTE,
      invoiceType: type,
    });
  };
};

export const checkReceipyProductionNote = (id, type) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_RECEIPY_REQUEST,
    });
   
    const query = firebase.firestore().collection(`${type}`).doc(id);
    const dbRef = await query.get();
    if (dbRef.exists) {
      const data = dbRef.data();
      await query
        .update({ check: !data.check })
        .then(async () => {
          let newData = { ...data, check: !data.check };
          if (newData.check) {
          } else {
          }
          dispatch({
            type: UPDATE_RECEIPY_SUCCESS,
            payload: newData,
          });
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_RECEIPY_FAILURE,
            payload: { error: err },
          });
        });
    }
  };
};
export const approveReceipyProductionNote = (id, type) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_RECEIPY_REQUEST,
    });

    const query = firebase.firestore().collection(`${type}`).doc(id);
    const dbRef = await query.get();
    if (dbRef.exists) {
      const data = dbRef.data();
      await query
        .update({ approve: !data.approve })
        .then(async () => {
          let newData = { ...data, approve: !data.approve };
          if (newData.approve) {
          } else {
          }
          dispatch({
            type: UPDATE_RECEIPY_SUCCESS,
            payload: newData,
          });
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_RECEIPY_FAILURE,
            payload: { error: err },
          });
        });
    }
  };
};
export const cancelReceipyProductionNote = (id, type) => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_RECEIPY_REQUEST,
    });

    const query = firebase.firestore().collection(`${type}`).doc(id);
    const dbRef = await query.get();
    if (dbRef.exists) {
      const data = dbRef.data();
      await query
        .update({ cancel: !data.cancel })
        .then(async () => {
          let newData = { ...data, cancel: !data.cancel };
          if (newData.cancel) {
          } else {
          }
          dispatch({
            type: UPDATE_RECEIPY_SUCCESS,
            payload: newData,
          });
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_RECEIPY_FAILURE,
            payload: { error: err },
          });
        });
    }
  };
};
export const deleteReceipyProductionNote = (id, type) => {
  return async (dispatch, getState) => {
    dispatch({
      type: DELETE_RECEIPY_REQUEST,
    });

    const query = firebase.firestore().collection(`${type}`).doc(id);
    const dbRef = await query.get();
    if (dbRef.exists) {
      await query
        .delete()
        .then(async () => {
          dispatch({
            type: DELETE_RECEIPY_SUCCESS,
            payload: id,
          });
        })
        .catch((err) => {
          dispatch({
            type: DELETE_RECEIPY_FAILURE,
            payload: { error: err },
          });
        });
    }
  };
};
