import React from "react";
import Input2 from "../../subcom/Input2";
import { CgRemove, CgAdd } from "react-icons/cg";
import { Table } from "react-bootstrap";
import CustomDropDown from "../../subcom/CustomDropDown";
import "../../Css/table.css";
const ItemTable = (props) => {
  const { items, edit, itemData, invoiceType, productionNote } = props;
  const handleIncrease = () => {
    props.handleIncrease();
  };
  let totalQuantity = 0;
  return (
    <div>
      <Table striped bordered hover className="mytable">
        <thead>
          <tr>
            <th style={{ width: "80px" }}>No</th>
            <th>Name</th>
            <th style={{ width: "80px" }}>UOM</th>
            <th style={{ width: "80px" }}>Quantity</th>
            {invoiceType === "Delivery Challan" ||
            invoiceType === "Good Receipt Note" ? null : (
              <>
                <th style={{ width: "100px" }}>Rate</th>
                {!productionNote && <th style={{ width: "80px" }}>Discount</th>}

                <th style={{ width: "80px" }}>Total</th>
              </>
            )}

            {edit && <th style={{ width: "50px" }}>Operation</th>}
          </tr>
        </thead>
        <tbody>
          {items &&
            items.map((item, index) => {
              totalQuantity = totalQuantity + item.quantity;
              return (
                <tr key={item.id} style={{ height: "6px" }}>
                  <td>{index + 1}</td>
                  <td>
                    {!edit ? (
                      item.name
                    ) : (
                      <CustomDropDown
                        list={itemData}
                        label={item.name}
                        selectedItem={(id) => props.selectedItemData(id, index)}
                      />
                    )}
                  </td>
                  <td>{item.uom}</td>
                  <td>
                    {!edit ? (
                      item.quantity
                    ) : (
                      <Input2
                        name="quantity"
                        onChange={(e) => props.handleQuantityChange(e, index)}
                        value={item.quantity > 0 ? item.quantity : ""}
                        type="number"
                        inputmode="decimal"
                      />
                    )}
                  </td>

                  {invoiceType === "Delivery Challan" ||
                  invoiceType === "Good Receipt Note" ? null : (
                    <>
                      <td>
                        {" "}
                        {!edit ? (
                          item.rate
                        ) : (
                          <Input2
                            name="rate"
                            onChange={(e) =>
                              props.handleItemRateChange(e, index)
                            }
                            value={item.rate > 0 ? item.rate : ""}
                            type="number"
                            inputmode="decimal"
                          />
                        )}
                      </td>
                      {!productionNote && (
                        <td>
                          {!edit ? (
                            item.discount
                          ) : (
                            <Input2
                              name="dicount"
                              onChange={(e) =>
                                props.handleItemDisountChange(e, index)
                              }
                              value={item.discount > 0 ? item.discount : ""}
                              type="number"
                              inputmode="decimal"
                            />
                          )}
                        </td>
                      )}

                      <td>{item.total}</td>
                    </>
                  )}
                  {edit && (
                    <td>
                      {index !== 0 && (
                        <CgRemove
                          size={32}
                          color="red"
                          onClick={(e) => {
                            props.handleDecrease(index);
                          }}
                        />
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
        </tbody>
      </Table>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems:'center',
          justifyContent:'flex-end',
         
        }}
      >
        <Table striped bordered hover size="sm" style={{ width: "200px",}}>
          <thead>
            <tr>
              <th>Total Quantity</th>
              <th>{totalQuantity}</th>
            </tr>
          </thead>
        </Table>
      </div>
      {edit && <CgAdd size={32} color="red" onClick={handleIncrease} />}
    </div>
  );
};

export default ItemTable;
