import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

import ListLevels from './ListLevels';
const Supplier = (props) => {

    const [integration, setIntegration] = useState([]);
    const [loading, setLoading] = useState(false);
    const coa = useSelector(state => state.coa);

    useEffect(() => {
        setLoading(coa.loading);
        setIntegration(coa.allLevels.filter(f => f.type === 'sp'));
    }, [coa])
    return (
        <div>
            <h5>Supplier </h5>
            <ListLevels integration={integration} type='sp' right={props.right} />
        </div>
    )

}

export default Supplier
