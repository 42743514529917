import React from 'react'
import { Form } from 'react-bootstrap';
const GroupingOption = (props) => {
    const {options}=props;
    return (
       <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start',
       
    }}>
           <h4 style={{ marginLeft:'30px'}}>Group Option</h4>
            <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft:'30px'
            }}>
            {
                props.check === 'itemWisePartyWise' ? <Form.Check
                    type="checkbox"
                    label="Customer"
                    value='customer'
                    name='groupOption'
                    checked={options.customer?"checked":''}
                    onChange={props.handleCheckValue}
                    style={{marginRight:'30px'}}
                /> : null
            }

            <Form.Check
                type="checkbox"
                label="Category"
                value='category'
                name='groupOption'
                checked={options.category?"checked":''}
                onChange={props.handleCheckValue}
                style={{marginRight:'30px'}}
            />
            <Form.Check
                type="checkbox"
                label="Type"
                value='type'
                name='groupOption'
                checked={options.type?"checked":''}
                onChange={props.handleCheckValue}
                style={{marginRight:'30px'}}
            />


        </div>
       </div>
    )
}

export default GroupingOption
