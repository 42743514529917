import firebase from "firebase";
export const saveCompanyAction = async (data) => {
  let query = await firebase
    .firestore()
    .collection("companycollection")
    .doc("mycompany");
  const check = await query.get();
  if (check.exists) {
    const picture = data.logo;
    if (picture !== "") {
      await uploadImageHandler(picture, data.email).then(async (url) => {
        query
          .update({
            ...data,
            logo: url,
          })
          .then(async () => {
            return await data;
          })
          .catch((err) => {
            console.log(err);
          });
      });
    } else {
      query
        .update({
          ...data,
        })
        .then(async () => {
          return await data;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  } else {
    const picture = data.logo;
    if (picture !== "") {
      await uploadImageHandler(picture, data.email).then(async (url) => {
        query
          .set({
            ...data,
            logo: url,
          })
          .then(async () => {
            return await data;
          })
          .catch((err) => {
            console.log(err);
          });
      });
    } else {
      query
        .set({
          ...data,
        })
        .then(async () => {
          return await data;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
export const loadCompany = async () => {
  let query = await firebase
    .firestore()
    .collection("companycollection")
    .doc("mycompany");
  const check = await query.get();
  if (check.exists) {
    const data = check.data();
    if (data) {
      return { ...data };
    }
  } else {
    return {
      name: "",
      address: "",
      phoneNo: "",
      mobileNo: "",
      ntn: "",
      stn: "",
      email: "",
      url: "",
      logo: "",
    };
  }
};

const uploadImageHandler = async (imgUrl, id) => {
  const storageRef = firebase.storage().ref();
  var metadata = {
    contentType: "image/jpeg",
  };
  const str = imgUrl.name;
  const change = str.split("").join("");
  const makeName = id + change;
  let url;
  var uploadTask = storageRef
    .child("company/" + makeName)
    .put(imgUrl, metadata);

  url = await uploadTask.snapshot.ref.getDownloadURL();
  return url;
};
