import {
    LOAD_CUSTOMER_REQUEST,
    LOAD_CUSTOMER_SUCCESS,
    LOAD_CUSTOMER_FAILURE,
    EDIT_CUSTOMER_REQUEST,
    EDIT_CUSTOMER_SUCCESS,
    EDIT_CUSTOMER_FAILURE
} from '../action/addCustomer';
const initialState = {
    loading: false,
    customers: [],
    suppliers: [],
    error: ''
};
const customerReducer = (state = initialState, action) => {
    switch (action.type) {

        case LOAD_CUSTOMER_REQUEST: {
            state = {
                ...state,
                loading: true,
            }
        }
            break;
        case LOAD_CUSTOMER_SUCCESS: {
            state = {
                ...state,
                customers: action.payload.customers,
                suppliers: action.payload.suppliers,
                loading: false,
            }

        }
            break;
        case LOAD_CUSTOMER_FAILURE: {
            state = {
                ...state,
                error: action.payload.error,
                loading: false,
            }

        }
            break;
        case EDIT_CUSTOMER_REQUEST: {
            state = {
                ...state,
                loading: true,
            }
        }
            break;
        case EDIT_CUSTOMER_SUCCESS: {
            let updated = action.payload.customer;

            const newArray = state.customers.map(c => {
                if (updated.id === c.id) {

                    return updated;
                } else {
                    return c;
                }
            })
            state = {
                ...state,
                loading: false,
                customers: newArray
            }

        }
            break;
        case EDIT_CUSTOMER_FAILURE: {
            state = {
                ...state,
                error: action.payload.error,
                loading: false,
            }

        }
            break;
    }

    return state;
};
export default customerReducer;