import React, { useEffect, useState } from "react";
import ItemTable from "../components/InvoiceCom/ItemTable";
import ProductionBottom from "../components/production/ProductionBottom";
import ProductionHeader from "../components/production/ProductionHeader";

const PrintNote = () => {
  const [receipyData, setReceipyData] = useState({});
  useEffect(() => {
    let data = localStorage.getItem("printOption")
      ? localStorage.getItem("printOption")
      : null;
    if (data) {
      const parseData = JSON.parse(data);
      setReceipyData(parseData);
    }
  }, []);
  useEffect(() => {
    if (receipyData.type) {
      window.print();
    }
  }, [receipyData]);
  return (
    <div>
      <ProductionHeader
        party={receipyData.receipyItemName}
        date={receipyData.date}
        forQuantity={receipyData.forQuantity}
        caption={receipyData.caption}
        edit={false}
      />
      <ItemTable
        items={receipyData.items}
        productionNote={true}
        edit={false}
      />
      <ProductionBottom receipyData={receipyData} edit={false} />
    </div>
  );
};

export default PrintNote;
