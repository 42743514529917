import firebase from 'firebase'

export const GET_TB_REQUEST = 'GET_TB_REQUEST';
export const GET_TB_SUCCESS = 'GET_TB_SUCCESS';
export const GET_TB_FAILURE = 'GET_TB_FAILURE';

export const getAllLevel = (date) => {
    return async dispatch => {
        dispatch({
            type: GET_TB_REQUEST,
        })

        let level1 = [], level2 = [], level3 = [], newLevel3 = [], error;
        await firebase
            .firestore()
            .collection('level1')
            .orderBy('id', 'asc').get().then((Level1Ref) => {
                if (Level1Ref.size > 0) {
                    const nextIdArray = Level1Ref.docs.map(d => {
                        return { ...d.data(), docId: d.id }
                    });
                    level1 = [...nextIdArray]
                } else {

                }
            }).catch((err) => { error = err })
        await firebase
            .firestore()
            .collection('level2')
            .orderBy('id', 'asc').get().then((Level2Ref) => {
                if (Level2Ref.size > 0) {
                    const nextIdArray = Level2Ref.docs.map(d => {
                        return { ...d.data(), docId: d.id }
                    });
                    level2 = [...nextIdArray]
                } else {

                }
            }).catch((err) => { error = err })
        await firebase
            .firestore()
            .collection('level3')
            .orderBy('id', 'asc').get().then((Level3Ref) => {
                if (Level3Ref.size > 0) {
                    const nextIdArray = Level3Ref.docs.map(d => {
                        return {
                            ...d.data(),
                            docId: d.id,
                        }
                    });

                    level3 = [...nextIdArray]

                } else {

                }
            }).then(async () => {
                let ledgerData = []

                const ledgerRef = await firebase
                    .firestore()
                    .collection("generalLedger").get();
                if (ledgerRef.size > 0) {
                    ledgerData = ledgerRef.docs.map(doc => {
                        return {
                            docId: doc.id,
                            ...doc.data()
                        }
                    })

                }
                let startDate = firebase
                    .firestore
                    .Timestamp
                    .fromDate(new Date(date.start));
                let endDate = firebase
                    .firestore
                    .Timestamp
                    .fromDate(new Date(date.end));
                if (ledgerData.length > 0) {
                    await level3.map(async level => {
                        const level3Data = await generalLedgerGet(level, ledgerData, startDate, endDate);
                        let crOBalance = 0, drOBalance = 0;

                        if (parseInt(level3Data.openingBalance) < 0) {
                            crOBalance = level3Data.openingBalance;
                        }
                        if (parseInt(level3Data.openingBalance) > 0) {
                            drOBalance = level3Data.openingBalance;
                        }

                        if (drOBalance > 0 || 
                            crOBalance > 0 || 
                            parseInt(level3Data.credit) > 0 || 
                            parseInt(level3Data.debit) > 0) {
                                newLevel3.push({
                                    docId: level.docId,
                                    id: level.id,
                                    title: level.title,
                                    parentId: level.parentId,
                                    credit: parseInt(level3Data.credit),
                                    debit: parseInt(level3Data.debit),
                                    obcredit: crOBalance,
                                    obdebit: drOBalance
        
                                })

                        }
                       


                    })
                }

            }).then(() => {

                const makeList = createLevel([...level1, ...level2, ...newLevel3]);
                dispatch({
                    type: GET_TB_SUCCESS,
                    payload: { data: makeList }
                })
            }).catch((err) => { error = err })








    }
}
async function generalLedgerGet(level, collection, start, end) {
    let credit = 0, debit = 0, openingBalance = 0;
    const filterLedger = collection.filter(f => f.ledgerId === level.docId);
    if (parseInt(level.credit) > 0 || parseInt(level.debit) > 0) {
        if (parseInt(level.credit) > 0) {
            openingBalance = openingBalance - parseInt(level.credit)
        }
        if (parseInt(level.debit) > 0) {
            openingBalance = openingBalance + parseInt(level.debit)
        }

    }
    if (filterLedger.length > 0) {


        filterLedger.forEach((d) => {
            const check = d.date;
            if (check < start) {
                if (parseInt(d.credit) > 0) {
                    openingBalance = openingBalance - parseInt(d.accCredit)
                } else {
                    openingBalance = openingBalance + parseInt(d.accDebit)
                }
            }

            if (check >= start && check <= end) {
                credit = credit + parseInt(d.accCredit)
                debit = debit + parseInt(d.accDebit)
            }

        });
    }

    return { credit, debit, openingBalance }

}
function createLevel(allLevels, parentId = null) {
    const LevelList = [];
    let catgory;
    if (parentId == null) {
        catgory = allLevels.filter((f) => f.parentId == undefined);
    } else {
        catgory = allLevels.filter((f) => f.parentId == parentId);
    }
    for (let cat of catgory) {
        LevelList.push({
            docId: cat.docId,
            id: cat.id,
            title: cat.title,
            parentId: cat.parentId,
            type: cat.type,
            children: createLevel(allLevels, cat.docId),
            credit: cat.credit ? cat.credit : 0,
            debit: cat.debit ? cat.debit : 0,
            obcredit: cat.obcredit ? cat.obcredit : 0,
            obdebit: cat.obdebit ? cat.obdebit : 0,
        });
    }
    return LevelList;
}
