import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import "../../Css/generalStyle.css";
import { getAllUser } from "../../action/superAdmin";
import ShowModal from "../../subcom/ShowModal";

import SuperAdminOption from "./SuperAdminOption";
import SignUp from "../../pages/SignUp";
function DataShow() {
  const userRed = useSelector((state) => state.user);
  const alluserRed = useSelector((state) => state.allusers);
  const [user, setUser] = useState({});
  const [showSignUp, setShowSignUp] = useState(false);
  const [userList, setUserList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      Object.keys(userRed.user).length > 0 &&
      userRed.user.role === "superadmin"
    ) {
      setUser(userRed.user);
    }
  }, [userRed]);
  useEffect(async () => {
    if (
      Object.keys(userRed.user).length > 0 &&
      userRed.user.role === "superadmin"
    ) {
      dispatch(getAllUser());
    }
  }, [userRed]);
  useEffect(async () => {
    setUserList(alluserRed.users);
    console.log({ user: alluserRed.users });
  }, [alluserRed]);
  const showSignUpFormHandler = () => {
    setShowSignUp(true);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        {alluserRed.loading && (
          <ShowModal show={alluserRed.loading} size={"lg"}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner animation="border" role="status" />
            </div>
          </ShowModal>
        )}
        {showSignUp && (
          <ShowModal show={showSignUp} size={"lg"}>
            <SignUp cancelSignUp={() => setShowSignUp(false)} />
          </ShowModal>
        )}

        <span onClick={showSignUpFormHandler} style={{ margin: "30px" }}>
          Add User
          <AiOutlinePlusCircle size={32} />
        </span>
      </div>
     
        {userList &&
          userList.length > 0 &&
          userList.map((user) => {
            return <SuperAdminOption user={user} key={user.email} />;
          })}
      
    </>
  );
}
export default DataShow;
