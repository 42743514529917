import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

import ListLevels from './ListLevels';
const Customer = (props) => {

    const [integration, setIntegration] = useState([]);
   
    const coa = useSelector(state => state.coa);

    useEffect(() => {
       
        setIntegration(coa.allLevels.filter(f => f.type === 'ct'));
    }, [coa])
    return (
        <div>
            <h5>Customer </h5>
            <ListLevels integration={integration} type='ct' right={props.right} />
        </div>
    )

}

export default Customer
