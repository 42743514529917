import React, { useState, useEffect } from "react";
import { Button, CloseButton } from "react-bootstrap";
import { loadProductionNote } from "../../action/productionCom";
import CustomDropDown from "../../subcom/CustomDropDown";
import Input from "../../subcom/Input";
import ShowModal from "../../subcom/ShowModal";
const CopyProductionNote = (props) => {
  const [productionNoteList, setProductionNoteList] = useState([]);
  const [productionNoteCaption, setProductionNoteCaption] = useState([]);
  const [showCopyVoucher, setShowCopyVoucher] = useState(false);
  const [sReceipty, setSReceipty] = useState({ id: "", name: "" });
  const [rQty, setSReceiptyQty] = useState("");
  const [rCaption, setSReceiptyCaption] = useState("");
  useEffect(() => {
    if (props.receipyNoteList && props.receipyNoteList.length > 0) {
      loadProductinNoteListHandler();
    }
  }, [props.receipyNoteList]);

  const loadProductinNoteListHandler = async () => {
    const list = props.receipyNoteList;
    const makeList = list.map((item) => {
      return {
        docId: item.docId,
        name: item.receipyItemName,
        caption: item.caption,
      };
    });
    const makeListCaptions = list.map((item) => {
      return {
        docId: item.docId,

        name: item.caption,
      };
    });
    setProductionNoteList(makeList);
    setProductionNoteCaption(makeListCaptions);
  };

  const handleCopyOtherInvoice = () => {
    setShowCopyVoucher(!showCopyVoucher);
  };

  const selectProductionNoteHandler = (id) => {
    let findReceipy = productionNoteList.find((f) => f.docId === id);
    setSReceipty({ id: findReceipy.docId, name: findReceipy.name });
  };
  const selectProductionNoteCaption = (id) => {
    let findReceipy = productionNoteCaption.find((f) => f.docId === id);
    setSReceiptyCaption(findReceipy.name);
  };
  const selectProductionNoteQty = (qty) => {
    let value;
    if (qty.target.value === "") {
      value = 0;
    } else {
      value = parseInt(qty.target.value);
    }
    setSReceiptyQty(value);
  };
  const copyReceipyHandler = () => {
    if (rCaption !== "" && rQty > 0 && sReceipty.name !== "") {
      props.copyReceipyHandler({
        caption: rCaption,
        forQuantity: rQty,
        id: sReceipty.id,
      });
      setShowCopyVoucher(false);
    } else {
      alert("please complete all parameters");
    }
  };
  if (productionNoteList) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <Button onClick={handleCopyOtherInvoice}>Copy Receipy</Button>

        {showCopyVoucher ? (
          <ShowModal
            show={showCopyVoucher}
            size={"lg"}
            onHide={() => setShowCopyVoucher(false)}
            center={true}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>
                <CloseButton
                  variant="danger"
                  onClick={() => setShowCopyVoucher(false)}
                />
              </span>
              <h3>Select Production Note</h3>
            </div>
            <CustomDropDown
              list={productionNoteList}
              label={sReceipty.name === "" ? "Choose Receipy" : sReceipty.name}
              selectedItem={selectProductionNoteHandler}
            />

            <CustomDropDown
              list={productionNoteCaption}
              label={rCaption === "" ? "Choose Caption" : rCaption}
              selectedItem={selectProductionNoteCaption}
            />

            <Input
              label={"For Quantity"}
              name="voucherId"
              type="number"
              onChange={selectProductionNoteQty}
              value={rQty}
            />
            <Button onClick={copyReceipyHandler} style={{ width: "100%" }}>
              GET RECEIPY
            </Button>
          </ShowModal>
        ) : null}
      </div>
    );
  } else {
    return <div>loading</div>;
  }
};

export default CopyProductionNote;

const tableStyle = {
  width: "120px",
  fontSize: "12px",
};
