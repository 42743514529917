import React, { useState } from "react";
import { AiOutlineSortDescending } from "react-icons/ai";

const PartyWise = (props) => {
  const { salesReport, pendingReport } = props;
  const [report, setReport] = useState(salesReport);
  const [sortedByClient, setSortedByClient] = useState(false);
  const [sortedByGrand, setSortedByGrand] = useState(false);
  let tax = 0;
  let discount = 0;
  let grand = 0;
  let advance = 0;
  let balance = 0;
  let gross = 0;

  const sortByGrand = async () => {
    let unsorted = [];
    setReport(unsorted);
    if (sortedByGrand) {
      unsorted = await salesReport.sort(function (a, b) {
        const aId = a.grand;
        const bId = b.grand;
        return parseInt(aId) - parseInt(bId);
      });
    } else {
      unsorted = await salesReport.sort(function (a, b) {
        const aId = a.grand;
        const bId = b.grand;
        return parseInt(bId) - parseInt(aId);
      });
    }

    setReport(unsorted);
    setSortedByGrand(!sortedByGrand);
  };
  const sortByClient = async () => {
    let unsorted = salesReport;

    if (sortedByClient) {
      unsorted = unsorted.sort((itemA, itemB) => {
        var nameA = itemA.party.toLowerCase(),
          nameB = itemB.party.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
    } else {
      unsorted = unsorted.sort((itemA, itemB) => {
        var nameA = itemA.party.toLowerCase(),
          nameB = itemB.party.toLowerCase();
        if (nameA > nameB)
          //sort string ascending
          return -1;
        if (nameA < nameB) return 1;
        return 0; //default return value (no sorting)
      });
    }

    setReport(unsorted);
    setSortedByClient(!sortedByClient);
  };
  if (report.length > 0) {
    return (
      <div>
        <table
          className="table table-striped table-hover table-bordered"
          style={{ textAlign: "center" }}
        >
          <thead>
            <tr>
              <th>Sr</th>
              {props.print ? (
                <th>Client ID</th>
              ) : (
                <th onClick={sortByClient}>
                  <AiOutlineSortDescending size={22} />
                  Client ID
                </th>
              )}

              <th>Gross Amount</th>
              <th>Tax</th>
              <th>Discount</th>
              {props.print ? (
                <th>Grand Total</th>
              ) : (
                <th onClick={sortByGrand}>
                  <AiOutlineSortDescending size={22} />
                  Grand Total
                </th>
              )}

              <th>Advance</th>
              <th>Balance</th>

              {/* <th>Operation</th> */}
            </tr>
          </thead>
          <tbody>
            {report.map((invoice, index) => {
              tax = tax + parseInt(invoice.tax);
              discount = discount + parseInt(invoice.discount);
              grand = grand + parseInt(invoice.grand);
              advance = advance + parseInt(invoice.advance);
              balance = balance + parseInt(invoice.balance);
              gross = gross + parseInt(invoice.gross);
              return (
                <tr key={invoice.docId}>
                  <td>{index + 1}</td>
                  <td style={{ textAlign: "start" }}>{invoice.party}</td>
                  <td>{invoice.gross}</td>
                  <td>{invoice.tax}</td>
                  <td>{invoice.discount}</td>
                  <td>{invoice.grand}</td>
                  <td>{invoice.advance}</td>
                  <td>{grand - advance}</td>
                </tr>
              );
            })}
            <tr>
              <th></th>

              <th>Total</th>
              <th>{gross}</th>
              <th>{tax}</th>
              <th>{discount}</th>
              <th>{grand}</th>
              <th>{advance}</th>
              <th>{grand - advance}</th>
            </tr>
          </tbody>
        </table>
      </div>
    );
  } else {
    return <div>Please Select Parameters to View Report</div>;
  }
};

export default PartyWise;
