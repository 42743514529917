import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as coaActions from "../../action/coaAction";
import ChartOfAccountTable from "../../components/chartOfAccountReport/ChartOfAccountTable";
import SlipHeader from "../../components/SlipHeader.js/SlipHeader";
import { loadcompanyData } from "../../action/generalMethod";
const PrintChartOfAccount = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const coa = useSelector((state) => state.coa);
  const [companyData, setCompanyData] = useState({});
  const globalRed = useSelector((state) => state.global);
  const [showLogo, setShowLogo] = useState(false);
  useEffect(() => {
    document.title = "Chart Of Account";
    dispatch(coaActions.getAllLevel());
  }, []);

  useEffect(async() => {
    setList(coa.allLevels);
    const cData = await loadcompanyData();
    setCompanyData(cData);
    if (globalRed.showLogo && globalRed.showLogo.length > 0) {
      let showOrNot=globalRed.showLogo;
      setShowLogo(showOrNot[2].children[0].children[2].show);
    }
  }, [coa,globalRed]);
  useEffect(async () => {
    if (list.length > 0) {
      await delay(4000);
     // window.print();
    }
  }, [list]);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
 
  return (
    <div style={{ margin: "10px" }}>
      <SlipHeader
        companyData={companyData}
        data={""}
        vNo={""}
        slipType={"Chart of account"}
        showLogo={showLogo}
      />
      <ChartOfAccountTable list={list} />
    </div>
  );
};

export default PrintChartOfAccount;
