import React from "react";
import { Table } from "react-bootstrap";
import Input2 from "../../subcom/Input2";
const BottomTable = (props) => {
  const { gross, freight, tax, discount, grand, other, invoiceOwner } =
    props.newEditInvoice;
  const { edit } = props;

  const { showType, invoiceCreator } = props;
  // console.log({ invoice: props.newEditInvoice });
  return (
    <div
      style={{
      
        width: "100%",
      }}
    >
      <Table striped bordered hover style={{ textAlign: "center" }}>
        <thead>
          <tr>
            {showType === "t" ? <th>Value Excl.Tax</th> : <th>Gross</th>}

            {showType !== "s" ? <th>Tax</th> : null}
            <th>Fright</th>
            <th>Discount</th>
            {showType === "t" ? <th>Value Incl.Tax</th> : <th>Grand</th>}

            <th>Advance</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ borderBottom: "5px solid blue ", color: "black" }}>
            <td>{gross}</td>
            {showType !== "s" ? (
              <td>
                {!edit ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ border: "1px solid black", padding: "5px" }}>
                      {tax + "%"}
                    </span>
                    <span style={{ border: "1px solid black", padding: "5px" }}>
                      {((gross / 100) * tax).toFixed(2)}
                    </span>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      <input
                        name="quantity"
                        onChange={props.handleTaxChange}
                        value={tax > 0 ? tax : ""}
                        type="number"
                        style={{ width: "40px" }}
                      />
                      %
                    </span>
                    <input
                      disabled={"disabled"}
                      name="quantity"
                      onChange={props.handleTaxChange}
                      value={(gross * tax) / 100}
                      type="number"
                      style={{ width: "70px", textAlign: "center" }}
                    />
                  </div>
                )}
              </td>
            ) : null}
            <td style={{ width: "100px" }}>
              {!edit ? (
                freight
              ) : (
                <Input2
                  name="quantity"
                  onChange={props.handleFrightChange}
                  value={freight > 0 ? freight : ""}
                  type="number"
                />
              )}
            </td>

            <td style={{ width: "100px" }}>
              {!edit ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span style={{ border: "1px solid black", padding: "5px" }}>
                    {discount + "%"}
                  </span>
                  <span style={{ border: "1px solid black", padding: "5px" }}>
                    {((gross / 100) * discount).toFixed(2)}
                  </span>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "150px",
                  }}
                >
                  <span>
                    <input
                      name="quantity"
                      onChange={props.handleDiscountChange}
                      value={discount > 0 ? discount : ""}
                      type="number"
                      style={{ width: "40px" }}
                    />
                    %
                  </span>
                  <input
                    disabled={"disabled"}
                    name="quantity"
                    onChange={props.handleDiscountChange}
                    value={(gross * discount) / 100}
                    type="number"
                    style={{ width: "70px", textAlign: "center" }}
                  />
                </div>
              )}
            </td>

            <td style={{ width: "100px" }}>{grand.toFixed(2)}</td>
            <td style={{ width: "100px" }}>
              {!edit ? (
                other
              ) : (
                <Input2
                  name="quantity"
                  onChange={props.handleOtherChange}
                  value={other > 0 ? other : ""}
                  type="number"
                />
              )}
            </td>
            <td style={{ width: "100px" }}>{(grand - other).toFixed(2)}</td>
          </tr>
        </tbody>
      </Table>
      <h6 style={{ display: "flex", flexDirection: "row-reverse" }}>
        created by:{invoiceOwner && invoiceOwner}
      </h6>
    </div>
  );
};

export default BottomTable;
