
import {
  TOGGLE_THEME_REQUEST,
  SAVE_PERMSSION_FAILURE,
  SAVE_PERMSSION_REQUEST,
  SAVE_PERMSSION_SUCCESS,
  SHOW_LOGO_SUCCESS,
} from "../action/globalAction";

const initialState = {
  loading: false,
  isDarkTheme: false,
  blackTheme: "dark",
  blackThemecolor: "white",
  whiteTheme: "light",
  whiteThemecolor: "black",
  error: "",
  saveWithAprrove: false,
  saveWithCheck: false,
  backDateEntry: false,
  stockWithApprove: false,
  stockWithCheck: false,
  updateStockWithGRN: false,
  updateStockWithDC: false,
  jvWithCheck: false,
  jvWithApprove: false,
  darkTheme: false,
  enforceCategory: false,
  companyInfoShow: false,
  date: "",
  restrictNegativeQty: "",
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_THEME_REQUEST:
      {
        state = {
          ...state,
          isDarkTheme: !state.isDarkTheme,
        };
      }
      break;
    case SAVE_PERMSSION_REQUEST:
      {
        state = {
          ...state,
          loading: true,
        };
      }
      break;
    case SAVE_PERMSSION_SUCCESS:
      {
       
        state = {
          ...state,
          saveWithAprrove: action.payload.saveWithAprrove,
          saveWithCheck: action.payload.saveWithCheck,
          backDateEntry: action.payload.backDateEntry,
          isDarkTheme: action.payload.darkTheme,
          stockWithCheck: action.payload.stockWithCheck,
          stockWithApprove: action.payload.stockWithApprove,
          jvWithApprove: action.payload.jvWithApprove,
          jvWithCheck: action.payload.jvWithCheck,
          enforceCategory: action.payload.enforceCategory,
          date: action.payload.date,
          restrictNegativeQty: action.payload.restrictNegativeQty,
          companyInfoShow: action.payload.companyInfoShow,
          showLogo: action.payload.showLogo,
          updateStockWithGRN: action.payload.updateStockWithGRN,
          updateStockWithDC: action.payload.updateStockWithDC,
          loading: false,
        };
      }
      break;
    case SHOW_LOGO_SUCCESS:
      {
        state = {
          ...state,
          showLogo: action.payload.showLogo,
          loading: false,
        };
      }
      break;
    case SAVE_PERMSSION_FAILURE:
      {
        state = {
          ...state,
          loading: false,
          error: "",
        };
      }
      break;
  }
  return state;
};
export default globalReducer;
