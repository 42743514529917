import React, { useState } from "react";
import Input from "../subcom/Input";
import "../Css/generalStyle.css";
import { FloatingLabel, Form, Button, Spinner } from "react-bootstrap";
import { signup } from "../action/signup";
import { useDispatch, useSelector } from "react-redux";
import ShowModal from "../subcom/ShowModal";

const SignUp = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("user");
  const [picture, setPicture] = useState("");

  const dispatch = useDispatch();

  const nameChangeHandler = (e) => {
    const val = e.target.value;
    setName(val);
  };
  const emailChangeHandler = (e) => {
    const val = e.target.value;
    setEmail(val);
  };
  const passwordChangeHandler = (e) => {
    const val = e.target.value;
    setPassword(val);
  };
  const roleChangeHandler = (e) => {
    const val = e.target.value;
    setRole(val);
  };
  const pictureChangeHandler = (e) => {
    const val = e.target.files[0];
    console.log({ val, e });
    setPicture(val);
  };
  const signupHandler = async () => {
    if (email === "") {
      alert("email cannot be empty");
    }
    if (password === "") {
      alert("password cannot be empty");
    }
    if (name === "") {
      alert("name cannot be empty");
    } else {
      dispatch(signup({ email, name, password, picture, role }));
      props.cancelSignUp();
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <Input
          label={"Name"}
          required={"required"}
          type={"text"}
          name={"name"}
          onChange={nameChangeHandler}
          value={name}
          disabled={""}
        />
        <Input
          label={"Email"}
          required={"required"}
          type={"email"}
          name={"email"}
          onChange={emailChangeHandler}
          value={email}
          disabled={""}
        />
        <Input
          label={"Password"}
          required={"required"}
          type={"password"}
          name={"email"}
          onChange={passwordChangeHandler}
          value={password}
          disabled={""}
        />
        <FloatingLabel label="Select Role">
          <Form.Select onChange={roleChangeHandler} value={role}>
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </Form.Select>
        </FloatingLabel>
        <input id="file-input" type="file" onChange={pictureChangeHandler} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          fontWeight: "bold",
        }}
      >
        <Button
          style={{
            fontWeight: "bold",
          }}
          onClick={props.cancelSignUp}
          variant="danger"
        >
          Cancel
        </Button>
        <Button
          onClick={signupHandler}
          style={{
            fontWeight: "bold",
          }}
        >
          ADD USER
        </Button>
      </div>
    </div>
  );
};

export default SignUp;
