import React, { useState, useEffect } from "react";
import { AiOutlineSortDescending } from "react-icons/ai";
const PartyWiseItemWise = (props) => {
  const { salesReport, options, pendingReport, pathname } = props;

  const [report, setReport] = useState(salesReport);
  const [sortItems, setSortItems] = useState(true);
  const [sortTitle, setSortTitle] = useState(true);
  const [sortQty, setSortQty] = useState(true);

  let grandSum = 0,
    grandQty = 0,
    grandIssue = 0;
  useEffect(() => {
    if (!options.customer && !options.category && !options.type) {
      let items = [];
      salesReport.map((sale) => {
        if (sale.category && sale.category.length > 0) {
          sale.category.map((cat) => {
            if (cat.types && cat.types.length > 0) {
              cat.types.map((type) => {
                if (type.items && type.items.length > 0) {
                  type.items.map((item) => {
                    items.push(item);
                  });
                }
              });
            }
          });
        }
      });
      setReport(items);
    } else {
      setReport(salesReport);
    }
  }, [options]);
  const sortByItems = () => {
    if (!options.customer && !options.category && !options.type) {
      let unsorted = report;
      if (sortItems) {
        unsorted = unsorted.sort((itemA, itemB) => {
          return parseInt(itemB.total) - parseInt(itemA.total);
        });
      } else {
        unsorted = unsorted.sort((itemA, itemB) => {
          return parseInt(itemA.total) - parseInt(itemB.total);
        });
      }
      setReport(unsorted);
    } else {
      let customers = [];
      salesReport.map((sale) => {
        if (sale.category && sale.category.length > 0) {
          let categories = [];
          sale.category.map((cat) => {
            let types = [];
            if (cat.types && cat.types.length > 0) {
              cat.types.map((type) => {
                let items = [];
                if (type.items && type.items.length > 0) {
                  if (sortItems) {
                    items = type.items.sort((itemA, itemB) => {
                      return parseInt(itemB.total) - parseInt(itemA.total);
                    });
                  } else {
                    items = type.items.sort((itemA, itemB) => {
                      return parseInt(itemA.total) - parseInt(itemB.total);
                    });
                  }
                }
                types.push({ ...type, items });
              });
            }
            categories.push({ ...cat, types });
          });
          customers.push({ ...sale, category: categories });
        }
      });
      setReport(customers);
    }

    setSortItems(!sortItems);
  };
  const sortByTitle = () => {
    if (!options.customer && !options.category && !options.type) {
      let unsorted = report;
      if (sortTitle) {
        unsorted = unsorted.sort((itemA, itemB) => {
          var nameA = itemA.name.toLowerCase(),
            nameB = itemB.name.toLowerCase();
          if (nameA < nameB)
            //sort string ascending
            return -1;
          if (nameA > nameB) return 1;
          return 0; //default return value (no sorting)
        });
      } else {
        unsorted = unsorted.sort((itemA, itemB) => {
          var nameA = itemA.name.toLowerCase(),
            nameB = itemB.name.toLowerCase();
          if (nameA > nameB)
            //sort string ascending
            return -1;
          if (nameA < nameB) return 1;
          return 0; //default return value (no sorting)
        });
      }
      setReport(unsorted);
    } else {
      let customers = [];
      salesReport.map((sale) => {
        if (sale.category && sale.category.length > 0) {
          let categories = [];
          sale.category.map((cat) => {
            let types = [];
            if (cat.types && cat.types.length > 0) {
              cat.types.map((type) => {
                let items = [];
                if (type.items && type.items.length > 0) {
                  if (sortTitle) {
                    items = type.items.sort((a, b) => {
                      var nameA = a.name.toLowerCase(),
                        nameB = b.name.toLowerCase();
                      if (nameA < nameB)
                        //sort string ascending
                        return -1;
                      if (nameA > nameB) return 1;
                      return 0; //default return value (no sorting)
                    });
                  } else {
                    items = type.items.sort((a, b) => {
                      var nameA = a.name.toLowerCase(),
                        nameB = b.name.toLowerCase();
                      if (nameA > nameB)
                        //sort string ascending
                        return -1;
                      if (nameA < nameB) return 1;
                      return 0; //default return value (no sorting)
                    });
                  }
                }
                types.push({ ...type, items });
              });
            }
            categories.push({ ...cat, types });
          });
          customers.push({ ...sale, category: categories });
        }
      });
      setReport(customers);
    }

    setSortTitle(!sortTitle);
  };
  const sortByQty = () => {
    if (!options.customer && !options.category && !options.type) {
      let unsorted = report;
      if (sortQty) {
        unsorted = unsorted.sort((itemA, itemB) => {
          return parseInt(itemB.quantity) - parseInt(itemA.quantity);
        });
      } else {
        unsorted = unsorted.sort((itemA, itemB) => {
          return parseInt(itemA.quantity) - parseInt(itemB.quantity);
        });
      }
      setReport(unsorted);
    } else {
      let customers = [];
      salesReport.map((sale) => {
        if (sale.category && sale.category.length > 0) {
          let categories = [];
          sale.category.map((cat) => {
            let types = [];
            if (cat.types && cat.types.length > 0) {
              cat.types.map((type) => {
                let items = [];
                if (type.items && type.items.length > 0) {
                  if (sortQty) {
                    items = type.items.sort((itemA, itemB) => {
                      return (
                        parseInt(itemB.quantity) - parseInt(itemA.quantity)
                      );
                    });
                  } else {
                    items = type.items.sort((itemA, itemB) => {
                      return (
                        parseInt(itemA.quantity) - parseInt(itemB.quantity)
                      );
                    });
                  }
                }
                types.push({ ...type, items });
              });
            }
            categories.push({ ...cat, types });
          });
          customers.push({ ...sale, category: categories });
        }
      });
      setReport(customers);
    }

    setSortQty(!sortQty);
  };

  if (report.length > 0) {
    return (
      <div>
        <table
          className="table table-striped table-hover table-bordered"
          style={{ textAlign: "center" }}
        >
          <thead>
            <tr>
              <th>Sr</th>
              <th>Date</th>
              <th>Voucher No</th>
              {props.print ? (
                <>
                  <th>Item Title</th>
                  <th>QTY</th>
                </>
              ) : (
                <>
                  <th onClick={sortByTitle}>
                    <AiOutlineSortDescending size={22} />
                    Item Title
                  </th>
                  <th onClick={sortByQty}>
                    <AiOutlineSortDescending size={22} />
                    QTY
                  </th>
                </>
              )}
              {!pendingReport ? (
                <>
                  <th>Rate</th>
                  {props.print ? (
                    <th>Total Amount</th>
                  ) : (
                    <th onClick={sortByItems}>
                      <AiOutlineSortDescending size={22} />
                      Total Amount
                    </th>
                  )}
                </>
              ) : (
                <>
                  {pathname === "grndcpending" ? (
                    <>
                      <th>issue</th>
                      <th>balance</th>
                    </>
                  ) : null}
                </>
              )}

              {/* <th>Operation</th> */}
            </tr>
          </thead>
          {!options.customer && !options.category && !options.type ? (
            <tbody>
              {report.map((item, index) => {
                grandIssue=grandIssue+item.issue;
                grandQty=grandQty+item.quantity;
                return (
                  <tr key={item.docId}>
                    <td>{index + 1}</td>
                    <td>
                      {item.date &&
                        new Date(item.date.seconds * 1000)
                          .toISOString()
                          .slice(0, 10)}
                    </td>
                    <td>{item.invoiceId}</td>
                    <td>{item.name}</td>
                    <td>{item.quantity}</td>
                    {!pendingReport ? (
                      <>
                        <td>{item.rate / item.quantity}</td>
                        <td>{item.total}</td>
                      </>
                    ) : (
                      <>
                        {pathname === "grndcpending" ? (
                          <>
                            <td>{item.issue}</td>
                            <td>{item.quantity-item.issue}</td>
                          </>
                        ) : null}
                      </>
                    )}
                  </tr>
                );
              })}
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th>Total</th>
                <th>{grandQty}</th>
                {!pendingReport ? (
                  <>
                    <th></th>
                    <th>{grandSum}</th>
                  </>
                ) : (
                  <>
                    {pathname === "grndcpending" ? (
                      <>
                        <th>{grandIssue}</th>
                        <th>{grandQty-grandIssue}</th>
                      </>
                    ) : null}
                  </>
                )}
              </tr>
            </tbody>
          ) : (
            <tbody>
              {report.map((sale) => {
                let customerSum = 0,
                  customerQty = 0,
                  customerIssue = 0;
                if (sale.category && sale.category.length > 0) {
                  return (
                    <>
                      {options.customer ? (
                        <tr>
                          <td
                            colSpan={
                              pendingReport && pathname !== "grndcpending"
                                ? 5
                                : 7
                            }
                            style={{ textAlign: "center" }}
                          >
                            {sale.name}
                          </td>
                        </tr>
                      ) : null}

                      {sale.category.map((cat) => {
                        let catSum = 0,
                          catQty = 0,
                          catIssue = 0;
                        if (cat.types && cat.types.length > 0) {
                          return (
                            <>
                              {options.category ? (
                                <tr>
                                  <th>{cat.name}</th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  {!pendingReport ? (
                                    <>
                                      <th></th>
                                      <th></th>
                                    </>
                                  ) : (
                                    <>
                                      {pathname === "grndcpending" ? (
                                        <>
                                          <th></th>
                                          <th></th>
                                        </>
                                      ) : null}
                                    </>
                                  )}
                                </tr>
                              ) : null}

                              {cat.types.map((type) => {
                                if (type.items && type.items.length > 0) {
                                  let sum = 0,
                                    qty = 0,
                                    issue = 0;
                                  return (
                                    <>
                                      {options.type ? (
                                        <tr>
                                          <th></th>
                                          <th>{type.name}</th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          {!pendingReport ? (
                                            <>
                                              <th></th>
                                              <th></th>
                                            </>
                                          ) : (
                                            <>
                                              {pathname === "grndcpending" ? (
                                                <>
                                                  <th></th>
                                                  <th></th>
                                                </>
                                              ) : null}
                                            </>
                                          )}
                                        </tr>
                                      ) : null}

                                      {type.items.map((item, index) => {
                                        sum = sum + parseInt(item.total);
                                        catSum = catSum + parseInt(item.total);
                                        qty = qty + parseInt(item.quantity);
                                        if (item.issue) {
                                          issue = issue + parseInt(item.issue);
                                          catIssue =
                                            catIssue + parseInt(item.issue);
                                          grandIssue =
                                            grandIssue + parseInt(item.issue);
                                        }
                                        catQty =
                                          catQty + parseInt(item.quantity);
                                        grandQty =
                                          grandQty + parseInt(item.quantity);
                                        grandSum =
                                          grandSum + parseInt(item.total);
                                        customerQty =
                                          customerQty + parseInt(item.quantity);
                                        if (item.issue) {
                                          customerIssue =
                                            customerIssue +
                                            parseInt(item.issue);
                                        }
                                        customerSum =
                                          customerSum + parseInt(item.total);
                                        return (
                                          <>
                                            <tr>
                                              <td>{index + 1}</td>
                                              <td>
                                                {new Date(
                                                  item.date.seconds * 1000
                                                )
                                                  .toISOString()
                                                  .slice(0, 10)}
                                              </td>
                                              <td>{item.invoiceId}</td>
                                              <td>{item.name}</td>
                                              <td>{item.quantity}</td>
                                              {!pendingReport ? (
                                                <>
                                                  <td>
                                                    {(
                                                      item.rate / item.quantity
                                                    ).toFixed(2)}
                                                  </td>
                                                  <td>{item.total}</td>
                                                </>
                                              ) : (
                                                <>
                                                  {pathname ===
                                                  "grndcpending" ? (
                                                    <>
                                                      <td>{item.issue}</td>
                                                      <td>
                                                        {item.quantity -
                                                          item.issue}
                                                      </td>
                                                    </>
                                                  ) : null}
                                                </>
                                              )}
                                            </tr>
                                          </>
                                        );
                                      })}
                                      {options.type ? (
                                        <tr>
                                          <th></th>
                                          <th></th>
                                          <th></th>

                                          <th> Type Wise Total</th>
                                          <th>{qty}</th>
                                          {!pendingReport ? (
                                            <>
                                              <th></th>
                                              <th>{sum}</th>
                                            </>
                                          ) : (
                                            <>
                                              {pathname === "grndcpending" ? (
                                                <>
                                                  <th>{issue}</th>
                                                  <th>{qty - issue}</th>
                                                </>
                                              ) : null}
                                            </>
                                          )}
                                        </tr>
                                      ) : null}
                                    </>
                                  );
                                }
                              })}
                              {options.category ? (
                                <tr>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th> Category Wise Total</th>
                                  <th>{catQty}</th>
                                  {!pendingReport ? (
                                    <>
                                      <th></th>
                                      <th>{catSum}</th>
                                    </>
                                  ) : (
                                    <>
                                      {pathname === "grndcpending" ? (
                                        <>
                                          <th>{catIssue}</th>
                                          <th>{catQty - catIssue}</th>
                                        </>
                                      ) : null}
                                    </>
                                  )}
                                </tr>
                              ) : null}
                            </>
                          );
                        }
                      })}
                      {options.customer ? (
                        <tr>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th> Customer Wise Total</th>
                          <th>{customerQty}</th>
                          {!pendingReport ? (
                            <>
                              <th></th>
                              <th>{customerSum}</th>
                            </>
                          ) : (
                            <>
                              {pathname === "grndcpending" ? (
                                <>
                                  <th>{customerIssue}</th>
                                  <th>{customerQty - customerIssue}</th>
                                </>
                              ) : null}
                            </>
                          )}
                        </tr>
                      ) : null}
                    </>
                  );
                }
              })}
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th> Grand Total</th>
                <th>{grandQty}</th>
                {!pendingReport ? (
                  <>
                    <th></th>
                    <th>{grandSum}</th>
                  </>
                ) : (
                  <>
                    {pathname === "grndcpending" ? (
                      <>
                        <th>{grandIssue}</th>
                        <th>{grandQty - grandIssue}</th>
                      </>
                    ) : null}
                  </>
                )}
              </tr>
            </tbody>
          )}
        </table>
      </div>
    );
  } else {
    return <div>Please Select Parameters to View Report</div>;
  }
};

export default PartyWiseItemWise;
