import firebase from "firebase";
export const LOAD_ALL_USERS_REQUEST = "LOAD_ALL_USERS_REQUEST";
export const LOAD_ALL_USERS_SUCCESS = "LOAD_ALL_USERS_SUCCESS";
export const LOAD_ALL_USERS_FAILURE = "LOAD_ALL_USERS_FAILURE";

export const UPDATE_ALL_USERS_REQUEST = "UPDATE_ALL_USERS_REQUEST";
export const UPDATE_ALL_USERS_SUCCESS = "UPDATE_ALL_USERS_SUCCESS";
export const UPDATE_ALL_USERS_FAILURE = "UPDATE_ALL_USERS_FAILURE";
export const UPDATE_USERS_REQUEST = "UPDATE_USERS_REQUEST";
export const UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS";
export const UPDATE_USERS_FAILURE = "UPDATE_USERS_FAILURE";

export const getAllUser = () => {
  return async (dispatch) => {
    dispatch({ type: LOAD_ALL_USERS_REQUEST });
    const userRightsRef = await firebase
      .firestore()
      .collection("userRight")
      .get();
    let userRightsData = [];
    if (userRightsRef.size > 0) {
      userRightsRef.docs.map((doc) => {
        userRightsData.push({ ...doc.data(), docId: doc.id });
      });
    }

    await firebase
      .firestore()
      .collection("user")
      .where("role", "!=", "superadmin")
      .get()
      .then((collection) => {
        let allUser;
        if (collection.size > 0) {
          allUser = collection.docs.map((doc) => {
            let findUserRight = userRightsData.find((f) => f.docId === doc.id);
            if (findUserRight) {
              return { ...doc.data(), right: findUserRight };
            }
          });

          if (allUser.length > 0) {
            dispatch({
              type: LOAD_ALL_USERS_SUCCESS,
              payload: { data: allUser },
            });
          }
        } else {
          dispatch({
            type: LOAD_ALL_USERS_FAILURE,
            payload: { error: "Users not found" },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: LOAD_ALL_USERS_FAILURE,
          payload: { error: err },
        });
      });
  };
};
export const updateRightsHandler = (email, docId, updateRight, objRights) => {
  return async (dispatch, getState) => {
    //  dispatch({ type: UPDATE_ALL_USERS_REQUEST });
    let newRight;

    if (!docId) {
      console.log({ email, docId, updateRight, objRights });
      Object.keys(objRights).map((key) => {
        let findUpdatedRights = updateRight[key];
        if (findUpdatedRights) {
          newRight = { ...newRight, [key]: findUpdatedRights };
        } else {
          newRight = { ...newRight, [key]: objRights[key] };
        }
      });
    } else {
      newRight = { ...objRights, [docId]: updateRight };
    }

    if (newRight && newRight.docId) {
      delete newRight.docId;
    }

    await firebase
      .firestore()
      .collection("userRight")
      .doc(email)
      .update({ ...newRight })
      .then(() => {
        dispatch({
          type: UPDATE_ALL_USERS_SUCCESS,
          payload: { docId: email, right: { ...newRight } },
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_ALL_USERS_FAILURE,
          payload: { error: err },
        });
      });
  };
};
export const updateNameAndRoleHandler = (email, name, role) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_USERS_REQUEST });
    await firebase
      .firestore()
      .collection("user")
      .doc(email)
      .update({ name, role })
      .then(() => {
        dispatch({
          type: UPDATE_USERS_SUCCESS,
          payload: { docId: email, name, role },
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_USERS_FAILURE,
          payload: { error: err },
        });
      });
  };
};
