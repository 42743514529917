import React from "react";
import "../Css/sideBar.css";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";
const CustomDrop = (props) => {
  const { title, show, index } = props;

  return (
    <div>
      <div
        className="btn btn-secondary dropdown-btn "
        key={index}
        onClick={() => props.toggleDropDown(index)}
      >
        <span
          style={{
            fontSize: "14px",
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textAlign: "start",
          }}
          className="text-sm-left"
        >
          {title && title.toUpperCase()}
        </span>
        <span>
          {show ? (
            <BsChevronUp style={arrowStyle} />
          ) : (
            <BsChevronDown style={arrowStyle} />
          )}
        </span>
      </div>
      {show && <ul className="list-group">{props.children}</ul>}
    </div>
  );
};
const arrowStyle = { width: "20px", height: "20px", color: "white" };
export default CustomDrop;
