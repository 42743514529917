// import {useState,useEffect} from 'react'

// const NewPageAdd = (props) => {

//   return (
//     <div></div>
//   );
// };

// export default NewPageAdd;
import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import {
  addRoute,
  createRouteHierarchy,
  deleteRoute,
  loadRoutes,
} from "../../action/addRoute";
import CustomDropDown from "../../subcom/CustomDropDown";
import Input from "../../subcom/Input";

const NewPageAdd = (props) => {
  const [routesList, setRoutesList] = useState([]);
  const [routesList2, setRoutesList2] = useState([]);
  const [grouping, setGrouping] = useState([]);
  const [routeName, setRouteName] = useState("");
  const [routeLink, setRouteLink] = useState("");
  const [routeRules, setRouteRules] = useState("");
  const [selectedRouteName, setSelectedRouteName] = useState("");
  const [selectedRouteName2, setSelectedRouteName2] = useState("");
  const [parentRouteId, setParentRouteId] = useState("");
  const [option, setOption] = useState("save");
  const [updateId, setUpdateId] = useState("");
  const [asChild, setasChild] = useState(true);
  const [listNumber, setListNumber] = useState("");
  useEffect(() => {
    loadHeaderComs();
  }, []);
  const loadHeaderComs = async () => {
    const list = await loadRoutes();
    setRoutesList(list);
    const unsort = createRouteHierarchy(list);
    const sortList = unsort.sort(function (a, b) {
      return a.no - b.no;
    });

    setGrouping(sortList);
  };

  const onRouteNameChange = (e) => setRouteName(e.target.value);
  const onRouteLinkChange = (e) => setRouteLink(e.target.value);

  const handleChangeRules = (value) => setRouteRules(value);
  const voucherAndInvoices = {
    view: true,
    enable: true,
    save: false,
    edit: false,
    delete: false,
    print: false,
    check: false,
    approve: false,
    cancel: false,
    visible: true,
  };
  const general = {
    view: true,
    enable: true,
    save: false,
    edit: false,
    delete: false,
    print: false,
    visible: true,
  };
  const reportActions = {
    view: true,
    enable: true,
    print: false,
    visible: true,
  };
  const saveRouteName = () => {
    if (routeRules === "" || routeName === "" || listNumber === "") {
      alert("please enter all info");
    } else {
      let applyRules;
      if (routeRules === "general") {
        applyRules = general;
      } else if (routeRules === "invoiceAndVoucher") {
        applyRules = voucherAndInvoices;
      } else if (routeRules === "reportAction") {
        applyRules = reportActions;
      }
      let saveData = {
        routeName,
        routeLink,
        applyRules,
        listNumber,
      };
      if (asChild) {
        saveData = {
          ...saveData,
          parentId: parentRouteId,
        };
      }

      if (option === "update") {
        saveData = { ...saveData, updateId };
      }
      addRoute(saveData, routeRules);
      setOption("save");
      setRouteName("");
      setRouteLink("");
      setUpdateId("");
      setParentRouteId("");
      setListNumber("");
      loadHeaderComs();
    }
  };
  const deleteRouteHandler = () => {
    const findChild = routesList.find((f) => f.parentId === updateId);
    if (findChild) {
      alert("cannot be deleted");
    } else {
      deleteRoute(updateId)
    }
  };
  const selectedItemData = (id) => {
    setParentRouteId(id);
    setRoutesList2(routesList.filter((f) => f.parentId === id));
    setSelectedRouteName(routesList.find((f) => f.docId === id).title);
    setSelectedRouteName2("");
  };
  const selectedItemData2 = (id) => {
    setParentRouteId(id);
    setSelectedRouteName2(routesList.find((f) => f.docId === id).title);
  };
  const handleUpdate = (grand) => {
    setOption("update");
    console.log({ grand });
    setRouteName(grand.title);
    setRouteLink(grand.href);
    setUpdateId(grand.docId);
    setListNumber(grand.no);
    if (grand.parentId) {
      setParentRouteId(grand.parentId);
      setasChild(true);
    } else {
      setasChild(false);
    }
  };
  const ChangeParentHandler = (e) => {
    setasChild(!asChild);
  };
  const onListNumberChange = (e) => {
    setListNumber(e.target.value);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "500px",
      }}
    >
      <Input
        label="Route Name"
        type="text"
        onChange={onRouteNameChange}
        value={routeName}
      />
      <Input
        label="Route Link"
        type="text"
        onChange={onRouteLinkChange}
        value={routeLink}
      />
      <Input
        label="List Number"
        type="number"
        onChange={onListNumberChange}
        value={listNumber}
      />
      <Form.Check
        type="checkbox"
        checked={asChild}
        label={"Set As Child"}
        onChange={ChangeParentHandler}
      />
      <Dropdown style={{ width: "100%" }}>
        <Dropdown.Toggle
          variant="success"
          id="dropdown-basic"
          style={{ width: "100%" }}
        >
          {routeRules === "" ? "Select Rules List" : routeRules.toUpperCase()}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleChangeRules("general")}>
            General Rules
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleChangeRules("invoiceAndVoucher")}>
            Invoice&Voucher Rules
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleChangeRules("reportAction")}>
            Report Actions
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {asChild && (
        <>
          <CustomDropDown
            list={routesList.filter((f) => {
              if (!f.parentId) {
                return f;
              }
            })}
            label={selectedRouteName}
            selectedItem={selectedItemData}
          />
          <CustomDropDown
            list={routesList2}
            label={selectedRouteName2}
            selectedItem={selectedItemData2}
          />
        </>
      )}
      <Button onClick={saveRouteName}>{option + " "}Save</Button>
      <Button onClick={deleteRouteHandler}>Delete</Button>
      <ul>
        {grouping.map((route) => {
          if (route.children.length > 0) {
            const sortChild = route.children.sort(function (a, b) {
              return a.no - b.no;
            });

            return (
              <>
                <li
                  key={route.title}
                  onClick={() => {
                    handleUpdate(route);
                  }}
                >
                  {route.title}-{route.no}
                </li>
                <ul>
                  {sortChild.map((chil) => {
                    if (chil.children.length > 0) {
                      const sortgrand = chil.children.sort(function (a, b) {
                        return a.no - b.no;
                      });

                      return (
                        <>
                          <li
                            key={chil.title}
                            onClick={() => {
                              handleUpdate(chil);
                            }}
                          >
                            {chil.title}
                          </li>
                          <ul>
                            {sortgrand.map((grand) => {
                              return (
                                <li
                                  key={grand.title}
                                  onClick={() => {
                                    handleUpdate(grand);
                                  }}
                                >
                                  {grand.title}
                                </li>
                              );
                            })}
                          </ul>
                        </>
                      );
                    } else {
                      return (
                        <li
                          key={chil.title}
                          onClick={() => {
                            handleUpdate(chil);
                          }}
                        >
                          {chil.title}
                        </li>
                      );
                    }
                  })}
                </ul>
              </>
            );
          } else {
            return (
              <li
                key={route.title}
                onClick={() => {
                  handleUpdate(route);
                }}
              >
                {route.title}-{route.no}
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default NewPageAdd;
