import React, { useState } from "react";
import { Table } from "react-bootstrap";
import * as XLSX from "xlsx";
import firebase from "firebase";
const COAMaker = () => {
  const [items, setItems] = useState([]);
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      const findParent = d.filter((f) => f.LevelNo === 1);
      const filterChild = d.filter((f) => f.LevelNo === 2);
      const filterGrand = d.filter((f) => f.LevelNo === 3);
      let coaList = [];
      findParent.map((p) => {
        let child = filterChild.filter((f) => f.ID.startsWith(p.ID));
        if (child.length > 0) {
          child.map((ch, index) => {
            let grand = filterGrand.filter((f) => f.ID.startsWith(ch.ID));
            if (grand.length > 0) {
              child[index] = { ...ch, children: grand };
            }
          });
        }
        coaList.push({ ...p, children: child });
      });
      // makeLeve1(findParent);
      // makeLeve2(filterChild);
     // makeLeve3(filterGrand);
      setItems(coaList);
      console.log({ d });
    });
  };
  // const makeLeve1 = async (findParent) => {
  //   console.log(findParent);
  //   findParent.map(async(level) => {
  //     await firebase.firestore().collection("level1").doc().set({
  //       id: level.ID,
  //       title: level.Title,
  //       type: level.Type.toLowerCase(),
  //     });
  //   });
  // };
  // const makeLeve2 = async (findParent) => {
  //   const getLevel1 = await firebase.firestore().collection("level1").get();
  //   if (getLevel1.size > 0) {
  //     const mapParents = getLevel1.docs.map((doc) => {
  //       const parentInfo = doc.data();
  //       let child = findParent.filter((f) => f.ID.startsWith(parentInfo.id));

  //       if (child.length > 0) {
  //         child.map(async (ch) => {
  //           const splitId = ch.ID.split("");
  //           let chId = `${splitId[2]}${splitId[3]}${splitId[4]}`;

  //           await firebase.firestore().collection("level2").doc().set({
  //             parentId: doc.id,
  //             level1Id: parentInfo.id,
  //             id: chId,
  //             title: ch.Title,
  //           });
  //         });
  //       }
  //     });
  //   }
  // };
  const makeLeve3 = async (findParent) => {
    const getLevel2 = await firebase.firestore().collection("level2").get();
    if (getLevel2.size > 0) {
      const mapParents = getLevel2.docs.map((doc) => {
        const parentInfo = doc.data();
        let child = findParent.filter((f) =>
          f.ID.startsWith(`${parentInfo.level1Id}${parentInfo.id}`)
        );
        console.log(child);
        if (child.length > 0) {
          child.map(async (ch) => {
            const splitId = ch.ID.split("");
            let chId = `${splitId[5]}${splitId[6]}${splitId[7]}${splitId[8]}`;

            await firebase.firestore().collection("level3").doc().set({
              parentId: doc.id,
              level1Id: parentInfo.id,
              id: chId,
              title: ch.Title,
            });
          });
        }
      });
    }
  };
  return (
    <div>
      <input
        type="file"
        onChange={(e) => {
          const file = e.target.files[0];
          readExcel(file);
        }}
      />
      <Table striped bordered hover responsive style={{ textAlign: "center" }}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Level No</th>
            <th>Type</th>
            <th>Category</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => {
            return (
              <>
                <tr>
                  <td>{item.ID}</td>
                  <td>{item.Title}</td>
                  <td>{item.LevelNo}</td>
                  <td>{item.Type}</td>
                  <td>{item.Category}</td>
                </tr>
                {item.children &&
                  item.children.length > 0 &&
                  item.children.map((child) => {
                    return (
                      <>
                        <tr>
                          <td>{child.ID}</td>
                          <td>{child.Title}</td>
                          <td>{child.LevelNo}</td>
                          <td>{child.Type}</td>
                          <td>{child.Category}</td>
                        </tr>
                        {child.children &&
                          child.children.length > 0 &&
                          child.children.map((grand) => {
                            return (
                              <tr>
                                <td>{grand.ID}</td>
                                <td>{grand.Title}</td>
                                <td>{grand.LevelNo}</td>
                                <td>{grand.Type}</td>
                                <td>{grand.Category}</td>
                              </tr>
                            );
                          })}
                      </>
                    );
                  })}
              </>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default COAMaker;
