import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const currentUserRed = useSelector((state) => state.user);
  const [rights, setRights] = useState();
  const [owner, setOwner] = useState();
  useEffect(() => {
    const user = currentUserRed.user;
    if (user && Object.keys(user).length > 0) {
      const right = user.right;
      let location = rest.location.pathname;
      let splitLocation = location.split("/");
      let url;
      if (splitLocation.length > 3) {
        url = `/${splitLocation[1]}/${splitLocation[2]}`;
      } else {
        url = location;
      }
      let foundRoute = Object.keys(right).find(
        (key) => right[key].href === url
      );
      if (foundRoute) {
        let getRight = right[foundRoute].right;
        if (user.role === "superadmin") {
          if (getRight && Object.keys(getRight).length > 0) {
            Object.keys(getRight).map((key) => {
              getRight = { ...getRight, [key]: true };
            });
          }
        }
        setRights(getRight);
      }
    }
    setOwner(user.name);
  }, [currentUserRed]);

  return (
    <Route
      {...rest}
      component={(props) => {
        const token = window.localStorage.getItem("user");
        if (token) {
          return <Component {...props} rights={rights} owner={owner} />;
        } else {
          return <Redirect to={`/login`} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
