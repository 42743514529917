import {
  LOAD_FIRST_NOTE,
  LOAD_LAST_NOTE,
  LOAD_NEXT_NOTE,
  LOAD_PREV_NOTE,
  LOAD_RECEIPY_REQUEST,
  LOAD_RECEIPY_SUCCESS,
  NEW_RECEIPY_FAILURE,
  NEW_RECEIPY_REQUEST,
  NEW_RECEIPY_SUCCESS,
  UPDATE_RECEIPY_FAILURE,
  UPDATE_RECEIPY_REQUEST,
  UPDATE_RECEIPY_SUCCESS,
} from "../action/productionCom";

const initialState = {
  loading: false,
  receipyNote: [],
  productionNote: [],
  error: "",
  next: 1,
  prev: 1,
};

const productionReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_RECEIPY_REQUEST:
      {
        state = {
          ...state,
          loading: true,
        };
      }
      break;
    case UPDATE_RECEIPY_SUCCESS:
      {
        const data = action.payload;
        console.log({data})
        let productionNote = [],
          receipyNote = [];
        if (data.cvId && data.cvId !== "") {
          state.productionNote.map((item) => {
            if (item.id === data.id) {
              productionNote.push(data);
            } else {
              productionNote.push(item);
            }
          });
          receipyNote = [...state.receipyNote];
        } else {
          state.receipyNote.map((item) => {
            if (item.id === data.id) {
              receipyNote.push(data);
            } else {
              receipyNote.push(item);
            }
          });
          productionNote = [...state.productionNote];
        }
        state = {
          ...state,
          productionNote,
          receipyNote,
          loading: false,
        };
      }
      break;
    case UPDATE_RECEIPY_FAILURE:
      {
        const error = action.payload.error;

        state = {
          ...state,
          error,
          loading: false,
        };
      }
      break;
    case NEW_RECEIPY_REQUEST:
      {
        state = {
          ...state,
          loading: true,
        };
      }
      break;
    case NEW_RECEIPY_SUCCESS:
      {
        const data = action.payload;
        let productionNote = [...state.productionNote],
          receipyNote = [...state.receipyNote];
        if (data.cvId && data.cvId !== "") {
          productionNote.push(data);
        } else {
          receipyNote.push(data);
        }
        state = {
          ...state,
          productionNote,
          receipyNote,
          loading: false,
        };
      }
      break;
    case NEW_RECEIPY_FAILURE:
      {
        const error = action.payload.error;

        state = {
          ...state,
          error,
          loading: false,
        };
      }
      break;
    case LOAD_RECEIPY_REQUEST:
      {
        state = {
          ...state,
          loading: false,
        };
      }
      break;
    case LOAD_RECEIPY_SUCCESS:
      {
        const data = action.payload;
        state = {
          ...state,
          productionNote: data.productionNote,
          receipyNote: data.receipyNote,
          loading: false,
          next: 1,
          prev: 1,
        };
      }
      break;
    case LOAD_LAST_NOTE:
      {
        state = {
          ...state,
          next: 1,
          prev: 1,
        };
      }
      break;
    case LOAD_NEXT_NOTE:
      {
        state = {
          ...state,
          next: state.next + 1,
        };
      }
      break;
    case LOAD_PREV_NOTE:
      {
        state = {
          ...state,
          prev: state.prev + 1,
        };
      }
      break;
    case LOAD_FIRST_NOTE:
      {
        console.log({ pr: "production" });
        state = {
          ...state,
          next: 1,
          prev: 1,
        };
      }
      break;
  }
  return state;
};
export default productionReducer;
