import React, { useState, useEffect } from 'react'
import { Button, Form, InputGroup, Col, } from 'react-bootstrap';

import { useDispatch } from 'react-redux';
import * as coaActions from '../../action/coaAction';
const NewLevel3 = (props) => {
    const { level2Array, level3Array, level2Data,type } = props;
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const [level3Id, setLevel3Id] = useState('');
    const [level3Title, setLevel3Title] = useState('');
    const [level1Id, setLevel1Id] = useState('')
    const [level2Id, setLevel2Id] = useState('')
    const [filterLevel2, setFilterLevel2] = useState([])
    const [validated, setValidated] = useState(false);
    useEffect(() => {
        setFilterLevel2(level2Array.filter(f => f.parentId === level1Id))
    }, [level1Id]);
    let filterLevel3
    if (level2Data) {

        filterLevel3 = level3Array.filter(f => f.parentId === level2Data.docId)
    }
    let id;
    
    if (filterLevel3 && filterLevel3.length > 0) {
        id = filterLevel3[filterLevel3.length - 1].id
        id = parseInt(id)
        if (id.toString().length == 1) {
            id = `000${id + 1}`
        } else if (id.toString().length == 2) {
            id = `00${id + 1}`
        } else if (id.toString().length == 3) {
            id = `0${id + 1}`
        }
        else if (id.toString().length == 4) {
            id = `${id + 1}`
        }
    }
    else {
        id = '0001'
    }

    const saveLevel = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            setShow(false);
            if (level3Title !== '') {
                dispatch(coaActions.defineLevel3(level2Data.id, level2Data.docId, level3Title, level3Id,type));
                setShow(false);
            }
            else {
                alert('please enter some values')
            }
        }
        setValidated(true);

    }
    const onLevel3IdChangeHandler = (e) => {
        setLevel3Id(e.target.value)
    }
    const onLevel3TitleChangeHandler = (e) => {
        setLevel3Title(e.target.value)
    }
    const cancelAction = () => {
        setLevel3Id('')
        setLevel3Title('')
        setLevel1Id('')
        setLevel2Id('')
        setShow(false);
    }
    return (

        <Form onSubmit={saveLevel} noValidate validated={validated}
            className='d-flex flex-column justify-content-center align-items-center'>

            <h3>Define Level 3</h3>

            <Form.Group as={Col} md="8" controlId="validationCustomUsername">
                <Form.Label>Username</Form.Label>
                <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                    <Form.Control
                        label="Level 2 Title"
                        required="required"
                        type="text"
                        name="id3"
                        disabled
                        value={level2Data.id}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please choose a id.
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>



            <Form.Group as={Col} md="8" controlId="validationCustomUsername">
                <Form.Label>Username</Form.Label>
                <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                    <Form.Control
                        label="Level 2 Title"
                        required="required"
                        type="text"
                        name="id3"
                        onChange={onLevel3IdChangeHandler}
                        value={level3Id === '' ? id : level3Id}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please choose a id.
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="8" controlId="validationCustomUsername">
                <Form.Label>Username</Form.Label>
                <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                    <Form.Control
                        label="Level 2 Title"
                        required="required"
                        type="text"
                        name="title2"
                        onChange={onLevel3TitleChangeHandler}
                        value={level3Title}
                        minLength={5}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please choose a level name.
                    </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>


            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    width: '100%',
                    marginTop: '10px'

                }}
            >
                <Button type='submit'> Save</Button>
                <Button variant='danger' onClick={cancelAction}> Cancel</Button>
            </div>




        </Form>


    )
}

export default NewLevel3
