import React, { useState, useEffect } from "react";
import { Spinner, Button, Dropdown } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";

import ShowModal from "../subcom/ShowModal";
import ReverseActionBtn from "../subcom/ReverseActionBtn";
import PartyTable from "./InvoiceCom/PartyTable";
import ItemTable from "./InvoiceCom/ItemTable";
import BottomTable from "./InvoiceCom/BottomTable";
import { useSelector, useDispatch } from "react-redux";

import * as invoiceActions from "../action/invoiceAction";
import { getOldVoucher } from "../action/invoiceAction";
import * as itemStockActions from "../action/itemStockAction";
import ActionBar from "./general/ActionBar";
import InvoiceHeader from "./InvoiceHeader";
import InvoiceCancel from "./InvoiceCom/InvoiceCancel";
import moment from "moment";
import CopyVoucherCom from "./InvoiceCom/CopyVoucherCom";
import POBottomTable from "./InvoiceCom/POBottomTable";
import CoAndQuotation from "./CoAndQuotation";
const InvoiceTable = (props) => {
  const history = useHistory();
  const { invoiceId, invoiceType, invoiceDate, invoiceRights, invoiceCreator } =
    props;
  const dispatch = useDispatch();
  const globalRed = useSelector((state) => state.global);
  const coa = useSelector((state) => state.coa);

  const itemRed = useSelector((state) => state.item);
  const stockRed = useSelector((state) => state.stock);
  const invoiceRed = useSelector((state) => state.invoice);
  const [customerData, setCustomerData] = useState([]);
  const [newEditInvoice, setNewEditInvoice] = useState({
    invoiceId: "",
    invoiceType: "",
    contact: "",
    date: "",
    party: "",
    ledgerId: "",
    items: [],
    gross: 0,
    freight: 0,
    tax: 0,
    discount: 0,
    other: 0,
    grand: 0,
    vNo: "",
    afterDiscount: 0,
    terms: "",
    shipping: "",
    validity: "",
    payment: "",
    currency: "",
    location: "",
    costCenter: "",
    pId: "",
  });
  const [deleteRow, setDeleteRow] = useState({ status: false, rowId: "" });
  const [invoiceHeader, setInvoiceHeader] = useState({
    docId: "",
    date: "",
    vNo: "",
  });
  const [oldInvoice, setOldInvoice] = useState({});
  const [delV, setDelV] = useState(false);
  const [showCancelReason, setShowCancelReason] = useState(false);

  const [edit, setEdit] = useState(false);
  const [deleteCon, setDeleteCon] = useState(false);
  const [checkNegativeQty, setCheckNegativeQty] = useState(false);
  const [listPrevousVouchers, setListPrevousVouchers] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [stockData, setStockData] = useState([]);
  const [newInvoice, setNewInvoice] = useState(false);
  const [voucherCopied, setCopiedVoucher] = useState({});
  useEffect(() => {}, []);

  useEffect(() => {
    if (deleteCon) {
      handleDecrease(deleteRow.rowId);
    }
  }, [deleteCon, deleteRow.rowId]);

  useEffect(() => {
    let savedInvoice;
    if (invoiceRights.view) {
      savedInvoice = invoiceRed.invoices.find((f) => f.invoiceId === invoiceId);
    } else {
      const userName = invoiceCreator;
      if (userName) {
        savedInvoice = invoiceRed.invoices.find(
          (f) => f.invoiceId === invoiceId && f.invoiceOwner === userName
        );
      }
    }
    if (savedInvoice) {
      setNewInvoice(false);
      setEdit(false);
      setNewEditInvoice(savedInvoice);
      setOldInvoice(savedInvoice);
      if (savedInvoice.cvId) {
        loadPendingVoucher(savedInvoice.cvId);
      }
    } else {
      makeNewInvoiceHandler();
    }
  }, [invoiceRed, invoiceId, invoiceDate]);

  useEffect(() => {
    let level = [];
    let type;
    if (
      invoiceType === "Sell" ||
      invoiceType === "Sell Return" ||
      invoiceType === "Delivery Challan" ||
      invoiceType === "Client Order" ||
      invoiceType === "Quotation"
    ) {
      type = "ct";
    } else if (
      invoiceType === "Purchase Order" ||
      invoiceType === "Purchase" ||
      invoiceType === "Good Receipt Note" ||
      invoiceType === "Purchase Return"
    ) {
      type = "sp";
    }
    coa.allLevels.map((level1) => {
      if (level1.children.length > 0 && level1.type === type) {
        level1.children.map((level2) => {
          if (level2.children.length > 0) {
            level2.children.map((level3) => {
              level.push(level3);
            });
          }
        });
      }
    });
    if (level.length > 0) {
      setCustomerData(level);
    }

    setItemData(itemRed.item);
    setStockData(stockRed.stock);
    setCheckNegativeQty(globalRed.restrictNegativeQty);
  }, [coa, itemRed, stockRed, globalRed]);
  const loadNextVoucherList = () => {
    const filterVouchers = invoiceRed.invoices.filter(
      (f) => f.cvId === invoiceId
    );

    if (filterVouchers.length > 0) {
      setListPrevousVouchers(filterVouchers);
    } else {
      alert("No voucher found");
    }
  };
  const selectedItemData = (docId, index) => {
    if (voucherCopied.type) {
      alert("Item Cannot be change");
    } else {
      const getItem = itemData.find((f) => f.docId === docId);

      let itemQty = 0;
      if (invoiceType === "Sell") {
        if (checkNegativeQty) {
          itemQty = loadData(docId);
        } else {
          itemQty = 1;
        }
      } else {
        itemQty = 1;
      }

      if (itemQty > 0) {
        let items = [...newEditInvoice.items];
        let gross = 0;
        items[index] = {
          id: getItem.docId,
          name: getItem.name,
          quantity: 1,
          discount: 0,
          rate: parseFloat(getItem.saleRate ? getItem.saleRate : 0),
          uom: getItem.uom?getItem.uom:"",
          total: parseFloat(getItem.saleRate ? getItem.saleRate : 0),
        };
        items.forEach((item) => {
          gross = gross + item.total;
        });
        let plus =
          parseFloat(gross) +
          parseFloat(newEditInvoice.freight) +
          parseFloat(newEditInvoice.tax);
        let overAllTotal =
          plus -
          (parseFloat(newEditInvoice.discount) +
            parseFloat(newEditInvoice.afterDiscount));
        setNewEditInvoice({
          ...newEditInvoice,
          items: items,
          gross: gross,
          grand: overAllTotal,
        });
      } else {
        alert("Item is out of stock");
      }
    }
  };
  const loadData = (docId) => {
    let enDate;
    if (invoiceHeader.date !== "") {
      enDate = invoiceHeader.date;
    } else {
      enDate = newEditInvoice.date;
    }
    if (enDate) {
      if (!(enDate instanceof Date) && enDate instanceof Object) {
        enDate = new Date(enDate.toDate());
        enDate = enDate.toISOString().slice(0, 10);
      }
    }
    let endFormat = moment(enDate).format("YYYY-MM-DD");
    let rDate = { start: globalRed.date, end: endFormat };
    let sItem = { id: docId };
    let invoices = invoiceRed.invoices;
    if (rDate.start !== "" && rDate.end !== "" && sItem !== "") {
      let s = new Date(rDate.start).toISOString().slice(0, 10);
      let e = new Date(rDate.end).toISOString().slice(0, 10);
      if (globalRed.stockWithApprove) {
        invoices = invoices.filter((f) => f.approve === true);
      }
      if (globalRed.stockWithCheck) {
        invoices = invoices.filter((f) => f.check === true);
      }
      let balanceInStock = 0;
      const fin = stockRed.stock.find((f) => f.docId === sItem.id);
      if (fin && fin.balanceInStock) {
        balanceInStock = balanceInStock + parseInt(fin.balanceInStock);
      }

      invoices.map((f) => {
        let dbDate = new Date(f.date.toDate());
        let check = new Date(dbDate).toISOString().slice(0, 10);

        if (check < s) {
          if (f.type === "Sell") {
            f.items.map((item) => {
              if (item.id === sItem.id) {
                balanceInStock = balanceInStock - parseInt(item.quantity);
              }
            });
          } else if (f.type === "Purchase") {
            f.items.map((item) => {
              if (item.id === sItem.id) {
                balanceInStock = balanceInStock + parseInt(item.quantity);
              }
            });
          }
        }
        if (check >= s && check <= e) {
          if (f.type === "Sell") {
            f.items.map((item) => {
              if (item.id === sItem.id) {
                balanceInStock = balanceInStock - parseInt(item.quantity);
              }
            });
          } else if (f.type === "Purchase") {
            f.items.map((item) => {
              if (item.id === sItem.id) {
                balanceInStock = balanceInStock + parseInt(item.quantity);
              }
            });
          }
        }
      });

      return balanceInStock;
    } else {
      alert("please enter all parameters");
      return;
    }
  };
  const handleQuantityChange = (e, index) => {
    let val = e.target.value;
    let copiedOrNote;
    if (voucherCopied.type) {
      let checkItems = [...voucherCopied.items];
      let currentItem = checkItems[index];
      if (
        currentItem &&
        currentItem.quantity &&
        (parseInt(val) < currentItem.quantity ||
          parseInt(val) === currentItem.quantity)
      ) {
        copiedOrNote = true;
      } else {
        copiedOrNote = false;
      }
    } else {
      copiedOrNote = true;
    }
    if (copiedOrNote) {
      let items = [...newEditInvoice.items];
      let docId = items[index].id;
      let oldQty = 0;
      if (oldInvoice.items) {
        let oldItems = [...oldInvoice.items];
        if (oldItems[index]) {
          oldQty = oldItems[index].quantity;
        } else {
          oldItems.map((item) => {
            if (item.id === docId) {
              oldQty = oldQty + parseInt(item.quantity);
            }
          });
        }
      }

      let balanceInStock = 0;
      let temp = 0;
      if (invoiceType === "Sell") {
        if (checkNegativeQty) {
          balanceInStock = loadData(docId);
        } else {
          balanceInStock = parseInt(val);
        }
      } else {
        balanceInStock = parseInt(val);
      }

      if (invoiceType === "Sell") {
        if (parseInt(val) > oldQty) {
          temp = parseInt(val) - oldQty;
        } else {
          temp = oldQty + parseInt(val);
          balanceInStock = oldQty + parseInt(val);
        }
      }

      if (balanceInStock >= temp) {
        let gross = 0;
        items[index] = {
          id: items[index].id,
          name: items[index].name,
          quantity: parseFloat(val),
          discount: 0,
          rate: items[index].rate,
          uom: items[index].uom,
          total: parseFloat(items[index].rate) * parseFloat(val),
        };
        items.forEach((item) => {
          gross = gross + item.total;
        });

        let overAlldiscountVal =
          (parseFloat(gross) * parseFloat(newEditInvoice.discount)) / 100 +
          (parseFloat(gross) * parseFloat(newEditInvoice.afterDiscount)) / 100;
        let taxVal = (parseFloat(gross) * parseFloat(newEditInvoice.tax)) / 100;
        let plus =
          parseFloat(gross) +
          parseFloat(newEditInvoice.freight) +
          parseFloat(taxVal);

        let overAllTotal = plus - parseFloat(overAlldiscountVal);
        setNewEditInvoice({
          ...newEditInvoice,
          gross: gross,
          grand: overAllTotal,
          items: items,
        });
      } else {
        alert("stock is less than required quantity");
      }
    } else {
      alert("item quantity cannot be exceed");
    }
  };
  const handleItemRateChange = (e, index) => {
    let val = e.target.value;
    let items = [...newEditInvoice.items];
    if (val === "") {
      val = 0;
    }

    if (items[index].name !== "") {
      items[index] = {
        id: items[index].id,
        name: items[index].name,
        quantity: items[index].quantity,
        discount: 0,
        rate: val,
        uom: items[index].uom,
        total: parseFloat(val) * items[index].quantity,
      };
      let gross = 0;
      items.forEach((item) => {
        gross = gross + item.total;
      });
      let overAlldiscountVal =
        (parseFloat(gross) * parseFloat(newEditInvoice.discount)) / 100 +
        (parseFloat(gross) * parseFloat(newEditInvoice.afterDiscount)) / 100;
      let taxVal = (parseFloat(gross) * parseFloat(newEditInvoice.tax)) / 100;
      let plus =
        parseFloat(gross) +
        parseFloat(newEditInvoice.freight) +
        parseFloat(taxVal);

      let overAllTotal = plus - parseFloat(overAlldiscountVal);
      setNewEditInvoice({
        ...newEditInvoice,
        gross: gross,
        grand: overAllTotal,
        items: items,
      });
    } else {
      alert("please item rate cannot be zero");
    }
  };
  const handleItemDisountChange = (e, index) => {
    let val = e.target.value;
    if (val === "") {
      val = 0;
    }
    let items = [...newEditInvoice.items];
    let discountVal =
      (parseFloat(items[index].quantity) *
        parseFloat(items[index].rate) *
        parseFloat(val)) /
      100;
    items[index] = {
      id: items[index].id,
      name: items[index].name,
      quantity: items[index].quantity,
      discount: val,
      rate: items[index].rate,
      uom: items[index].uom,
      total: items[index].rate * items[index].quantity - discountVal,
    };
    let gross = 0;
    items.forEach((item) => {
      gross = gross + item.total;
    });

    let overAlldiscountVal =
      (parseFloat(gross) * parseFloat(newEditInvoice.discount)) / 100;
    let taxVal = (parseFloat(gross) * parseFloat(newEditInvoice.tax)) / 100;
    let plus =
      parseFloat(gross) +
      parseFloat(newEditInvoice.freight) +
      parseFloat(taxVal);

    let overAllTotal = plus - parseFloat(overAlldiscountVal);
    setNewEditInvoice({
      ...newEditInvoice,
      gross: gross,
      grand: overAllTotal,
      items: items,
    });
  };
  const selectedCustomer = (docId) => {
    if (voucherCopied.type) {
      alert("Supplier Cannot be changed");
    } else {
      const getCustomer = customerData.find((f) => f.docId === docId);
      setNewEditInvoice({
        ...newEditInvoice,
        party: getCustomer.title ? getCustomer.title : "",
        contact: getCustomer.contactNumber ? getCustomer.contactNumber : "",
        ledgerId: getCustomer.docId,
        invoiceId: newEditInvoice.invoiceId,
        type: newEditInvoice.invoiceType,
      });
    }
  };
  const handleFrightChange = (e) => {
    let val = e.target.value;
    if (val === "") {
      val = 0;
    }
    let gross = parseFloat(newEditInvoice.gross);
    let overAlldiscountVal =
      (gross * parseFloat(newEditInvoice.discount)) / 100 +
      (gross * parseFloat(newEditInvoice.afterDiscount)) / 100;
    let taxVal =
      (parseFloat(newEditInvoice.gross) * parseFloat(newEditInvoice.tax)) / 100;
    let plus =
      parseFloat(newEditInvoice.gross) + parseFloat(val) + parseFloat(taxVal);

    let overAllTotal = plus - parseFloat(overAlldiscountVal);
    setNewEditInvoice({ ...newEditInvoice, freight: val, grand: overAllTotal });
  };
  const handleTaxChange = (e) => {
    let val = e.target.value;
    if (val === "") {
      val = 0;
    }
    let gross = parseFloat(newEditInvoice.gross);
    let overAlldiscountVal =
      (parseFloat(gross) * parseFloat(newEditInvoice.discount)) / 100 +
      (parseFloat(gross) * parseFloat(newEditInvoice.afterDiscount)) / 100;
    let taxVal = (parseFloat(newEditInvoice.gross) * parseFloat(val)) / 100;
    let plus =
      parseFloat(newEditInvoice.gross) +
      parseFloat(newEditInvoice.freight) +
      parseFloat(taxVal);

    let overAllTotal = plus - parseFloat(overAlldiscountVal);
    setNewEditInvoice({ ...newEditInvoice, tax: val, grand: overAllTotal });
  };
  const handleDiscountAfterChange = (e) => {
    let val = e.target.value;

    if (val === "") {
      val = 0;
    }
    let gross = parseFloat(newEditInvoice.gross);
    let discountVal = (parseFloat(gross) * parseFloat(val)) / 100;
    let discount =
      (parseFloat(gross) * parseFloat(newEditInvoice.discount)) / 100;
    let taxVal =
      (parseFloat(newEditInvoice.gross) * parseFloat(newEditInvoice.tax)) / 100;
    let plus =
      parseFloat(newEditInvoice.gross) +
      parseFloat(newEditInvoice.freight) +
      parseFloat(taxVal);

    let overAllTotal = plus - (discount + parseFloat(discountVal));

    setNewEditInvoice({
      ...newEditInvoice,
      afterDiscount: val,
      grand: overAllTotal,
    });
  };
  const handleDiscountChange = (e) => {
    let val = e.target.value;
    if (val === "") {
      val = 0;
    }
    let gross = parseFloat(newEditInvoice.gross);
    let discountVal =
      (parseFloat(gross) * parseFloat(newEditInvoice.discount)) / 100 +
      (parseFloat(gross) * parseFloat(newEditInvoice.afterDiscount)) / 100;
    let taxVal =
      (parseFloat(newEditInvoice.gross) * parseFloat(newEditInvoice.tax)) / 100;
    let plus =
      parseFloat(newEditInvoice.gross) +
      parseFloat(newEditInvoice.freight) +
      parseFloat(taxVal);

    let overAllTotal = plus - parseFloat(discountVal);

    setNewEditInvoice({
      ...newEditInvoice,
      discount: val,
      grand: overAllTotal,
    });
  };
  const handleOtherChange = (e) => {
    let val = e.target.value;
    if (val === "") {
      val = 0;
    }
    let gross = parseFloat(newEditInvoice.gross);
    let discountVal =
      (parseFloat(gross) * parseFloat(newEditInvoice.discount)) / 100 +
      (parseFloat(gross) * parseFloat(newEditInvoice.afterDiscount)) / 100;
    let taxVal =
      (parseFloat(newEditInvoice.gross) * parseFloat(newEditInvoice.tax)) / 100;

    let plus =
      parseFloat(newEditInvoice.gross) +
      parseFloat(taxVal) +
      parseFloat(newEditInvoice.freight);
    let overAllTotal = plus - parseFloat(discountVal);
    setNewEditInvoice({ ...newEditInvoice, other: val, grand: overAllTotal });
  };
  const handleValidityChange = (e) => {
    let val = e.target.value;

    setNewEditInvoice({ ...newEditInvoice, validity: val });
  };
  const handletermChange = (e) => {
    let val = e.target.value;

    setNewEditInvoice({ ...newEditInvoice, terms: val });
  };
  const handlepaymentChange = (e) => {
    let val = e.target.value;

    setNewEditInvoice({ ...newEditInvoice, payment: val });
  };
  const handleShippingChange = (e) => {
    let val = e.target.value;

    setNewEditInvoice({ ...newEditInvoice, shipping: val });
  };
  const onCurrencyChange = (e) => {
    let val = e.target.value;

    setNewEditInvoice({ ...newEditInvoice, currency: val });
  };
  const onLocationChange = (val) => {
    setNewEditInvoice({ ...newEditInvoice, location: val });
  };
  const onProjectIdChange = (val) => {
    setNewEditInvoice({ ...newEditInvoice, pId: val });
  };
  const onCostCenterChange = (val) => {
    setNewEditInvoice({ ...newEditInvoice, costCenter: val });
  };

  const handleIncrease = () => {
    if (voucherCopied.type) {
      alert("Voucher items cannot be increased");
    } else {
      let id = newEditInvoice.items.length;
      let newItem = {
        id: id,
        name: "",
        uom: "",
        quantity: 0,
        rate: 0,
        discount: 0,
        total: 0,
      };
      let itemList = [...newEditInvoice.items, newItem];

      setNewEditInvoice({
        ...newEditInvoice,
        items: itemList,
      });
    }
  };
  const handleDecrease = (index) => {
    var myArray = [...newEditInvoice.items];
    myArray = myArray.filter(function (obj, alt) {
      return alt !== index;
    });
    let gross = 0;
    myArray.forEach((item) => {
      gross = gross + item.total;
    });
    let plus = parseFloat(gross) + parseFloat(newEditInvoice.freight);
    let min =
      parseFloat(newEditInvoice.tax) +
      parseFloat(newEditInvoice.discount) +
      parseFloat(newEditInvoice.afterDiscount);
    let overAllTotal = plus - min;
    setNewEditInvoice({
      ...newEditInvoice,
      items: myArray,
      gross: gross,
      grand: overAllTotal,
    });
    setDeleteCon(false);
    setDeleteRow({ status: false, rowId: "" });
  };
  const handleDecreaseRowPermission = (id) => {
    setDeleteRow({ status: true, rowId: id });
  };
  const checkInvoice = () => {
    if (!edit) {
      let message = "can not checked Voucher because\n";
      if (newEditInvoice && !newEditInvoice.approve && !newEditInvoice.cancel) {
        dispatch(invoiceActions.checkInvoice(invoiceId, invoiceType));
      } else {
        if (newEditInvoice.approve) {
          message = message + "Voucher is approved";
        }
        if (newEditInvoice.cancel) {
          message = message + "Voucher is canceld ";
        }
        alert(message);
      }
    } else {
      alert("Checked Voucher can not be Checked during edit");
    }
  };
  const cancelInvoice = () => {
    if (!edit) {
      if (!newEditInvoice.check) {
        if (!edit) {
          setShowCancelReason(true);
        } else {
          alert("During Edit Voucher can not be canceled");
        }
      } else {
        alert("Checked Voucher can not be canceled");
      }
    } else {
      alert("Checked Voucher can not be canceled during edit");
    }
  };
  const voucherOperationForCancel = (reason) => {
    let message = ` can not cancel`;
    if (newEditInvoice && !newEditInvoice.approve && !newEditInvoice.check) {
      dispatch(invoiceActions.cancelInvoice(invoiceId, reason, invoiceType));
      setShowCancelReason(false);
    } else {
      alert(message);
    }
  };
  const approveInvoice = () => {
    if (!edit) {
      let message = "can not approve inovice\n";

      if (newEditInvoice && newEditInvoice.check && !newEditInvoice.cancel) {
        dispatch(invoiceActions.approveInvoice(invoiceId, invoiceType));
      } else {
        if (!newEditInvoice.check) {
          message = message + "Voucher is not checked";
        }
        if (newEditInvoice.cancel) {
          message = message + "Voucher is is canceld ";
        }
        alert(message);
      }
    } else {
      alert("can not approve during edit");
    }
  };

  const saveInvoice = () => {
    let items = [...newEditInvoice.items];
    let error = items.filter((f) => f.name === "");
    let error2 = [];
    if (invoiceType === "Sell") {
      if (checkNegativeQty) {
        if (invoiceId) {
        } else {
          stockData.forEach((findStock) => {
            let check = 0;
            items.forEach((item) => {
              if (item.id === findStock.docId) {
                check = check + item.quantity;
              }
            });
            if (check > 0) {
              let stockQty = 0;
              if (findStock.balanceInStock !== "") {
                stockQty = stockQty + parseInt(findStock.balanceInStock);
              }
              if (findStock.receivedQty !== "") {
                if (parseInt(findStock.receivedQty) > 0) {
                  stockQty = stockQty + parseInt(findStock.receivedQty);
                }
              }
              if (findStock.issuedQty !== "") {
                if (parseInt(findStock.issuedQty) > 0) {
                  stockQty = stockQty - parseInt(findStock.issuedQty);
                }
              }
              if (check > stockQty) {
                error2.push(findStock.name);
              }
            }
          });
        }
      }
    }
    if (error.length > 0 || error2.length > 0) {
      if (error2.length > 0) {
        let text = "";
        text = text + "," + error2.map((name) => name);
        alert(text + "Items are out of stock");
      } else {
        if (newInvoice) {
          dispatch(invoiceActions.cancelSaveInvoice("new"));
        } else {
          dispatch(invoiceActions.cancelSaveInvoice("edit"));
        }
        alert("something is wrong");
      }
    } else {
      if (newInvoice) {
        if (invoiceRights.save) {
          dispatch(
            invoiceActions.addInvoice(
              newEditInvoice,
              invoiceCreator,
              voucherCopied
            )
          );
          dispatch(itemStockActions.loadStockReport());
          moveToInvoice();
        } else {
          alert("not enough right to make new invoice");
        }
      } else {
        if (invoiceRights.edit) {
          dispatch(invoiceActions.editInvoice(newEditInvoice, invoiceId));
          dispatch(itemStockActions.loadStockReport());
          moveToInvoice();
        } else {
          alert("not enough right to changes");
        }
      }
    }
  };
  const moveToInvoice = () => {
    setEdit(false);
    setNewInvoice(false);
    history.push({
      pathname: `/invoice/${invoiceType}/${newEditInvoice.invoiceId}`,
      state: {
        response: "",
      },
    });
  };
  const changeDateHandler = (date) => {
    if (invoiceId) {
      setInvoiceHeader({ ...invoiceHeader, date: date });
      setNewEditInvoice({ ...newEditInvoice, date: date });
    } else {
      let lastId, iType, vNo;
      if (invoiceType === "Sell") {
        iType = "si";
      } else if (invoiceType === "Purchase") {
        iType = "pu";
      } else if (invoiceType === "Sell Return") {
        iType = "sr";
      } else if (invoiceType === "Purchase Return") {
        iType = "pr";
      } else if (invoiceType === "Good Receipt Note") {
        iType = "gr";
      } else if (invoiceType === "Delivery Challan") {
        iType = "dc";
      } else if (invoiceType === "Purchase Order") {
        iType = "po";
      } else if (invoiceType === "Client Order") {
        iType = "co";
      } else if (invoiceType === "Quotation") {
        iType = "qo";
      }

      const d = new Date(date);
      const month = d.getMonth() + 1;
      const year = d.getFullYear().toString().slice(2);
      if (invoiceRed.invoices.length > 0) {
        const totalChild = invoiceRed.invoices.filter((f) => {
          if (f.invoiceId.includes(`${iType}-${month}-${year}`)) {
            return f;
          }
        });

        if (totalChild.length > 0) {
          const sortedArray = totalChild.sort((a, b) => {
            return b.vNo - a.vNo;
          });

          lastId = parseFloat(sortedArray[0].vNo);
          lastId = lastId + 1;
          vNo = lastId;
        } else {
          lastId = 1;
          vNo = 1;
        }
      } else {
        lastId = 1;
        vNo = 1;
      }

      const p1 = iType + "-" + month + "-" + year + "-" + lastId;
      setInvoiceHeader({ docId: p1, date: d, vNo: vNo });
      setInvoiceHeader({ ...invoiceHeader, date: date });
      setNewEditInvoice({
        ...newEditInvoice,
        date: date,
        invoiceId: p1,
        vNo: vNo,
        invoiceType: invoiceType,
      });
    }
  };

  const handleSelectedVoucher = (data) => {
    if (!invoiceId) {
      setCopiedVoucher(data);
      setNewEditInvoice({
        invoiceId: newEditInvoice.invoiceId,
        invoiceType: invoiceType,
        type: invoiceType,
        contact: data.contact,
        date: newEditInvoice.date,
        party: data.party,
        ledgerId: data.ledgerId,
        items: data.items,
        gross: newEditInvoice.gross,
        freight: newEditInvoice.freight,
        tax: newEditInvoice.tax,
        discount: newEditInvoice.discount,
        other: newEditInvoice.other,
        grand: newEditInvoice.grand,
        vNo: newEditInvoice.vNo,
        afterDiscount: newEditInvoice.afterDiscount
          ? newEditInvoice.afterDiscount
          : 0,
        terms: newEditInvoice.terms ? newEditInvoice.terms : "",
        validity: newEditInvoice.validity ? newEditInvoice.validity : "",
        shipping: newEditInvoice.shipping ? newEditInvoice.shipping : "",
        payment: newEditInvoice.payment ? newEditInvoice.payment : "",
        currency: newEditInvoice.currency ? newEditInvoice.currency : "",
        location: newEditInvoice.location ? newEditInvoice.location : "",
        pId: newEditInvoice.pId ? newEditInvoice.pId : "",
        costCenter: newEditInvoice.costCenter ? newEditInvoice.costCenter : "",
      });
    } else {
      alert(`please make new ${invoiceType} `);
    }
  };
  const deletethisVoucher = () => {
    if (listPrevousVouchers.length === 0) {
      setDelV(true);
    } else {
      alert("this voucher is used in other voucher cannot be deleted");
    }
  };
  const loadPendingVoucher = async (id) => {
    const voucher = await getOldVoucher(id);
    if (voucher) {
      setCopiedVoucher(voucher);
    }
  };
  const makeNewInvoiceHandler = () => {
    setOldInvoice({});
    let lastId, iType, sr, date;
    if (invoiceType === "Sell") {
      iType = "si";
    } else if (invoiceType === "Purchase") {
      iType = "pu";
    } else if (invoiceType === "Sell Return") {
      iType = "sr";
    } else if (invoiceType === "Purchase Return") {
      iType = "pr";
    } else if (invoiceType === "Good Receipt Note") {
      iType = "gr";
    } else if (invoiceType === "Delivery Challan") {
      iType = "dc";
    } else if (invoiceType === "Purchase Order") {
      iType = "po";
    } else if (invoiceType === "Client Order") {
      iType = "co";
    } else if (invoiceType === "Quotation") {
      iType = "qo";
    }
    const d = new Date();
    date = d;
    const month = d.getMonth() + 1;
    const year = d.getFullYear().toString().slice(2);
    if (invoiceRed.invoices.length > 0) {
      const totalChild = invoiceRed.invoices.filter((f) => {
        if (f.invoiceId.includes(`${iType}-${month}-${year}`)) {
          return f;
        }
      });

      if (totalChild.length > 0) {
        const sortedArray = totalChild.sort((a, b) => {
          return b.vNo - a.vNo;
        });

        lastId = parseFloat(sortedArray[0].vNo);
        lastId = lastId + 1;
        sr = lastId;
      } else {
        lastId = 1;
        sr = 1;
      }
    } else {
      lastId = 1;
      sr = 1;
    }

    const p1 = iType + "-" + month + "-" + year + "-" + lastId;
    setInvoiceHeader({ docId: p1, date: date, vNo: sr });
    clearInputData(date, p1, invoiceType, sr);
  };
  const clearInputData = (date, id, type, vNo) => {
    setNewInvoice(true);
    setEdit(true);
    setNewEditInvoice({
      contact: "",
      date: date,
      party: "",
      ledgerId: "",
      items: [],
      gross: 0,
      freight: 0,
      tax: 0,
      discount: 0,
      other: 0,
      grand: 0,
      invoiceId: id,
      invoiceType: type,
      vNo: vNo,
      afterDiscount: 0,
      terms: "",
      shipping: "",
      validity: "",
      payment: "",
      currency: "",
      location: "",
      costCenter: "",
      pId: "",
    });
  };
  return (
    <div style={{ margin: "10px", textAlign: "center" }}>
      {deleteRow.status && (
        <ShowModal show={deleteRow.status} size={"sm"}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "12px",
            }}
          >
            <p style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>
              Delete this Row
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => {
                  setDeleteCon(true);
                }}
                style={{ margin: "10px" }}
                variant="danger"
              >
                YES
              </Button>
              <Button
                onClick={() => setDeleteRow({ status: false, rowId: "" })}
                style={{ margin: "10px" }}
                variant="secondary"
              >
                NO
              </Button>
            </div>
          </div>
        </ShowModal>
      )}
      {delV ? (
        <ShowModal show={delV} size={"sm"}>
          <ReverseActionBtn
            link={`/invoice/${invoiceType}`}
            message="Delete This Voucher"
            okAction={() => {
              dispatch(
                invoiceActions.deleteInvoice(
                  newEditInvoice,
                  invoiceId,
                  invoiceType
                )
              );
            }}
            cancelAction={() => {
              setDelV(false);
            }}
          />
        </ShowModal>
      ) : null}

      {showCancelReason && (
        <ShowModal show={showCancelReason} size={"sm"}>
          <InvoiceCancel
            cancel={newEditInvoice.cancel}
            check={newEditInvoice.check}
            cancelReasonModal={() => setShowCancelReason(false)}
            saveReasonModal={voucherOperationForCancel}
          />
        </ShowModal>
      )}
      <ActionBar
        makeNewVoucher={() => {}}
        handleCheckVoucher={checkInvoice}
        handleApproveVoucher={approveInvoice}
        handleCancelVoucher={cancelInvoice}
        handleSaveVoucher={saveInvoice}
        deleteVoucher={deletethisVoucher}
        handleChangeVoucher={(option) => {}}
        edit={edit}
        editVoucher={() => setEdit(!edit)}
        invoiceId={newEditInvoice.invoiceId}
        check="invoice"
        voucherType={invoiceType}
        date={newEditInvoice.date}
        invoiceRights={invoiceRights}
      />
      {invoiceType === "Quotation" ||
      invoiceType === "Client Order" ||
      invoiceType === "Purchase Order" ? (
        <CoAndQuotation
          party={newEditInvoice.party}
          currency={newEditInvoice.currency}
          location={newEditInvoice.location}
          contact={newEditInvoice.contact}
          pId={newEditInvoice.pId}
          costCenter={newEditInvoice.costCenter}
          customerData={customerData}
          selectedCustomer={selectedCustomer}
          invoiceType={invoiceType}
          date={newEditInvoice.date}
          vNo={newEditInvoice.invoiceId}
          vType={newEditInvoice.invoiceType}
          changeDate={changeDateHandler}
          edit={edit}
          editInvoice={invoiceId}
          onCurrencyChange={onCurrencyChange}
          onLocationChange={onLocationChange}
          onCostCenterChange={onCostCenterChange}
          onProjectIdChange={onProjectIdChange}
        />
      ) : (
        <InvoiceHeader
          date={newEditInvoice.date}
          vNo={newEditInvoice.invoiceId}
          vType={newEditInvoice.invoiceType}
          changeDate={changeDateHandler}
          edit={edit}
          editInvoice={invoiceId}
        />
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {invoiceType !== "Purchase Order" && invoiceType !== "Quotation" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {invoiceId ? null : (
              <CopyVoucherCom
                invoiceType={invoiceType}
                handleSelectedVoucher={handleSelectedVoucher}
              />
            )}

            {voucherCopied.type && (
              <Button variant="outline-secondary">
                <Link
                  as={Link}
                  target="_blank"
                  style={{ color: "black", textDecoration: "none" }}
                  to={`/invoice/${voucherCopied.type}/${voucherCopied.invoiceId}`}
                >
                  {`Go To ${voucherCopied.type}`}
                </Link>
              </Button>
            )}
          </div>
        ) : null}

        {invoiceId && (
          <>
            {listPrevousVouchers.length === 0 && (
              <Button onClick={loadNextVoucherList}>Next Voucher</Button>
            )}
            {listPrevousVouchers.length > 0 && (
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Next Vouchers
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {listPrevousVouchers.map((item) => {
                    return (
                      <Dropdown.Item
                        as={Link}
                        target="_blank"
                        style={{ color: "black", textDecoration: "none" }}
                        to={`/invoice/${item.type}/${item.invoiceId}`}
                        key={item.invoiceId}
                      >
                        {item.invoiceId.toUpperCase()}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </>
        )}
      </div>

      <div>
        {invoiceType !== "Quotation" &&
          invoiceType !== "Client Order" &&
          invoiceType !== "Purchase Order" && (
            <PartyTable
              party={newEditInvoice.party}
              contact={newEditInvoice.contact}
              customerData={customerData}
              selectedCustomer={selectedCustomer}
              edit={edit}
              invoiceType={invoiceType}
            />
          )}

        {newEditInvoice.party === "" ? null : (
          <div>
            <ItemTable
              items={newEditInvoice.items}
              edit={edit}
              selectedItemData={selectedItemData}
              handleQuantityChange={handleQuantityChange}
              handleItemDisountChange={handleItemDisountChange}
              handleItemRateChange={handleItemRateChange}
              itemData={itemData}
              handleIncrease={handleIncrease}
              handleDecrease={handleDecreaseRowPermission}
              invoiceType={invoiceType}
            />
            {invoiceType === "Delivery Challan" ||
            invoiceType === "Good Receipt Note" ? null : (
              <div style={{ width: "100%", height: "100%" }}>
                {invoiceType === "Purchase Order" ? (
                  <POBottomTable
                    invoiceCreator={invoiceCreator}
                    newEditInvoice={newEditInvoice}
                    edit={edit}
                    handleOtherChange={handleOtherChange}
                    handleDiscountChange={handleDiscountChange}
                    handleTaxChange={handleTaxChange}
                    handleFrightChange={handleFrightChange}
                    handleDiscountAfterChange={handleDiscountAfterChange}
                    handleValidityChange={handleValidityChange}
                    handleShippingChange={handleShippingChange}
                    handletermChange={handletermChange}
                    handlepaymentChange={handlepaymentChange}
                  />
                ) : (
                  <BottomTable
                    invoiceCreator={invoiceCreator}
                    newEditInvoice={newEditInvoice}
                    edit={edit}
                    handleOtherChange={handleOtherChange}
                    handleDiscountChange={handleDiscountChange}
                    handleTaxChange={handleTaxChange}
                    handleFrightChange={handleFrightChange}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoiceTable;
