import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { Card, Tab, Tabs } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import ShowModal from "../subcom/ShowModal";

import ProductionCom from "../components/ProductionCom";
import { loadProductionNote } from "../action/productionCom";
import ListReceipyProduction from "../components/production/ListReceipyProduction";
const ProductionNote = (props) => {
  const dispatch = useDispatch();
  const invoiceType = "productionnote";
  const noteId = props.match.params.id;
  const invoiceDate = props.match.params.date;
  const [tab, setTab] = useState("tab1");
  const invoiceRed = useSelector((state) => state.invoice);
  const [invoiceByType, setInvoiceByType] = useState([]);
  const userRed = useSelector((state) => state.user);
  const [userRights, setUserRights] = useState({});
  const [invoiceCreator, setInvoiceCreator] = useState({});

  useEffect(() => {
    dispatch(loadProductionNote());
    if (userRed.user) {
      let rights = userRed.user.right;
      if (rights && rights.length > 0) {
        setUserRights(rights[5]);
      }
    }
    console.log({ rights: userRed.user.right });
  }, []);

  const makeNewId = () => {
    setTab("tab1");
  };

  if (!invoiceRed.loading) {
    return (
      <Layout>
        <div>
          <Card style={{ display: "flex", width: "100%" }}>
            <Card.Body className="no-gutters p-0 m-0">
              <div className="mb-3 ml-2">
                <Tabs activeKey={tab} className="m-2">
                  <Tab
                    eventKey="tab1"
                    title={
                      <Button
                        onClick={makeNewId}
                        className="btnclass gradient-button-4"
                      >
                        {" "}
                        <Link
                          style={{ color: "white", textDecoration: "none" }}
                          to={`/${invoiceType}`}
                        >
                          New Receipy
                        </Link>
                      </Button>
                    }
                  >
                    {tab === "tab1" && (
                      <ProductionCom
                        invoiceType={invoiceType}
                        noteId={noteId}
                        invoiceDate={invoiceDate}
                        userRights={userRights}
                        invoiceCreator={invoiceCreator}
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="list"
                    title={
                      <Button
                        className="btnclass gradient-button-4"
                        style={buttonStyle}
                        onClick={() => {
                          setTab("list");
                        }}
                      >
                        List Receipies
                      </Button>
                    }
                  >
                    <ListReceipyProduction />
                  </Tab>
                </Tabs>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Layout>
    );
  } else {
    return (
      <ShowModal show={invoiceRed.loading} onHide={() => {}}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="grow" />
        </div>
      </ShowModal>
    );
  }
};

const buttonStyle = {
  marginRight: "10px",
  fontWeight: "bold",
  fontSize: "12px",
  width: "120px",
};
export default ProductionNote;
const style1 = {
  fontSize: "90%",
  marginLeft: "auto",
  textAlign: "center",
  marginRight: "auto",
  float: "justify",
  width: "80%",
};

const style2 = {
  textAlign: "center",
};
