import React from "react";
import { ListGroup } from "react-bootstrap";
import ListItem from "../../subcom/ListItem";
import Accordion1 from "../../subcom/Accordion1";
const LogoShowCom = (props) => {
  const showLogo = props.showLogo;

  return (
    <div>
      {showLogo &&
        showLogo.length > 0 &&
        showLogo.map((item) => {
          return (
            <Accordion1 title={item.title}>
              <ListGroup>
                <div>
                  {item.children && item.children.length > 0
                    ? item.children.map((child) => {
                        return (
                          <div>
                            {child.children && child.children.length > 0 ? (
                              <Accordion1 title={child.title}>
                                {child.children.map((grand) => {
                                  return (
                                    <ListItem
                                      label={grand.title}
                                      onChange={() => {
                                        props.parentOptionChange(
                                          item,
                                          child,
                                          grand
                                        );
                                      }}
                                      value={grand.show}
                                    />
                                  );
                                })}
                              </Accordion1>
                            ) : (
                              <ListItem
                                label={child.title}
                                onChange={() => {
                                  props.parentOptionChange(item, child, null);
                                }}
                                value={child.show}
                              />
                            )}
                          </div>
                        );
                      })
                    : "not child"}
                </div>
              </ListGroup>
            </Accordion1>
          );
        })}
    </div>
  );
};

export default LogoShowCom;
