import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import LinkCom from "../general/LinkCom";

const GLWithDetailsTable = (props) => {
  const { ledgerList, openingBalance, pathname, print } = props;
  const [list, setList] = useState([]);
  const [op, setOp] = useState(0);
  let balance = 0;
  let debit = 0;
  let credit = 0;
  useEffect(() => {
    balance = parseFloat(op);
    setList(ledgerList);
    setOp(openingBalance);
  }, [ledgerList, openingBalance, pathname]);
  balance = balance + op;

  return (
    <Table size="sm" bordered striped hovered>
      {props.children}
      <tbody>
        <tr>
          <td></td>
          <td></td>
          <td>Opening Balance</td>
          {pathname === "/gldetails" && (
            <>
              <td></td>
              <td></td>
              <td></td>
            </>
          )}

          <td></td>
          <td></td>
          <td style={{ textAlign: "center" }}>
            {balance > 0 ? `${balance}(dr)` : `${Math.abs(balance)}cr`}
          </td>
        </tr>
        {list &&
          list.map((inv) => {
            let discount = 0,
              tax = 0,
              advance = 0,
              gross = 0,
              freight = 0;

            if (inv.invoiceId) {
              gross = parseFloat(inv.gross);
              tax = (gross / 100) * parseFloat(inv.tax);
              discount = (gross / 100) * parseFloat(inv.discount);
              advance = parseFloat(inv.other);
              freight = parseFloat(inv.freight);
            }
            advance = parseFloat(inv.other) > 0 ? parseFloat(inv.other) : 0;
            balance =
              balance +
              parseFloat(inv.accDebit) -
              parseFloat(inv.accCredit) -
              advance;

            debit = debit + parseFloat(inv.accDebit) - advance;

            credit = credit + parseFloat(inv.accCredit) - advance;

            let date = new Date(inv.date.seconds * 1000)
              .toISOString()
              .slice(0, 10);

            return (
              <>
                {inv.items ? (
                  <>
                    <tr style={{ borderTop: "2px solid blue" }}>
                      <td>{date}</td>
                      {print === "print" ? (
                        <td>{inv.invoiceId ? inv.invoiceId : inv.id}</td>
                      ) : (
                        <td>
                          {" "}
                          <LinkCom
                            vNo={inv.invoiceId ? inv.invoiceId : inv.id}
                          />
                        </td>
                      )}
                      <td style={{ textAlign: "start" }}>Sale</td>
                      {pathname === "/gldetails" && (
                        <>
                          <td></td>
                          <td></td>
                          <td></td>
                        </>
                      )}

                      {parseFloat(inv.gross) > 0 && (
                        <>
                          {inv.type === "Purchase" ||
                          inv.type === "Sell Return" ? (
                            <>
                              <td></td>
                              <td style={{ textAlign: "center" }}>
                                {inv.gross}
                              </td>
                            </>
                          ) : (
                            <>
                              <td style={{ textAlign: "center" }}>
                                {inv.gross}
                              </td>
                              <td></td>
                            </>
                          )}
                        </>
                      )}

                      <td style={{ textAlign: "center" }}>
                        {balance - (tax + freight) + (advance + discount) > 0
                          ? `${
                              balance - (tax + freight) + (advance + discount)
                            }(dr)`
                          : `${Math.abs(
                              balance - (tax + freight) + (advance + discount)
                            )}cr`}
                      </td>
                    </tr>
                    {pathname === "/gldetails" && (
                      <>
                        {inv.items.map((item) => {
                          return (
                            <tr>
                              <td>{date}</td>
                              {print === "print" ? (
                                <td>
                                  {inv.invoiceId ? inv.invoiceId : inv.id}
                                </td>
                              ) : (
                                <td>
                                  {" "}
                                  <LinkCom
                                    vNo={inv.invoiceId ? inv.invoiceId : inv.id}
                                  />
                                </td>
                              )}

                              <td style={{ textAlign: "start" }}>
                                {item.name}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item.quantity}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item.rate && item.rate}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item.total && item.total}
                              </td>
                              <td></td>
                              <td></td>
                              <td style={{ textAlign: "center" }}>
                                {balance -
                                  (tax + freight) +
                                  (advance + discount) >
                                0
                                  ? `${
                                      balance -
                                      (tax + freight) +
                                      (advance + discount)
                                    }(dr)`
                                  : `${Math.abs(
                                      balance -
                                        (tax + freight) +
                                        (advance + discount)
                                    )}cr`}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}

                    {parseFloat(inv.tax) > 0 && (
                      <tr>
                        <td>{date}</td>
                        {print === "print" ? (
                          <td>{inv.invoiceId ? inv.invoiceId : inv.id}</td>
                        ) : (
                          <td>
                            {" "}
                            <LinkCom
                              vNo={inv.invoiceId ? inv.invoiceId : inv.id}
                            />
                          </td>
                        )}
                        <td style={{ textAlign: "start" }}>Tax</td>
                        {pathname === "/gldetails" && (
                          <>
                            <td></td>
                            <td></td>
                            <td></td>
                          </>
                        )}

                        <td style={{ textAlign: "center" }}>{tax}</td>
                        <td></td>
                        <td style={{ textAlign: "center" }}>
                          {" "}
                          {balance - freight + (advance + discount) > 0
                            ? `${balance - freight + (advance + discount)}(dr)`
                            : `${Math.abs(
                                balance - freight + (advance + discount)
                              )}cr`}
                        </td>
                      </tr>
                    )}
                    {parseInt(inv.discount) > 0 && (
                      <tr>
                        <td>{date}</td>
                        {print === "print" ? (
                          <td>{inv.invoiceId ? inv.invoiceId : inv.id}</td>
                        ) : (
                          <td>
                            {" "}
                            <LinkCom
                              vNo={inv.invoiceId ? inv.invoiceId : inv.id}
                            />
                          </td>
                        )}

                        <td style={{ textAlign: "start" }}>Discount</td>
                        {pathname === "/gldetails" && (
                          <>
                            <td></td>
                            <td></td>
                            <td></td>
                          </>
                        )}

                        <td></td>
                        <td style={{ textAlign: "center" }}>
                          {(parseInt(inv.gross) / 100) * parseInt(inv.discount)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {" "}
                          {balance - freight + advance > 0
                            ? `${balance - freight + advance}(dr)`
                            : `${Math.abs(balance - freight + advance)}cr`}
                        </td>
                      </tr>
                    )}
                    {parseFloat(advance) > 0 && (
                      <tr>
                        <td>{date}</td>
                        {print === "print" ? (
                          <td>{inv.invoiceId ? inv.invoiceId : inv.id}</td>
                        ) : (
                          <td>
                            {" "}
                            <LinkCom
                              vNo={inv.invoiceId ? inv.invoiceId : inv.id}
                            />
                          </td>
                        )}

                        <td style={{ textAlign: "start" }}>Advance</td>
                        {pathname === "/gldetails" && (
                          <>
                            <td></td>
                            <td></td>
                            <td></td>
                          </>
                        )}

                        <td></td>
                        <td style={{ textAlign: "center" }}>{advance}</td>
                        <td style={{ textAlign: "center" }}>
                          {" "}
                          {balance > 0
                            ? `${balance}(dr)`
                            : `${Math.abs(balance)}cr`}
                        </td>
                      </tr>
                    )}
                  </>
                ) : (
                  <tr style={{ borderTop: "2px solid blue" }}>
                    <td>{date}</td>
                    {print === "print" ? (
                      <td>{inv.invoiceId ? inv.invoiceId : inv.id}</td>
                    ) : (
                      <td>
                        {" "}
                        <LinkCom vNo={inv.invoiceId ? inv.invoiceId : inv.id} />
                      </td>
                    )}
                    <td style={{ textAlign: "start" }}>{inv.lineNarration}</td>
                    {pathname === "/gldetails" && (
                      <>
                        <td></td>
                        <td></td>
                        <td></td>
                      </>
                    )}

                    <td style={{ textAlign: "center" }}>
                      {inv.accDebit > 0 && inv.accDebit}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {inv.accCredit > 0 && inv.accCredit}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {balance > 0
                        ? `${balance}(dr)`
                        : `${Math.abs(balance)}cr`}
                    </td>
                  </tr>
                )}
              </>
            );
          })}
        <tr>
          <td></td>
          <td></td>

          {pathname === "/gldetails" && (
            <>
              <th></th>
              <th></th>
              <th></th>
            </>
          )}
          <th>Total</th>
          <th style={{ textAlign: "center" }}>{`${debit}(dr)`}</th>

          <th style={{ textAlign: "center" }}>{`${Math.abs(credit)}cr`}</th>
          <th style={{ textAlign: "center" }}>
            {balance > 0 ? `${balance}(dr)` : `${Math.abs(balance)}cr`}
          </th>
        </tr>
      </tbody>
    </Table>
  );
};

export default GLWithDetailsTable;
