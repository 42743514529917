import React, { useEffect, useState } from "react";
import ShowModal from "../../subcom/ShowModal";
import ListOption from "../../subcom/ListOption";
import { Form, Button } from "react-bootstrap";
import firebase from "firebase";

import CustomDrop from "../../subcom/CustomDrop";
import { updateRightsHandler } from "../../action/superAdmin";
import { useDispatch, useSelector } from "react-redux";
const ViewRight = (props) => {
  const { show, name, email, objRights } = props;
  const [data, setData] = useState();
  const [showDropdown, setShowDropdown] = useState(null);
  const alluserRed = useSelector((state) => state.allusers);
  const [showDropdownChild, setShowDropdownChild] = useState(null);
  const [userRight, setUserRight] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    loadHeaderComs();
  }, [alluserRed]);
  const loadHeaderComs = async () => {
    let rights = [];
    if (alluserRed.users && alluserRed.users.length > 0) {
      const getUser = alluserRed.users.find((f) => f.email === email);
      const myRights = getUser.right;
      if (data && data.docId) {
        const re = myRights[data.docId];

        setData({ ...data, right: re.right });
      }

      Object.keys(myRights).map((key) => {
        rights.push({ ...myRights[key], docId: key });
      });
      const unsort = createRouteHierarchy(rights);

      const sortList = unsort.sort(function (a, b) {
        return a.no - b.no;
      });
      setUserRight(sortList);
    }
  };
  const handleUpdate = async (route) => {
    setData(route);
  };
  const selectAllChild = async (value) => {
    let changes = {};
    data.children.map((child) => {
      child.children.length > 0 &&
        child.children.map((grand) => {
          let updateRight = {};
          let docId = grand.docId;
          let right = grand.right;
          Object.keys(right).map((key) => {
            updateRight = { ...updateRight, [key]: value };
          });
          let updateRecord = {
            href: grand.href,
            no: grand.no,
            title: grand.title,
            right: updateRight,
          };
          if (grand.parentId) {
            updateRecord = {
              ...updateRecord,
              parentId: grand.parentId,
            };
          }
          changes = {
            ...changes,
            [docId]: updateRecord,
          };
        });
      let updateRight = {};
      let docId = child.docId;
      let right = child.right;
      Object.keys(right).map((key) => {
        updateRight = { ...updateRight, [key]: value };
      });
      let updateRecord = {
        href: child.href,
        no: child.no,
        title: child.title,
        right: updateRight,
      };
      if (child.parentId) {
        updateRecord = {
          ...updateRecord,
          parentId: child.parentId,
        };
      }
      changes = {
        ...changes,
        [docId]: updateRecord,
      };
    });

    const getUser = alluserRed.users.find((f) => f.email === email);
    const myRights = getUser.right;
    dispatch(updateRightsHandler(email, null, changes, myRights));
  };
  const selectAllFields = async () => {
    let updateRight = {};
    let docId = data.docId;
    let right = data.right;
    Object.keys(right).map((key) => {
      updateRight = { ...updateRight, [key]: true };
    });
    let updateRecord = {
      href: data.href,
      no: data.no,
      title: data.title,
      right: updateRight,
    };
    if (data.parentId) {
      updateRecord = { ...updateRecord, parentId: data.parentId };
    }
    const getUser = alluserRed.users.find((f) => f.email === email);
    const myRights = getUser.right;
    dispatch(updateRightsHandler(email, docId, updateRecord, myRights));
  };
  const onMainRightChange = async (docId, fieldName) => {
    let right = data.right;
    right = { ...right, [fieldName]: !right[fieldName] };

    let updateRight = {
      href: data.href,
      no: data.no,
      title: data.title,
      right,
    };
    if (data.parentId) {
      updateRight = { ...updateRight, parentId: data.parentId };
    }
    const getUser = alluserRed.users.find((f) => f.email === email);
    const myRights = getUser.right;
    dispatch(updateRightsHandler(email, docId, updateRight, myRights));
  };

  const toggleDropDown = (no) => {
    if (showDropdown === no) {
      setShowDropdown(null);
      setShowDropdownChild(null);
    } else {
      setShowDropdown(no);
      setShowDropdownChild(null);
    }
  };
  const toggleDropDownChild = (no) => {
    if (showDropdownChild === no) {
      setShowDropdownChild(null);
    } else {
      setShowDropdownChild(no);
    }
  };

  return (
    <div style={{ width: "100%", height: "200px", overflow: "auto" }}>
      {show ? (
        <ShowModal show={show} size={"xl"} onHide={props.handlViewRights}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p>User Name:{name && name.toUpperCase()}</p>
            <p onClick={props.handlViewRights}>close</p>
          </div>
          <div className="row">
            <div className="col-6">
              <h3>Right List</h3>
              <div>
                {userRight.length > 0 &&
                  userRight.map((item, index) => {
                    if (item.children && item.children.length > 0) {
                      return (
                        <CustomDrop
                          title={item.title}
                          index={index}
                          show={showDropdown === index ? true : false}
                          toggleDropDown={(no) => {
                            toggleDropDown(no);
                            handleUpdate(item);
                          }}
                        >
                          {item.children.map((child, cIndex) => {
                            if (child.children && child.children.length > 0) {
                              return (
                                <li className="list-group-item dropdown-list">
                                  <CustomDrop
                                    title={child.title}
                                    index={cIndex}
                                    show={
                                      showDropdownChild === cIndex
                                        ? true
                                        : false
                                    }
                                    toggleDropDown={(no) => {
                                      toggleDropDownChild(no);
                                      handleUpdate(child);
                                    }}
                                  >
                                    {child.children.map((grand) => {
                                      return (
                                        <li
                                          className="list-group-item "
                                          onClick={() => {
                                            handleUpdate(grand);
                                          }}
                                        >
                                          {grand.title}
                                        </li>
                                      );
                                    })}
                                  </CustomDrop>
                                </li>
                              );
                            } else {
                              return (
                                <li
                                  className="list-group-item"
                                  onClick={() => {
                                    handleUpdate(child);
                                    setShowDropdownChild(null);
                                  }}
                                >
                                  {child.title}
                                </li>
                              );
                            }
                          })}
                        </CustomDrop>
                      );
                    } else {
                      if (item.title) {
                        return (
                          <li
                            className="btn btn-secondary dropdown-btn "
                            to={item.href}
                            style={{
                              textDecoration: "none",
                            }}
                            onClick={() => {
                              handleUpdate(item);
                              setShowDropdown(index);
                            }}
                          >
                            {item.title}
                          </li>
                        );
                      }
                    }
                  })}
              </div>
            </div>
            <div className="col-6">
              {data && (
                <>
                  <h2>{data.title}</h2>
                  <Button
                    onClick={() => selectAllChild(true)}
                    variant={"success"}
                  >
                    Select All
                  </Button>
                  <Button
                    onClick={() => selectAllChild(false)}
                    variant={"danger"}
                  >
                    UnSelect All
                  </Button>

                  <Form.Check
                    value={true}
                    checked={true}
                    onChange={selectAllFields}
                    label={"Select All"}
                  />

                  <ListOption
                    data={data}
                    onMainRightChange={onMainRightChange}
                  />
                </>
              )}
            </div>
          </div>
        </ShowModal>
      ) : null}
    </div>
  );
};

export default ViewRight;
function createRouteHierarchy(allLevels, parentId = null) {
  const LevelList = [];
  let catgory;
  if (parentId === null) {
    catgory = allLevels.filter((f) => f.parentId == undefined);
  } else {
    catgory = allLevels.filter((f) => f.parentId == parentId);
  }
  for (let cat of catgory) {
    LevelList.push({
      ...cat,
      children: createRouteHierarchy(allLevels, cat.docId),
    });
  }
  return LevelList;
}
