import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap';
import Input from '../../subcom/Input';
import ActionButtons from '../../subcom/ActionButtons';
import * as addCustomerActions from '../../action/addCustomer';
import { useDispatch, useSelector } from 'react-redux';
const CustomerCom = (props) => {
    const { level3Data, type } = props;
    const dispatch = useDispatch();
    const customerRed = useSelector(state => state.customer);




    const [data, setData] = useState({
        id: level3Data.docId,
        name: level3Data.title,
        phoneNumber: '',
        address: '',
        email: '',
        contactNumber: '',
        customerType: props.type,
        level3: level3Data.docId,


    });
    const onImgUrlChange = (e) => {
        const img = e.target.files[0];
        setData({ ...data, imgUrl: img })
    }


    const onPhoneNumberChange = (e) => {
        setData({ ...data, phoneNumber: e.target.value })
    }
    const onAddressChange = (e) => {
        setData({ ...data, address: e.target.value })
    }
    const onEmailChange = (e) => {
        setData({ ...data, email: e.target.value })
    }
    const onContactPersonChange = (e) => {
        setData({ ...data, contactNumber: e.target.value })
    }
    const saveInfo = async () => {
        let status = true;
        for (let x in data) {
            if (data[x] === '') {
                status = false;
            }
        }
        if (status) {

            dispatch(addCustomerActions.edit(data));

            clearInfo();
        } else {
            alert('please check input fields');
        }


    }
    const findCustomerMethod = () => {
        let find;
        if (type === 'sp') {
            find = customerRed.suppliers.find(f => f.level3 === level3Data.docId);
        }else{
            find = customerRed.customers.find(f => f.level3 === level3Data.docId);
        }
      
        if (find) {

            setData({
                id: level3Data.docId,
                name: find.name,
                phoneNumber: find.phoneNumber,
                address: find.address,
                email: find.email,
                contactNumber: find.contactNumber,
                customerType: find.customerType,
                level3: level3Data.docId,
                imgUrl: find.photoUrl

            })

        } else {
            clearInfo()
        }
    }
    const clearInfo = () => {
        setData({
            id: level3Data.docId,
            name: level3Data.title,
            phoneNumber: '',
            address: '',
            email: '',
            contactNumber: '',
            customerType: props.type,
            level3: level3Data.docId,


        })
    }
    const cancelInfo = () => {
        findCustomerMethod()

    }
    useEffect(() => {
        findCustomerMethod()
    }, [level3Data, customerRed]);
    return (
        <>


            {
                level3Data.docId && <Row>
                    <Col sm={12}>

                        <Input
                            label='Phone Number'
                            name={"phoneNumber"}
                            type='text'
                            value={data.phoneNumber}
                            onChange={onPhoneNumberChange} />
                        <Input
                            label='Address'
                            name={"address"}
                            value={data.address}
                            type='text'
                            onChange={onAddressChange} />

                        <Input
                            label='Email'
                            name='email'
                            type='email'
                            value={data.email}
                            onChange={onEmailChange} />
                        <Input
                            label='Contact Person'
                            name='contactPerson'
                            type='tel'
                            value={data.contactNumber}
                            onChange={onContactPersonChange} />

                    </Col>
                </Row>

            }
            <ActionButtons saveInfo={saveInfo} cancelInfo={cancelInfo} />
        </>)
}
export default CustomerCom;