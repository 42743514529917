import { GET_LEDGER_REQUEST, GET_LEDGER_SUCCESS, GET_LEDGER_FAILURE } from '../action/ledgerAction'
const initialState = {
    loading: false,
    data: [],
    openingBalance: 0,
    error: '',

};
const ledgerReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_LEDGER_REQUEST: {
            state = {
                ...state,
                loading: true,
                data: [],
                error: ''
            }
        }
            break;
        case GET_LEDGER_SUCCESS: {
            state = {
                ...state,
                loading: false,
                data: action.payload.data,
                openingBalance: action.payload.openingBalance,
                error: ''
            }
        }
            break;
        case GET_LEDGER_FAILURE: {
            state = {
                ...state,
                loading: false,
                data: [],
                error: action.payload.error
            }
        }
            break;
    }

    return state;
};
export default ledgerReducer;