import React, { useState } from "react";
import { Button, Dropdown, FormControl, FloatingLabel } from "react-bootstrap";
const CustomDropDown = (props) => {
  const label = props.label;
  const list = props.list;

  return (
    <Dropdown>
      <Dropdown.Toggle as={Toggle} size="lg">
        {label === "" ? "Choose Name" : label}
      </Dropdown.Toggle>
      <Dropdown.Menu as={Menu}>
        {list.map((item) => (
          <Dropdown.Item
            key={item.docId}
            onClick={() => {
              props.selectedItem(item.docId);
            }}
          >
            {item.name ? item.name : item.title}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
const CustomToggle = ({ children, onClick }, ref) => {
  return (
    <Button
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{width:'100%'}}
    >
      <span  style={{width:'100%'}}> {children}</span>
    </Button>
  );
};
const Toggle = React.forwardRef(CustomToggle);
const CustomMenu = ({ children, style, className }, ref) => {
  const [value, setValue] = useState("");
  return (
    <div style={style} className={className} ref={ref}>
      <FloatingLabel label={"Type to Filter"}>
        <FormControl
          autoFocus
          className="m-1 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
      </FloatingLabel>

      <ul
        className="overflow-auto p-3 mb-3 mb-md-0 mr-md-3 bg-light"
        style={{ maxHeight: "250px" }}
      >
        {React.Children.toArray(children).filter((f) => {
          return !value || f.props.children.toLowerCase().includes(value);
        })}
      </ul>
    </div>
  );
};
const Menu = React.forwardRef(CustomMenu);
export default CustomDropDown;
