import React from "react";
import { Table, InputGroup, Form } from "react-bootstrap";
const InputGroupCom = (props) => {
  const { type, onChange, value, label } = props;
  return (
    <InputGroup className="g-0 p-0 m-0 w-100">
      <InputGroup.Text>{label}</InputGroup.Text>
      <Form.Control type={type} value={value} onChange={onChange} />
    </InputGroup>
  );
};

export default InputGroupCom;
