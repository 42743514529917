import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import Home from "./pages/Home";
import JVPage from "./pages/JVPage";
import InvoicePage from "./pages/InvoicePage";
import COA from "./pages/chartOfAccount/COA";
import AddItem from "./pages/chartOfAccount/AddItem";
import Print from "./pages/Print";
import StockReport from "./pages/stockReportsFolder/StockReport";
import ItemLedger from "./pages/stockReportsFolder/ItemLedger";
import ItemLedgerWithAvg from "./pages/stockReportsFolder/ItemLedgerWithAvg";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import GlobalSettings from "./pages/GlobalSettings";
import { useDispatch, useSelector } from "react-redux";
import * as globalActions from "./action/globalAction";
import OpeningBalance from "./pages/chartOfAccount/OpeningBalance";
import OpeningQuantity from "./pages/chartOfAccount/OpeningQuantity";
import { loadStockReport } from "./action/itemStockAction";
import StockReportWithAvg from "./pages/stockReportsFolder/StockReportWithAvg";
import SalesReport from "./pages/SalesReport";
import PrintReport from "./pages/PrintReport";
import TrailBalance from "./pages/dailyreports/TrailBalance";
import PrintTrialBalance from "./pages/dailyreports/PrintTrialBalance";
import ChartOfAccount from "./pages/dailyreports/ChartOfAccount";
import PrintChartOfAccount from "./pages/dailyreports/PrintChartOfAccount";
import NotFound from "./pages/NotFound";
import GLWithDetails from "./pages/dailyreports/GLWithDetails";
import PaymentAndReceipt from "./pages/dailyreports/PaymentAndReceipt";
import PrintPaymentReceipt from "./pages/dailyreports/PrintPaymentReceipt";
import PrintStockReport from "./pages/stockReportsFolder/PrintStockReport";
import PrintStockWithAvg from "./pages/stockReportsFolder/PrintStockWithAvg";
import PrintItemLadger from "./pages/dailyreports/PrintItemLedger";
import PrintItemLedgerWithAvg from "./pages/dailyreports/PrintItemLedgerWithAvg";
import PostDatedCheque from "./pages/dailyreports/PostDatedCheque";
import PrintPostDatedCheque from "./pages/dailyreports/PrintPostDatedCheque";
import Login from "./pages/Login";
import PrivateRoute from "./components/PrivateRoute";
import { isUserLoggedIn } from "./action/signup";
import CompanySetup from "./pages/CompanySetup";
import PendingReports from "./pages/stockReportsFolder/PendingReports";
import ProductionNote from "./pages/ProductionNote";
import PrintNote from "./pages/PrintNote";
import * as itemActions from "./action/itemAction";
import * as coaActions from "./action/coaAction";
import COAMaker from "./pages/COAMaker";
import NewPageAdd from "./components/newpage/NewPageAdd";
import StockCoaMaker from "./pages/StockCoaMaker";
import PrintPageExample from "./pages/PrintPageExample";

const App = (props) => {
  const dispatch = useDispatch();
  const userRed = useSelector((state) => state.user);

  useEffect(() => {
    if (!userRed.auth) {
      dispatch(isUserLoggedIn());
    }
    if (userRed.auth) {
      dispatch(globalActions.checkGlobalSettings());
      dispatch(loadStockReport());
     dispatch(itemActions.loadItems());
      dispatch(coaActions.getAllLevel());
    }
  }, [userRed.auth]);
  return (
    <Router>
      <Switch>
        <PrivateRoute component={Home} exact path="/" />
        <PrivateRoute component={CompanySetup} exact path="/companysetup" />

        <PrivateRoute component={JVPage} exact path="/jvpage/:type" />
        <PrivateRoute component={JVPage} exact path="/jvpage/:type/:id" />

        <PrivateRoute component={InvoicePage} exact path="/invoice/:type" />
        <PrivateRoute component={InvoicePage} exact path="/invoice/:type/:id" />

        <PrivateRoute component={COA} exact path="/chartofaccount/:aTab" />

        <PrivateRoute
          component={ItemLedgerWithAvg}
          exact
          path="/ItemLedgerWithAvg"
        />
        <PrivateRoute
          component={ItemLedgerWithAvg}
          exact
          path="/ItemLedgerWithAvg/:id/:sd/:ed"
        />
        <PrivateRoute
          component={PrintItemLedgerWithAvg}
          exact
          path="/printitemledgerwithavg"
        />
        <PrivateRoute component={GlobalSettings} exact path="/globalsettings" />
        <PrivateRoute component={ItemLedger} exact path="/itemledger" />
        <PrivateRoute
          component={ItemLedger}
          exact
          path="/itemledger/:id/:sd/:ed"
        />
        <PrivateRoute
          component={PrintItemLadger}
          exact
          path="/printitemledger"
        />
        <PrivateRoute component={StockReport} exact path="/stockreport" />
        <PrivateRoute
          component={PrintStockReport}
          exact
          path="/printstockreport"
        />
        <PrivateRoute component={PendingReports} exact path="/grndc/:type" />
        <PrivateRoute
          component={PendingReports}
          exact
          path="/grndcpending/:type"
        />
        <PrivateRoute
          component={StockReportWithAvg}
          exact
          path="/stockreportavg"
        />
        <PrivateRoute
          component={PrintStockWithAvg}
          exact
          path="/printstockreportavg"
        />
        <PrivateRoute component={ProductionNote} exact path="/productionnote" />
        <PrivateRoute
          component={ProductionNote}
          exact
          path="/productionnote/:id"
        />
        <PrivateRoute component={PrintNote} exact path="/printnote" />

        <PrivateRoute component={AddItem} exact path="/additem" />

        <PrivateRoute component={Print} exact path="/print/:type/:vNo/:pType" />
        <PrivateRoute
          component={Print}
          exact
          path="/print/:type/:name/:start/:end/:id"
        />
        <PrivateRoute component={OpeningBalance} exact path="/openingbalance" />
        <PrivateRoute
          component={OpeningQuantity}
          exact
          path="/openingquantity"
        />
        <PrivateRoute component={SalesReport} exact path="/report/:type" />
        <PrivateRoute component={TrailBalance} exact path="/trailbalance" />

        <PrivateRoute component={PrintReport} exact path="/printreport/:q" />
        <PrivateRoute
          component={PrintTrialBalance}
          exact
          path="/printtrialbalance"
        />
        <PrivateRoute component={ChartOfAccount} exact path="/chartofaccount" />
        <PrivateRoute component={PrintChartOfAccount} exact path="/coaprint" />
        <PrivateRoute component={GLWithDetails} exact path="/gldetails" />
        <PrivateRoute component={GLWithDetails} exact path="/ledger" />

        <PrivateRoute
          component={GLWithDetails}
          exact
          path="/ledger/:party/:sd/:ed"
        />
        <PrivateRoute
          component={PaymentAndReceipt}
          exact
          path="/paymentreceipt/:type"
        />
        <PrivateRoute
          component={PrintPaymentReceipt}
          exact
          path="/printpaymentreceipt"
        />
        <PrivateRoute
          component={PostDatedCheque}
          exact
          path="/postdatedcheque"
        />
        <PrivateRoute
          component={PrintPostDatedCheque}
          exact
          path="/printpostdatedcheque"
        />

        <Route component={Login} exact path="/login" />
        <Route component={COAMaker} exact path="/coamaker" />
        <Route component={StockCoaMaker} exact path="/stockcoamaker" />
        <Route component={NewPageAdd} exact path="/newpage" />
        <Route component={PrintPageExample} exact path="/printexample" />

        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
