import React from 'react'
import { Form, ListGroup } from 'react-bootstrap';
const ListItem = (props) => {
    return (
        <ListGroup.Item className="m-1" >
            <div className="d-flex flex-row justify-content-between  " >
                <Form.Label>{props.label}</Form.Label>
                <Form.Check
                    type="checkbox"
                    value={props.value}
                    onChange={props.onChange}
                    checked={props.value && 'checked'}
                    />
            </div>


        </ListGroup.Item>
    )
}

export default ListItem
