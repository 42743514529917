import React from "react";
import { Table } from "react-bootstrap";
import CustomDropDown from "../../subcom/CustomDropDown";
import { Link } from "react-router-dom";
import Input2 from "../../subcom/Input2";

const ProductionBottom = (props) => {
  const {
    gross,
    grand,
    miscPurchaseExpenses,
    miscComplainExpenses,
    hiddenExpenses,
    laborExpenses,
    totalExpenses,
    miscExpenses,
  } = props.receipyData;

  const edit = props.edit;
  return (
    <Table striped bordered hover style={{ textAlign: "center" }}>
      <thead>
        <tr>
          <th>Total Gross</th>
          <th>Misc Expenses</th>
          <th>Misc Pur exp</th>
          <th>Miscomplain exp</th>
          <th>Hidden exp</th>
          <th>Labour exp</th>
          <th>Total exp</th>
          <th>Total Grand exp</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {" "}
            {!edit ? (
              gross
            ) : (
              <Input2
                name="voucherId"
                type="number"
                value={gross}
                disabled={true}
              />
            )}
          </td>
          <td>
            {!edit ? (
              miscExpenses
            ) : (
              <Input2
                name="voucherId"
                type="number"
                onChange={props.miscExpensesHandler}
                value={miscExpenses}
              />
            )}
          </td>
          <td>
            {!edit ? (
              miscPurchaseExpenses
            ) : (
              <Input2
                name="voucherId"
                type="number"
                onChange={props.miscPurchaseExpensesHandler}
                value={miscPurchaseExpenses}
              />
            )}
          </td>
          <td>
            {!edit ? (
              miscComplainExpenses
            ) : (
              <Input2
                name="voucherId"
                type="number"
                onChange={props.miscomplainExpensesHandler}
                value={miscComplainExpenses}
              />
            )}
          </td>

          <td>
            {!edit ? (
              hiddenExpenses
            ) : (
              <Input2
                name="voucherId"
                type="number"
                onChange={props.hiddenExpensesHandler}
                value={hiddenExpenses}
              />
            )}
          </td>
          <td>
            {!edit ? (
              laborExpenses
            ) : (
              <Input2
                name="voucherId"
                type="number"
                onChange={props.laborExpensesHandler}
                value={laborExpenses}
              />
            )}
          </td>
          <td>
            {!edit ? (
              totalExpenses
            ) : (
              <Input2
                name="voucherId"
                type="number"
                value={totalExpenses}
                disabled={true}
              />
            )}
          </td>
          <td>
            {!edit ? (
              grand
            ) : (
              <Input2
                name="voucherId"
                type="number"
                onChange={props.handleForQtyChange}
                value={grand}
                disabled={true}
              />
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default ProductionBottom;
