import React, { useEffect, useState } from "react";
import PartyWiseItemWise from "../components/SalesCom/PartyWiseItemWise";
import ItemWise from "../components/SalesCom/ItemWise";
import InvoiceWise from "../components/SalesCom/InvoiceWise";
import PartyWise from "../components/SalesCom/PartyWise";
import SlipHeader from "../components/SlipHeader.js/SlipHeader";
import { loadcompanyData } from "../action/generalMethod";
const PrintReport = (props) => {
  const [reportData, setReportData] = useState();
  const [companyData, setCompanyData] = useState({});
  const [pendingReport, setPendingReport] = useState(false);
  useEffect(async () => {
    let data = localStorage.getItem("printOption")
      ? localStorage.getItem("printOption")
      : null;
    if (data) {
      const parseData = JSON.parse(data);
      setReportData(parseData);

      if (
        parseData.reportParam === "Good Receipt Note" ||
        parseData.reportParam === "Delivery Challan"
      ) {
        setPendingReport(true);
      }
    }
    const cData = await loadcompanyData();
    setCompanyData(cData);
  }, []);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  useEffect(async () => {
    if (reportData) {
      await delay(4000);
      window.print();
    }
  }, [reportData]);

  if (reportData) {
    const { options, report, reportType, rDate, customer, reportParam } =
      reportData;

    document.title = "Print" + "-" + reportType;
    return (
      <div
        style={{
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          marginTop: "20px",
          backgroundColor: "white",
        }}
      >
        <SlipHeader
          companyData={companyData}
          data={""}
          vNo={""}
          slipType={
            reportParam.toUpperCase() +
            "-" +
            reportType.toUpperCase() +
            "-REPORT"
          }
        />
        {reportType === "itemWise" ? (
          <ItemWise
            party={customer.name}
            reportName="Item Wise"
            date={rDate}
            salesReport={report}
            options={options}
            print={true}
            pendingReport={pendingReport}
          />
        ) : null}
        {reportType === "itemWisePartyWise" ? (
          <PartyWiseItemWise
            party={customer.name}
            reportName="Item Wise Party Wise"
            date={rDate}
            salesReport={report}
            options={options}
            print={true}
            pendingReport={pendingReport}
          />
        ) : null}
        {reportType === "invoiceWise" ? (
          <InvoiceWise
            party={customer.name}
            reportName="Invoice Wise"
            salesReport={report}
            date={rDate}
            print={true}
          />
        ) : null}
        {reportType === "partyWise" ? (
          <PartyWise
            party={customer.name}
            reportName="Party Wise"
            salesReport={report}
            date={rDate}
            print={true}
          />
        ) : null}
      </div>
    );
  } else {
    return <div>loading</div>;
  }
};

export default PrintReport;
