import {
  GET_JV_REQUEST,
  GET_JV_SUCCESS,
  GET_JV_FAILURE,
  EDIT_JV_REQUEST,
  EDIT_JV_SUCCESS,
  EDIT_JV_FAILURE,
  NEW_JV_REQUEST,
  NEW_JV_SUCCESS,
  NEW_JV_FAILURE,
  LOAD_JV_REQUEST,
  LOAD_JV_SUCCESS,
  LOAD_JV_FAILURE,
  OP_JV_REQUEST,
  OP_JV_SUCCESS,
  CANCEL_JV_SAVING,
  LOAD_NEXT_JV,
  LOAD_PREV_JV,
  LOAD_FIRST_JV,
  LOAD_LAST_JV,
  DELETE_JV_REQUEST,
  DELETE_JV_SUCCESS,
  DELETE_JV_FAILURE,
} from "../action/jvAction";
const initialState = {
  loading: false,
  requiredJv: {},
  jvList: [],
  error: "",
  save: "",
  next: 1,
  last: 0,
  first: 0,
  prev: 1,
};
const jvReducer = (state = initialState, action) => {
  switch (action.type) {
    case OP_JV_REQUEST:
      {
        state = {
          ...state,
          loading: true,
        };
      }
      break;
    case CANCEL_JV_SAVING:
      {
        state = {
          ...state,
          loading: false,
          save: "",
        };
      }
      break;
    case OP_JV_SUCCESS:
      {
        const list = state.jvList;
        const updatedJv = action.payload.jv;
        let data = [];
        list.map((item) => {
          if (updatedJv.id !== item.id) {
            data.push(item);
          } else {
            data.push(updatedJv);
          }
        });
        state = {
          ...state,
          loading: false,
          jvList: data,
          requiredJv: updatedJv,
        };
      }
      break;
    case GET_JV_REQUEST:
      {
        state = {
          ...state,
          loading: true,
        };
      }
      break;
    case GET_JV_SUCCESS:
      {
        state = {
          ...state,
          loading: false,
          requiredJv: action.payload.jv,
        };
      }
      break;
    case GET_JV_FAILURE:
      {
        state = {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      }
      break;
    case EDIT_JV_REQUEST:
      {
        state = {
          ...state,
          save: "edit",
          loading: true,
        };
      }
      break;
    case EDIT_JV_SUCCESS:
      {
        const list = state.jvList;
        const updatedJv = action.payload.jv;

        let data = [];
        list.map((item) => {
          if (updatedJv.id !== item.id) {
            data.push(item);
          } else {
            data.push(updatedJv);
          }
        });
        state = {
          ...state,
          jvList: data,
          requiredJv: updatedJv,
          save: "",
          loading: false,
        };
      }
      break;
    case EDIT_JV_FAILURE:
      {
        state = {
          ...state,
          save: "",
          loading: false,
        };
      }
      break;
    case NEW_JV_REQUEST:
      {
        state = {
          ...state,
          save: "new",
        };
      }
      break;
    case NEW_JV_SUCCESS:
      {
        let list = [...state.jvList];
        const newJv = action.payload.jv;
        list.push(newJv);
        state = {
          loading: false,
          requiredJv: {},
          jvList: list,
          error: "",
          save: "",
          next: 1,
          last: 0,
          first: 0,
          prev: 1,
        };
      }
      break;
    case NEW_JV_FAILURE:
      {
        state = {
          ...state,
          save: "",
          error: action.payload.error,
        };
      }
      break;
    case LOAD_JV_REQUEST:
      {
        state = {
          ...state,
          loading: true,
        };
      }
      break;
    case LOAD_JV_SUCCESS:
      {
        const jvList = action.payload.jv;
        console.log(jvList.length - 2);
        state = {
          
          jvList: jvList,
          loading: false,
          last: jvList.length - 1,
          first: 0,
          next: 1,
          prev: 1,
        };
      }
      break;
    case LOAD_JV_FAILURE:
      {
        state = {
          ...state,
          error: action.payload.error,
          loading: false,
        };
      }
      break;
    case LOAD_NEXT_JV:
      {
        state = {
          ...state,
          next: state.next + 1,
        };
      }
      break;
    case LOAD_PREV_JV:
      {
        console.log("LOAD_PREV_JV");
        state = {
          ...state,
          prev: state.prev + 1,
        };
      }
      break;
    case LOAD_FIRST_JV:
      {
        state = {
          ...state,
          first: 0,
          last: state.jvList.length - 1,
          next: 1,
          prev: 1,
        };
      }
      break;
    case LOAD_LAST_JV:
      {
        state = {
          ...state,
          first: 0,
          last: state.jvList.length - 1,
          next: 1,
          prev: 1,
        };
      }
      break;
    case DELETE_JV_REQUEST:
      {
        state = {
          ...state,
          loading: true,
        };
      }
      break;
    case DELETE_JV_SUCCESS:
      {
        const list = [...state.jvList];
        const removedJv = action.payload.id;
        const newList = list.filter((f) => f.id !== removedJv);
        state = {
          ...state,
          jvList: newList,
          loading: false,
          last: newList.length - 1,
          first: 0,
          next: 1,
          prev: 1,
        };
      }
      break;
    case DELETE_JV_FAILURE:
      {
        const error = action.payload.error;
        state = {
          ...state,
          error: error,
          loading: false,
        };
      }
      break;
  }
  return state;
};
export default jvReducer;
