import firebase from "firebase";
export const GET_LEDGER_REQUEST = "GET_LEDGER_REQUEST";
export const GET_LEDGER_SUCCESS = "GET_LEDGER_SUCCESS";
export const GET_LEDGER_FAILURE = "GET_LEDGER_FAILURE";
export const generalLedgerGet = (docId, start, end) => {
  return async (dispatch) => {
    let openingBalance = 0;
    let s = new Date(start).toISOString().slice(0, 10);
    let e = new Date(end).toISOString().slice(0, 10);
    const dbOpenBalance = await firebase
      .firestore()
      .collection("level3")
      .doc(docId)
      .get();
    if (dbOpenBalance.exists) {
      let tableData = dbOpenBalance.data();
      if (parseInt(tableData.credit) > 0 || parseInt(tableData.debit) > 0) {
        if (parseInt(tableData.credit) > 0) {
          openingBalance = openingBalance - parseInt(tableData.credit);
        } else {
          openingBalance = openingBalance + parseInt(tableData.debit);
        }
      }
    }
    await firebase
      .firestore()
      .collection("generalLedger")
      .get()
      .then((collection) => {
        let ladgerData = [];
        collection.docs.map((doc) => {
          const data = doc.data();
          ladgerData.push({ docId: doc.id, ...data });
        });

        let requiredData = ladgerData.filter((f) => f.ledgerId === docId);
        requiredData.forEach((d) => {
          let dbDate = new Date(d.date.toDate());
          let check = new Date(dbDate).toISOString().slice(0, 10);

          if (check < s) {
            openingBalance =
              openingBalance + parseInt(d.accDebit) - parseInt(d.accCredit);
          }
        });
        let data = [];
        requiredData.forEach((d) => {
          let narration = "";
          let dbDate = new Date(d.date.toDate());
          let check = new Date(dbDate).toISOString().slice(0, 10);
          if (check >= s && check <= e) {
            if (d.id) {
              if (d.lineNarration === "") {
                if (d.accCredit > 0) {
                  narration = narration + "credited  against";
                  ladgerData.forEach((f) => {
                    if (f.id) {
                      if (f.id === d.id) {
                        if (f.accDebit > 0) {
                          narration = narration + " " + `${f.party}`;
                        }
                      }
                    }
                  });
                } else {
                  narration = narration + "debited  against";
                  ladgerData.forEach((f) => {
                    if (f.id) {
                      if (f.id === d.id) {
                        if (f.accCredit > 0) {
                          narration = narration + " " + `${f.party}`;
                        }
                      }
                    }
                  });
                }
              } else {
                narration = d.lineNarration;
              }
            } else {
              if (d.narration !== "") {
                narration = d.narration;
              }
            }

            data.push({ ...d, lineNarration: narration });
          }
        });
        data = data.sort((a, b) => {
          let dbDate = new Date(a.date.toDate());
          let dbDate2 = new Date(b.date.toDate());
          return dbDate - dbDate2;
        });
        dispatch({
          type: GET_LEDGER_SUCCESS,
          payload: { data: data, openingBalance },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: GET_LEDGER_FAILURE, payload: { error: err } });
      });
  };
};
export const generalLedgerGet2 = (docId, start, end) => {
  return async (dispatch) => {
    try {
      let openingBalance = 0;
      let s = new Date(start).toISOString().slice(0, 10);
      let e = new Date(end).toISOString().slice(0, 10);
      const dbOpenBalance = await firebase
        .firestore()
        .collection("level3")
        .doc(docId)
        .get();
      const sellRef = await firebase
        .firestore()
        .collection("invoicesSell")
        .where("ledgerId", "==", docId)
        .get();
      const sellReturnRef = await firebase
        .firestore()
        .collection("invoicesSell Return")
        .where("ledgerId", "==", docId)
        .get();
      const purchaseRef = await firebase
        .firestore()
        .collection("invoicesPurchase")
        .where("ledgerId", "==", docId)
        .get();
      const purchaseReturnRef = await firebase
        .firestore()
        .collection("invoicesPurchase Return")
        .where("ledgerId", "==", docId)
        .get();
      const sellInvoiceData = sellRef.docs.map((doc) => doc.data());
      const sellInvoiceReturnData = sellReturnRef.docs.map((doc) => doc.data());
      const purchaseInvoiceData = purchaseRef.docs.map((doc) => doc.data());
      const purchaseInvoiceReturnData = purchaseReturnRef.docs.map((doc) =>
        doc.data()
      );
      const invoices = [
        ...sellInvoiceData,
        ...purchaseInvoiceData,
        ...sellInvoiceReturnData,
        ...purchaseInvoiceReturnData,
      ];
      console.log({ invoices });
      if (dbOpenBalance.exists) {
        let tableData = dbOpenBalance.data();
        if (parseInt(tableData.credit) > 0 || parseInt(tableData.debit) > 0) {
          if (parseInt(tableData.credit) > 0) {
            openingBalance = openingBalance - parseInt(tableData.credit);
          } else {
            openingBalance = openingBalance + parseInt(tableData.debit);
          }
        }
      }

      await firebase
        .firestore()
        .collection("generalLedger")
        .get()
        .then((collection) => {
          let ladgerData = [];
          collection.docs.map((doc) => {
            const data = doc.data();
            ladgerData.push({ docId: doc.id, ...data });
          });

          let requiredData = ladgerData.filter((f) => f.ledgerId === docId);
          requiredData.forEach((d) => {
            let dbDate = new Date(d.date.toDate());
            let check = new Date(dbDate).toISOString().slice(0, 10);

            if (check < s) {
              openingBalance =
                openingBalance + parseInt(d.accDebit) - parseInt(d.accCredit);
            }
          });
          let data = [];
          requiredData.forEach((d) => {
            let narration = "";
            let docData;
            let dbDate = new Date(d.date.toDate());
            let check = new Date(dbDate).toISOString().slice(0, 10);
            if (check >= s && check <= e) {
              if (d.id) {
                if (d.lineNarration === "") {
                  if (d.accCredit > 0) {
                    narration = narration + "credited  against";
                    ladgerData.forEach((f) => {
                      if (f.id) {
                        if (f.id === d.id) {
                          if (f.accDebit > 0) {
                            narration = narration + " " + `${f.party}`;
                          }
                        }
                      }
                    });
                  } else {
                    narration = narration + "debited  against";
                    ladgerData.forEach((f) => {
                      if (f.id) {
                        if (f.id === d.id) {
                          if (f.accCredit > 0) {
                            narration = narration + " " + `${f.party}`;
                          }
                        }
                      }
                    });
                  }
                } else {
                  narration = d.lineNarration;
                }
              } else {
                if (d.narration !== "") {
                  narration = d.narration;
                }
              }
              if (d.invoiceId) {
                const findInvoice = invoices.find(
                  (f) => f.invoiceId === d.invoiceId
                );

                if (findInvoice) {
                  let docData = {
                    items: findInvoice.items,
                    gross: findInvoice.gross,
                    other: findInvoice.other,
                    tax: findInvoice.tax,
                    discount: findInvoice.discount,
                    grand: findInvoice.grand,
                    freight: findInvoice.freight,
                    ...d,
                    lineNarration: narration,
                    type: findInvoice.invoiceType,
                  };
                  data.push(docData);
                }
              } else {
                data.push({ ...d, lineNarration: narration });
              }
            }
          });
          data = data.sort((a, b) => {
            let dbDate = new Date(a.date.toDate());
            let dbDate2 = new Date(b.date.toDate());
            return dbDate - dbDate2;
          });
          dispatch({
            type: GET_LEDGER_SUCCESS,
            payload: { data: data, openingBalance },
          });
        })
        .catch((err) => {
          console.log(err);
          dispatch({ type: GET_LEDGER_FAILURE, payload: { error: err } });
        });
    } catch (err) {
      console.log({ err: "error" + err });
    }
  };
};
