import { combineReducers } from "redux";
import customerReducer from './customerReducer';
import invoiceReducer from './invoiceReducer';
import itemReducer from './itemReducer';
import cateAndTypeReducer from './cateAndTypeReducer';
import ledgerReducer from './ledgerReducer';
import stockReducer from './stockReducer';
import jvReducer from './jvReducer';
import globalReducer from "./globalReducer";
import trialBalanceReducer from "./trialBalanceReducer";
import generalChartAccount from "./generalChartAccount";
import userReducer from "./userReducer";
import superReducer from "./superReducer";
import productionReducer from "./productionReducer";
const rootReducer = combineReducers({
    user:userReducer,
    customer: customerReducer,
    invoice: invoiceReducer,
    item: itemReducer,
    catAndTypes: cateAndTypeReducer,
    ledger: ledgerReducer,
    jv: jvReducer,
    stock: stockReducer,
    global: globalReducer,
    coa: generalChartAccount,
    tb: trialBalanceReducer,
    allusers: superReducer,
    note:productionReducer
});

export default rootReducer;