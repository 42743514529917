import React from 'react'
import { Form, } from 'react-bootstrap';

const DefineNewLevels = (props) => {
    const { level3Data } = props;
    return (
        <div>
            <Form.Control
                type='text'
                value={level3Data.id}
                disabled
                name='id'
            />
            <Form.Control
                type='text'
                name='title'
                disabled
                value={level3Data.title}
            />

        </div>
    )
}

export default DefineNewLevels
