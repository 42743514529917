import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import ItemLedgerSearch from "../../components/general/ItemLedgerSearch";
import ShowModal from "../../subcom/ShowModal";
import ItemTab from "../../components/ItemCom/ItemTab";
import EditStock from "../../components/stockCom/EditStock";
import * as itemStockActions from "../../action/itemStockAction";
import * as itemActions from "../../action/itemAction";
import { Table, Spinner, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CustomDropDown from "../../subcom/CustomDropDown";
import * as invoiceActions from "../../action/invoiceAction";
import moment from "moment";
import { Link } from "react-router-dom";
import OffcanvasCom from "../../subcom/OffcanvasCom";
import { loadProductionNote } from "../../action/productionCom";
const StockReport = (props) => {
  const dispatch = useDispatch();
  const stockRed = useSelector((state) => state.stock);
  const catAndTypesRed = useSelector((state) => state.catAndTypes);
  const itemRed = useSelector((state) => state.item);
  const [stockList, setStockList] = useState([]);
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [showStockModal, setShowStockModal] = useState(false);
  const [currentStockData, setCurrentStockData] = useState("");
  const [sCategory, setSCategory] = useState({
    docId: 0,
    name: "All Categories",
  });
  const noteRed = useSelector((state) => state.note);
  const invoiceRed = useSelector((state) => state.invoice);
  const globalRed = useSelector((state) => state.global);
  const [sType, setSType] = useState({ docId: 0, name: "All types" });
  const [sItem, setSItem] = useState({ docId: 0, name: "All Items" });
  const [rDate, setRDate] = useState({
    start: "",
    end: "",
  });
  
  const [stockReportRight, setStockReportRight] = useState({});
  useEffect(() => {
    dispatch(itemStockActions.loadStockReport());
    dispatch(itemActions.loadTypesCategories());
    dispatch(itemActions.loadItems());
    dispatch(invoiceActions.loadInvoiceAll());
    dispatch(loadProductionNote());
  }, []);
  useEffect(() => {
    
    setStockReportRight(props.rights);
  }, []);

  useEffect(() => {
    setLoading(stockRed.loading);
    setLoading(itemRed.loading);
    setItemList([{ docId: 0, name: "All Items" }, ...itemRed.item]);
    setTypeList([{ docId: 0, name: "All types" }, ...catAndTypesRed.types]);
    setCategoryList([
      { docId: 0, name: "All Categories" },
      ...catAndTypesRed.categories,
    ]);
  }, [stockRed, catAndTypesRed, itemRed, invoiceRed]);

  useEffect(() => {
    if (globalRed.date !== "") {
      let endDate = new Date().toISOString().slice(0, 10);
      let endFormat = moment(endDate).format("YYYY-MM-DD");
      setRDate({ start: globalRed.date, end: endFormat });
    }
  }, [globalRed]);

  useEffect(() => {
    if (itemList.length > 0 && rDate.start !== "" && rDate.end !== "") {
      loadData();
    }
  }, [itemList, rDate]);

  const closeStockModal = () => {
    setShowStockModal(false);
  };
  const closeAddProductModal = () => {
    setShowAddProduct(false);
  };
  const loadData = async () => {
    let data = [];

    if (rDate.start !== "" && rDate.end !== "") {
      let filteredId = itemRed.item;
      if (sCategory.docId !== 0) {
        filteredId = filteredId.filter((f) => f.category === sCategory.docId);
      }
      if (sType.docId !== 0) {
        filteredId = filteredId.filter((f) => f.type === sType.docId);
      }
      if (sItem.docId !== 0) {
        filteredId = filteredId.filter((f) => f.docId === sItem.docId);
      }
      let invoices = invoiceRed.invoices;

      if (globalRed.updateStockWithGRN || globalRed.updateStockWithDC) {
        invoices = invoices.filter((f) => {
          if (f.type !== "Sell" && f.type !== "Purchase") {
            return f;
          }
        });

        if (!globalRed.updateStockWithGRN) {
          invoices = invoices.filter((f) => {
            if (f.type === "Delivery Challan") {
              return f;
            }
          });
        }
        if (!globalRed.updateStockWithDC) {
          invoices = invoices.filter((f) => {
            if (f.type === "Good Delivery Note") {
              return f;
            }
          });
        }
      } else {
        invoices = invoices.filter((f) => {
          if (f.type !== "Delivery Challan" && f.type !== "Good Receipt Note") {
            return f;
          }
        });
      }
      if (globalRed.stockWithApprove) {
        invoices = invoices.filter((f) => f.approve === true);
      }
      if (globalRed.stockWithCheck) {
        invoices = invoices.filter((f) => f.check === true);
      }
      let otherVouchers = [...noteRed.productionNote];
      invoices = [...invoices, ...otherVouchers];
      const sortByDate = invoices.sort((a, b) => {
        let dbDate, dbDate2;
        if (typeof a.date === "string") {
          dbDate = a.date;
        }
        if (typeof a.date === "object") {
          dbDate = new Date(a.date.toDate());
        }
        if (typeof b.date === "string") {
          dbDate = a.date;
        }
        if (typeof b.date === "object") {
          dbDate = new Date(b.date.toDate());
        }

        return dbDate - dbDate2;
      });
      filteredId.map((m) => {
        let sum = 0,
          num = 0,
          openingBalance = 0,
          openingRate = 0,
          purchaseRate = 0;
        const findStock = stockRed.stock.find((f) => f.docId === m.docId);
        if (m.purchaseRate !== "") {
          purchaseRate = parseFloat(m.purchaseRate);
        }
        if (findStock) {
          sum = sum + purchaseRate;
          num = num + 1;

          let tReceivedSum = 0;
          let tIssuedSum = 0;
          let itemIssued = 0;
          let itemReceived = 0;
          let s = new Date(rDate.start).toISOString().slice(0, 10);
          let e = new Date(rDate.end).toISOString().slice(0, 10);
          sortByDate.map((f) => {
            let check;
            if (typeof f.date === "string") {
              check = f.date;
            } else {
              let dbDate = new Date(f.date.toDate());
              check = new Date(dbDate).toISOString().slice(0, 10);
            }

            if (check < s) {
              if (
                f.type === "Sell" ||
                f.type === "Delivery Challan" ||
                f.type === "Purchase Return"
              ) {
                f.items.map((item) => {
                  if (item.id === findStock.docId) {
                    let getItemQty = 0;
                    if (item.quantity) {
                      getItemQty = parseInt(item.quantity);
                    }
                    openingBalance = parseInt(openingBalance) - getItemQty;
                  }
                });
              } else if (
                f.type === "Purchase" ||
                f.type === "Good Receipt Note" ||
                f.type === "Sell Return"
              ) {
                f.items.map((item) => {
                  if (item.id === findStock.docId) {
                    openingBalance =
                      parseInt(openingBalance) + parseInt(item.quantity);
                    num = num + 1;
                    sum = sum + (item.rate * item.quantity) / item.quantity;
                    openingRate = sum / num;
                  }
                });
              }
              if (f.type === "Production Note") {
                f.items.map((item) => {
                  if (item.id === findStock.docId) {
                    openingBalance =
                      parseInt(openingBalance) - parseInt(item.quantity);
                  }
                });
              }
              if (f.type === "Production Note") {
                if (f.receipyItemId === findStock.docId) {
                  openingBalance =
                    parseInt(openingBalance) + parseInt(f.forQuantity);
                }
              }
            }

            if (check >= s && check <= e) {
              if (
                f.type === "Sell" ||
                f.type === "Delivery Challan" ||
                f.type === "Purchase Return"
              ) {
                f.items.map((item) => {
                  if (item.id === findStock.docId) {
                    tIssuedSum = tIssuedSum + parseFloat(item.rate);
                    itemIssued = itemIssued + parseInt(item.quantity);
                  }
                });
              } else if (
                f.type === "Purchase" ||
                f.type === "Good Receipt Note" ||
                f.type === "Sell Return"
              ) {
                f.items.map((item) => {
                  if (item.id === findStock.docId) {
                    num = num + 1;
                    sum = sum + (item.rate * item.quantity) / item.quantity;
                    tReceivedSum = tReceivedSum + parseFloat(item.rate);
                    itemReceived = itemReceived + parseInt(item.quantity);
                  }
                });
              }
              if (f.type === "Production Note") {
                f.items.map((item) => {
                  if (item.id === findStock.docId) {
                    tIssuedSum = tIssuedSum + parseFloat(item.rate);
                    itemIssued = itemIssued + parseInt(item.quantity);
                  }
                });
              }
              if (f.type === "Production Note") {
                if (f.receipyItemId === findStock.docId) {
                  tReceivedSum = tReceivedSum + parseFloat(0);
                  itemReceived = itemReceived + parseInt(f.forQuantity);
                }
              }
            }
          });

          if (openingRate === 0) {
            openingRate = purchaseRate;
          }
          let getBalanceInStock = 0;
          if (findStock.balanceInStock) {
            getBalanceInStock = parseInt(findStock.balanceInStock);
          }
          data.push({
            docId: findStock.docId,
            name: findStock.name,
            opQty: getBalanceInStock + openingBalance,
            opValue: (getBalanceInStock + openingBalance) * openingRate,
            receivedQty: itemReceived,
            receivedValue: tReceivedSum,
            issuedQty: itemIssued,
            issuedValue: tIssuedSum,
            closeQty:
              parseInt(getBalanceInStock) +
              parseInt(itemReceived) -
              parseInt(itemIssued) +
              openingBalance,
            avgRate: sum / num,
          });
        }
      });
      setStockList(data);
    } else {
      alert("please check start and end Date");
    }
  };

  const onCategorySelected = (cId) => {
    let name = categoryList.find((f) => f.docId === cId);
    setSCategory({ docId: cId, name: name.name });
    setSItem({ docId: 0, name: "All Items" });
  };
  const onTypeSelected = (docId) => {
    const select = typeList.find((f) => f.docId === docId);
    setSType({ docId: select.docId, name: select.name });
    setSItem({ docId: 0, name: "All Items" });
  };
  const onItemSelected = (docId) => {
    setSCategory({ docId: 0, name: "All Categories " });
    setSType({ docId: 0, name: "All Types" });
    setRDate({ start: "", end: "" });
    if (docId === 0) {
      setSItem({ docId: 0, name: "All Items" });
    } else {
      const select = itemRed.item.find((f) => f.docId === docId);
      setSItem({ docId: select.docId, name: select.name });
    }
  };

  const handleStartDate = (e) => {
    const val = e.target.value;
    setRDate({ start: val, end: rDate.end });
  };
  const handleEndDate = (e) => {
    const val = e.target.value;
    setRDate({ start: rDate.start, end: val });
  };
  const resetAllValues = () => {
    dispatch(itemStockActions.loadStockReport());
    dispatch(itemActions.loadTypesCategories());
    dispatch(itemActions.loadItems());
    setSItem({ docId: "", name: "" });
    setSCategory({ docId: "", name: "" });
    setSType({ docId: "", name: "" });
    setRDate({ start: "", end: "" });
  };
  const handlePrintOption = () => {
    localStorage.setItem(
      "printStockWithAvgReport",
      JSON.stringify({
        rDate,
        stockList,
      })
    );
  };
  if (!loading) {
    return (
      <Layout>
        {showAddProduct && (
          <ShowModal show={showAddProduct}>
            <ItemTab
              closeAddProductModal={closeAddProductModal}
              cancelAddProduct={() => setShowAddProduct(false)}
            />
          </ShowModal>
        )}
        {showStockModal && (
          <ShowModal show={showStockModal}>
            <EditStock
              closeStockModal={closeStockModal}
              data={currentStockData}
            />
          </ShowModal>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "20px",
            padding: "3px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <OffcanvasCom
              show={showOffCanvas}
              handleOffcanvas={() => setShowOffCanvas(!showOffCanvas)}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "100%", margin: "3px" }}>
                  {categoryList.length > 0 && (
                    <CustomDropDown
                      list={categoryList}
                      label={sCategory.name}
                      selectedItem={(docId) => onCategorySelected(docId)}
                    />
                  )}
                </div>
                <div style={{ width: "100%", margin: "3px" }}>
                  {typeList.length > 0 && (
                    <CustomDropDown
                      list={typeList}
                      label={sType.name}
                      selectedItem={(docId) => onTypeSelected(docId)}
                    />
                  )}
                </div>
                <div style={{ width: "100%", margin: "3px" }}>
                  <ItemLedgerSearch
                    itemList={itemList}
                    label={sItem.name}
                    selectedItem={(id) => onItemSelected(id)}
                    handleStartDate={handleStartDate}
                    handleEndDate={handleEndDate}
                    start={rDate.start}
                    end={rDate.end}
                    loadData={() => {
                      loadData();
                      setShowOffCanvas(!showOffCanvas);
                    }}
                  />
                </div>
              </div>
              {stockList.length > 0 &&
                Object.keys(stockReportRight).length > 0 &&
                stockReportRight.print && (
                  <Button
                    onClick={handlePrintOption}
                    style={{ width: "100%", margin: "3px" }}
                  >
                    <Link
                      to={`/printstockreportavg`}
                      target="_blank"
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      Print
                    </Link>
                  </Button>
                )}
            </OffcanvasCom>
            <h5 onClick={resetAllValues}>Stock Report With Average</h5>
          </div>
          <span
            onClick={() => setShowAddProduct(true)}
            style={{
              boxShadow: "5px 5px 1px blue",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            Add Items+
          </span>
        </div>

        {stockList.length > 0 && (
          <Table size="sm" bordered striped hovered>
            <thead>
              <tr>
                <th>#</th>

                <th>name</th>
                <th>Opening Quantity</th>
                <th>Opening value</th>
                <th>Received Quantity</th>
                <th>Received Value</th>
                <th>Issued Quantity</th>
                <th>Issued Value</th>
                <th>Closing Qty</th>
                <th>Closing Value</th>
                {/* <th>Operation</th> */}
              </tr>
            </thead>
            <tbody>
              {stockList.length > 0 &&
                stockList.map((st, index) => (
                  <tr key={index}>
                    <td>{index}</td>

                    <td>
                      <Link
                        to={`/ItemLedgerWithAvg/${st.docId}/${rDate.start}/${rDate.end}`}
                      >
                        {st.name && st.name}
                      </Link>
                    </td>
                    <td>{st.opQty}</td>
                    <td>{st.opValue}</td>
                    <td>{st.receivedQty}</td>
                    <td>{st.receivedValue}</td>
                    <td>{st.issuedQty}</td>
                    <td>{st.issuedValue}</td>
                    <td>{st.closeQty}</td>
                    <td>{(st.closeQty * st.avgRate).toFixed(2)}</td>
                    {/* <td onClick={() => showStockModalHandler(st)}>edit</td> */}
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
      </Layout>
    );
  } else {
    return (
      <Layout>
        {" "}
        {loading && (
          <ShowModal show={loading}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner animation="border" role="status" />
            </div>
          </ShowModal>
        )}
      </Layout>
    );
  }
};

export default StockReport;
