import React, { useEffect, useState } from "react";
import SlipHeader from "../../components/SlipHeader.js/SlipHeader";
import { loadcompanyData } from "../../action/generalMethod";
const PrintPostDatedCheque = (props) => {
  const [reportData, setReportData] = useState();
  const [companyData, setCompanyData] = useState({});
  useEffect(async() => {
    let data = localStorage.getItem("printPostDatedCheck")
      ? localStorage.getItem("printPostDatedCheck")
      : null;
    if (data) {
      const parseData = JSON.parse(data);
      setReportData(parseData);
    }
    const cData = await loadcompanyData();
    setCompanyData(cData);
  }, []);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  useEffect(async () => {
    if (reportData) {
      await delay(4000);
      window.print();
    }
  }, [reportData]);

  if (reportData) {
    const { report } = reportData;

    return (
      <div>
        <SlipHeader
        companyData={companyData}
        data={""}
        vNo={""}
        slipType={"Post Dated Cheque"}
      />
        <table className="table table-striped table-hover table-bordered text-center">
          <thead>
            <tr>
              <th>Date</th>
              <th>V-No </th>
              <th>Account Title</th>

              <th>Bank Title</th>

              <th>Cheque No</th>

              <th>Cheque Date</th>
              <th>Cheque Amount </th>
            </tr>
          </thead>
          <tbody>
            {report.length > 0 &&
              report.map((item) => {
                let date = new Date(item.date.seconds * 1000)
                  .toISOString()
                  .slice(0, 10);
                return (
                  <tr>
                    <td>{date}</td>
                    <td>
                      {item.vNo}
                    </td>
                    <td>{item.accountTitle}</td>
                    <td>{item.bankTitle}</td>
                    <td>{item.checkNo}</td>
                    <td>{item.checkDate}</td>
                    <td>{item.amount}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  } else {
    return <div>loading</div>;
  }
};

export default PrintPostDatedCheque;
