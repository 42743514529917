import React from "react";
import { Modal } from "react-bootstrap";
const ShowModal = (props) => {
  return (
    <Modal
      show={props.show}
      size={props.size ? props.size : "lg"}
      onHide={props.onHide}
      center={props.center?false:true}
    >
      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
};
export default ShowModal;
