import React, { useState, useEffect } from "react";
import Input from "../../subcom/Input";
import ActionButtons from "../../subcom/ActionButtons";
import * as itemActions from "../../action/itemAction";
import { Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import CustomDropDown from "../../subcom/CustomDropDown";
import "../../Css/rowClass.css";
const ItemTab = (props) => {
  const edit = props.dataForEdit;
  const dispatch = useDispatch();
  const others = useSelector((state) => state.catAndTypes);
  const globalRed = useSelector((state) => state.global);
  const dataCategory = [
    { docId: 1, name: "No." },
    { docId: 2, name: "Pcs" },
    { docId: 3, name: "Kg" },
    { docId: 4, name: "Gm" },
    { docId: 5, name: "Liter" },
    { docId: 6, name: "ML" },
    { docId: 7, name: "Gellon" },
    { docId: 8, name: "Meter" },
    { docId: 9, name: "Yard" },
    { docId: 10, name: "Feet" },
    { docId: 11, name: "Carton" },
    { docId: 12, name: "Box" },
    { docId: 13, name: "Packet" },
  ];
  const [data, setData] = useState({
    docId: edit ? edit.docId : "",
    name: edit ? edit.name : "",
    openingStock: edit ? edit.openingStock : "",
    serial: edit ? edit.serial : "",
    barcode: edit ? edit.barcode : "",
    des: edit ? edit.des : "",
    uom: edit ? edit.uom : "",
    subunit: edit ? edit.subunit : "",
    packSize: edit ? edit.packSize : "",
    purchaseRate: edit ? edit.purchaseRate : "",
    saleRate: edit ? edit.saleRate : "",
    maxLevel: edit ? edit.maxLevel : "",
    minLevel: edit ? edit.minLevel : "",
    category: edit ? edit.category : "",
    type: edit ? edit.type : "",
    id: edit ? edit.id : uuidv4(),
    typeSr: edit ? edit.typeSr : uuidv4(),
    categorySr: edit ? edit.categorySr : uuidv4(),
  });
  const [loadedCategories, setLoadedCategories] = useState([]);
  const [loadedType, setLoadedType] = useState([]);
  const [showCatModal, setCatModal] = useState(false);
  const [showTypeModal, setTypeModal] = useState(false);

  const [sCategory, setSCategory] = useState("");
  useEffect(() => {
    dispatch(itemActions.loadTypesCategories());
  }, []);
  useEffect(() => {
    setLoadedCategories(others.categories);
    setLoadedType(others.types);
  }, [others]);

  const onOpeningStockChange = (e) => {
    let val = e.target.value;
    setData({ ...data, openingStock: val });
  };

  const onNameChange = (e) => {
    let val = e.target.value;
    setData({ ...data, name: val });
  };
  const onSerialChange = (e) => {
    let val = e.target.value;
    setData({ ...data, serial: val });
  };
  const onPackSizeChange = (e) => {
    let val = e.target.value;
    setData({ ...data, packSize: val });
  };
  const onBarcodeChange = (e) => {
    let val = e.target.value;
    setData({ ...data, barcode: val });
  };
  const onUOMchange = (docId) => {
    const uom = dataCategory.find((f) => f.docId === docId);
    setData({ ...data, uom: uom.name });
  };

  const onSubUnitChange = (docId) => {
    const subUnit = dataCategory.find((f) => f.docId === docId);
    setData({ ...data, subunit: subUnit.name });
  };
  const onPurchaseRageChange = (e) => {
    let val = e.target.value;
    setData({ ...data, purchaseRate: val });
  };
  const onSaleRageChange = (e) => {
    let val = e.target.value;
    setData({ ...data, saleRate: val });
  };
  const onMaxLevelChange = (e) => {
    let val = e.target.value;
    setData({ ...data, maxLevel: val });
  };
  const onMinLevelChange = (e) => {
    let val = e.target.value;
    setData({ ...data, minLevel: val });
  };
  const onCategoriesChange = (docId) => {
    setSCategory(docId);
    const category = others.categories.find((f) => f.docId === docId);
    setData({ ...data, category: category.docId, categorySr: category.id });
    if (globalRed.enforceCategory) {
      setLoadedType(others.types.filter((f) => f.parentDocId === docId));
    }
  };
  const onTypesChange = (docId) => {
    const type = loadedType.find((f) => f.docId === docId);
    setData({ ...data, type: type.docId, typeSr: type.id });
  };
  const onImgUrlChange = (e) => {
    const img = e.target.files[0];
    setData({ ...data, imgUrl: img });
  };
  const saveInfo = async () => {
    if (edit) {
      dispatch(itemActions.EditItem(data));
      props.cancelAddProduct();
    } else {
      await dispatch(itemActions.addItem(data));
      props.cancelAddProduct();
    }
  };
  const closeAddProduct = () => props.closeAddProductModal();

  const handleCatModal = () => {
    setCatModal(!showCatModal);
  };
  const handleTypeModal = () => {
    setTypeModal(!showTypeModal);
  };

  const createLabel = (array, docId) => {
    if (array.length > 0) {
      let find = array.find((f) => f.docId === docId);
      let name;
      if (find) {
        name = find.name;
      } else {
        name = "";
      }
      return name;
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <h1 style={{ marginTop: "10px", marginLeft: "20px" }}> Add Items </h1>

        <Form.Check
          style={{ marginTop: "10px", marginLeft: "20px" }}
          label="Enforce Categories"
          name="enforceCategories"
          value={globalRed.enforceCategory}
          checked={globalRed.enforceCategory && "checked"}
        />
      </div>
      {edit ? null : (
        <Row>
          <Col sm={6}>
            <div className="rowClass">
              {loadedCategories.length > 0 && (
                <CustomDropDown
                  list={loadedCategories}
                  label={createLabel(loadedCategories, data.category)}
                  selectedItem={(docId) => onCategoriesChange(docId)}
                />
              )}
            </div>
          </Col>
          <Col sm={6}>
            {data.category !== "" && (
              <div className="rowClass">
                {loadedType.length > 0 && (
                  <CustomDropDown
                    list={loadedType}
                    label={createLabel(loadedType, data.type)}
                    selectedItem={(docId) => onTypesChange(docId)}
                  />
                )}
              </div>
            )}
          </Col>
        </Row>
      )}

      {data.type !== "" ? (
        <div>
          <Row>
            <Col sm={6}>
              <Input
                label="Name"
                type="text"
                name={"name"}
                onChange={onNameChange}
                value={data.name}
              />
              <Input
                label="Serial"
                type="text"
                name={"serial"}
                onChange={onSerialChange}
                value={data.serial}
              />
            </Col>
            <Col sm={6}>
              {!edit && (
                <Input
                  label="Opening Stock"
                  type="number"
                  name={"openingstock"}
                  onChange={onOpeningStockChange}
                  value={data.openingStock}
                />
              )}

              <Input
                label="Barcode"
                type="text"
                name={"barcode"}
                onChange={onBarcodeChange}
                value={data.barcode}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <CustomDropDown
                list={dataCategory}
                label={data.uom}
                selectedItem={(docId) => onUOMchange(docId)}
              />
            </Col>
            <Col sm={6}>
              <CustomDropDown
                list={dataCategory}
                label={data.subunit}
                selectedItem={(id) => onSubUnitChange(id)}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Input
                label="Purchase Rate"
                type="text"
                name={"purchaseRage"}
                onChange={onPurchaseRageChange}
                value={data.purchaseRate}
              />{" "}
            </Col>{" "}
            <Col sm={6}>
              <Input
                label="Sale Rate"
                type="text"
                name={"saleRate"}
                onChange={onSaleRageChange}
                value={data.saleRate}
              />{" "}
            </Col>{" "}
          </Row>
          <Row>
            <Col sm={6}>
              <Input
                label="Maximun Level"
                type="text"
                name={"maxLevel"}
                onChange={onMaxLevelChange}
                value={data.maxLevel}
              />{" "}
            </Col>{" "}
            <Col sm={6}>
              <Input
                label="Minimum Level"
                type="text"
                name={"minLevel"}
                onChange={onMinLevelChange}
                value={data.minLevel}
              />{" "}
            </Col>{" "}
          </Row>

          <Row>
            <Col sm={12}>
              <Input
                label="Pack Size"
                type="text"
                name={"packSize"}
                onChange={onPackSizeChange}
                value={data.packSize}
              />
            </Col>
          </Row>
        </div>
      ) : null}

      <ActionButtons saveInfo={saveInfo} cancelInfo={props.cancelAddProduct} />
    </div>
  );
};

export default ItemTab;
