import React from "react";
import { Bar } from "react-chartjs-2";
const rand = () => Math.floor(Math.random() * 255);

const genData = () => ({
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "Scale",
      data: [rand(), rand(), rand(), rand(), rand(), rand()],
      backgroundColor: ["red", "black", "blue", "grey", "purple", "yellow"],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
});

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};
const BarChart = () => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Bar
        data={genData}
        options={{
          ...options,
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: "right",
          },
          responsive: true,
        }}
      />
    </div>
  );
};

export default BarChart;
