import React from "react";
import { Table } from "react-bootstrap";
import "../Css/PurchaseOrderStyle.css";
const PrintPageExample = () => {
  return (
    <div className="poContainer">
      <div className="header">
        <p>Jillani International</p>
        <p>Example address International</p>
        <p>Purchase Order</p>
      </div>
      <div className="order-info">
        <div className="box1">
          {/* box Info for customer */}
          <div className="boxInfo">
            <span className="fieldName">M/s</span>
            <span className="fieldValue">Solehri Trading and Co</span>
          </div>

          {/* box Info for Phone */}
          <div className="boxInfo">
            <span className="fieldName">Ph</span>
            <span className="fieldValue">03001234567</span>
          </div>

          {/* box Info for Attn */}
          <div className="boxInfo">
            <span className="fieldName">Attn</span>
            <span className="fieldValue">Example</span>
          </div>
          {/* box Info for Address */}
          <div className="boxInfo">
            <span className="fieldName">Address</span>
            <span className="fieldValue">Example</span>
          </div>
        </div>

        <div className="box2">
          <p>Dated</p>
          <p>Purchase Order No</p>
          <p>Your Ref</p>
          <p>Deliver Date</p>
          <p>Revision No</p>
        </div>
        <div className="box2">
          <p>Dated</p>
          <p>Purchase Order No</p>
          <p>Your Ref</p>
          <p>Deliver Date</p>
          <p>Revision No</p>
        </div>
      </div>

      {/* item list */}
      <Table className="sptable">
        <thead>
          <tr>
            <th className="srClass">Sr</th>
            <th>Item Description</th>
            <th className="QtyClass">Qty</th>
            <th className="QtyClass">Unit</th>
            <th className="QtyClass">Rate</th>
            <th className="QtyClass">Desc </th>
            <th className="QtyClass">Rate</th>
            <th className="QtyClass">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Sr</td>
            <td>Item Description</td>
            <td>Qty</td>
            <td>Unit</td>
            <td>Rate</td>
            <td>Desc </td>
            <td>Rate</td>
            <td>Total</td>
          </tr>
          <tr>
            <td>Sr</td>
            <td>Item Description</td>
            <td>Qty</td>
            <td>Unit</td>
            <td>Rate</td>
            <td>Desc </td>
            <td>Rate</td>
            <td>Total</td>
          </tr>
        </tbody>
      </Table>

      {/* Table Footer */}
      <Table className="sptable">
        <thead>
          <tr>
            <th rowSpan="2">Gross Amount </th>
            <th rowSpan="2">Discount </th>
            <th rowSpan="2">Net Amount </th>
            <th colSpan="2">Sales Amount</th>
            <th rowSpan="2">Gross Payable</th>
            <th rowSpan="2">Freight</th>
            <th rowSpan="2">Total</th>
          </tr>
          <tr>
            <th>%</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Gross Amount </td>
            <td>Discount </td>
            <td>Net Amount </td>
            <td>Sales Amount</td>
            <td>Sales Amount</td>
            <td>Gross Payable</td>
            <td>Freight</td>
            <td>Total</td>
          </tr>
        </tbody>
      </Table>
      {/* Payment */}

      <div className="payment">
        <span className="border-right">Payment</span>
        <span>Example for payment</span>
      </div>
      {/* Term and Conditions */}
      <div className="conditions">
        <span className="border-right">Terms and Conditions:</span>
        <ul>
          <li>
            Terms and conditions refer to the contractual rights and obligations
            of a party to any contract. They refer to the broader concept of
            guidelines that parties must follow in an agreement. Your business
            can create them for any formalized business agreement.
          </li>
          <li>
            Terms and conditions refer to the contractual rights and obligations
            of a party to any contract. They refer to the broader concept of
            guidelines that parties must follow in an agreement. Your business
            can create them for any formalized business agreement.
          </li>
        </ul>
      </div>
      {/* Prepared by */}

      <div className="payment">
        <span className="border-right">Payment</span>
        <span>Example for payment</span>
      </div>
    </div>
  );
};

export default PrintPageExample;
