import React, { useState, useEffect } from "react";
import { AiOutlineSortDescending } from "react-icons/ai";
const ItemWise = (props) => {
  const { salesReport, options, pendingReport, pathname } = props;
  const [report, setReport] = useState(salesReport);
  const [sortItems, setSortItems] = useState(true);
  const [sortTitle, setSortTitle] = useState(true);
  const [sortQty, setSortQty] = useState(true);
  let grandSum = 0,
    grandQty = 0,
    grandIssue = 0;
  useEffect(() => {
    if (!options.category && !options.type) {
      let items = [];
      salesReport.map((cat) => {
        if (cat.types && cat.types.length > 0) {
          cat.types.map((type) => {
            if (type.items && type.items.length > 0) {
              type.items.forEach((item) => {
                items.push(item);
              });
            }
          });
        }
      });
      setReport(items);
    } else {
      setReport(salesReport);
    }
  }, [options]);

  const sortByItems = () => {
    if (!options.category && !options.type) {
      let unsorted = report;
      if (sortItems) {
        unsorted = unsorted.sort((itemA, itemB) => {
          return parseInt(itemB.total) - parseInt(itemA.total);
        });
      } else {
        unsorted = unsorted.sort((itemA, itemB) => {
          return parseInt(itemA.total) - parseInt(itemB.total);
        });
      }
      setReport(unsorted);
    } else {
      let unsorted = salesReport;
      let cats = [];
      unsorted.map((cat) => {
        let types = [];
        if (cat.types && cat.types.length > 0) {
          cat.types.map((type) => {
            let items = [];
            if (type.items && type.items.length > 0) {
              if (sortItems) {
                items = type.items.sort((itemA, itemB) => {
                  return parseInt(itemB.total) - parseInt(itemA.total);
                });
              } else {
                items = type.items.sort((itemA, itemB) => {
                  return parseInt(itemA.total) - parseInt(itemB.total);
                });
              }
            }
            types.push({ ...type, items });
          });
        }
        cats.push({ ...cat, types });
      });
      setReport(cats);
    }

    setSortItems(!sortItems);
  };
  const sortByQty = () => {
    if (!options.category && !options.type) {
      let unsorted = report;
      if (sortQty) {
        unsorted = unsorted.sort((itemA, itemB) => {
          return parseInt(itemB.quantity) - parseInt(itemA.quantity);
        });
      } else {
        unsorted = unsorted.sort((itemA, itemB) => {
          return parseInt(itemA.quantity) - parseInt(itemB.quantity);
        });
      }
      setReport(unsorted);
    } else {
      let unsorted = salesReport;
      let cats = [];
      unsorted.map((cat) => {
        let types = [];
        if (cat.types && cat.types.length > 0) {
          cat.types.map((type) => {
            let items = [];
            if (type.items && type.items.length > 0) {
              if (sortQty) {
                items = type.items.sort((itemA, itemB) => {
                  return parseInt(itemB.quantity) - parseInt(itemA.quantity);
                });
              } else {
                items = type.items.sort((itemA, itemB) => {
                  return parseInt(itemA.quantity) - parseInt(itemB.quantity);
                });
              }
            }
            types.push({ ...type, items });
          });
        }
        cats.push({ ...cat, types });
      });
      setReport(cats);
    }

    setSortQty(!sortQty);
  };
  const sortByTitle = () => {
    if (!options.category && !options.type) {
      let unsorted = report;
      if (sortTitle) {
        unsorted = unsorted.sort((itemA, itemB) => {
          var nameA = itemA.name.toLowerCase(),
            nameB = itemB.name.toLowerCase();
          if (nameA < nameB)
            //sort string ascending
            return -1;
          if (nameA > nameB) return 1;
          return 0; //default return value (no sorting)
        });
      } else {
        unsorted = unsorted.sort((itemA, itemB) => {
          var nameA = itemA.name.toLowerCase(),
            nameB = itemB.name.toLowerCase();
          if (nameA > nameB)
            //sort string ascending
            return -1;
          if (nameA < nameB) return 1;
          return 0; //default return value (no sorting)
        });
      }
      setReport(unsorted);
    } else {
      let unsorted = salesReport;
      let cats = [];
      unsorted.map((cat) => {
        let types = [];
        if (cat.types && cat.types.length > 0) {
          cat.types.map((type) => {
            let items = [];
            if (type.items && type.items.length > 0) {
              if (sortTitle) {
                items = type.items.sort((a, b) => {
                  var nameA = a.name.toLowerCase(),
                    nameB = b.name.toLowerCase();
                  if (nameA < nameB)
                    //sort string ascending
                    return -1;
                  if (nameA > nameB) return 1;
                  return 0; //default return value (no sorting)
                });
              } else {
                items = type.items.sort((a, b) => {
                  var nameA = a.name.toLowerCase(),
                    nameB = b.name.toLowerCase();
                  if (nameA > nameB)
                    //sort string ascending
                    return -1;
                  if (nameA < nameB) return 1;
                  return 0; //default return value (no sorting)
                });
              }
            }
            types.push({ ...type, items });
          });
        }
        cats.push({ ...cat, types });
      });

      setReport(cats);
    }

    setSortTitle(!sortTitle);
  };

  if (report.length > 0) {
    return (
      <div>
        <table
          className="table table-striped table-hover table-bordered"
          style={{ textAlign: "center" }}
        >
          <thead>
            <tr>
              <th>Sr</th>
              {props.print ? (
                <th>Item Title</th>
              ) : (
                <th onClick={sortByTitle}>
                  <AiOutlineSortDescending size={22} />
                  Item Title
                </th>
              )}

              <th>UOM</th>
              {props.print ? (
                <th>QTY</th>
              ) : (
                <th onClick={sortByQty}>
                  <AiOutlineSortDescending size={22} />
                  QTY
                </th>
              )}
              {!pendingReport ? (
                <>
                  <th>Rate</th>
                  {props.print ? (
                    <th>Total Amount</th>
                  ) : (
                    <th onClick={sortByItems}>
                      <AiOutlineSortDescending size={22} />
                      Total Amount
                    </th>
                  )}
                </>
              ) : (
                <>
                  {pathname === "grndcpending" ? (
                    <>
                      <th>issue</th>
                      <th>balance</th>
                    </>
                  ) : null}
                </>
              )}

              {/* <th>Operation</th> */}
            </tr>
          </thead>
          {options.category || options.type ? (
            <tbody>
              {report.map((cat) => {
                let sum = 0,
                  qty = 0,
                  issue = 0,
                  catShow = true;
                if (cat.types && cat.types.length > 0) {
                  return (
                    <>
                      {options.category ? (
                        <tr key={cat.docId}>
                          <th colSpan={6} style={{ textAlign: "start" }}>
                            {cat.name}
                          </th>
                        </tr>
                      ) : null}
                      {cat.types.map((type) => {
                        let typeSum = 0,
                          typeQty = 0,
                          typeIssue = 0;
                        if (type.items && type.items.length > 0) {
                          return (
                            <>
                              {options.type ? (
                                <tr key={type.docId}>
                                  <th></th>
                                  <th>{type.name}</th>
                                  <th></th>
                                  <th></th>
                                  {!pendingReport ? (
                                    <>
                                      <th></th>
                                      <th></th>
                                    </>
                                  ) : (
                                    <>
                                      {pathname === "grndcpending" ? (
                                        <>
                                          <th></th>
                                          <th></th>
                                        </>
                                      ) : null}
                                    </>
                                  )}
                                </tr>
                              ) : null}

                              {type.items &&
                                type.items.length > 0 &&
                                type.items.map((item, index) => {
                                  if (parseInt(item.quantity) > 0) {
                                    sum = sum + parseInt(item.total);
                                    qty = qty + parseInt(item.quantity);
                                    if (item.issue) {
                                      issue = issue + parseInt(item.issue);
                                      typeIssue =
                                        typeIssue + parseInt(item.issue);
                                      grandIssue =
                                        grandIssue + parseInt(item.issue);
                                    }
                                    typeSum = typeSum + parseInt(item.total);
                                    typeQty = typeQty + parseInt(item.quantity);
                                    grandQty =
                                      grandQty + parseInt(item.quantity);
                                    grandSum = grandSum + parseInt(item.total);
                                    return (
                                      <tr key={item.docId}>
                                        <td>{index + 1}</td>
                                        <td>{item.name}</td>
                                        <td>{item.unit}</td>
                                        <td>{item.quantity}</td>
                                        {!pendingReport ? (
                                          <>
                                            <td>{item.rate.toFixed(2)}</td>
                                            <td>{item.total}</td>
                                          </>
                                        ) : (
                                          <>
                                            {pathname === "grndcpending" ? (
                                              <>
                                                <td>{item.issue}</td>
                                                <td>
                                                  {item.quantity - item.issue}
                                                </td>
                                              </>
                                            ) : null}
                                          </>
                                        )}
                                      </tr>
                                    );
                                  } else {
                                    catShow = false;
                                  }
                                })}
                              {options.type ? (
                                <tr>
                                  <th></th>
                                  <th></th>
                                  <th> Type Total</th>
                                  <th>{typeQty}</th>
                                  {!pendingReport ? (
                                    <>
                                      <th></th>
                                      <th>{typeSum}</th>
                                    </>
                                  ) : (
                                    <>
                                      {pathname === "grndcpending" ? (
                                        <>
                                          <td>{typeIssue}</td>
                                          <td>{typeQty - typeIssue}</td>
                                        </>
                                      ) : null}
                                    </>
                                  )}
                                </tr>
                              ) : null}
                            </>
                          );
                        }
                      })}
                      {options.category ? (
                        <tr>
                          <th></th>
                          <th></th>
                          <th> Category Total</th>
                          <th>{qty}</th>
                          {!pendingReport ? (
                            <>
                              <th></th>
                              <th>{sum}</th>
                            </>
                          ) : (
                            <>
                              {pathname === "grndcpending" ? (
                                <>
                                  <td>{issue}</td>
                                  <td>{qty - issue}</td>
                                </>
                              ) : null}
                            </>
                          )}
                        </tr>
                      ) : null}
                    </>
                  );
                }
              })}

              <tr>
                <th> </th>
                <th></th>
                <th>Grand Total</th>
                <th>{grandQty}</th>
                {!pendingReport ? (
                  <>
                    <th></th>
                    <th>{grandSum}</th>
                  </>
                ) : (
                  <>
                    {pathname === "grndcpending" ? (
                      <>
                        <td>{grandIssue}</td>
                        <td>{grandQty - grandIssue}</td>
                      </>
                    ) : null}
                  </>
                )}
              </tr>
            </tbody>
          ) : (
            <tbody>
              {report.map((item, index) => {
                grandQty = grandQty + parseInt(item.quantity);
                grandSum = grandSum + parseInt(item.total);
                grandIssue = grandIssue + parseInt(item.issue);

                if (parseInt(item.quantity) > 0) {
                  return (
                    <tr key={item.docId}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.unit}</td>
                      <td>{item.quantity}</td>
                      {!pendingReport ? (
                        <>
                          <td>{item.rate.toFixed(2)}</td>
                          <td>{item.total}</td>
                        </>
                      ) : (
                        <>
                          {pathname === "grndcpending" ? (
                            <>
                              <td>{item.issue}</td>
                              <td>{item.quantity - item.issue}</td>
                            </>
                          ) : null}
                        </>
                      )}
                    </tr>
                  );
                }
              })}
              <tr>
                <th></th>
                <th></th>
                <th>Total</th>
                <th>{grandQty}</th>
                {!pendingReport ? (
                  <>
                    <th></th>
                    <th>{grandSum}</th>
                  </>
                ) : (
                  <>
                    {pathname === "grndcpending" ? (
                      <>
                        <td>{grandIssue}</td>
                        <td>{grandQty - grandIssue}</td>
                      </>
                    ) : null}
                  </>
                )}
              </tr>
            </tbody>
          )}
        </table>
      </div>
    );
  } else {
    return <div>Please Select Parameters to View Report</div>;
  }
};

export default ItemWise;
