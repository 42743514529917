import React, { useState, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap';
import ShowModal from '../../subcom/ShowModal';
const UpdateTitle = (props) => {

    return (
        <div>

            <Form.Control
                type='text'
                value={props.id}
                disabled
                name='title'
            />
            <Form.Control
                type='text'
                onChange={(e) =>props.updateTitle(e.target.value)}
                value={props.title}
                name='title'
            />


        </div>
    )
}

export default UpdateTitle
