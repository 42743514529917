import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form} from "react-bootstrap";
import CustomDropDown from "../../subcom/CustomDropDown";
import * as coaActions from "../../action/coaAction";
const SearchBar = (props) => {
  const dispatch = useDispatch();
 
  const {fDate,fVNO,fBank,fAccount,fCNO,fCDate,fAmount}=props;
  const [customerData, setCustomerData] = useState([]);
  const [sCustomer, setSCustomer] = useState({ docId: 0, name: "" });
  const coaRed = useSelector((state) => state.coa);
  const loadCustomerOrSupplier = () => {
    let data = [];

    coaRed.allLevels.map((level1) => {
      if (level1.children && level1.children.length > 0) {
        level1.children.map((level2) => {
          if (level2.children && level2.children.length > 0) {
            level2.children.map((level3) => {
              data.push(level3);
            });
          }
        });
      }
    });
    return data;
  };
  useEffect(() => {
    dispatch(coaActions.getAllLevel());
  }, []);
  useEffect(async () => {
    const customers = await loadCustomerOrSupplier();
    setCustomerData([{ docId: 0, name: "All Customer" }, ...customers]);
  }, [coaRed]);
  const selectedCustomer = (id, type) => {
    const filterCustomer = customerData.find((f) => f.docId === id);
    props.selectedCustomer(filterCustomer.title, type);
  };
  return (
    <tr>
      <td>
     
        <Form.Control
        style={{width:'150px'}}
          type="date"
          name="sDate"
          onChange={props.searchByDate}
          value={fDate}
        />
      </td>
      <td>
    
        <Form.Control
          style={{width:'80px'}}
          type="text"
          name="sVNo"
          onChange={props.searchByVNo}
          value={fVNO}
        />
      </td>
      <td>
        <CustomDropDown
          list={customerData}
          label={fAccount}
          selectedItem={(id) => selectedCustomer(id, "account")}
        />
      </td>
      <td>
        <CustomDropDown
          list={customerData}
          label={fBank}
          selectedItem={(id) => selectedCustomer(id, "bank")}
        />
      </td>
      <td>
        <Form.Control
         style={{width:'80px'}}
          type="text"
          name="sVNo"
          onChange={props.searchByCNoHandler}
          value={fCNO}
        />
      </td>
      <td>
        <Form.Control
          style={{width:'150px'}}
          type="date"
          name="searchCDate"
          onChange={props.searchByCheckDateHander}
          value={fCDate}
        />
      </td>
      <td>
      <Form.Control
          style={{width:'80px'}}
          type="text"
          name="searchCAmount"
          onChange={props.searchByAmountHander}
          value={fAmount}
        />
      </td>
    </tr>
  );
};

export default SearchBar;
