import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import * as coaActions from "../../action/coaAction";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Table } from "react-bootstrap";
import Level3Search from "../../components/accountCart/Level3Search";
const OpeningBalance = (props) => {
  const dispatch = useDispatch();

  const [level3Array, setLevel3Array] = useState([]);
  const [integration, setIntegration] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);
  const [showId, setShowId] = useState("");
  const [showTitle, setShowTitle] = useState("");
  const [showType, setShowType] = useState("");

  const coa = useSelector((state) => state.coa);
  const [openingBalanceright, setOpeningBalanceRight] = useState({});

  useEffect(() => {
    dispatch(coaActions.getAllLevel());
    setOpeningBalanceRight(props.rights);
  }, []);
  useEffect(() => {
    setUpdatedList([]);
    let data = [];
    coa.allLevels.length > 0 &&
      coa.allLevels.map((level1) => {
        if (level1.children.length > 0) {
          level1.children.map((level2) => {
            if (level2.children.length > 0) {
              level2.children.map((level3) => {
                const newDoc = {
                  showId: level1.id + level2.id + level3.id,
                  id: level3.id,
                  title: level3.title,
                  docId: level3.docId,
                  parentId: level3.parentId,
                  credit: level3.credit,
                  debit: level3.debit,
                  type: level1.type,
                };

                data.push(newDoc);
              });
            }
          });
        }
      });
    setLevel3Array(data);
    setIntegration(data);
  }, [coa]);

  const handleDebitChange = (value, index) => {
    console.log({ value });
    let list = [...level3Array];
    let check = list[index];
    if (parseInt(check.credit) === 0) {
      let updatedDoc = {
        ...check,
        debit: parseInt(value),
      };
      list[index] = updatedDoc;
      setLevel3Array(list);
      makeUpdateList(updatedDoc);
    }
  };
  const makeUpdateList = (doc) => {
    console.log(doc);
    const check = updatedList.find((f) => f.docId === doc.docId);
    let newUpdatedList = [...updatedList];
    if (check) {
      newUpdatedList = newUpdatedList.filter((f) => f.docId !== check.docId);
      console.log(updatedList);
    }
    newUpdatedList.push(doc);
    setUpdatedList(newUpdatedList);
  };

  const handleCreditChange = (value, index) => {
    let list = [...level3Array];
    let check = list[index];

    if (parseInt(check.debit) === 0) {
      console.log(check.debit);
      let updatedDoc = {
        ...check,
        credit: value,
      };
      list[index] = updatedDoc;
      setLevel3Array(list);
      makeUpdateList(updatedDoc);
    }
  };
  const handleUpdateLevel = () => {
    console.log(updatedList);
    if (updatedList.length > 0) {
      dispatch(coaActions.addCreditDebitLevel3(updatedList));
      setUpdatedList([]);
    }
  };
  const searchById = (val) => {
    let list = [...integration];
    setShowTitle("");
    setShowId(val);
    list = list.filter((f) => {
      if (f.showId.toString().toLowerCase().startsWith(val)) {
        return f;
      }
    });
    setLevel3Array(list);
  };
  const searchByTitle = (val) => {
    let list = [...integration];
    setShowId("");
    setShowTitle(val);
    list = list.filter((f) => {
      if (f.title.toString().toLowerCase().includes(val)) {
        return f;
      }
    });
    setLevel3Array(list);
  };
  const searchByType = (val) => {
    let list = [...integration];
    setShowId("");
    setShowTitle("");
    list = list.filter((f) => {
      if (f.type.toString().toLowerCase().includes(val)) {
        return f;
      }
    });
    setLevel3Array(list);
  };
  const handleReset = () => {
    setLevel3Array(integration);
    setShowId("");
    setShowTitle("");
    setShowType("");
  };
  if (!coa.loading) {
    return (
      <Layout>
        <div
          style={{
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            marginTop: "20px",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px",
            }}
          >
            <h4>Opening Balance</h4>
            <Button
              onClick={handleUpdateLevel}
              disabled={!openingBalanceright.edit}
            >
              Update
            </Button>
          </div>

          <Level3Search
            searchById={searchById}
            showId={showId}
            searchByTitle={searchByTitle}
            showTitle={showTitle}
            handleReset={handleReset}
            searchByType={searchByType}
            type={showType}
          />
          <div style={{ maxHeight: "500px", overflow: "auto" }}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th style={{ width: "100px" }}>Debit</th>
                  <th style={{ width: "100px" }}>Credit</th>
                </tr>
              </thead>
              <tbody>
                {level3Array.length > 0 &&
                  level3Array.map((level, index) => (
                    <tr key={level.docId}>
                      <td> {level.showId}</td>
                      <td> {level.title}</td>
                      <td>
                        <Form.Control
                          disabled={!openingBalanceright.edit}
                          type="number"
                          value={level.debit}
                          onChange={(e) =>
                            handleDebitChange(e.target.value, index)
                          }
                        />
                      </td>
                      <td>
                        <Form.Control
                          disabled={!openingBalanceright.edit}
                          type="number"
                          value={level.credit}
                          onChange={(e) =>
                            handleCreditChange(e.target.value, index)
                          }
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Layout>
    );
  } else {
    return <div>loading</div>;
  }
};

export default OpeningBalance;
