import React from "react";
import { FloatingLabel, Form, Button } from "react-bootstrap";
import CustomDropDown from "../../subcom/CustomDropDown";
import "../../Css/StartAndEndDate.css";
const StartAndEndDate = (props) => {
  const { rDate, selectedCustomerName, customers } = props;
  return (
    <div className="mainHeading">
      <div style={{ width: "100%", marginTop: "5px" }}>
        <CustomDropDown
          list={customers}
          label={selectedCustomerName}
          selectedItem={(id) => props.selectedItemData(id)}
        />
      </div>

      <div className="inputclass">
        <FloatingLabel label="Start Date">
          <Form.Control
            name="startDate"
            type="date"
            onChange={props.handleStartDate}
            placeholder="please select date"
            value={rDate.start}
          />
        </FloatingLabel>
        <FloatingLabel label="End Date">
          <Form.Control
            name="endDate"
            type="date"
            onChange={props.handleEndDate}
            placeholder="please select date"
            value={rDate.end}
          />
        </FloatingLabel>
        <Button className="btnStyling" onClick={props.loadData}>
          Search
        </Button>
      </div>
    </div>
  );
};

export default StartAndEndDate;
