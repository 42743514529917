import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Input from "../subcom/Input";
import { Button, Spinner } from "react-bootstrap";
import { loadCompany, saveCompanyAction } from "../action/companyAction";
import ShowModal from "../subcom/ShowModal";
const CompanySetup = (props) => {
  const [data, setData] = useState({
    name: "",
    address: "",
    phoneNo: "",
    mobileNo: "",
    ntn: "",
    stn: "",
    email: "",
    url: "",
    logo: "",
    tagline: "",
    businessType: "",
  });
  const [logo, setLogo] = useState("");
  const [loading,setLoading]=useState(false);
  const onNameChange = (e) => {
    setData({ ...data, name: e.target.value });
  };
  const onMNChange = (e) => {
    setData({ ...data, mobileNo: e.target.value });
  };
  const onPNChange = (e) => {
    setData({ ...data, phoneNo: e.target.value });
  };
  const onEmailChange = (e) => {
    setData({ ...data, email: e.target.value });
  };
  const onNTNChange = (e) => {
    setData({ ...data, ntn: e.target.value });
  };
  const onSTNChange = (e) => {
    setData({ ...data, stn: e.target.value });
  };
  const onAddressChange = (e) => {
    setData({ ...data, address: e.target.value });
  };
  const onURLChange = (e) => {
    setData({ ...data, url: e.target.value });
  };
  const onTagLineChange = (e) => {
    setData({ ...data, tagline: e.target.value });
  };
  const onBusinesTypeChange = (e) => {
    setData({ ...data, businessType: e.target.value });
  };
  const onFileChange = (e) => {
    const val = e.target.files[0];
    setLogo(val);
  };
  const saveCompany = async () => {
    saveCompanyAction({ ...data, logo: logo }).then(() => {
      loadCompanyData();
    });
  };
  useEffect(() => {

    loadCompanyData();
  }, []);
  const loadCompanyData = async () => {
    setLoading(true);
    const d = await loadCompany();
    setData({
      name: d.name,
      address: d.address,
      phoneNo: d.phoneNo,
      mobileNo: d.mobileNo,
      ntn: d.ntn,
      stn: d.stn,
      email: d.email,
      url: d.url,
      logo: d.picture,
      tagline: d.tagline,
      businessType: d.businessType,
    });
    setLoading(false);
  };
  return (
    <Layout>
      {loading && (
          <ShowModal show={loading} size={"lg"}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner animation="border" role="status" />
            </div>
          </ShowModal>
        )}
      <div style={{ margin: "10px" }}>
        <div className="row">
          <div className="col-6">
            <Input
              label="Company Name"
              type="text"
              onChange={onNameChange}
              value={data.name}
            />

            <Input
              label="Mobile NO"
              type="tel"
              onChange={onMNChange}
              value={data.mobileNo}
            />

            <Input
              label="Phone No"
              type="tel"
              onChange={onPNChange}
              value={data.phoneNo}
            />
            <Input
              label="Tag Line"
              type="type"
              onChange={onTagLineChange}
              value={data.tagline}
            />
          </div>
          <div className="col-6">
            <Input
              label="Email"
              type="email"
              onChange={onEmailChange}
              value={data.email}
            />
            <Input
              label="NTN"
              type="text"
              onChange={onNTNChange}
              value={data.ntn}
            />
            <Input
              label="STN"
              type="text"
              onChange={onSTNChange}
              value={data.stn}
            />
            <Input
              label="Business Type"
              type="type"
              onChange={onBusinesTypeChange}
              value={data.businessType}
            />
          </div>
        </div>

        <Input label="URL" type="url" onChange={onURLChange} value={data.url} />
        <Input
          label="Address"
          type="textarea"
          onChange={onAddressChange}
          value={data.address}
        />
        <Input label="Logo" type="file" onChange={onFileChange} />
        <Button onClick={saveCompany}>Save</Button>
      </div>
    </Layout>
  );
};

export default CompanySetup;
