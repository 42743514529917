import React, { useEffect, useState } from "react";
import SlipHeader from "../../components/SlipHeader.js/SlipHeader";
import { loadcompanyData } from "../../action/generalMethod";
const PrintStockReport = (props) => {
  const [reportData, setReportData] = useState();
  const [companyData, setCompanyData] = useState({});
  useEffect(async () => {
    let data = localStorage.getItem("printStockReport")
      ? localStorage.getItem("printStockReport")
      : null;
    if (data) {
      const parseData = JSON.parse(data);
      setReportData(parseData);
    }
    const cData = await loadcompanyData();
    setCompanyData(cData);
  }, []);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  useEffect(async () => {
    if (reportData) {
      await delay(4000);
      window.print();
    }
  }, [reportData]);

  if (reportData) {
    const { stockList, rDate } = reportData;

    return (
      <div>
        <SlipHeader
          companyData={companyData}
          data={""}
          vNo={""}
          slipType={"Stock Report"}
        />
        {stockList.length > 0 && (
          <table className="table table-striped table-hover table-bordered">
            <thead>
              <tr>
                <th>#</th>

                <th>name</th>
                <th>Opening Quantity</th>

                <th>Received Quantity</th>

                <th>Issued Quantity</th>

                <th>Closing Qty</th>

                {/* <th>Operation</th> */}
              </tr>
            </thead>
            <tbody>
              {stockList.length > 0 &&
                stockList.map((st, index) => (
                  <tr key={index}>
                    <td>{index}</td>

                    <td>{st.name && st.name}</td>
                    <td>{st.balanceInStock}</td>

                    <td>{st.receivedQty}</td>

                    <td>{st.issuedQty}</td>

                    <td>{st.balanceInStock + st.receivedQty - st.issuedQty}</td>

                    {/* <td onClick={() => showStockModalHandler(st)}>edit</td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    );
  } else {
    return <div>loading</div>;
  }
};

export default PrintStockReport;
