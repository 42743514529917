import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import ShowModal from '../../subcom/ShowModal';
import { useDispatch } from 'react-redux'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import * as coaActions from '../../action/coaAction';
const NewLevel1 = (props) => {
    const type = props.type;
  
    const dispatch = useDispatch();
    const [level1Id, setLevel1Id] = useState('');
    const [level1Title, setLevel1Title] = useState('');
    const [show, setShow] = useState(false);
    const { id } = props.nextId;
    const nextId =id? parseInt(id):0;
   
    const onLevel1IdChangeHandler = (e) => {
        setLevel1Id(e.target.value)
    }
    const onLevel1TitleChangeHandler = (e) => {
        setLevel1Title(e.target.value)
    }
    const saveLevel = () => {

        if (level1Title !== ''&& type) {
            dispatch(coaActions.defineLevel1({ level1Title, id: level1Id, type: type }));
            setShow(false);
        }
        else {
            alert('please enter some values')
        }

    }
    const cancelLevel1 = () => {
        setLevel1Title('');
        setLevel1Id('');
        setShow(false);
    }
    return (
        <div>
            <ShowModal show={show} onHide={() => setShow(false)}>
                <div style={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: 'column',
                    height: '200px'
                }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: 'column',
                        height: '200px',
                        width: '80%'
                    }}>
                        <h3>Define Level 1</h3>
                        <Form.Control
                            label="Level 1 Title"
                            required="required"
                            type="number"
                            name="id"
                            onChange={onLevel1IdChangeHandler}
                            value={level1Id !== "" ? level1Id : nextId + 1}
                        />
                        <Form.Control
                            label="Level 1 Title"
                            required="required"
                            type="text"
                            name="title1"
                            onChange={onLevel1TitleChangeHandler}
                            value={level1Title}
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                width: '100%',
                                marginTop: '10px'

                            }}
                        >
                            <Button onClick={saveLevel}> Save</Button>
                            <Button variant='danger' onClick={cancelLevel1}> Cancel</Button>
                        </div>
                    </div>


                </div>

            </ShowModal>
            <AiOutlinePlusCircle size={32}
                onClick={() => setShow(true)}
            />
        </div>
    )
}

export default NewLevel1
