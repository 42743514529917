import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ListLevels from "./ListLevels";
const OwnerEquity = (props) => {
  const [integration, setIntegration] = useState([]);
  const [loading, setLoading] = useState(false);
  const coa = useSelector((state) => state.coa);

  useEffect(() => {
    setLoading(coa.loading);
    setIntegration(coa.allLevels.filter((f) => f.type === "oe"));
  }, [coa]);

  return (
    <div>
      <h5>Owner Equity</h5>
      <ListLevels integration={integration} type="oe" right={props.right} />
    </div>
  );
};

export default OwnerEquity;
