import React, { useState } from "react";

import { AiOutlineSortDescending } from "react-icons/ai";
const InvoiceWise = (props) => {
  const { salesReport, pendingReport} = props;
  const [report, setReport] = useState(salesReport);
  const [sortedByGrand, setSortedByGrand] = useState(false);
  const [sortedByVN, setSortedByVN] = useState(false);
  const [sortedByDate, setSortedByDate] = useState(false);
  const [sortedByClient, setSortedByClient] = useState(false);

  let tax = 0;
  let discount = 0;
  let grand = 0;
  let advance = 0;
  let balance = 0;
  let gross = 0;
  const sortByDate = async () => {
    let unsorted = [];
    setReport(unsorted);
    if (sortedByDate) {
      unsorted = await salesReport.sort(function (a, b) {
        let aDate = a.date.toDate().toLocaleDateString();
        let bDate = b.date.toDate().toLocaleDateString();
        return new Date(aDate) - new Date(bDate);
      });
    } else {
      unsorted = await salesReport.sort(function (a, b) {
        let aDate = a.date.toDate().toLocaleDateString();
        let bDate = b.date.toDate().toLocaleDateString();
        return new Date(bDate) - new Date(aDate);
      });
    }

    setReport(unsorted);
    setSortedByDate(!sortedByDate);
  };
  const sortByInvoice = async () => {
    let unsorted = [];
    setReport(unsorted);
    if (sortedByVN) {
      unsorted = await salesReport.sort(function (a, b) {
        const aId = a.invoiceId.split("-")[3];
        const bId = b.invoiceId.split("-")[3];
        return parseInt(aId) - parseInt(bId);
      });
    } else {
      unsorted = await salesReport.sort(function (a, b) {
        const aId = a.invoiceId.split("-")[3];
        const bId = b.invoiceId.split("-")[3];
        return parseInt(bId) - parseInt(aId);
      });
    }
    setReport(unsorted);
    setSortedByVN(!sortedByVN);
  };
  const sortByGrand = async () => {
    let unsorted = [];
    setReport(unsorted);
    if (sortedByGrand) {
      unsorted = await salesReport.sort(function (a, b) {
        const aId = a.grand;
        const bId = b.grand;
        return parseInt(aId) - parseInt(bId);
      });
    } else {
      unsorted = await salesReport.sort(function (a, b) {
        const aId = a.grand;
        const bId = b.grand;
        return parseInt(bId) - parseInt(aId);
      });
    }

    setReport(unsorted);
    setSortedByGrand(!sortedByGrand);
  };
  const sortByClient = async () => {
    let unsorted = salesReport;

    if (sortedByClient) {
      unsorted = unsorted.sort((itemA, itemB) => {
        var nameA = itemA.party.toLowerCase(),
          nameB = itemB.party.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
    } else {
      unsorted = unsorted.sort((itemA, itemB) => {
        var nameA = itemA.party.toLowerCase(),
          nameB = itemB.party.toLowerCase();
        if (nameA > nameB)
          //sort string ascending
          return -1;
        if (nameA < nameB) return 1;
        return 0; //default return value (no sorting)
      });
    }
    console.log({ unsorted });

    setReport(unsorted);
    setSortedByClient(!sortedByClient);
  };
  if (report.length > 0) {
    return (
      <div>
     
        <table
          className="table table-striped table-hover table-bordered"
          style={{ textAlign: "center" }}
        >
          <thead>
            <tr>
              <th>#</th>
              {props.print ? (
                <>
                  <th>Date</th>
                  <th>Voucher No</th>
                  <th>Client ID</th>
                </>
              ) : (
                <>
                  <th onClick={sortByDate}>
                    <AiOutlineSortDescending size={22} />
                    Date
                  </th>
                  <th onClick={sortByInvoice}>
                    <AiOutlineSortDescending size={22} />
                    Voucher No
                  </th>
                  <th onClick={sortByClient}>
                    <AiOutlineSortDescending size={22} />
                    Client ID
                  </th>
                </>
              )}

              <th>Gross Amount</th>
              <th>Tax</th>
              <th>Discount</th>
              {props.print ? (
                <th>Grand Total</th>
              ) : (
                <th onClick={sortByGrand}>
                  <AiOutlineSortDescending size={22} /> Grand Total
                </th>
              )}

              <th>Advance</th>
              <th>Balance</th>

              {/* <th>Operation</th> */}
            </tr>
          </thead>
          <tbody>
            {report.map((invoice, index) => {
              tax = tax + parseInt(invoice.tax);
              discount = discount + parseInt(invoice.discount);
              grand = grand + parseInt(invoice.grand);
              advance = advance + parseInt(invoice.advance);
              balance = balance + parseInt(invoice.balance);
              gross = gross + parseInt(invoice.gross);
              return (
                <tr key={invoice.docId}>
                  <td>{index + 1}</td>
                  <td>
                    {new Date(invoice.date.seconds * 1000)
                      .toISOString()
                      .slice(0, 10)}
                  </td>
                  <td>{invoice.invoiceId}</td>
                  <td style={{ textAlign: "start" }}>{invoice.party}</td>
                  <td>{invoice.gross}</td>
                  <td>{invoice.tax}</td>
                  <td>{invoice.discount}</td>
                  <td>{invoice.grand}</td>
                  <td>{invoice.advance}</td>
                  <td>{(invoice.grand - invoice.advance).toFixed(2)}</td>
                </tr>
              );
            })}
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th>Total</th>
              <th>{gross}</th>
              <th>{tax}</th>
              <th>{discount}</th>
              <th>{grand}</th>
              <th>{advance}</th>
              <th>{(grand - advance).toFixed(2)}</th>
            </tr>
          </tbody>
        </table>
      </div>
    );
  } else {
    return <div>Please Select Parameters to View Report</div>;
  }
};

export default InvoiceWise;
