import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import ListInvoices from "../components/InvoiceCom/ListInvoices";
import { Card, Tab, Tabs } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import ShowModal from "../subcom/ShowModal";
import * as invoiceActions from "../action/invoiceAction";
import InvoiceTable from "../components/InvoiceTable";
const InvoicePage = (props) => {
  const dispatch = useDispatch();
  const { rights, owner } = props;
  const invoiceType = props.match.params.type;
  const invoiceId = props.match.params.id;
  const invoiceDate = props.match.params.date;
  const [tab, setTab] = useState("invoice");
  const invoiceRed = useSelector((state) => state.invoice);
  const [invoiceByType, setInvoiceByType] = useState([]);
  const userRed = useSelector((state) => state.user);
  const [invoiceRights, setInvoiceRights] = useState({});
  const [invoiceCreator, setInvoiceCreator] = useState({});
  useEffect(() => {
    dispatch(invoiceActions.loadInvoiceAll());
    document.title = invoiceType;
  }, []);
  useEffect(() => {
    if (rights && rights.view) {
      setInvoiceRights(rights);
      setInvoiceCreator(owner);
    }
  }, [rights, owner]);

  const makeNewId = () => {
    setTab("invoice");
  };

  const invoiceByTypeHandler = async () => {
    if (invoiceRed.invoices) {
      let filterInvoice = [];
      if (invoiceRed.invoices.length > 0) {
        if (invoiceRights && invoiceRights.view) {
          filterInvoice = await invoiceRed.invoices.filter((f) => {
            if (f.type === invoiceType.toString()) {
              return f;
            }
          });
        } else {
          const user = userRed.user;

          if (user) {
            if (user.name) {
              const userName = user.name;
              filterInvoice = await invoiceRed.invoices.filter((f) => {
                if (f.type === invoiceType.toString()) {
                  if (f.invoiceOwner === userName) {
                    return f;
                  }
                }
              });
            }
          }
        }
      }

      setInvoiceByType(filterInvoice);
    }
  };

  if (!invoiceRed.loading && rights && rights.view) {
    return (
      <Layout>
        <div>
          <Card style={{ display: "flex", width: "100%" }}>
            <Card.Body className="no-gutters p-0 m-0">
              <div className="mb-3 ml-2">
                <Tabs activeKey={tab} className="m-2">
                  <Tab
                    eventKey="invoice"
                    title={
                      <div
                        onClick={makeNewId}
                        className="btnclass gradient-button-4"
                      >
                        {" "}
                        <Link
                          style={{ textDecoration: "none", color: "white" }}
                          to={`/invoice/${invoiceType}`}
                        >
                          New Entry
                        </Link>
                      </div>
                    }
                  >
                    {tab === "invoice" && (
                      <InvoiceTable
                        invoiceType={invoiceType}
                        invoiceId={invoiceId}
                        invoiceDate={invoiceDate}
                        invoiceRights={invoiceRights}
                        invoiceCreator={invoiceCreator}
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="list"
                    title={
                      <div
                        className="btnclass gradient-button-4"
                        style={buttonStyle}
                        onClick={() => {
                          invoiceByTypeHandler();
                          setTab("list");
                        }}
                      >
                        List
                      </div>
                    }
                  >
                    {invoiceByType.length > 0 && (
                      <ListInvoices
                        list={invoiceByType}
                        invoiceType={invoiceType}
                        check={"invoice"}
                        changeTab={invoiceByTypeHandler}
                      />
                    )}
                  </Tab>
                  <Tab
                    title={
                      <h2 style={{ marginLeft: "100px" }}>
                        {" "}
                        {invoiceType === "Quotation" ||
                        invoiceType === "Client Order" ||
                        invoiceType === "Good Receipt Note" ||
                        invoiceType === "Purchase Order" ||
                        invoiceType === "Delivery Challan" ? (
                          <h5>{invoiceType}</h5>
                        ) : (
                          <h5>{invoiceType + "-" + "Invoice"}</h5>
                        )}
                      </h2>
                    }
                    disabled
                  ></Tab>
                </Tabs>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Layout>
    );
  } else {
    return (
      <ShowModal show={invoiceRed.loading} onHide={() => {}}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="grow" />
        </div>
      </ShowModal>
    );
  }
};

const buttonStyle = {
  marginRight: "10px",
  fontWeight: "bold",
  fontSize: "12px",
};
export default InvoicePage;
