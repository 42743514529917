import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as coaActions from "../../action/coaAction";
import ChartOfAccountTable from "../../components/chartOfAccountReport/ChartOfAccountTable";
import Layout from "../../components/Layout";
const ChartOfAccount = (props) => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const coa = useSelector((state) => state.coa);
 
  useEffect(() => {
    document.title = "Chart Of Account";
    dispatch(coaActions.getAllLevel());
  }, []);
  useEffect(() => {
    setList(coa.allLevels);
    
    
  }, [coa]);
  const currentUserRed = useSelector((state) => state.user);
  const [reportRight, setReportRight] = useState({});
  useEffect(() => {
    setReportRight(props.rights);
  }, []);
  return (
    <Layout>
      <div style={{ width: "100%", height: "500px", overflow: "auto" }}>
        <ChartOfAccountTable list={list} />
      </div>
      <div style={{ width: "100%", float: "right" }}>
        {reportRight.print ? (
          <Link className="btn btn-primary" target="_blank" to="/coaprint">
            Print
          </Link>
        ) : null}
      </div>
    </Layout>
  );
};

export default ChartOfAccount;
