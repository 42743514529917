import firebase from "firebase";
export const LOAD_ALL_INVOICE_REQUEST = "LOAD_ALL_INVOICE_REQUEST";
export const LOAD_ALL_INVOICE_SUCCESS = "LOAD_ALL_INVOICE_SUCCESS";
export const LOAD_ALL_INVOICE_FAILURE = "LOAD_ALL_INVOICE_FAILURE";
export const LOAD_ALL_INVOICE_CANCEL = "LOAD_ALL_INVOICE_CANCEL";

export const LOAD_ALL_OP_REQUEST = "LOAD_ALL_OP_REQUEST";
export const LOAD_ALL_OP_SUCCESS = "LOAD_ALL_OP_SUCCESS";
export const LOAD_ALL_OP_FAILURE = "LOAD_ALL_OP_FAILURE";

export const EDIT_INVOICE_REQUEST = "EDIT_INVOICE_REQUEST";
export const EDIT_INVOICE_SUCCESS = "EDIT_INVOICE_SUCCESS";
export const EDIT_INVOICE_FAILURE = "EDIT_INVOICE_FAILURE";

export const DELETE_INVOICE_REQUEST = "DELETE_INVOICE_REQUEST";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_FAILURE = "DELETE_INVOICE_FAILURE";

export const LOAD_LAST_INVOICE = "LOAD_LAST_INVOICE";
export const LOAD_NEXT_INVOICE = "LOAD_NEXT_INVOICE";
export const LOAD_FIRST_INVOICE = "LOAD_FIRST_INVOICE";
export const LOAD_PREV_INVOICE = "LOAD_PREV_INVOICE";

export const NEW_INVOICE_REQUEST = "NEW_INVOICE_REQUEST";
export const NEW_INVOICE_SUCCESS = "NEW_INVOICE_SUCCESS";
export const NEW_INVOICE_FAILURE = "NEW_INVOICE_FAILURE";
export const CANCEL_NEW_INVOICE_REQUEST = "CANCEL_NEW_INVOICE_REQUEST";
export const CANCEL_EDIT_INVOICE_REQUEST = "CANCEL_EDIT_INVOICE_REQUEST";

export const addInvoice = (data, invoiceCreator, voucherCopied) => {
  return async (dispatch, getState) => {
    dispatch({
      type: NEW_INVOICE_REQUEST,
    });
    let id = data.invoiceId;
    console.log({ data });
    const newInvoiceQuery = firebase
      .firestore()
      .collection(`invoices${data.type}`)
      .doc(id);
    let pendingQuery;
    if (data.type !== "Sell Return" || data.type !== "Purchase Return") {
      pendingQuery = firebase
        .firestore()
        .collection(`Pending${data.type}`)
        .doc(id);
    }

    const {
      saveWithAprrove,
      saveWithCheck,
      stockWithCheck,
      stockWithApprove,
      updateStockWithGRN,
      updateStockWithDC,
    } = getState().global;
    let serverDate = firebase.firestore.Timestamp.fromDate(new Date(data.date));

    if (data.items.length > 0) {
      const stock = getState().stock.stock;
      const checkDocIsSavedOrNot = await newInvoiceQuery.get();
      if (!checkDocIsSavedOrNot.exists) {
        await newInvoiceQuery
          .set({
            ...data,
            invoiceOwner: invoiceCreator,
            date: serverDate,
            cancel: false,
            check: false,
            cancelReason: "",
            approve: false,
            cvId: voucherCopied.invoiceId ? voucherCopied.invoiceId : "",
          })
          .then(async () => {
            if (pendingQuery) {
              await createPendingInvoice(data, pendingQuery);
            }
            if (voucherCopied.type) {
              if (
                voucherCopied.type !== "Sell Return" ||
                voucherCopied.type !== "Purchase Return"
              ) {
                let updatependingQuery = firebase
                  .firestore()
                  .collection(`Pending${voucherCopied.type}`)
                  .doc(voucherCopied.invoiceId);
                await updatePendingInvoice(
                  data,
                  voucherCopied,
                  updatependingQuery
                );
              }
            }
          })
          .then(async () => {
            if (updateStockWithGRN || updateStockWithDC) {
              if (data.type === "Good Receipt Note") {
                if (updateStockWithGRN) {
                  if (!stockWithApprove) {
                    if (!stockWithCheck) {
                      await addStockData(data, stock);
                    }
                  }
                }
              }
              if (data.type === "Delivery Challan") {
                if (updateStockWithDC) {
                  if (!stockWithApprove) {
                    if (!stockWithCheck) {
                      await addStockData(data, stock);
                    }
                  }
                }
              }
            } else {
              if (!updateStockWithGRN) {
                if (!stockWithApprove) {
                  if (!stockWithCheck) {
                    await addStockData(data, stock);
                  }
                }
              }
            }
          })
          .then(async () => {
            if (
              data.type !== "Good Receipt Note" &&
              data.type !== "Delivery Challan"
            ) {
              if (!saveWithAprrove) {
                if (!saveWithCheck) {
                  console.log({ rep: "ledgering" });
                  await addLedger(data);
                }
              }
            }
          })
          .then(() => {
            dispatch({
              type: NEW_INVOICE_SUCCESS,
              payload: {
                invoice: {
                  ...data,
                  invoiceOwner: invoiceCreator.name,
                  date: serverDate,
                },
              },
            });
          })
          .catch((error) => console.log(error.message));
      } else {
        dispatch({
          type: NEW_INVOICE_FAILURE,
          payload: { error: "document already exit" },
        });
      }
    } else {
      dispatch(cancelSaveInvoice("new"));
    }
  };
};
const createPendingInvoice = async (data, pendingQuery) => {
  let newItems = [];

  data.items.map((item) => {
    let newItem = {
      discount: 0,
      id: item.id,
      name: item.name,
      rate: parseFloat(item.rate),
      total: parseFloat(item.rate) * parseInt(item.quantity),
      uom: item.uom,
      quantity: item.quantity,
      issue: 0,
    };
    newItems.push(newItem);
  });
  await pendingQuery.set({ ...data, items: newItems });

  console.log(newItems);
};
const updatePendingInvoice = async (data, voucherCopied, pendingQuery) => {
  console.log({ data, voucherCopied });
  let newItems = [];
  if (voucherCopied.type) {
    voucherCopied.items.map((item, index) => {
      const check = data.items.find((f) => f.id === item.id);
      if (check) {
        if (check.quantity < item.quantity) {
          let quantity = item.quantity - check.quantity;
          let newItem = {
            discount: 0,
            id: item.id,
            name: item.name,
            rate: parseFloat(item.rate),
            total: parseFloat(item.rate) * parseInt(item.quantity),
            uom: item.uom,
            quantity: item.quantity,
            issue: item.quantity - quantity,
          };
          newItems.push(newItem);
        } else {
          let newItem = {
            discount: 0,
            id: item.id,
            name: item.name,
            rate: parseFloat(item.rate),
            total: parseFloat(item.rate) * parseInt(item.quantity),
            uom: item.uom,
            quantity: item.quantity,
            issue: item.quantity,
          };
          newItems.push(newItem);
        }
      } else {
        newItems.push({ ...voucherCopied.items[index], issue: 0 });
      }
    });
    await pendingQuery.set({ ...voucherCopied, items: newItems });
  }

  console.log(newItems);
};
export const makeInvoiceId = (param) => {
  console.log(param);
};

export const editInvoice = (data, vNo) => {
  return async (dispatch, getState) => {
    dispatch({
      type: EDIT_INVOICE_REQUEST,
    });

    let serverDate;
    const { saveWithAprrove, saveWithCheck, stockWithCheck, stockWithApprove } =
      getState().global;
    const requiredInvoice = getState().invoice.invoices.find(
      (f) => f.invoiceId === vNo
    );
    if (data.date instanceof Date) {
      serverDate = firebase.firestore.Timestamp.fromDate(new Date(data.date));
    } else if (!(data.date instanceof Date) && data.date instanceof Object) {
      serverDate = data.date;
      console.log("instance of firebase", { serverDate });
    } else {
      serverDate = firebase.firestore.Timestamp.fromDate(new Date(data.date));
    }

    if (data.cvId) {
      await reverseCopiedVoucher(data);
    }
    if (data.items.length > 0) {
      const stock = getState().stock.stock;
      await firebase
        .firestore()
        .collection(`invoices${data.type}`)
        .doc(vNo)
        .set({
          ...data,
          date: serverDate,
        })
        .then(async () => {})
        .then(async () => {
          if (!stockWithApprove) {
            if (!stockWithCheck) {
              await removeStockData(requiredInvoice, stock);
            }
          }
        })
        .then(async () => {
          if (!stockWithApprove) {
            if (!stockWithCheck) {
              await addStockData(data, stock);
            }
          }
        })
        .then(async () => {
          if (
            data.type !== "Good Receipt Note" &&
            data.type !== "Delivery Challan"
          ) {
            if (!saveWithAprrove || !saveWithCheck) {
              if (!saveWithCheck) {
                await removeLedger(vNo);
              }
            }
          }
        })
        .then(async () => {
          if (
            data.type !== "Good Receipt Note" &&
            data.type !== "Delivery Challan"
          ) {
            if (!saveWithAprrove) {
              if (!saveWithCheck) {
                await addLedger(data);
              }
            }
          }
        })
        .then(() => {
          dispatch({
            type: EDIT_INVOICE_SUCCESS,
            payload: { invoice: { ...data, date: serverDate } },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch(cancelSaveInvoice("edit"));
    }
  };
};
export const getOldVoucher = async (id) => {
  const cvId = id;
  const splitCvId = cvId.split("-");
  const checkType = splitCvId[0];
  let collection;
  if (checkType === "gr") {
    collection = "PendingGood Receipt Note";
  }
  if (checkType === "dc") {
    collection = "PendingDelivery Challan";
  }
  if (checkType === "pu") {
    collection = "PendingPurchase";
  }
  if (checkType === "si") {
    collection = "PendingSell";
  }
  const voucher = await firebase
    .firestore()
    .collection(`${collection}`)
    .doc(id)
    .get();
  if (voucher.exists) {
    const voucherData = voucher.data();
    return voucherData;
  }
};
const reverseCopiedVoucher = async (data) => {
  const cvId = data.cvId;
  const splitCvId = cvId.split("-");
  const checkType = splitCvId[0];
  let collection;
  if (checkType === "gr") {
    collection = "PendingGood Receipt Note";
  }
  if (checkType === "dc") {
    collection = "PendingDelivery Challan";
  }
  if (checkType === "pu") {
    collection = "PendingPurchase";
  }
  if (checkType === "si") {
    collection = "PendingSell";
  }
  if (collection) {
    let query = firebase.firestore().collection(`${collection}`).doc(data.cvId);
    const getOldVoucher = await query.get();
    if (getOldVoucher.exists) {
      const oldData = getOldVoucher.data();
      let newItems = [];
      let items = oldData.items;
      items.map((item) => {
        let findItem = data.items.find((f) => f.id === item.id);
        if (
          findItem.quantity < item.quantity ||
          findItem.quantity === item.quantity
        ) {
          newItems.push({ ...item, issue: findItem.quantity });
        } else {
          newItems.push(item);
        }
      });
      console.log({ newItems });
      await query.update({ items: newItems });
    }
  }
};
export const loadInvoiceByType = (type) => {
  return async (dispatch) => {
    dispatch({
      type: LOAD_ALL_INVOICE_REQUEST,
    });
    console.log({ type: `invoices${type}` });
    const invoiceRef = await firebase
      .firestore()
      .collection(`invoices${type}`)
      .orderBy("date", "asc")
      .get();

    if (invoiceRef.size > 0) {
      const invoiceData = await invoiceRef.docs.map((doc) => {
        return {
          invoiceId: doc.id,
          ...doc.data(),
        };
      });

      if (invoiceData.length > 0) {
        dispatch({
          type: LOAD_ALL_INVOICE_SUCCESS,
          payload: { invoices: invoiceData },
        });
      }
    } else {
      dispatch({
        type: LOAD_ALL_INVOICE_FAILURE,
        payload: { error: "something is wrong" },
      });
    }
  };
};
export const loadDCAndGrn = async () => {
  let deliveryChallanRef,
    goodReceiptNoteRef,
    deliveryChallanData = [],
    goodReceiptNoteData = [];

  deliveryChallanRef = await firebase
    .firestore()
    .collection(`invoicesDelivery Challan`)
    .orderBy("date", "asc")
    .get();
  goodReceiptNoteRef = await firebase
    .firestore()
    .collection(`invoicesGood Receipt Note`)
    .orderBy("date", "asc")
    .get();

  await deliveryChallanRef.docs.map((doc) => {
    deliveryChallanData.push({
      invoiceId: doc.id,
      ...doc.data(),
    });
  });
  await goodReceiptNoteRef.docs.map((doc) => {
    goodReceiptNoteData.push({
      invoiceId: doc.id,
      ...doc.data(),
    });
  });
  const invoices = [...deliveryChallanData, ...goodReceiptNoteData];
  console.log({ invoices });
  return invoices;
};
export const loadPendingsGRDC = async () => {
  let deliveryChallanRef,
    goodReceiptNoteRef,
    deliveryChallanData = [],
    goodReceiptNoteData = [];

  deliveryChallanRef = await firebase
    .firestore()
    .collection(`PendingDelivery Challan`)
    .orderBy("date", "asc")
    .get();
  goodReceiptNoteRef = await firebase
    .firestore()
    .collection(`PendingGood Receipt Note`)
    .orderBy("date", "asc")
    .get();

  deliveryChallanRef.docs.map((doc) => {
    deliveryChallanData.push({
      invoiceId: doc.id,
      ...doc.data(),
    });
  });
  goodReceiptNoteRef.docs.map((doc) => {
    goodReceiptNoteData.push({
      invoiceId: doc.id,
      ...doc.data(),
    });
  });
  const invoices = [...deliveryChallanData, ...goodReceiptNoteData];

  return invoices;
};
export const loadPendingsInvoices = async () => {
  let invoiceSellRef,
    invoicePurchaseRef,
    deliveryChallanRef,
    goodReceiptNoteRef,
    purchaseOrderRef,
    quotationRef,
    clientOrderRef,
    invoiceData1 = [],
    invoiceData2 = [],
    deliveryChallanData = [],
    quotationData = [],
    goodReceiptNoteData = [],
    clientOrderData = [],
    purchaseOrderData = [];
  quotationRef = await firebase
    .firestore()
    .collection(`PendingQuotation`)
    .orderBy("date", "asc")
    .get();
  clientOrderRef = await firebase
    .firestore()
    .collection(`PendingClient Order`)
    .orderBy("date", "asc")
    .get();
  invoiceSellRef = await firebase
    .firestore()
    .collection(`PendingSell`)
    .orderBy("date", "asc")
    .get();
  purchaseOrderRef = await firebase
    .firestore()
    .collection(`PendingPurchase Order`)
    .orderBy("date", "asc")
    .get();
  invoicePurchaseRef = await firebase
    .firestore()
    .collection(`PendingPurchase`)
    .orderBy("date", "asc")
    .get();

  deliveryChallanRef = await firebase
    .firestore()
    .collection(`PendingDelivery Challan`)
    .orderBy("date", "asc")
    .get();
  goodReceiptNoteRef = await firebase
    .firestore()
    .collection(`PendingGood Receipt Note`)
    .orderBy("date", "asc")
    .get();
  quotationRef.docs.map((doc) => {
    quotationData.push({
      invoiceId: doc.id,
      ...doc.data(),
    });
  });
  clientOrderRef.docs.map((doc) => {
    clientOrderData.push({
      invoiceId: doc.id,
      ...doc.data(),
    });
  });
  purchaseOrderRef.docs.map((doc) => {
    purchaseOrderData.push({
      invoiceId: doc.id,
      ...doc.data(),
    });
  });
  invoiceSellRef.docs.map((doc) => {
    invoiceData1.push({
      invoiceId: doc.id,
      ...doc.data(),
    });
  });

  invoicePurchaseRef.docs.map((doc) => {
    invoiceData2.push({
      invoiceId: doc.id,
      ...doc.data(),
    });
  });

  deliveryChallanRef.docs.map((doc) => {
    deliveryChallanData.push({
      invoiceId: doc.id,
      ...doc.data(),
    });
  });
  goodReceiptNoteRef.docs.map((doc) => {
    goodReceiptNoteData.push({
      invoiceId: doc.id,
      ...doc.data(),
    });
  });
  const invoices = [
    ...invoiceData1,
    ...invoiceData2,
    ...deliveryChallanData,
    ...goodReceiptNoteData,
    ...purchaseOrderData,
    ...quotationData,
    ...clientOrderData,
  ];

  return invoices;
};
export const loadInvoiceAll = () => {
  return async (dispatch) => {
    dispatch({
      type: LOAD_ALL_INVOICE_REQUEST,
    });

    let invoiceSellRef,
      invoiceSellReturnRef,
      invoicePurchaseRef,
      invoicePurchaseReturnRef,
      deliveryChallanRef,
      goodReceiptNoteRef,
      clientOrderRef,
      purchaseOrderRef,
      quotationRef,
      invoiceData1 = [],
      invoiceData2 = [],
      invoiceData3 = [],
      invoiceData4 = [],
      purchaseOrderData = [],
      clientOrderData = [],
      deliveryChallanData = [],
      goodReceiptNoteData = [],
      quotationData = [];
    purchaseOrderRef = await firebase
      .firestore()
      .collection(`invoicesPurchase Order`)
      .orderBy("date", "asc")
      .get();
    quotationRef = await firebase
      .firestore()
      .collection(`invoicesQuotation`)
      .orderBy("date", "asc")
      .get();
    clientOrderRef = await firebase
      .firestore()
      .collection(`invoicesClient Order`)
      .orderBy("date", "asc")
      .get();
    invoiceSellRef = await firebase
      .firestore()
      .collection(`invoicesSell`)
      .orderBy("date", "asc")
      .get();
    invoicePurchaseRef = await firebase
      .firestore()
      .collection(`invoicesPurchase`)
      .orderBy("date", "asc")
      .get();
    invoicePurchaseReturnRef = await firebase
      .firestore()
      .collection(`invoicesPurchase Return`)
      .orderBy("date", "asc")
      .get();
    invoiceSellReturnRef = await firebase
      .firestore()
      .collection(`invoicesSell Return`)
      .orderBy("date", "asc")
      .get();
    deliveryChallanRef = await firebase
      .firestore()
      .collection(`invoicesDelivery Challan`)
      .orderBy("date", "asc")
      .get();
    goodReceiptNoteRef = await firebase
      .firestore()
      .collection(`invoicesGood Receipt Note`)
      .orderBy("date", "asc")
      .get();
    clientOrderRef.docs.map((doc) => {
      clientOrderData.push({
        invoiceId: doc.id,
        ...doc.data(),
      });
    });
    purchaseOrderRef.docs.map((doc) => {
      purchaseOrderData.push({
        invoiceId: doc.id,
        ...doc.data(),
      });
    });
    invoiceSellRef.docs.map((doc) => {
      invoiceData1.push({
        invoiceId: doc.id,
        ...doc.data(),
      });
    });
    invoiceSellReturnRef.docs.map((doc) => {
      invoiceData3.push({
        invoiceId: doc.id,
        ...doc.data(),
      });
    });
    invoicePurchaseRef.docs.map((doc) => {
      invoiceData2.push({
        invoiceId: doc.id,
        ...doc.data(),
      });
    });
    invoicePurchaseReturnRef.docs.map((doc) => {
      invoiceData4.push({
        invoiceId: doc.id,
        ...doc.data(),
      });
    });
    deliveryChallanRef.docs.map((doc) => {
      deliveryChallanData.push({
        invoiceId: doc.id,
        ...doc.data(),
      });
    });
    goodReceiptNoteRef.docs.map((doc) => {
      goodReceiptNoteData.push({
        invoiceId: doc.id,
        ...doc.data(),
      });
    });
    quotationRef.docs.map((doc) => {
      quotationData.push({
        invoiceId: doc.id,
        ...doc.data(),
      });
    });
    const invoices = [
      ...invoiceData1,
      ...invoiceData2,
      ...invoiceData3,
      ...invoiceData4,
      ...deliveryChallanData,
      ...goodReceiptNoteData,
      ...purchaseOrderData,
      ...clientOrderData,
      ...quotationData,
    ];

    dispatch({
      type: LOAD_ALL_INVOICE_SUCCESS,
      payload: { invoices: invoices },
    });
  };
};

export const cancelSaveInvoice = (param) => {
  return async (dispatch) => {
    if (param === "new") {
      dispatch({
        type: CANCEL_NEW_INVOICE_REQUEST,
      });
    } else {
      dispatch({
        type: CANCEL_EDIT_INVOICE_REQUEST,
      });
    }
  };
};
export const deleteInvoice = (data, vNo, type) => {
  return async (dispatch, getState) => {
    dispatch({
      type: DELETE_INVOICE_REQUEST,
    });
    const { saveWithAprrove, saveWithCheck, stockWithCheck, stockWithApprove } =
      getState().global;
    const stock = getState().stock.stock;
    console.log(`invoices${type}`);
    await firebase
      .firestore()
      .collection(`invoices${type}`)
      .doc(vNo)
      .delete()
      .then(async () => {
        if (!stockWithApprove) {
          if (!stockWithCheck) {
            await removeStockData(data, stock);
          }
        }
      })
      .then(async () => {
        if (!saveWithAprrove) {
          if (!saveWithCheck) {
            await addLedger(data);
          }
        }
      })
      .then(() => {
        dispatch({
          type: DELETE_INVOICE_SUCCESS,
          payload: { invoiceId: vNo },
        });
      })
      .catch((err) => {
        dispatch({
          type: DELETE_INVOICE_FAILURE,
          payload: { error: err },
        });
        console.log(err);
      });
  };
};
const removeLedger = async (vNo) => {
  const idsRef = await firebase
    .firestore()
    .collection("generalLedger")
    .where("invoiceId", "==", vNo)
    .get();
  idsRef.docs.map(async (doc, index) => {
    await firebase.firestore().collection("generalLedger").doc(doc.id).delete();
  });
};
export const addLedger = async (data) => {
  try {
    let debitAmount, creditAmount;
    if (data.type === "Sell" || data.type === "Purchase Return") {
      debitAmount = data.grand ? data.grand : 0;
      creditAmount = 0;
    } else if (data.type === "Purchase" || data.type === "Sell Return") {
      debitAmount = 0;
      creditAmount = data.grand ? data.grand : 0;
    }
  
    if (debitAmount || creditAmount) {
      let ledgerData = {
        date: data.date,
        party: data.party,
        accCredit: creditAmount,
        accDebit: debitAmount,
        narration: "",
        balance: "",
        ledgerId: data.ledgerId,
        invoiceId: data.invoiceId,
      };

      await firebase
        .firestore()
        .collection("generalLedger")
        .doc()
        .set(ledgerData)
        .then(() => {
          console.log({data:"enter",ledgerData})
          // dispatch({
          //     type: NEW_INVOICE_SUCCESS,
          // })
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  } catch (err) {
    console.log({ ledger: "error" + err });
  }
};
export const removeStockData = async (data) => {
  if (
    data.type === "Sell" ||
    data.type === "Purchase Return" ||
    data.type === "Delivery Challan"
  ) {
    data.items.map(async (item) => {
      const findRef = await firebase
        .firestore()
        .collection("StockReport")
        .doc(item.id)
        .get();
      if (findRef.exists) {
        const findDoc = findRef.data();
        await firebase
          .firestore()
          .collection("StockReport")
          .doc(item.id)
          .update({
            issuedQty: parseInt(findDoc.issuedQty) - parseInt(item.quantity),
          });
      }
    });
  } else if (
    data.type === "Purchase" ||
    data.type === "Sell Return" ||
    data.type === "Good Receipt Note"
  ) {
    data.items.map(async (item) => {
      const findRef = await firebase
        .firestore()
        .collection("StockReport")
        .doc(item.id)
        .get();
      if (findRef.exists) {
        const findDoc = findRef.data();
        await firebase
          .firestore()
          .collection("StockReport")
          .doc(item.id)
          .update({
            receivedQty:
              parseInt(findDoc.receivedQty) - parseInt(item.quantity),
          });
      }
    });
  }
};
export const addStockData = async (data) => {
  if (
    data.type === "Sell" ||
    data.type === "Purchase Return" ||
    data.type === "Delivery Challan"
  ) {
    data.items.map(async (item) => {
      await firebase
        .firestore()
        .collection("StockReport")
        .doc(item.id)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            const docData = doc.data();
            if (docData) {
              await firebase
                .firestore()
                .collection("StockReport")
                .doc(item.id)
                .update({
                  issuedQty: firebase.firestore.FieldValue.increment(
                    item.quantity
                  ),
                });
            }
          }
        });
    });
  } else if (
    data.type === "Purchase" ||
    data.type === "Sell Return" ||
    data.type === "Good Receipt Note"
  ) {
    data.items.map(async (item) => {
      await firebase
        .firestore()
        .collection("StockReport")
        .doc(item.id)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            const docData = doc.data();
            if (docData) {
              await firebase
                .firestore()
                .collection("StockReport")
                .doc(item.id)
                .update({
                  receivedQty: firebase.firestore.FieldValue.increment(
                    item.quantity
                  ),
                });
            }
          }
        });
    });
  }
};
export const approveInvoice = (id, type) => {
  return async (dispatch, getState) => {
    dispatch({
      type: LOAD_ALL_OP_REQUEST,
    });
    const {
      saveWithAprrove,
      stockWithApprove,
      updateStockWithGRN,
      updateStockWithDC,
      stockWithCheck,
    } = getState().global;
    const stock = getState().stock.stock;
    const query = firebase.firestore().collection(`invoices${type}`).doc(id);
    const dbRef = await query.get();
    if (dbRef.exists) {
      const data = dbRef.data();
      await query
        .update({ approve: !data.approve })
        .then(async () => {
          let newData = { ...data, approve: !data.approve };
          if (newData.approve) {
            if (saveWithAprrove) {
              await addLedger(newData);
            }
            if (updateStockWithGRN || updateStockWithDC) {
              if (data.type === "Good Receipt Note") {
                if (updateStockWithGRN) {
                  if (stockWithApprove) {
                    if (stockWithCheck) {
                      await addStockData(newData, stock);
                    }
                  }
                }
              }
              if (data.type === "Delivery Challan") {
                if (updateStockWithDC) {
                  if (stockWithApprove) {
                    if (stockWithCheck) {
                      await addStockData(newData, stock);
                    }
                  }
                }
              }
            } else {
              if (!updateStockWithGRN) {
                if (stockWithApprove) {
                  if (stockWithCheck) {
                    await addStockData(newData, stock);
                  }
                }
              }
            }
          } else {
            if (saveWithAprrove) {
              await removeLedger(newData.invoiceId);
            }
            if (updateStockWithGRN || updateStockWithDC) {
              if (data.type === "Good Receipt Note") {
                if (updateStockWithGRN) {
                  if (stockWithApprove) {
                    if (stockWithCheck) {
                      await removeStockData(newData, stock);
                    }
                  }
                }
              }
              if (data.type === "Delivery Challan") {
                if (updateStockWithDC) {
                  if (stockWithApprove) {
                    if (stockWithCheck) {
                      await removeStockData(newData, stock);
                    }
                  }
                }
              }
            } else {
              if (!updateStockWithGRN) {
                if (stockWithApprove) {
                  if (stockWithCheck) {
                    await removeStockData(newData, stock);
                  }
                }
              }
            }
          }
          dispatch({
            type: LOAD_ALL_OP_SUCCESS,
            payload: { invoice: newData },
          });
        })
        .catch((err) => {
          dispatch({
            type: LOAD_ALL_OP_FAILURE,
            payload: { error: err },
          });
        });
    }
  };
};
export const checkInvoice = (id, type) => {
  return async (dispatch, getState) => {
    dispatch({
      type: LOAD_ALL_OP_REQUEST,
    });
    const {
      saveWithAprrove,
      saveWithCheck,
      stockWithCheck,
      stockWithApprove,
      updateStockWithGRN,
      updateStockWithDC,
    } = getState().global;
    const stock = getState().stock.stock;
    const query = firebase.firestore().collection(`invoices${type}`).doc(id);
    const dbRef = await query.get();
    if (dbRef.exists) {
      const data = dbRef.data();
      await query
        .update({ check: !data.check })
        .then(async () => {
          let newData = { ...data, check: !data.check };
          if (newData.check) {
            if (!saveWithAprrove) {
              if (saveWithCheck) {
                addLedger(newData);
              }
            }
            if (updateStockWithGRN || updateStockWithDC) {
              if (data.type === "Good Receipt Note") {
                if (updateStockWithGRN) {
                  if (!stockWithApprove) {
                    if (stockWithCheck) {
                      addStockData(newData, stock);
                    }
                  }
                }
              }
              if (data.type === "Delivery Challan") {
                if (updateStockWithDC) {
                  if (!stockWithApprove) {
                    if (stockWithCheck) {
                      addStockData(newData, stock);
                    }
                  }
                }
              }
            } else {
              if (!updateStockWithGRN) {
                if (!stockWithApprove) {
                  if (stockWithCheck) {
                    addStockData(newData, stock);
                  }
                }
              }
            }
          } else {
            if (!saveWithAprrove) {
              if (saveWithCheck) {
                removeLedger(newData.invoiceId);
              }
            }
            if (updateStockWithGRN || updateStockWithDC) {
              if (data.type === "Good Receipt Note") {
                if (updateStockWithGRN) {
                  if (!stockWithApprove) {
                    if (stockWithCheck) {
                      removeStockData(newData, stock);
                    }
                  }
                }
              }
              if (data.type === "Delivery Challan") {
                if (updateStockWithDC) {
                  if (!stockWithApprove) {
                    if (stockWithCheck) {
                      removeStockData(newData, stock);
                    }
                  }
                }
              }
            } else {
              if (!updateStockWithGRN) {
                if (!stockWithApprove) {
                  if (stockWithCheck) {
                    removeStockData(newData, stock);
                  }
                }
              }
            }
          }
          dispatch({
            type: LOAD_ALL_OP_SUCCESS,
            payload: { invoice: newData },
          });
        })
        .catch((err) => {
          dispatch({
            type: LOAD_ALL_OP_FAILURE,
            payload: { error: err },
          });
        });
    }
  };
};
export const cancelInvoice = (id, reason, type) => {
  return async (dispatch) => {
    dispatch({
      type: LOAD_ALL_OP_REQUEST,
    });
    let filterDoc, status, error;
    if (id) {
      const query = firebase.firestore().collection(`invoices${type}`).doc(id);
      const dbRef = await query.get();
      if (dbRef.exists) {
        const data = dbRef.data();
        if (!data.cancel) {
          await query
            .update({ cancel: !data.cancel, cancelReason: reason })
            .then(() => {
              status = true;
            })
            .catch((err) => {
              error = err;
            });
          filterDoc = { ...data, cancel: !data.cancel, cancelReason: reason };
        } else {
          await query
            .update({ cancel: !data.cancel, cancelReason: reason })
            .then(() => {
              status = true;
            })
            .catch((err) => {
              error = err;
            });
          filterDoc = { ...data, cancel: !data.cancel, cancelReason: "" };
        }
        dispatch({
          type: LOAD_ALL_OP_SUCCESS,
          payload: { invoice: { ...filterDoc } },
        });
      } else {
        dispatch({
          type: LOAD_ALL_OP_FAILURE,
          payload: { error: error },
        });
      }
    }
  };
};

export const loadnext = (type) => {
  return async (dispatch, getState) => {
    dispatch({
      type: LOAD_NEXT_INVOICE,
      invoiceType: type,
    });
  };
};
export const loadprev = (type) => {
  return async (dispatch) => {
    dispatch({
      type: LOAD_PREV_INVOICE,
      invoiceType: type,
    });
  };
};
export const loadfirst = (type) => {
  return async (dispatch) => {
    console.log({ type }, "action");
    dispatch({
      type: LOAD_FIRST_INVOICE,
      payload: type,
    });
  };
};
export const loadlast = (type) => {
  return async (dispatch) => {
    dispatch({
      type: LOAD_LAST_INVOICE,
      invoiceType: type,
    });
  };
};
