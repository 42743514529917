import React from 'react'
import { Form, Table, FloatingLabel } from 'react-bootstrap';
import { BsSearch, } from 'react-icons/bs';
import { BiReset } from 'react-icons/bi';
const Level3Search = (props) => {
    return (
        <Table striped bordered hover style={{ textAlign: 'center' }}>

            <tbody>
                <tr>

                    <td>
                        <FloatingLabel label={"ID"}>
                            <Form.Control
                                name="ID"
                                type="text"
                                onChange={(e) => props.searchById(e.target.value)}
                                value={props.showId}
                                placeholder={"ID"}

                            />

                        </FloatingLabel>

                    </td>

                    <td>
                        <FloatingLabel label={"Title"}>
                            <Form.Control
                                name="Title"
                                type="text"
                                onChange={(e) => props.searchByTitle(e.target.value)}
                                value={props.showTitle}
                                placeholder={"Title"}
                            />

                        </FloatingLabel>

                    </td>
                    {
                        props.children
                    }
                    {
                        props.check !== 'openingQty' ?
                            <td>
                                <FloatingLabel label={"Search By Account Type"}>
                                    <Form.Control
                                        placeholder={"Search By Account Type"}

                                        as='select'
                                        onChange={(e) => props.searchByType(e.target.value)}>
                                        <option value="">Open this select menu</option>
                                        <option value="oe">Owner Equity</option>
                                        <option value="lb">Liabilities</option>
                                        <option value="ct">Customer</option>
                                        <option value="sp">Supplier</option>
                                        <option value="oa">Assets</option>
                                        <option value="in">Incomes</option>
                                        <option value="ex">Expenses</option>
                                    </Form.Control>

                                </FloatingLabel>

                            </td> : null
                    }



                    <td >
                        <BsSearch
                            size={32}
                            color='blue'
                            style={{ margin: '3px' }}
                            onClick={props.handleSearchInvoice}>

                        </BsSearch>
                        <BiReset
                            size={32}
                            color='red'
                            style={{ margin: '3px' }}
                            onClick={props.handleReset}></BiReset>
                    </td>



                </tr>
            </tbody>

        </Table>
    )
}

export default Level3Search
