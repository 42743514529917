import React from "react";

import Layout from "../components/Layout";
import HomeDetails from "../components/sidebar/HomeDetails";
import SideBarCom from "../components/sidebar/SideBarCom";

const Home = (props) => {
  return (
    <Layout>
      <div className="row ">
        <div
          className="col-md-2 p-0 m-0 g-0 "
          style={{ backgroundColor: "#00BFFF" }}
        >
          <SideBarCom />
        </div>
        <div
          className="col-md-10 p-0 m-0 g-0 "
          style={{ backgroundColor: "#E0E0E0" }}
        >
          <HomeDetails />
        </div>
      </div>
    </Layout>
  );
};

export default Home;
