import React, { useEffect, useState } from "react";
import SlipHeader from "../../components/SlipHeader.js/SlipHeader";
import { loadcompanyData } from "../../action/generalMethod";
import { useSelector } from "react-redux";
const PrintPaymentReceipt = (props) => {
  const [reportData, setReportData] = useState();
  const [companyData, setCompanyData] = useState({});
  const globalRed = useSelector((state) => state.global);
  const [showLogo, setShowLogo] = useState(false);
  useEffect(async () => {
    let data = localStorage.getItem("printpaymentreceipt")
      ? localStorage.getItem("printpaymentreceipt")
      : null;
    if (data) {
      const parseData = JSON.parse(data);
      setReportData(parseData);
      
    }
    const cData = await loadcompanyData();
    setCompanyData(cData);
  }, []);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  useEffect(async () => {
    if (reportData) {

      let showOrNot;

      if (globalRed.showLogo && globalRed.showLogo.length > 0) {
        showOrNot = globalRed.showLogo;
        console.log({ reportData });
        if (reportData.reportParam === "receipt") {
          setShowLogo(showOrNot[2].children[0].children[4].show);
        } else {
          setShowLogo(showOrNot[2].children[0].children[5].show);
          console.log({show:showOrNot[2].children[0].children[5].show})
        }
      }
      await delay(4000);
       window.print();
    }
  }, [reportData]);

  if (reportData) {
    const { customer, listName, rDate, report, show, reportParam } = reportData;
    const reportType =
      reportParam && show
        ? reportParam.toUpperCase() + "-" + show.toUpperCase() + "-REPORT"
        : "";
    let grand = 0;

    return (
      <div>
        <SlipHeader
          companyData={companyData}
          data={""}
          vNo={""}
          slipType={reportType}
          showLogo={showLogo}
        />

        <table className="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              {show === "details" && (
                <>
                  <th>Dated</th>
                  <th>VNo</th>
                </>
              )}

              <th>Account Title</th>
              {show === "details" && (
                <>
                  <th>Remarks</th>
                  <th>Ref No</th>
                  <th>Ref Date</th>
                </>
              )}

              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {report.length > 0 &&
              report.map((item) => {
                let total = 0;
                if (item.data && item.data.length > 0) {
                  return (
                    <>
                      <>
                        {show === "details" && (
                          <tr>
                            <td
                              colSpan={7}
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                            >
                              {item.title}
                            </td>
                          </tr>
                        )}

                        {item.data.map((d) => {
                          if (d.items && d.items.length > 0) {
                            return (
                              <>
                                {d.items.map((jvItems) => {
                                  if (
                                    parseFloat(jvItems.accDebit) > 0 ||
                                    parseFloat(jvItems.accCredit) > 0
                                  ) {
                                    if (reportParam === "payment") {
                                      total =
                                        total + parseFloat(jvItems.accDebit);
                                      grand =
                                        grand + parseFloat(jvItems.accDebit);
                                    } else {
                                      total =
                                        total + parseFloat(jvItems.accCredit);
                                      grand =
                                        grand + parseFloat(jvItems.accCredit);
                                    }
                                    let date = new Date(d.date.seconds * 1000)
                                      .toISOString()
                                      .slice(0, 10);
                                    if (show === "details") {
                                      return (
                                        <tr>
                                          <td>{date}</td>
                                          <td>{d.id}</td>
                                          <td>{jvItems.party}</td>
                                          <td>{jvItems.lineNarration}</td>
                                          <td>{jvItems.checkDate}</td>
                                          <td>{jvItems.checkNo}</td>

                                          <td className="thRight">
                                            {reportParam === "payment"
                                              ? jvItems.accDebit
                                              : jvItems.accCredit}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  }
                                })}
                              </>
                            );
                          }
                        })}
                      </>

                      <tr>
                        {show === "details" && (
                          <>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </>
                        )}
                        <td>{show !== "details" ? item.title : "Sub Total"}</td>
                        <td className="thRight">{total}</td>
                      </tr>
                    </>
                  );
                }
              })}
            <tr>
              {show === "details" && (
                <>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </>
              )}
              <td></td>
              <td className="thRight">{grand}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  } else {
    return <div>loading</div>;
  }
};

export default PrintPaymentReceipt;
