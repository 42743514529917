import firebase from "firebase";

export const LOAD_CUSTOMER_REQUEST = "LOAD_CUSTOMER_REQUEST";
export const LOAD_CUSTOMER_SUCCESS = "LOAD_CUSTOMER_SUCCESS";
export const LOAD_CUSTOMER_FAILURE = "LOAD_CUSTOMER_FAILURE";
export const ADD_CUSTOMER_REQUEST = "ADD_CUSTOMER_REQUEST";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAILURE = "ADD_CUSTOMER_FAILURE";
export const EDIT_CUSTOMER_REQUEST = "EDIT_CUSTOMER_REQUEST";
export const EDIT_CUSTOMER_SUCCESS = "EDIT_CUSTOMER_SUCCESS";
export const EDIT_CUSTOMER_FAILURE = "EDIT_CUSTOMER_FAILURE";

export const edit = (data) => {
  return async (dispatch) => {
    dispatch({
      type: EDIT_CUSTOMER_REQUEST,
    });
    let query;
    if (data.customerType === "sp") {
      query = firebase.firestore().collection("supplier");
    } else {
      query = firebase.firestore().collection("customer");
    }

    let docId = data.id;

    const customerData = {
      id: data.id,
      name: data.name,
      phoneNumber: data.phoneNumber,
      address: data.address,
      email: data.email,
      contactNumber: data.contactNumber,
      customerType: data.customerType,
      level3: data.level3,
    };
    await query
      .doc(docId)
      .set({ ...customerData })
      .then(() => {
        dispatch({
          type: EDIT_CUSTOMER_SUCCESS,
          payload: { customer: customerData },
        });
      })
      .catch((error) => {
        dispatch({
          type: EDIT_CUSTOMER_FAILURE,
          payload: { error: "ReferenceError" },
        });
      });
  };
};

export const loadCustomer = () => {
  return async (dispatch) => {
    dispatch({ type: LOAD_CUSTOMER_REQUEST });
    const customerRef = await firebase.firestore().collection("customer").get();
    const supplierRef = await firebase.firestore().collection("supplier").get();

    if (customerRef) {
      const customers = customerRef.docs.map((doc) => {
        const data = doc.data();
        return {
          docId: doc.id,
          ...data,
        };
      });
      const suppliers = supplierRef.docs.map((doc) => {
        const data = doc.data();
        return {
          docId: doc.id,
          ...data,
        };
      });
      dispatch({
        type: LOAD_CUSTOMER_SUCCESS,
        payload: { customers, suppliers },
      });
    } else {
      dispatch({
        type: LOAD_CUSTOMER_FAILURE,
        payload: { error: "data not found" },
      });
    }
  };
};
