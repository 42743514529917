import React from 'react'
import { Form } from 'react-bootstrap';
const Input2 = (props) => {
    return (
        <Form.Control
            style={props.style}
            name={props.name}
            type={props.type}
            onChange={props.onChange}
            value={props.value}
            min={0}
            required={props.required}
            disabled={props.disabled}
        />
    )
}

export default Input2
