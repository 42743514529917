import React, { useState } from "react";
import { Table } from "react-bootstrap";
import * as XLSX from "xlsx";
import firebase from "firebase";
const StockCoaMaker = () => {
  const [items, setItems] = useState([]);
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then(async (d) => {
      // let categoryList = [];
      // let typeList = [];
      // d.map(async (item, index) => {
      //   categoryList.push(item.Category);
      //   typeList.push(item.Type);
      // });
      // let unqiueCateories = [...new Set(categoryList)];
      // let unqiueType = [...new Set(typeList)];
      // console.log({ unqiueCateories, unqiueType });
      // makeCategoryCollection(unqiueType,"types");
      // await makeCategoryCollection(unqiueCateories, "categories");
      // await makeCategoryCollection(unqiueType, "types");
      // const makeCatRef = await firebase
      //   .firestore()
      //   .collection("categories")
      //   .get();
      // const makeTypeRef = await firebase.firestore().collection("types").get();
      // const categories = makeCatRef.docs.map((doc) => doc.data());
      // const types = makeTypeRef.docs.map((doc) => doc.data());
      // d.map(async (item, index) => {
      //   const findCatId = categories.find((f) => f.name === item.Category);
      //   const findTypeId = types.find((f) => f.name === item.Type);
      //   let id = index + 1;
      //   if (index.toString().length === 1) {
      //     id = `000${id}`;
      //   }
      //   if (index.toString().length === 2) {
      //     id = `00${id}`;
      //   }
      //   if (index.toString().length === 3) {
      //     id = `0${id}`;
      //   }
      //   if (index.toString().length === 4) {
      //     id = `${id}`;
      //   }
      //   console.log({ uom: item.Unit });
      //   await firebase
      //     .firestore()
      //     .collection("items")
      //     .doc()
      //     .set({
      //       id: `${id}`,
      //       name: item.ItemTitle,
      //       category: findCatId.name,
      //       categorySr: findCatId.id,
      //       typeSr: findTypeId.id,
      //       type: findTypeId.name,
      //       uom: item.Unit ? item.Unit : "",
      //     });
      // });
    });
  };

  const makeCategoryCollection = async (data, collection) => {
    let query = firebase.firestore().collection(collection);
    data.map(async (item, index) => {
      let id = index + 1;

      if (index.toString().length === 1) {
        id = `00${id}`;
      }
      if (index.toString().length === 2) {
        id = `0${id}`;
      }
      if (index.toString().length === 3) {
        id = `${id}`;
      }
      console.log({ id });
      await query.doc().set({ id, name: item });
    });
  };

  return (
    <div>
      <input
        type="file"
        onChange={(e) => {
          const file = e.target.files[0];
          readExcel(file);
        }}
      />
      <Table striped bordered hover responsive style={{ textAlign: "center" }}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Level No</th>
            <th>Type</th>
            <th>Category</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => {
            return (
              <>
                <tr>
                  <td>{item.ID}</td>
                  <td>{item.Title}</td>
                  <td>{item.LevelNo}</td>
                  <td>{item.Type}</td>
                  <td>{item.Category}</td>
                </tr>
                {item.children &&
                  item.children.length > 0 &&
                  item.children.map((child) => {
                    return (
                      <>
                        <tr>
                          <td>{child.ID}</td>
                          <td>{child.Title}</td>
                          <td>{child.LevelNo}</td>
                          <td>{child.Type}</td>
                          <td>{child.Category}</td>
                        </tr>
                        {child.children &&
                          child.children.length > 0 &&
                          child.children.map((grand) => {
                            return (
                              <tr>
                                <td>{grand.ID}</td>
                                <td>{grand.Title}</td>
                                <td>{grand.LevelNo}</td>
                                <td>{grand.Type}</td>
                                <td>{grand.Category}</td>
                              </tr>
                            );
                          })}
                      </>
                    );
                  })}
              </>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default StockCoaMaker;
