import {
    GET_TB_REQUEST,
    GET_TB_SUCCESS
} from "../action/trialBalance";

const initialState = {
    loading: false,
    levels: [],
    error: ''

};


const trialBalanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TB_REQUEST: {
            state = {
                ...state,
                loading: true
            }
        }
            break;
        case GET_TB_SUCCESS: {
            const data = action.payload.data;
            
            state = {
                ...state,
                levels: data,
                loading: false
            }
        }
            break;



    }
    return state;
};
export default trialBalanceReducer;
