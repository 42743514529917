import {
  SIGNUP_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
} from "../action/signup";
import {
  LOAD_ALL_USERS_FAILURE,
  LOAD_ALL_USERS_REQUEST,
  LOAD_ALL_USERS_SUCCESS,
  UPDATE_ALL_USERS_FAILURE,
  UPDATE_ALL_USERS_REQUEST,
  UPDATE_ALL_USERS_SUCCESS,
  UPDATE_USERS_FAILURE,
  UPDATE_USERS_REQUEST,
  UPDATE_USERS_SUCCESS,
} from "../action/superAdmin";

const initialState = {
  users: {},
  loading: false,
  error: "",
};

const superReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ALL_USERS_REQUEST:
      {
        state = {
          ...initialState,
        };
      }
      break;
    case LOAD_ALL_USERS_SUCCESS:
      {
        state = {
          loading: false,
          users: action.payload.data,
          error: "",
        };
      }
      break;
    case LOAD_ALL_USERS_FAILURE:
      {
        state = {
          users: {},
          loading: false,
          error: action.payload,
        };
      }
      break;
    case UPDATE_ALL_USERS_REQUEST:
      {
        state = {
          ...state,
          loading: true,
        };
      }
      break;
    case UPDATE_ALL_USERS_SUCCESS:
      {
        const docId = action.payload.docId;
        const right = action.payload.right;
        let updatedUsers = [];
        state.users.map((user) => {
          if (user.email === docId) {
            updatedUsers.push({ ...user, right });
          } else {
            updatedUsers.push(user);
          }
        });
        
        state = {
          loading: false,
          users: updatedUsers,
          error: "",
        };
      }
      break;
    case UPDATE_ALL_USERS_FAILURE:
      {
        state = {
          users: {},
          loading: false,
          error: action.payload,
        };
      }
      break;
    case UPDATE_USERS_REQUEST:
      {
        state = {
          ...state,
          loading: true,
        };
      }
      break;
    case UPDATE_USERS_SUCCESS:
      {
        const docId = action.payload.docId;
        const name = action.payload.name;
        const role = action.payload.role;
        let updatedUsers = [];
        state.users.map((user) => {
          if (user.email === docId) {
            updatedUsers.push({ ...user, name, role });
          } else {
            updatedUsers.push(user);
          }
        });

        state = {
          loading: false,
          users: updatedUsers,
          error: "",
        };
      }
      break;
    case UPDATE_USERS_FAILURE:
      {
        state = {
          users: {},
          loading: false,
          error: action.payload.error,
        };
      }
      break;
    case SIGNUP_REQUEST:
      {
        state = {
          ...state,
          loading: true,
          error: "",
        };
      }
      break;
    case SIGNUP_SUCCESS:
      {
        let updatedUsers = [...state.users];
        updatedUsers.push(action.payload);
        state = {
          loading: false,
          users: updatedUsers,
          error: "",
        };
      }
      break;
    case SIGNUP_FAILURE:
      {
        state = {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      }
      break;
  }

  return state;
};
export default superReducer;
