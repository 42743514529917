import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import ItemTab from "../../components/ItemCom/ItemTab";
import ItemListTable from "../../components/ItemCom/ItemListTable";

import ShowModal from "../../subcom/ShowModal";
import { useSelector, useDispatch } from "react-redux";
import * as itemActions from "../../action/itemAction";
import { Spinner, Tabs, Tab, Card, Button } from "react-bootstrap";
import EditItem from "../../components/ItemCom/ItemTab";
import CategoryTab from "../../components/ItemCom/CategoryTab";
import TypeTab from "../../components/ItemCom/TypeTab";
import { CgAdd } from "react-icons/cg";
import "../../Css/rowClass.css";

const AddItem = (props) => {
  const dispatch = useDispatch();
  const [showAddProduct, setShowAddProduct] = useState(false);

  const [showEditProduct, setShowEditProduct] = useState(false);
  const [showEditType, setShowEditType] = useState(false);
  const [showEditCategory, setShowEditCategory] = useState(false);
  const [editProduct, setEditProduct] = useState({});
  const [editValue, setEditValue] = useState();
  const [loading, setLoading] = useState(false);
  const others = useSelector((state) => state.catAndTypes);
  const [loadedCategories, setLoadedCategories] = useState([]);
  const [loadedType, setLoadedType] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const currentUserRed = useSelector((state) => state.user);
  const [defineItemRight, setDefineItemRight] = useState({});
  useEffect(() => {
    setDefineItemRight(props.rights);
  }, []);
  const AddItemShowHandle = () => {
    setShowAddProduct(true);
  };
  const closeAddProductModal = () => {
    setShowAddProduct(false);
  };
  const handleEditProduct = (item) => {
    setShowEditProduct(true);
    setEditProduct(item);
  };

  const handleDeleteProduct = (docId) => {
    dispatch(itemActions.DeleteItem(docId, "items"));
  };
  const itemRed = useSelector((state) => state.item);
  const [loadedItems, setLoadedItems] = useState([]);
  useEffect(() => {
    dispatch(itemActions.loadItems());
  }, []);
  useEffect(() => {
    setLoading(itemRed.loading);
    setLoadedItems(itemRed.item);
  }, [itemRed]);
  useEffect(() => {
    dispatch(itemActions.loadTypesCategories());
  }, []);
  useEffect(() => {
    setLoadedCategories(others.categories);
    setLoadedType(others.types);
  }, [others]);
  const handleDeleteCategory = (docId) => {
    dispatch(itemActions.deleteCategories(docId, "categories"));
  };
  const handleEditCategory = (item) => {
    setShowEditCategory(true);
    setEditValue(item);
  };
  const handleDeleteType = (docId) => {
    dispatch(itemActions.deleteType(docId, "types"));
  };
  const handleEditType = (item) => {
    setShowEditType(true);
    setEditValue(item);
  };
  const handleNewType = () => {
    setShowEditType(true);
  };
  const handleNewCategory = () => {
    if (defineItemRight.save) {
      setShowEditCategory(true);
    } else {
      alert("you do not have rights for current operation");
    }
  };
  const cancelAddProduct = () => {
    setShowEditProduct(false);
    setShowAddProduct(false);
  };
  return (
    <Layout>
      {showAddProduct && (
        <ShowModal show={showAddProduct}>
          <ItemTab
            closeAddProductModal={closeAddProductModal}
            cancelAddProduct={cancelAddProduct}
          />
        </ShowModal>
      )}
      {showEditProduct && (
        <ShowModal show={showEditProduct}>
          <EditItem
            cancelAddProduct={cancelAddProduct}
            closeAddProductModal={closeAddProductModal}
            dataForEdit={editProduct}
          />
        </ShowModal>
      )}

      {loading && (
        <ShowModal show={loading}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner animation="border" role="status" />
          </div>
        </ShowModal>
      )}
      {showEditCategory && (
        <ShowModal show={showEditCategory}>
          <CategoryTab
            right={defineItemRight}
            editValue={editValue}
            handleCatModal={() => {
              setEditValue();
              setShowEditCategory(false);
            }}
          />
        </ShowModal>
      )}
      {showEditType && (
        <ShowModal show={showEditType}>
          <TypeTab
            editValue={editValue}
            handleTypeModal={() => {
              setEditValue();
              setShowEditType(false);
            }}
            list={editValue ? null : loadedCategories}
          />
        </ShowModal>
      )}

      <div
        style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", marginTop: "20px" }}
      >
        <Card>
          <Tabs activeKey={activeTab} style={{ margin: "20px" }}>
            <Tab
              eventKey="addCategoryTab"
              title={
                <Button onClick={() => setActiveTab("addCategoryTab")}>
                  Category
                </Button>
              }
            >
              {activeTab === "addCategoryTab" && (
                <div>
                  <div className="rowClass">
                    <h3>Categories</h3>
                    <span onClick={handleNewCategory}>
                      <CgAdd size={32} color="red" />{" "}
                    </span>
                  </div>
                  {loadedCategories.length > 0 && (
                    <ItemListTable
                      right={defineItemRight}
                      loadedItems={loadedCategories}
                      check={"categories"}
                      handleDeleteProduct={handleDeleteCategory}
                      handleEditProduct={handleEditCategory}
                    />
                  )}
                </div>
              )}
            </Tab>
            <Tab
              eventKey="addTypeTab"
              title={
                <Button onClick={() => setActiveTab("addTypeTab")}>Type</Button>
              }
            >
              {activeTab === "addTypeTab" && (
                <div>
                  <div className="rowClass">
                    <h3>Type</h3>
                    <span onClick={handleNewType}>
                      <CgAdd size={32} color="red" />{" "}
                    </span>
                  </div>

                  {loadedType.length > 0 && (
                    <ItemListTable
                      right={defineItemRight}
                      loadedItems={loadedType}
                      check="types"
                      handleDeleteProduct={handleDeleteType}
                      handleEditProduct={handleEditType}
                    />
                  )}
                </div>
              )}
            </Tab>
            <Tab
              eventKey="addItemTab"
              title={
                <Button onClick={() => setActiveTab("addItemTab")}>Item</Button>
              }
            >
              {activeTab === "addItemTab" && (
                <div>
                  <div className="rowClass">
                    <h1>Add Item</h1>
                    <h1 onClick={AddItemShowHandle}>Add</h1>
                  </div>{" "}
                  <ItemListTable
                    right={defineItemRight}
                    handleDeleteProduct={handleDeleteProduct}
                    handleEditProduct={handleEditProduct}
                    loadedItems={loadedItems}
                    check={"item"}
                  />
                </div>
              )}
            </Tab>
          </Tabs>
        </Card>
      </div>
    </Layout>
  );
};

export default AddItem;
