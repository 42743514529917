const invoicesLogoList = [
  {
    id: "001",
    title: "Sell",
    show: true,
  },
  {
    id: "002",
    title: "Purchase",
    show: true,
  },
  {
    id: "003",
    title: "Sell Return",
    show: true,
  },
  {
    id: "004",
    title: "Purchase Return",
    show: true,
  },
  {
    id: "005",
    title: "Good Receipt Note",
    show: true,
  },
  {
    id: "006",
    title: "Delivery Challan",
    show: true,
  },
  {
    id: "007",
    title: "Purchase Order",
    show: true,
  },
  {
    id: "008",
    title: "Client Order",
    show: true,
  },
  {
    id: "008",
    title: "Client Order",
    show: true,
  },
  {
    id: "009",
    title: "Offer/Quotation",
    show: true,
  },
];
const VouchersLogoList = [
  {
    id: "001",
    title: "Cash Receipt",
    show: true,
  },
  {
    id: "002",
    title: "Bank Receipt",
    show: true,
  },
  {
    id: "003",
    title: "Cash Payment",
    show: true,
  },
  {
    id: "004",
    title: "Bank Payment",
    show: true,
  },
  {
    id: "005",
    title: "Journal Voucher",
    show: true,
  },
];
const dailyReportLogoList = [
  {
    id: "0001",
    title: "Chart of Account",
    show: true,
  },
  {
    id: "0002",
    title: "General Ladger",
    show: true,
  },
  {
    id: "0003",
    title: "General Ladger - Details",
    show: true,
  },
  {
    id: "0004",
    title: "Trial Balance",
    show: true,
  },
  {
    id: "0005",
    title: "Receipt Summay && Details",
    show: true,
  },
  {
    id: "0006",
    title: "Payment Summay && Details",
    show: true,
  },
  {
    id: "0007",
    title: "Post Dated Cheque",
    show: true,
  },
];
const stockReportLogoList = [
  {
    id: "0001",
    title: "Stock Report",
    show: true,
  },
  {
    id: "0002",
    title: "Stock Report With Value",
    show: true,
  },
  {
    id: "0003",
    title: "Item Ladger",
    show: true,
  },
  {
    id: "0004",
    title: "Item Ladger With Value",
    show: true,
  },
  {
    id: "0005",
    title: "Delivery Challan Details",
    show: true,
  },
  {
    id: "0006",
    title: "Good Receipt Note Details",
    show: true,
  },
  {
    id: "0007",
    title: "Delivery Challan Details",
    show: true,
  },
  {
    id: "0008",
    title: "Good Receipt Note Details",
    show: true,
  },
];
const ReportsAndStatementsLogoList = [
  {
    id: "001",
    title: "Daily Report",
    children: dailyReportLogoList,
  },
  {
    id: "002",
    title: "Stock Report",
    children: stockReportLogoList,
  },
  {
    id: "003",
    title: "Sale Report",
    show: true,
  },
  {
    id: "004",
    title: "Purchase Report",
    show: true,
  },
];

export const showLogoList = [
  {
    id: "1",
    title: "Invoices",
    children: invoicesLogoList,
  },
  {
    id: "2",
    title: "Vouchers",
    children: VouchersLogoList,
  },
  {
    id: "3",
    title: "Report and Statements",
    children: ReportsAndStatementsLogoList,
  },
];
export const locationList=[
  {docId:1,title:'Location 1'},
  {docId:2,title:'Location 2'},
  {docId:3,title:'Location 3'},
  {docId:4,title:'Location 4'},
  {docId:5,title:'Location 5'},
]
export const costCenterList=[
  {docId:1,title:'Cost 1'},
  {docId:2,title:'Cost 2'},
  {docId:3,title:'Cost 3'},
  {docId:4,title:'Cost 4'},
  {docId:5,title:'Cost 5'},
]
export const projectIDList=[
  {docId:1,title:'projectID 1'},
  {docId:2,title:'projectID 2'},
  {docId:3,title:'projectID 3'},
  {docId:4,title:'projectID 4'},
  {docId:5,title:'projectID 5'},
]