import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
const TrialBalanceTable = (props) => {
  const { levels, options, show, rDate } = props;
  let obCredit = 0,
    obDebit = 0,
    dbCredit = 0,
    dbDebit = 0,
    cbCredit = 0,
    cbDebit = 0;
  return (
    <Table striped bordered hover size='sm' style={{ textAlign: "center" ,fontSize:"14px"}}>
      <thead>
        <tr>
          <th>ID</th>
          <th>Title</th>
          {(show === "all" || show === "openingBalance") && (
            <th colSpan={2}>Opening Balance</th>
          )}
          {(show === "all" || show === "duringBalance") && (
            <th colSpan={2}>During The Period</th>
          )}
          {(show === "all" || show === "closingBalance") && (
            <th colSpan={2}>Closing Balance</th>
          )}
        </tr>
        <tr>
          <th></th>
          <th></th>
          {(show === "all" || show === "openingBalance") && (
            <>
              <th>Debit</th>
              <th>Credit</th>
            </>
          )}
          {(show === "all" || show === "duringBalance") && (
            <>
              <th>Debit</th>
              <th>Credit</th>
            </>
          )}
          {(show === "all" || show === "closingBalance") && (
            <>
              <th>Debit</th>
              <th>Credit</th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {levels.length > 0 &&
          levels.map((level) => {
            let obCredit1 = 0,
              obDebit1 = 0,
              dbCredit1 = 0,
              dbDebit1 = 0,
              cbCredit1 = 0,
              cbDebit1 = 0;
            return (
              <>
                {options.level1 && (
                  <tr>
                    <td>{level.id}</td>
                    <td>{level.title}</td>
                    <td colSpan={6}></td>
                  </tr>
                )}

                {level.children.length > 0 &&
                  level.children.map((level2) => {
                    let obCredit2 = 0,
                      obDebit2 = 0,
                      dbCredit2 = 0,
                      dbDebit2 = 0,
                      cbCredit2 = 0,
                      cbDebit2 = 0;
                    return (
                      <>
                        {options.level2 && (
                          <tr>
                            <td>{level2.id}</td>
                            <td>
                              <span style={{ marginLeft: "20px" }}>
                                {level2.title}
                              </span>
                            </td>
                            <td colSpan={6}></td>
                          </tr>
                        )}

                        {level2.children.length > 0 &&
                          level2.children.map((level3) => {
                            let leve3Closing = 0;
                            let closingCredit =
                              Math.abs(level3.obcredit) +
                              Math.abs(level3.credit);
                            let closingDebit =
                              Math.abs(level3.obdebit) + Math.abs(level3.debit);
                            leve3Closing = closingDebit - closingCredit;

                            obCredit2 = obCredit2 + Math.abs(level3.obcredit);
                            obDebit2 = obDebit2 + level3.obdebit;
                            dbCredit2 = dbCredit2 + Math.abs(level3.credit);
                            dbDebit2 = dbDebit2 + level3.debit;
                            cbCredit2 = cbCredit2 + closingCredit;
                            cbDebit2 = cbDebit2 + closingDebit;

                            obCredit1 = obCredit1 + Math.abs(level3.obcredit);
                            obDebit1 = obDebit1 + level3.obdebit;
                            dbCredit1 = dbCredit1 + Math.abs(level3.credit);
                            dbDebit1 = dbDebit1 + level3.debit;
                            cbCredit1 = cbCredit1 + closingCredit;
                            cbDebit1 = cbDebit1 + closingDebit;

                            obCredit = obCredit + Math.abs(level3.obcredit);
                            obDebit = obDebit + level3.obdebit;
                            dbCredit = dbCredit + Math.abs(level3.credit);
                            dbDebit = dbDebit + level3.debit;
                            cbCredit = cbCredit + closingCredit;
                            cbDebit = cbDebit + closingDebit;

                            if (options.level3) {
                              return (
                                <tr>
                                  <td>
                                    {level.id}
                                    {level2.id}
                                    {level3.id}
                                  </td>
                                  <td>
                                    {rDate &&
                                    rDate.start &&
                                    rDate.end &&
                                    !props.print ? (
                                      <Link
                                        to={`/ledger/${level3.docId}/${rDate.start}/${rDate.end}`}
                                      >
                                        <span style={{ marginLeft: "40px" }}>
                                          {level3.title}
                                        </span>
                                      </Link>
                                    ) : (
                                      level3.title
                                    )}
                                  </td>
                                  {(show === "all" ||
                                    show === "openingBalance") && (
                                    <>
                                      <td style={{ textAlign: "center" }}>
                                        {level3.obdebit}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {Math.abs(level3.obcredit)}
                                      </td>
                                    </>
                                  )}
                                  {(show === "all" ||
                                    show === "duringBalance") && (
                                    <>
                                      <td style={{ textAlign: "center" }}>
                                        {level3.debit}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {Math.abs(level3.credit)}
                                      </td>
                                    </>
                                  )}
                                  {(show === "all" ||
                                    show === "closingBalance") && (
                                    <>
                                      <td style={{ textAlign: "center" }}>
                                        {leve3Closing > 0 ? leve3Closing : 0}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {leve3Closing < 0
                                          ? Math.abs(leve3Closing)
                                          : 0}
                                      </td>
                                    </>
                                  )}
                                </tr>
                              );
                            }
                          })}
                        {options.level2 && (
                          <tr>
                            <th></th>
                            <th>{level2.title}</th>
                            {(show === "all" || show === "openingBalance") && (
                              <>
                                <th style={{ textAlign: "center" }}>
                                  {obDebit2}
                                </th>
                                <th style={{ textAlign: "center" }}>
                                  {obCredit2}
                                </th>
                              </>
                            )}
                            {(show === "all" || show === "duringBalance") && (
                              <>
                                <th style={{ textAlign: "center" }}>
                                  {dbDebit2}
                                </th>
                                <th style={{ textAlign: "center" }}>
                                  {dbCredit2}
                                </th>
                              </>
                            )}
                            {(show === "all" || show === "closingBalance") && (
                              <>
                                <th style={{ textAlign: "center" }}>
                                  {cbDebit2}
                                </th>
                                <th style={{ textAlign: "center" }}>
                                  {Math.abs(cbCredit2)}
                                </th>
                              </>
                            )}
                          </tr>
                        )}
                      </>
                    );
                  })}
                {options.level1 && (
                  <tr>
                    <th></th>
                    <th>{level.title}</th>
                    {(show === "all" || show === "openingBalance") && (
                      <>
                        <th style={{ textAlign: "center" }}>{obDebit1}</th>
                        <th style={{ textAlign: "center" }}>{obCredit1}</th>
                      </>
                    )}
                    {(show === "all" || show === "duringBalance") && (
                      <>
                        <th style={{ textAlign: "center" }}>{dbDebit1}</th>
                        <th style={{ textAlign: "center" }}>{dbCredit1}</th>
                      </>
                    )}
                    {(show === "all" || show === "closingBalance") && (
                      <>
                        <th style={{ textAlign: "center" }}>{cbDebit1}</th>
                        <th style={{ textAlign: "center" }}>
                          {Math.abs(cbCredit1)}
                        </th>
                      </>
                    )}
                  </tr>
                )}
              </>
            );
          })}
        <tr>
          <th></th>
          <th>Grand Total</th>
          {(show === "all" || show === "openingBalance") && (
            <>
              <th style={{ textAlign: "center" }}>{obDebit}</th>
              <th style={{ textAlign: "center" }}>{obCredit}</th>
            </>
          )}
          {(show === "all" || show === "duringBalance") && (
            <>
              <th style={{ textAlign: "center" }}>{dbDebit}</th>
              <th style={{ textAlign: "center" }}>{dbCredit}</th>
            </>
          )}
          {(show === "all" || show === "closingBalance") && (
            <>
              <th style={{ textAlign: "center" }}>{cbDebit}</th>
              <th style={{ textAlign: "center" }}>{Math.abs(cbCredit)}</th>
            </>
          )}
        </tr>
      </tbody>
    </Table>
  );
};

export default TrialBalanceTable;
