import React, { useEffect, useState } from "react";
import Input from "../subcom/Input";
import "../Css/generalStyle.css";

import { Button } from "react-bootstrap";
import { isUserLoggedIn, signin } from "../action/signup";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
const Login = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const userRed = useSelector((state) => state.user);
  useEffect(()=>{
    dispatch(isUserLoggedIn());
  },[]);
  const emailChangeHandler = (e) => {
    const val = e.target.value;
    setEmail(val);
  };
  const passwordChangeHandler = (e) => {
    const val = e.target.value;
    setPassword(val);
  };
  const signupHandler = async () => {
    if (email === "") {
      alert("email cannot be empty");
    }
    if (password === "") {
      alert("password cannot be empty");
    } else {
      dispatch(signin({ email, password }));
    }
  };
  if(userRed.auth){
    return <Redirect to={`/`} />
  }else{
    return (
      <div
      className="d-flex justify-content-center align-items-center flex-column"
        style={{ width: "100%", height: "100vh" }}
      >
        <div className="col-7">
          <Input
            label={"Email"}
            required={"required"}
            type={"email"}
            name={"email"}
            onChange={emailChangeHandler}
            value={email}
            disabled={""}
          />
          <Input
            label={"Password"}
            required={"required"}
            type={"password"}
            name={"email"}
            onChange={passwordChangeHandler}
            value={password}
            disabled={""}
          />
          <Button onClick={signupHandler} >
            Login
          </Button>
        </div>
      </div>
    );
  }
 
};

export default Login;
