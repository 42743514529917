import React, { useEffect, useState } from "react";
import { Table, Card, Form, Spinner, Button } from "react-bootstrap";
import DropdownForVoucherAdj from "../subcom/VoucherAdjDrop";

import { useSelector, useDispatch } from "react-redux";
import * as jvActions from "../action/jvAction";
import * as coaActions from "../action/coaAction";
import * as InActions from "../action/invoiceAction";
import Input2 from "../subcom/Input2";
import ShowModal from "../subcom/ShowModal";
import { CgRemove, CgAdd } from "react-icons/cg";

import ActionBar from "./general/ActionBar";
import InvoiceHeader from "./InvoiceHeader";
import InvoiceCancel from "./InvoiceCom/InvoiceCancel";
import ReverseActionBtn from "../subcom/ReverseActionBtn";
import CustomDropDown from "../subcom/CustomDropDown";
import JvBottom from "./jvcom/JvBottom";
import { useHistory } from "react-router-dom";
const JournalVoucher = (props) => {
  const history = useHistory();
  const { vNo, jvType, jvCreator, jvRights } = props;
  const { theme, color } = props;
  const dispatch = useDispatch();
  const [totalJV, setTotalJV] = useState({
    totalCredit: 0,
    totalDebit: 0,
  });
  const [narration, setNarration] = useState({ val: "", index: 0 });
  const coa = useSelector((state) => state.coa);
  const invoiceRed = useSelector((state) => state.invoice);
  const jvRed = useSelector((state) => state.jv);
  const [customerData, setCustomerData] = useState([]);
  const [listInvoices, setListInvoices] = useState([]);
  const [editJvOps, setEditJvOps] = useState({});
  const [jvOwner, setJvOwner] = useState("");
  const [jvHeader, setJvHeader] = useState({
    date: "",
    jvId: "",
    jvType: "",
    vNo: "",
  });
  const [header, setHeader] = useState({
    date: "",
    jvId: "",
    jvType: "",
    vNo: "",
  });
  const [editJv, setEditJv] = useState([
    {
      no: 0,
      party: "",
      voucherAdj: 0,
      accDebit: 0,
      accCredit: 0,
      lineNarration: "",
      ledgerId: "",
      checkNo: "",
      checkDate: "",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [newJv, setNewJv] = useState(false);

  const [showCancelReason, setShowCancelReason] = useState(false);
  const [deleteRow, setDeleteRow] = useState({ status: false, rowId: "" });
  const [delV, setDelV] = useState(false);

  useEffect(() => {
    document.title = "Journal Voucher";
    if (jvType) {
      dispatch(InActions.loadInvoiceAll());
      dispatch(coaActions.getAllLevel());
    }
  }, []);
  useEffect(() => {
    let level = [];
    coa.allLevels.map((level1) => {
      if (level1.children.length > 0) {
        level1.children.map((level2) => {
          if (level2.children.length > 0) {
            level2.children.map((level3) => {
              level.push(level3);
            });
          }
        });
      }
    });
    if (level.length > 0) {
      setCustomerData(level);
      setLoading(invoiceRed.loading);
      setListInvoices(invoiceRed.invoices);
    }
  }, [invoiceRed, coa]);

  useEffect(() => {
    let newTotalCredit = 0;
    let newTotalDebit = 0;
    editJv.map((item) => {
      newTotalCredit = parseInt(newTotalCredit) + parseInt(item.accCredit);
      newTotalDebit = parseInt(newTotalDebit) + parseInt(item.accDebit);
    });
    setTotalJV({ totalCredit: newTotalCredit, totalDebit: newTotalDebit });
  }, [editJv]);

  useEffect(() => {
    if (jvRed.save !== "") {
      setLoading(true);
    } else {
      setLoading(false);
    }
    let savedJv;
    if (jvRights.view) {
      savedJv = jvRed.jvList.find((f) => f.id === vNo);
    } else {
      const userName = jvCreator.name;
      if (userName) {
        savedJv = jvRed.jvList.find(
          (f) => f.id === vNo && f.jvOwner === userName
        );
      }
    }
    if (savedJv) {
      setEditJvOps(savedJv);
      setJvHeader({
        jvId: savedJv.id,
        date: savedJv.date,
        vNo: savedJv.vNo,
        jvType: jvType,
      });
      setJvOwner(savedJv.jvOwner);
      setEditJv(savedJv.jvItems);
    } else {
      setEdit(true);
      setNewJv(true);
      let lastId, iType, srId;
      if (jvType === "Cash Payment") {
        iType = "cp";
      } else if (jvType === "Bank Payment") {
        iType = "bp";
      } else if (jvType === "Cash Receipt") {
        iType = "cr";
      } else if (jvType === "Bank Receipt") {
        iType = "br";
      } else if (jvType === "Journal Voucher") {
        iType = "jv";
      }
      const d = new Date();

      const month = d.getMonth() + 1;
      const year = d.getFullYear().toString().slice(2);
      if (jvRed.jvList.length > 0) {
        const totalChild = jvRed.jvList.filter((f) => {
          if (f.id.includes(`${iType}-${month}-${year}`)) {
            return f;
          }
        });
        if (totalChild.length > 0) {
          const sortedArray = totalChild.sort((a, b) => {
            return b.vNo - a.vNo;
          });
          lastId = parseInt(sortedArray[0].vNo);
          lastId = lastId + 1;
          srId = lastId;
        } else {
          lastId = 1;
          srId = 1;
        }
      } else {
        lastId = 1;
        srId = 1;
      }

      const p1 = iType + "-" + month + "-" + year + "-" + lastId;
      setJvHeader({ date: d, jvId: p1, jvType: jvType, vNo: srId });
    }
  }, [jvRed, vNo]);
  useEffect(() => {
    if (header.jvId !== "") {
      setJvHeader({
        jvId: header.jvId,
        jvType: header.jvType,
        vNo: header.vNo,
        date: header.date,
      });
    }
  }, [header]);
  const changeDateHandler = (date) => {
    let lastId, iType, srId;
    if (vNo) {
      const checkDate = new Date(date);
      const getMonth = checkDate.getMonth() + 1;
      const vNoSplit = vNo.toString().split("-");
      if (vNoSplit[1] === getMonth.toString()) {
        setHeader({
          date: checkDate,
          jvId: vNo,
          jvType: jvType,
          vNo: vNoSplit[3],
        });
      }
    } else {
      if (edit === true && newJv === true) {
        if (jvType === "Cash Payment") {
          iType = "cp";
        } else if (jvType === "Bank Payment") {
          iType = "bp";
        } else if (jvType === "Cash Receipt") {
          iType = "cr";
        } else if (jvType === "Bank Receipt") {
          iType = "br";
        } else if (jvType === "Journal Voucher") {
          iType = "jv";
        }
        const d = new Date(date);
        const month = d.getMonth() + 1;
        const year = d.getFullYear().toString().slice(2);
        if (jvRed.jvList.length > 0) {
          const totalChild = jvRed.jvList.filter((f) => {
            if (f.id.includes(`${iType}-${month}-${year}`)) {
              return f;
            }
          });
          if (totalChild.length > 0) {
            const sortedArray = totalChild.sort((a, b) => {
              return b.vNo - a.vNo;
            });
            lastId = parseInt(sortedArray[0].vNo);
            lastId = lastId + 1;
            srId = lastId;
          } else {
            lastId = 1;
            srId = 1;
          }
        } else {
          lastId = 1;
          srId = 1;
        }

        const p1 = iType + "-" + month + "-" + year + "-" + lastId;
        setHeader({ date: d, jvId: p1, jvType: jvType, vNo: srId });
      }
    }
  };

  const clearInputData = () => {
    setNewJv(true);
    setEdit(true);
    setEditJv([
      {
        no: 0,
        party: "",
        voucherAdj: 0,
        accDebit: 0,
        accCredit: 0,
        lineNarration: "",
        ledgerId: "",
        checkNo: "",
        checkDate: "",
      },
    ]);
  };
  const handlerAccDebit = (e, index) => {
    let list = [...editJv];
    let val = e.target.value;
    let debitVal;
    if (val === "") {
      debitVal = 0;
    } else {
      debitVal = parseInt(val);
    }

    let fAmount = findAmount("credit", debitVal, index);
    console.log({fAmount})
    if (list[index].party !== "") {
      list[index] = {
        party: list[index].party,
        accCredit: list[index].accCredit,
        accDebit: debitVal,
        voucherAdj: list[index].voucherAdj,
        lineNarration: list[index].lineNarration,
        ledgerId: list[index].ledgerId,
        no: list[index].no,
        checkNo: fAmount.checkNo===''?list[index].checkNo: fAmount.checkNo,
        checkDate: fAmount.checkDate===''?list[index].checkDate:fAmount.checkDate,
      };
      setEditJv(list);
    } else {
      alert("please select account first");
    }
  };
  const handlerAccCredit = (e, index) => {
    let list = [...editJv];
    let val = e.target.value;
    let debitVal;
    if (val === "") {
      debitVal = 0;
    } else {
      debitVal = parseInt(val);
    }
    let fAmount = findAmount("debit", debitVal, index);
    if (list[index].party !== "") {
      list[index] = {
        party: list[index].party,
        accCredit: debitVal,
        accDebit: list[index].accDebit,
        voucherAdj: list[index].voucherAdj,
        lineNarration: list[index].lineNarration,
        ledgerId: list[index].ledgerId,
        no: list[index].no,
        checkNo: fAmount.checkNo,
        checkDate: fAmount.checkDate,
      };
      setEditJv(list);
    } else {
      alert("please select account first");
    }
  };
  const findAmount = (type, val, index) => {
    let list = [...editJv];

    let findRow;
    if (type === "debit") {
      let findcredit = list.find((f) => f.accCredit === val);
      if (!findcredit) {
        findRow = list.find((f) => f.accDebit === val);
      }
    } else {
      let findDebit = list.find((f) => f.accDebit === val);
      if (!findDebit) {
        findRow = list.find((f) => f.accCredit === val);
      }
    }
    let checkNo, checkDate;
    if (findRow) {
      checkNo = findRow.checkNo;
      checkDate = findRow.checkDate;
    } else {
      if (vNo) {
        checkNo = list[index].checkNo ? list[index].checkNo : "";
        checkDate = list[index].checkDate ? list[index].checkDate : "";
      } else {
        checkNo = "";
        checkDate = "";
      }
    }

    return { checkNo, checkDate };
  };
  const checkNoChangeHandler = (e, index) => {
    let list = [...editJv];
    let val = e.target.value;
    let checkNoVal;
    if (val === "") {
      checkNoVal = 0;
    } else {
      checkNoVal = val;
    }
    list[index] = {
      party: list[index].party,
      accCredit: list[index].accCredit,
      accDebit: list[index].accDebit,
      voucherAdj: list[index].voucherAdj,
      lineNarration: list[index].lineNarration,
      ledgerId: list[index].ledgerId,
      no: list[index].no,
      checkNo: checkNoVal,
      checkDate: list[index].checkDate,
    };
    setEditJv(list);
  };
  const checkDateChangeHandler = (e, index) => {
    let list = [...editJv];
    let val = e.target.value;
    let dateVal;
    if (val === "") {
      dateVal = 0;
    } else {
      dateVal = val;
    }
    list[index] = {
      party: list[index].party,
      accCredit: list[index].accCredit,
      accDebit: list[index].accDebit,
      voucherAdj: list[index].voucherAdj,
      lineNarration: list[index].lineNarration,
      ledgerId: list[index].ledgerId,
      no: list[index].no,
      checkNo: list[index].checkNo,
      checkDate: dateVal,
    };
    setEditJv(list);
  };
  const handlerVoucherAdj = (data, index) => {
    let list = [...editJv];
    let narration = `cash received Against ${data.invoiceId} `;
    if (list[index].party !== "") {
      if (jvType === "Cash Receipt" || jvType === "Bank Receipt") {
        list[index] = {
          no: list[index].no,
          party: list[index].party,
          voucherAdj: data.invoiceId,
          accCredit: data.grand,
          accDebit: 0,
          lineNarration: narration,
          ledgerId: list[index].ledgerId,
          checkNo: list[index].checkNo ? list[index].checkNo : "",
          checkDate: list[index].checkDate ? list[index].checkDate : "",
        };
      } else {
        list[index] = {
          no: list[index].no,
          party: list[index].party,
          voucherAdj: data.invoiceId,
          accCredit: 0,
          accDebit: data.grand,
          lineNarration: narration,
          ledgerId: list[index].ledgerId,
          checkNo: list[index].checkNo ? list[index].checkNo : "",
          checkDate: list[index].checkDate ? list[index].checkDate : "",
        };
      }
      setNarration({ index: index, val: narration });
      setEditJv(list);
    } else {
      alert("please select account first");
    }
  };
  const selectedItemData = (id, index) => {
    const getCustomer = customerData.find((f) => f.docId === id);
    let list = [...editJv];
    list[index] = {
      party: getCustomer.title,
      ledgerId: getCustomer.docId,
      accCredit: list[index].accCredit,
      accDebit: list[index].accDebit,
      voucherAdj: list[index].voucherAdj,
      lineNarration: list[index].lineNarration,
      no: list[index].no,
      checkNo: list[index].checkNo,
      checkDate: list[index].checkDate,
    };
    setEditJv(list);
  };
  const onDecrease = (index) => {
    var myArray = [...editJv];
    myArray = myArray.filter(function (obj, alt) {
      return alt !== index;
    });
    setEditJv(myArray);

    setDeleteRow({ status: false, rowId: "" });
  };
  const onIncrease = () => {
    const newNo = editJv.length;
    const newItem = {
      no: newNo,
      party: "",
      voucherAdj: "",
      accDebit: 0,
      accCredit: 0,
      lineNarration: "",
      ledgerId: "",
      checkNo: "",
      checkDate: "",
    };
    setEditJv([...editJv, newItem]);
    setNarration({ val: "", index: newNo });
  };
  const handlerLineNarration = (e) => {
    let val = e.target.value;
    let list = [...editJv];
    list[narration.index] = {
      no: list[narration.index].no,
      party: list[narration.index].party,
      voucherAdj: list[narration.index].voucherAdj,
      accDebit: list[narration.index].accDebit,
      accCredit: list[narration.index].accCredit,
      lineNarration: val,
      ledgerId: list[narration.index].ledgerId,
      checkNo: list[narration.index].checkNo,
      checkDate: list[narration.index].checkDate,
    };
    setNarration({ val: val, index: narration.index });
    setEditJv(list);
  };
  const generalHandler = (index) => {
    let list = [...editJv];
    let val = list[index].lineNarration;
    setNarration({ val: val, index: index });
  };
  const saveVoucher = () => {
    if (totalJV.totalDebit !== totalJV.totalCredit) {
      alert("total debit and credit are not equal");
    } else {
      if (newJv) {
        if (jvRights.save) {
          dispatch(
            jvActions.addJournalVoucher(
              { jv: editJv, jvHeader, totalJV },
              jvCreator
            )
          );
          moveToInvoice();
        } else {
          alert("you do not have enough rights to save new Invoice");
        }
      } else {
        if (jvRights.edit) {
          dispatch(jvActions.EditJournalVoucher(editJv, jvHeader, vNo));
          moveToInvoice();
        } else {
          alert("you do not have enough rights to edit Invoice");
        }
      }
    }
  };
  const moveToInvoice = () => {
    history.push({
      pathname: `/jvpage/${jvType}/${jvHeader.jvId}`,
      state: {
        response: "",
      },
    });
    setEdit(false);
    setNewJv(false);
  };
  const cancelVoucher = (op) => {
    let message = `can not be cancel\n`;

    if (editJvOps && !editJvOps.check && !editJvOps.approve) {
      setShowCancelReason(true);
    } else {
      if (editJvOps.check) {
        message = message + "Invoice is  checked\n";
      }
      if (editJvOps.approve) {
        message = message + "Invoice is approve ";
      }
      alert(message);
    }
  };
  const approveVoucher = () => {
    let message = "can not approve inovice\n";
    if (editJvOps && editJvOps.check && !editJvOps.cancel) {
      dispatch(jvActions.approveJv(vNo, jvType));
    } else {
      if (!editJvOps.check) {
        message = message + "Voucher is not checked";
      }
      if (editJvOps.cancel) {
        message = message + "Voucher is is canceld ";
      }
      alert(message);
    }
  };
  const checkVoucher = () => {
    let message = "can not checked Voucher because\n";
    if (editJvOps && !editJvOps.approve && !editJvOps.cancel) {
      dispatch(jvActions.checkJv(vNo, jvType));
    } else {
      if (editJvOps.approve) {
        message = message + "Voucher is approved";
      }
      if (editJvOps.cancel) {
        message = message + "Voucher is canceld ";
      }
      alert(message);
    }
  };
  const deleteVoucher = () => {
    setDelV(true);
  };
  const handleDecreaseRowPermission = (id) => {
    setDeleteRow({ status: true, rowId: id });
  };
  const VoucherOperationForCancel = (reason) => {
    dispatch(jvActions.cancelJv(vNo, reason, jvType));

    setShowCancelReason(false);
  };

  return (
    <div>
      {showCancelReason && (
        <ShowModal show={showCancelReason} size={"sm"}>
          <InvoiceCancel
            cancel={editJvOps.cancel}
            check={editJvOps.check}
            cancelReasonModal={() => setShowCancelReason(false)}
            saveReasonModal={VoucherOperationForCancel}
          />
        </ShowModal>
      )}
      {loading && (
        <ShowModal show={loading} onHide={() => setLoading(false)}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner animation="border" role="status" />
          </div>
        </ShowModal>
      )}
      {deleteRow.status && (
        <ShowModal show={deleteRow.status} size={"sm"}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "12px",
            }}
          >
            <p style={{ fontSize: "20px", fontWeight: "bold", color: "red" }}>
              Delete this Row
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => onDecrease(deleteRow.rowId)}
                style={{ margin: "10px" }}
                variant="danger"
              >
                YES
              </Button>
              <Button
                onClick={() => setDeleteRow({ status: false, rowId: "" })}
                style={{ margin: "10px" }}
                variant="secondary"
              >
                NO
              </Button>
            </div>
          </div>
        </ShowModal>
      )}
      {delV ? (
        <ShowModal show={delV} size={"sm"}>
          <ReverseActionBtn
            link={`/jvpage/${jvType}`}
            message="Delete This Voucher"
            okAction={() => {
              dispatch(jvActions.deleteJournalVoucher(vNo, jvType));
            }}
            cancelAction={() => {
              setDelV(false);
            }}
          />
        </ShowModal>
      ) : null}
      <InvoiceHeader
        vNo={jvHeader.jvId}
        date={jvHeader.date}
        vType={jvType}
        changeDate={changeDateHandler}
        edit={edit}
        editInvoice={vNo}
      />
      <ActionBar
        vNo={jvHeader.jvId}
        handleCheckVoucher={checkVoucher}
        handleApproveVoucher={approveVoucher}
        handleCancelVoucher={cancelVoucher}
        handleSaveVoucher={saveVoucher}
        deleteVoucher={deleteVoucher}
        handleChangeVoucher={(option) => console.log(option)}
        edit={true}
        editVoucher={() => setEdit(!edit)}
        voucherType={jvType}
        check="jvpage"
        date={jvHeader.date}
        invoiceRights={jvRights}
      />

      {jvHeader.jvId !== "" ? (
        <>
          <Table
            striped
            bordered
            hover size="sm"
            style={{
              backgroundColor: theme === "dark" ? "black" : "white",
              color: color === "white" ? "white" : "black",
              fontSize:'14px'
            }}
          >
            <thead>
              <tr>
                <th style={{ width: "50px" }}>#</th>
                <th >Particular</th>
                <th style={{ width: "80px" }}>Adj Voucher</th>
                {jvType === "Bank Payment" || jvType === "Bank Receipt" ? (
                  <>
                    <th style={{ width: "120px" }}>Check No</th>
                    <th style={{ width: "120px" }}>Check date</th>
                  </>
                ) : null}
                <th style={{ width: "120px" }}>Dr. Amount</th>
                <th style={{ width: "120px" }}>Cr. Amount</th>
                {edit && <th style={{ width: "80px" }}>Operation</th>}
              </tr>
            </thead>
            <tbody>
              {editJv &&
                editJv.map((item, index) => (
                  <tr
                    key={index}
                    style={
                      narration.index === index
                        ? {
                            backgroundColor:
                              theme === "dark" ? "white" : "black",
                            color: color === "white" ? "black" : "white",
                          }
                        : {
                            backgroundColor:
                              theme === "dark" ? "black" : "white",
                            color: color,
                          }
                    }
                  >
                    <td onClick={() => generalHandler(index)}>{index}</td>
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto",
                       
                      }}
                    >
                      {!edit ? (
                        item.party
                      ) : (
                        <div style={{width:"100%"}}>
                          {customerData.length > 0 && (
                            <CustomDropDown
                              selectedItem={(e) => selectedItemData(e, index)}
                              label={item.party}
                              list={customerData}
                            />
                          )}
                        </div>
                      )}
                    </td>
                    <td>
                      {!edit ? (
                        <span>
                          {item.voucherAdj !== "0" && item.voucherAdj}
                        </span>
                      ) : (
                        <div>
                          {listInvoices.length > 0 && (
                            <DropdownForVoucherAdj
                              listInvoices={listInvoices}
                              selectedParty={item.voucherAdj}
                              handlerVoucherAdj={(invoice) =>
                                handlerVoucherAdj(invoice, index)
                              }
                              value={item.voucherAdj}
                              ledgerId={item.ledgerId}
                            />
                          )}
                        </div>
                      )}
                    </td>
                    {jvType === "Bank Payment" || jvType === "Bank Receipt" ? (
                      <>
                        <td>
                          {!edit ? (
                            <span>{item.checkNo !== 0 && item.checkNo}</span>
                          ) : (
                            <Input2
                              style={{ textAlign: "center" }}
                              type={"text"}
                              onChange={(e) => checkNoChangeHandler(e, index)}
                              value={item.checkNo}
                            />
                          )}
                        </td>
                        <td>
                          {!edit ? (
                            <span>
                              {item.checkDate !== 0 && item.checkDate}
                            </span>
                          ) : (
                            <Input2
                              style={{ textAlign: "center" }}
                              type={"date"}
                              onChange={(e) => checkDateChangeHandler(e, index)}
                              value={item.checkDate}
                            />
                          )}
                        </td>
                      </>
                    ) : null}
                    <td  style={{ textAlign: "center" }}>
                      {!edit ? (
                        <span>{item.accDebit !== 0 && item.accDebit}</span>
                      ) : (
                        <Input2
                          style={{ textAlign: "center" }}
                          name={"accDebit"}
                          type={"number"}
                          onChange={(e) => handlerAccDebit(e, index)}
                          value={item.accDebit > 0 ? item.accDebit : ""}
                          disabled={item.accCredit !== 0 ? true : false}
                        />
                      )}
                    </td>

                    <td  style={{ textAlign: "center" }}>
                      {!edit ? (
                        <span>{item.accCredit !== 0 && item.accCredit}</span>
                      ) : (
                        <Input2
                          style={{ textAlign: "center" }}
                          name={"accCredit"}
                          type={"number"}
                          onChange={(e) => handlerAccCredit(e, index)}
                          value={item.accCredit > 0 ? item.accCredit : ""}
                          disabled={item.accDebit !== 0 ? true : false}
                        />
                      )}
                    </td>

                    {edit && (
                      <td>
                        {index !== 0 && (
                          <CgRemove
                            size={32}
                            color="red"
                            onClick={(e) => handleDecreaseRowPermission(index)}
                          />
                        )}
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
          {edit && <CgAdd size={32} color="red" onClick={onIncrease} />}

          <Card>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                color: color,
              }}
            >
              <h6
                style={{
                  border: "1px solid #eee",
                  padding: "20px",
                  margin: "3px",
                  backgroundColor: theme === "dark" ? "black" : "white",
                  color: color,
                }}
              >
                Line Narration
              </h6>
              <Form.Control
                type="textarea"
                style={{
                  width: "95%",
                  height: "auto",
                  padding: "20px",
                  margin: "3px",
                }}
                value={narration.val}
                disabled={edit ? false : true}
                onChange={handlerLineNarration}
              />
            </div>
          </Card>
          <JvBottom totalJV={totalJV} jvCreator={jvCreator} jvOwner={jvOwner} />
        </>
      ) : null}
    </div>
  );
};

export default JournalVoucher;
