import {
  LOAD_ALL_INVOICE_REQUEST,
  LOAD_ALL_INVOICE_SUCCESS,
  LOAD_ALL_INVOICE_FAILURE,
  EDIT_INVOICE_REQUEST,
  EDIT_INVOICE_SUCCESS,
  EDIT_INVOICE_FAILURE,

  NEW_INVOICE_REQUEST,
  NEW_INVOICE_SUCCESS,
  NEW_INVOICE_FAILURE,
  CANCEL_EDIT_INVOICE_REQUEST,
  CANCEL_NEW_INVOICE_REQUEST,
  LOAD_ALL_OP_REQUEST,
  LOAD_ALL_OP_SUCCESS,
  LOAD_ALL_OP_FAILURE,
  LOAD_FIRST_INVOICE,
  LOAD_LAST_INVOICE,
  LOAD_NEXT_INVOICE,
  LOAD_PREV_INVOICE,
  DELETE_INVOICE_REQUEST,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_FAILURE,
} from "../action/invoiceAction";
const initialState = {
  save: "",
  loading: false,
  invoices: [],
  error: "",
  next: 1,
  prev: 1,

};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ALL_OP_REQUEST:
      {
        state = {
          ...state,
          loading: true,
        };
      }
      break;
    case LOAD_ALL_OP_SUCCESS:
      {
        const invoices = state.invoices;
        const updatedInvoice = action.payload.invoice;
        let data = [];
        invoices.map((item) => {
          if (updatedInvoice.invoiceId !== item.invoiceId) {
            data.push(item);
          } else {
            data.push(updatedInvoice);
          }
        });
        state = {
          ...state,
          loading: false,
          invoices: data,
         
        };
      }
      break;
    case LOAD_ALL_OP_FAILURE:
      {
        state = {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      }
      break;
    case LOAD_ALL_INVOICE_REQUEST:
      {
        state = {
          ...state,
          loading: true,
          invoices: [],
        };
      }
      break;
    case LOAD_ALL_INVOICE_SUCCESS:
      {
        const invoices = action.payload.invoices;

        state = {
          ...state,
          loading: false,
          invoices: invoices,
          options: action.payload.options,
         
         
          next: 1,
          prev: 1,
        };
      }
      break;
    case LOAD_ALL_INVOICE_FAILURE:
      {
        state = {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      }
      break;
    case EDIT_INVOICE_REQUEST:
      {
        state = {
          ...state,
          save: "edit",
        };
      }
      break;
    case CANCEL_EDIT_INVOICE_REQUEST:
      {
        state = {
          ...state,
          save: "",
        };
      }
      break;
    case EDIT_INVOICE_SUCCESS:
      {
        const invoices = state.invoices;
        const updatedInvoice = action.payload.invoice;
        let data = [];
        invoices.map((item) => {
          if (updatedInvoice.invoiceId !== item.invoiceId) {
            data.push(item);
          } else {
            data.push(updatedInvoice);
          }
        });
        state = {
          ...state,
          save: "",
          invoices: data,
  
        };
      }
      break;
    case EDIT_INVOICE_FAILURE:
      {
        state = {
          ...state,
          loading: false,
          save: "",
        };
      }
      break;
  
  
   
    case NEW_INVOICE_REQUEST:
      {
        state = {
          ...state,
          save: "new",
        };
      }
      break;
    case CANCEL_NEW_INVOICE_REQUEST:
      {
        state = {
          ...state,
          save: "",
        };
      }
      break;
    case NEW_INVOICE_SUCCESS:
      {
        const newInvoice = action.payload.invoice;
        const type = newInvoice.type;
        let filterSell = state.invoices.filter((f) => f.type === "Sell");
        let filterPurchase = state.invoices.filter(
          (f) => f.type === "Purchase"
        );
        if (type === "Sell") {
          filterSell = [...filterSell, newInvoice];
        } else {
          filterPurchase = [...filterPurchase, newInvoice];
        }
        state = {
          ...state,
          save: "",
          invoices: [...filterSell, ...filterPurchase],
        };
      }
      break;
    case NEW_INVOICE_FAILURE:
      {
        state = {
          ...state,
          save: "",
        };
      }
      break;
    case LOAD_FIRST_INVOICE:
      {
        state = {
          ...state,
       
          next: 1,
          prev: 1,
        };
      }
      break;
    case LOAD_LAST_INVOICE:
      {
        state = {
          ...state,
         
          next: 1,
          prev: 1,
        };
      }
      break;
    case LOAD_NEXT_INVOICE:
      {
        state = {
          ...state,
          next: state.next + 1,
        };
      }
      break;
    case LOAD_PREV_INVOICE:
      {
        state = {
          ...state,
          prev: state.prev + 1,
        };
      }
      break;
    case DELETE_INVOICE_REQUEST:
      {
        state = {
          ...state,
          loading: true,
        };
      }
      break;
    case DELETE_INVOICE_SUCCESS:
      {
        let list = [...state.invoices];
        const removedInvoice = action.payload.invoiceId;
        const filterList = list.filter((f) => f.invoiceId !== removedInvoice);
        state = {
          ...state,
          invoices: filterList,
          loading: false,
          next: 1,
          prev: 1,
        };
      }
      break;
    case DELETE_INVOICE_FAILURE:
      {
        const error = action.payload.error;
        state = {
          ...state,
          error: error,
          loading: false,
        };
      }
      break;
  }
  return state;
};
export default invoiceReducer;
