import {
    cateTypeConstants,
    ADD_TYPE_REQUEST,
    ADD_TYPE_SUCCESS,
    ADD_TYPE_FAILURE,
    ADD_CATEGORY_REQUEST,
    ADD_CATEGORY_SUCCESS,
    ADD_CATEGORY_FAILURE,
    DELETE_TYPE_REQUEST,
    DELETE_TYPE_SUCCESS,
    DELETE_TYPE_FAILURE,
    DELETE_CATEGORY_REQUEST,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAILURE,
    EDIT_TYPE_REQUEST,
    EDIT_TYPE_SUCCESS,
    EDIT_TYPE_FAILURE,
    EDIT_CATEGORY_REQUEST,
    EDIT_CATEGORY_FAILURE,
    EDIT_CATEGORY_SUCCESS
} from '../action/itemAction';
const initialState = {
    loading: false,
    categories: [],
    types: [],
    error: ''
};
const cateAndTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case cateTypeConstants.LOAD_CATTYPES_REQUEST:
            {
                state = {
                    ...state,
                    loading: true,
                }
            }
            break;
        case cateTypeConstants.LOAD_CATTYPES_SUCCESS:
            {
                state = {
                    ...state,
                    categories: action.payload.categories,
                    types: action.payload.types,
                    loading: false,
                }

            }
            break;
        case cateTypeConstants.LOAD_CATTYPES_FAILURE:
            {
                state = {
                    ...state,
                    error: action.payload.error,
                    loading: false,
                }

            }
            break;

        case DELETE_CATEGORY_REQUEST: {
            state = {
                ...state,
                loading: true,

            }

        }
            break;
        case DELETE_CATEGORY_SUCCESS: {
            const filteredCategories = state.categories.filter(f => f.docId !== action.payload.data)
            state = {
                ...state,
                loading: false,
                categories: filteredCategories

            }

        }
            break;
        case DELETE_CATEGORY_FAILURE: {
            state = {
                ...state,
                loading: false,
                error: action.payload.error

            }

        }
            break;
        case DELETE_TYPE_REQUEST: {
            state = {
                ...state,
                loading: true,

            }

        }
            break;
        case DELETE_TYPE_SUCCESS: {
            const filteredType = state.types.filter(f => f.docId !== action.payload.data)
            state = {
                ...state,
                loading: false,
                types: filteredType

            }

        }
            break;
        case DELETE_TYPE_FAILURE: {
            console.log( action.payload)
            state = {
                ...state,
                loading: false,
                error:''

            }

        }
            break;

        case EDIT_CATEGORY_REQUEST: {
            state = {
                ...state,
                loading: true,

            }

        }
            break;
        case EDIT_CATEGORY_SUCCESS: {
            let newArray = [];
            const data = action.payload.data;
            state.categories.forEach((item) => {

                if (item.docId === data.docId) {
                    newArray.push(data)

                } else {
                    newArray.push(item)
                }
            })
            state = {
                ...state,
                loading: false,
                categories: newArray

            }

        }
            break;
        case EDIT_CATEGORY_FAILURE: {
            state = {
                ...state,
                loading: false,
                error: action.payload.error

            }

        }
            break;

        case EDIT_TYPE_REQUEST: {
            state = {
                ...state,
                loading: true,

            }

        }
            break;
        case EDIT_TYPE_SUCCESS: {
            let newArray = [];
            const data = action.payload.data;
            state.types.forEach((item) => {

                if (item.docId === data.docId) {
                    newArray.push(data)

                } else {
                    newArray.push(item)
                }
            })
            state = {
                ...state,
                loading: false,
                types: newArray

            }

        }
            break;
        case EDIT_TYPE_FAILURE: {
            state = {
                ...state,
                loading: false,
                error: action.payload.error

            }

        }
            break;

        case ADD_TYPE_REQUEST: {
            state = {
                ...state,
                loading: true,

            }

        }
            break;
        case ADD_TYPE_SUCCESS: {
            state = {
                ...state,
                loading: false,
                types: [...state.types, action.payload.data]

            }

        }
            break;
        case ADD_TYPE_FAILURE: {
            state = {
                ...state,
                loading: false,
                error: action.payload.error

            }

        }
            break;

        case ADD_CATEGORY_REQUEST: {
            state = {
                ...state,
                loading: true,

            }

        }
            break;
        case ADD_CATEGORY_SUCCESS: {
            state = {
                ...state,
                loading: false,
                categories: [...state.categories, action.payload.data]

            }

        }
            break;
        case ADD_CATEGORY_FAILURE: {
            state = {
                ...state,
                loading: false,
                error: action.payload.error

            }

        }
            break;
    }

    return state;
};
export default cateAndTypeReducer;