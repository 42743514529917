import React from "react";
import { Form } from "react-bootstrap";

import "../Css/generalStyle.css";
const ListOption = (props) => {
  const { docId, right } = props.data;
  
  return (
    <div>
      <div className="listOption2">
        {right &&
          Object.keys(right).length > 0 &&
          Object.keys(right).map((key) => (
            <Form.Check
              value={right[key]}
              label={key}
              checked={right[key]}
              onChange={(e) => props.onMainRightChange(docId, key)}
            />
          ))}
      </div>
    </div>
  );
};

export default ListOption;
