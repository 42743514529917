import { addLedger, addStockData } from "./invoiceAction";
import moment from "moment";
import { showLogoList } from "../data/data1";
import * as jvActions from "./jvAction";
import firebase from "firebase";
export const TOGGLE_THEME_REQUEST = "TOGGLE_THEME_REQUEST";
export const SAVE_PERMSSION_REQUEST = "SAVE_PERMSSION_REQUEST";
export const SAVE_PERMSSION_SUCCESS = "SAVE_PERMSSION_SUCCESS";
export const SAVE_PERMSSION_FAILURE = "SAVE_PERMSSION_FAILURE";

//export const SHOW_LOGO_REQUEST = "SHOW_LOGO_REQUEST";
export const SHOW_LOGO_SUCCESS = "SHOW_LOGO_SUCCESS";
//export const SHOW_LOGO_FAILURE = "SHOW_LOGO_FAILURE";

export const SAVE_INOICE_WITHOUT_CHECK = "SAVE_INOICE_WITHOUT_CHECK";
export const themeToggle = () => {
  return async (dispatch) => {
    dispatch({
      type: TOGGLE_THEME_REQUEST,
    });
  };
};
export const changeShowLogo = (showLogo) => {
  return async (dispatch) => {
    dispatch({
      type: SHOW_LOGO_SUCCESS,
      payload: { showLogo },
    });
  };
};

export const checkGlobalSettings =  () => {
  return async (dispatch) => {
    dispatch({
      type: SAVE_PERMSSION_REQUEST,
    });
    const settings = localStorage.getItem("savesetting")
      ? localStorage.getItem("savesetting")
      : null;
    if (settings) {
      const useSettings = JSON.parse(settings);
      const showLogoCheck = useSettings.showLogo;
      let showLogo;
      if (showLogoCheck) {
        showLogo = showLogoCheck;
      } else {
        showLogo = await createLogoListForUsers();
      }

      dispatch({
        type: SAVE_PERMSSION_SUCCESS,
        payload: { ...useSettings, showLogo },
      });
    }
    if (!settings) {
      const query = firebase
        .firestore()
        .collection("gobalsettings")
        .doc("savesetting");
      const logoRef = await firebase
        .firestore()
        .collection("gobalsettings")
        .doc("showLogoSetting")
        .get();
      const dbRef = await query.get();
      const data = dbRef.data();
      const logoData = logoRef.data();

      let endDate = new Date().toISOString().slice(0, 10);
      let endFormat = moment(endDate).format("YYYY-MM-DD");
      localStorage.setItem(
        "savesetting",
        JSON.stringify({ ...data, showLogo:logoData, date: endFormat })
      );
      dispatch({
        type: SAVE_PERMSSION_SUCCESS,
        payload: { ...data, showLogo: logoData, date: endFormat },
      });
    }
  };
};
export const saveSettings = (perm) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SAVE_PERMSSION_REQUEST,
    });
    const stock = getState().stock.stock;
    let status, error;
    const query = firebase
      .firestore()
      .collection("gobalsettings")
      .doc("savesetting");

    const dbRef = await query.get();

    const logoRef = await firebase
      .firestore()
      .collection("gobalsettings")
      .doc("showLogoSetting")
      .get();
    const logoData = logoRef.data();
    if (dbRef.exists) {
      await query
        .update(perm)
        .then(() => {
          status = true;
        })
        .catch((err) => (error = err));
    } else {
      await query
        .set(perm)
        .then(() => {
          status = true;
        })
        .catch((err) => (error = err));
    }
    if (status) {
      localStorage.setItem("savesetting", JSON.stringify(perm));

      await reverseActions(stock);
      await refreshEnteries(perm, stock);
      dispatch({
        type: SAVE_PERMSSION_SUCCESS,
        payload: { ...perm },
      });
    } else {
      dispatch({
        type: SAVE_PERMSSION_FAILURE,
        payload: { error },
      });
    }
  };
};
const refreshEnteries = async (perm) => {
  if (perm.saveWithAprrove) {
    let query = firebase.firestore();
    const checkedInvoicesSell = await query
      .collection("invoicesSell")
      .where("check", "==", true)
      .where("approve", "==", true)
      .get();
    const checkedInvoicesPurchase = await query
      .collection("invoicesPurchase")
      .where("check", "==", true)
      .where("approve", "==", true)
      .get();
    const checkedInvoicesSellReturn = await query
      .collection("invoicesSell Return")
      .where("check", "==", true)
      .where("approve", "==", true)
      .get();
    if (checkedInvoicesSellReturn.size > 0) {
      const mapDocs = checkedInvoicesSellReturn.docs.map((doc) => doc.data());
      console.log({ Purchase: mapDocs });
      await mapDocs.forEach(async (invoice) => {
        await addLedger(invoice);
      });
    }
    const checkedInvoicesPurchaseReturn = await query
      .collection("invoicesPurchase Return")
      .where("check", "==", true)
      .where("approve", "==", true)
      .get();
    if (checkedInvoicesPurchaseReturn.size > 0) {
      const mapDocs = checkedInvoicesPurchaseReturn.docs.map((doc) =>
        doc.data()
      );
      console.log({ Purchase: mapDocs });
      await mapDocs.forEach(async (invoice) => {
        await addLedger(invoice);
      });
    }
    // const checkedInvoicesDeliveryChallan = await query
    //   .collection("invoicesDelivery Challan")
    //   .where("check", "==", true)
    //   .where("approve", "==", true)
    //   .get();
    // if (checkedInvoicesDeliveryChallan.size > 0) {
    //   const mapDocs = checkedInvoicesDeliveryChallan.docs.map((doc) =>
    //     doc.data()
    //   );
    //   console.log({ Purchase: mapDocs });
    //   await mapDocs.forEach(async (invoice) => {
    //     await addLedger(invoice);
    //   });
    // }
    // const invoicesGoodReceiptNote = await query
    //   .collection("invoicesGood Receipt Note")
    //   .where("check", "==", true)
    //   .where("approve", "==", true)
    //   .get();
    // if (invoicesGoodReceiptNote.size > 0) {
    //   const mapDocs = invoicesGoodReceiptNote.docs.map((doc) => doc.data());
    //   console.log({ Purchase: mapDocs });
    //   await mapDocs.forEach(async (invoice) => {
    //     await addLedger(invoice);
    //   });
    // }
    if (checkedInvoicesSell.size > 0) {
      const mapDocs = checkedInvoicesSell.docs.map((doc) => doc.data());
      console.log({ sell: mapDocs });
      await mapDocs.forEach(async (invoice) => {
        await addLedger(invoice);
      });
    }
    if (checkedInvoicesPurchase.size > 0) {
      const mapDocs = checkedInvoicesPurchase.docs.map((doc) => doc.data());
      console.log({ Purchase: mapDocs });
      await mapDocs.forEach(async (invoice) => {
        await addLedger(invoice);
      });
    }
  }
  if (!perm.saveWithAprrove && perm.saveWithCheck) {
    let query = firebase.firestore();
    const checkedInvoicesSell = await query
      .collection("invoicesSell")
      .where("check", "==", true)
      .where("approve", "==", false)
      .get();
    const checkedInvoicesPurchase = await query
      .collection("invoicesPurchase")
      .where("check", "==", true)
      .where("approve", "==", false)
      .get();
    if (checkedInvoicesSell.size > 0) {
      const mapDocs = checkedInvoicesSell.docs.map((doc) => doc.data());
      console.log({ sell: mapDocs });
      await mapDocs.forEach(async (invoice) => {
        await addLedger(invoice);
      });
    }
    if (checkedInvoicesPurchase.size > 0) {
      const mapDocs = checkedInvoicesPurchase.docs.map((doc) => doc.data());
      console.log({ Purchase: mapDocs });
      await mapDocs.forEach(async (invoice) => {
        await addLedger(invoice);
      });
    }
    const checkedInvoicesSellReturn = await query
      .collection("invoicesSell Return")
      .where("check", "==", true)
      .where("approve", "==", false)
      .get();
    if (checkedInvoicesSellReturn.size > 0) {
      const mapDocs = checkedInvoicesSellReturn.docs.map((doc) => doc.data());
      console.log({ Purchase: mapDocs });
      await mapDocs.forEach(async (invoice) => {
        await addLedger(invoice);
      });
    }
    const checkedInvoicesPurchaseReturn = await query
      .collection("invoicesPurchase Return")
      .where("check", "==", true)
      .where("approve", "==", false)
      .get();
    if (checkedInvoicesPurchaseReturn.size > 0) {
      const mapDocs = checkedInvoicesPurchaseReturn.docs.map((doc) =>
        doc.data()
      );
      console.log({ Purchase: mapDocs });
      await mapDocs.forEach(async (invoice) => {
        await addLedger(invoice);
      });
    }
    // const checkedInvoicesDeliveryChallan = await query
    //   .collection("invoicesDelivery Challan")
    //   .where("check", "==", true)
    //   .where("approve", "==", false)
    //   .get();
    // if (checkedInvoicesDeliveryChallan.size > 0) {
    //   const mapDocs = checkedInvoicesDeliveryChallan.docs.map((doc) =>
    //     doc.data()
    //   );
    //   console.log({ Purchase: mapDocs });
    //   await mapDocs.forEach(async (invoice) => {
    //     await addLedger(invoice);
    //   });
    // }
    // const invoicesGoodReceiptNote = await query
    //   .collection("invoicesGood Receipt Note")
    //   .where("check", "==", true)
    //   .where("approve", "==", false)
    //   .get();
    // if (invoicesGoodReceiptNote.size > 0) {
    //   const mapDocs = invoicesGoodReceiptNote.docs.map((doc) => doc.data());
    //   console.log({ Purchase: mapDocs });
    //   await mapDocs.forEach(async (invoice) => {
    //     await addLedger(invoice);
    //   });
    // }
  }
  if (!perm.saveWithAprrove && !perm.saveWithCheck) {
    let query = firebase.firestore();
    const checkedInvoicesSell = await query.collection("invoicesSell").get();
    const checkedInvoicesPurchase = await query
      .collection("invoicesPurchase")
      .get();
    if (checkedInvoicesSell.size > 0) {
      const mapDocs = checkedInvoicesSell.docs.map((doc) => doc.data());
      await mapDocs.forEach(async (invoice) => {
        await addLedger(invoice);
      });
    }
    if (checkedInvoicesPurchase.size > 0) {
      const mapDocs = checkedInvoicesPurchase.docs.map((doc) => doc.data());
      await mapDocs.forEach(async (invoice) => {
        await addLedger(invoice);
      });
    }
    const checkedInvoicesSellReturn = await query
      .collection("invoicesSell Return")
      .get();
    if (checkedInvoicesSellReturn.size > 0) {
      const mapDocs = checkedInvoicesSellReturn.docs.map((doc) => doc.data());
      console.log({ Purchase: mapDocs });
      await mapDocs.forEach(async (invoice) => {
        await addLedger(invoice);
      });
    }
    const checkedInvoicesPurchaseReturn = await query
      .collection("invoicesPurchase Return")
      .get();
    if (checkedInvoicesPurchaseReturn.size > 0) {
      const mapDocs = checkedInvoicesPurchaseReturn.docs.map((doc) =>
        doc.data()
      );
      console.log({ Purchase: mapDocs });
      await mapDocs.forEach(async (invoice) => {
        await addLedger(invoice);
      });
    }
    // const checkedInvoicesDeliveryChallan = await query
    //   .collection("invoicesDelivery Challan")
    //   .get();
    // if (checkedInvoicesDeliveryChallan.size > 0) {
    //   const mapDocs = checkedInvoicesDeliveryChallan.docs.map((doc) =>
    //     doc.data()
    //   );
    //   console.log({ Purchase: mapDocs });
    //   await mapDocs.forEach(async (invoice) => {
    //     await addLedger(invoice);
    //   });
    // }
    // const invoicesGoodReceiptNote = await query
    //   .collection("invoicesGood Receipt Note")
    //   .get();
    // if (invoicesGoodReceiptNote.size > 0) {
    //   const mapDocs = invoicesGoodReceiptNote.docs.map((doc) => doc.data());
    //   console.log({ Purchase: mapDocs });
    //   await mapDocs.forEach(async (invoice) => {
    //     await addLedger(invoice);
    //   });
    // }
  }
  if (perm.jvWithApprove) {
    const BPquery = await firebase
      .firestore()
      .collection("Bank PaymentVoucher")
      .where("check", "==", true)
      .where("approve", "==", true)
      .get();
    if (BPquery.size > 0) {
      const BPData = BPquery.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
      console.log({ BPData });
      await BPData.forEach(async (bp) => {
        await jvActions.addLedger(bp.data.jvItems, bp.id, bp.data.date);
      });
    }
    const CPquery = await firebase
      .firestore()
      .collection("Cash PaymentVoucher")
      .where("check", "==", true)
      .where("approve", "==", true)
      .get();
    if (CPquery.size > 0) {
      const CPData = CPquery.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
      await CPData.forEach(async (cp) => {
        await jvActions.addLedger(cp.data.jvItems, cp.id, cp.data.date);
      });
    }
    const CRquery = await firebase
      .firestore()
      .collection("Cash ReceiptVoucher")
      .where("check", "==", true)
      .where("approve", "==", true)
      .get();
    if (CRquery.size > 0) {
      const CRData = CRquery.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
      await CRData.forEach(async (cr) => {
        await jvActions.addLedger(cr.data.jvItems, cr.id, cr.data.date);
      });
    }
    const BRquery = await firebase
      .firestore()
      .collection("Bank ReceiptVoucher")
      .where("check", "==", true)
      .where("approve", "==", true)
      .get();
    if (BRquery.size > 0) {
      const BRData = BRquery.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
      await BRData.forEach(async (br) => {
        await jvActions.addLedger(br.data.jvItems, br.id, br.data.date);
      });
    }
    const JVquery = await firebase
      .firestore()
      .collection("Journal VoucherVoucher")
      .where("check", "==", true)
      .where("approve", "==", true)
      .get();
    if (JVquery.size > 0) {
      const JVData = JVquery.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
      await JVData.forEach(async (jv) => {
        await jvActions.addLedger(jv.data.jvItems, jv.id, jv.data.date);
      });
    }
  }
  if (!perm.jvWithApprove && perm.jvWithCheck) {
    const BPquery = await firebase
      .firestore()
      .collection("Bank PaymentVoucher")
      .where("check", "==", true)
      .where("approve", "==", false)
      .get();
    if (BPquery.size > 0) {
      const BPData = BPquery.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
      console.log({ BPData });
      await BPData.forEach(async (bp) => {
        await jvActions.addLedger(bp.data.jvItems, bp.id, bp.data.date);
      });
    }
    const CPquery = await firebase
      .firestore()
      .collection("Cash PaymentVoucher")
      .where("check", "==", true)
      .where("approve", "==", false)
      .get();
    if (CPquery.size > 0) {
      const CPData = CPquery.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
      await CPData.forEach(async (cp) => {
        await jvActions.addLedger(cp.data.jvItems, cp.id, cp.data.date);
      });
    }
    const CRquery = await firebase
      .firestore()
      .collection("Cash ReceiptVoucher")
      .where("check", "==", true)
      .where("approve", "==", false)
      .get();
    if (CRquery.size > 0) {
      const CRData = CRquery.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
      await CRData.forEach(async (cr) => {
        await jvActions.addLedger(cr.data.jvItems, cr.id, cr.data.date);
      });
    }
    const BRquery = await firebase
      .firestore()
      .collection("Bank ReceiptVoucher")
      .where("check", "==", true)
      .where("approve", "==", false)
      .get();
    if (BRquery.size > 0) {
      const BRData = BRquery.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
      await BRData.forEach(async (br) => {
        await jvActions.addLedger(br.data.jvItems, br.id, br.data.date);
      });
    }
    const JVquery = await firebase
      .firestore()
      .collection("Journal VoucherVoucher")
      .where("check", "==", true)
      .where("approve", "==", false)
      .get();
    if (JVquery.size > 0) {
      const JVData = JVquery.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
      await JVData.forEach(async (jv) => {
        await jvActions.addLedger(jv.data.jvItems, jv.id, jv.data.date);
      });
    }
  }
  if (!perm.jvWithApprove && !perm.jvWithCheck) {
    const BPquery = await firebase
      .firestore()
      .collection("Bank PaymentVoucher")
      .get();
    if (BPquery.size > 0) {
      const BPData = BPquery.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
      await BPData.forEach(async (bp) => {
        await jvActions.addLedger(bp.data.jvItems, bp.id, bp.data.date);
      });
    }
    const CPquery = await firebase
      .firestore()
      .collection("Cash PaymentVoucher")
      .get();
    if (CPquery.size > 0) {
      const CPData = CPquery.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
      await CPData.forEach(async (cp) => {
        await jvActions.addLedger(cp.data.jvItems, cp.id, cp.data.date);
      });
    }
    const CRquery = await firebase
      .firestore()
      .collection("Cash ReceiptVoucher")
      .get();
    if (CRquery.size > 0) {
      const CRData = CRquery.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
      await CRData.forEach(async (cr) => {
        await jvActions.addLedger(cr.data.jvItems, cr.id, cr.data.date);
      });
    }
    const BRquery = await firebase
      .firestore()
      .collection("Bank ReceiptVoucher")
      .get();
    if (BRquery.size > 0) {
      const BRData = BRquery.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
      await BRData.forEach(async (br) => {
        await jvActions.addLedger(br.data.jvItems, br.id, br.data.date);
      });
    }
    const JVquery = await firebase
      .firestore()
      .collection("Journal VoucherVoucher")
      .get();
    if (JVquery.size > 0) {
      const JVData = JVquery.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
      await JVData.forEach(async (jv) => {
        await jvActions.addLedger(jv.data.jvItems, jv.id, jv.data.date);
      });
    }
  }
  if (perm.updateStockWithGRN || perm.updateStockWithDC) {
    if (perm.updateStockWithGRN) {
      if (perm.stockWithApprove) {
        let GRNRef = await firebase
          .firestore()
          .collection("invoicesGood Receipt Note")
          .where("approve", "==", true)
          .get();

        if (GRNRef.size > 0) {
          const GRNData = GRNRef.docs.map((doc) => {
            return {
              invoiceId: doc.id,
              ...doc.data(),
            };
          });
          console.log({ GRNData });

          await GRNData.forEach(async (invoice) => {
            await addStockData(invoice);
          });
        }
      }
      if (!perm.stockWithApprove && perm.stockWithCheck) {
        let GRNRef = await firebase
          .firestore()
          .collection("invoicesGood Receipt Note")
          .where("check", "==", true)
          .get();

        if (GRNRef.size > 0) {
          const GRNData = GRNRef.docs.map((doc) => {
            return {
              invoiceId: doc.id,
              ...doc.data(),
            };
          });
          console.log({ GRNData });

          await GRNData.forEach(async (invoice) => {
            await addStockData(invoice);
          });
        }
      }
      if (!perm.stockWithApprove && !perm.stockWithCheck) {
        let GRNRef = await firebase
          .firestore()
          .collection("invoicesGood Receipt Note")
          .get();

        if (GRNRef.size > 0) {
          const GRNData = GRNRef.docs.map((doc) => {
            return {
              invoiceId: doc.id,
              ...doc.data(),
            };
          });
          console.log({ GRNData });

          await GRNData.forEach(async (invoice) => {
            await addStockData(invoice);
          });
        }
      }
    }
    if (perm.updateStockWithDC) {
      if (perm.stockWithApprove) {
        let DCRef = await firebase
          .firestore()
          .collection("invoicesDelivery Challan")
          .where("approve", "==", true)
          .get();

        if (DCRef.size > 0) {
          const DCData = DCRef.docs.map((doc) => {
            return {
              invoiceId: doc.id,
              ...doc.data(),
            };
          });
          console.log({ DCData });

          await DCData.forEach(async (invoice) => {
            await addStockData(invoice);
          });
        }
      }
      if (!perm.stockWithApprove && perm.stockWithCheck) {
        let DCRef = await firebase
          .firestore()
          .collection("invoicesDelivery Challan")
          .where("check", "==", true)
          .get();

        if (DCRef.size > 0) {
          const DCData = DCRef.docs.map((doc) => {
            return {
              invoiceId: doc.id,
              ...doc.data(),
            };
          });
          console.log({ DCData });

          await DCData.forEach(async (invoice) => {
            await addStockData(invoice);
          });
        }
      }
      if (!perm.stockWithApprove && !perm.stockWithCheck) {
        let DCRef = await firebase
          .firestore()
          .collection("invoicesDelivery Challan")
          .get();

        if (DCRef.size > 0) {
          const DCData = DCRef.docs.map((doc) => {
            return {
              invoiceId: doc.id,
              ...doc.data(),
            };
          });
          console.log({ DCData });

          await DCData.forEach(async (invoice) => {
            await addStockData(invoice);
          });
        }
      }
    }
  } else {
    if (perm.stockWithApprove) {
      let sellRef = await firebase
        .firestore()
        .collection("invoicesSell")
        .where("approve", "==", true)
        .get();
      let purchaseRef = await firebase
        .firestore()
        .collection("invoicesPurchase")
        .where("approve", "==", true)
        .get();
      if (sellRef.size > 0) {
        const sellData = sellRef.docs.map((doc) => {
          return {
            invoiceId: doc.id,
            ...doc.data(),
          };
        });
        console.log({ sellData });

        await sellData.forEach(async (invoice) => {
          await addStockData(invoice);
        });
      }
      if (purchaseRef.size > 0) {
        const purchaseData = purchaseRef.docs.map((doc) => {
          return {
            invoiceId: doc.id,
            ...doc.data(),
          };
        });
        await purchaseData.forEach(async (invoice) => {
          await addStockData(invoice);
        });
      }
    }
    if (!perm.stockWithApprove && perm.stockWithCheck) {
      let sellRef = await firebase
        .firestore()
        .collection("invoicesSell")
        .where("check", "==", true)
        .get();
      let purchaseRef = await firebase
        .firestore()
        .collection("invoicesPurchase")
        .where("check", "==", true)
        .get();
      if (sellRef.size > 0) {
        const sellData = sellRef.docs.map((doc) => {
          return {
            invoiceId: doc.id,
            ...doc.data(),
          };
        });
        await sellData.forEach(async (invoice) => {
          await addStockData(invoice);
        });
      }
      if (purchaseRef.size > 0) {
        const purchaseData = purchaseRef.docs.map((doc) => {
          return {
            invoiceId: doc.id,
            ...doc.data(),
          };
        });
        await purchaseData.forEach(async (invoice) => {
          await addStockData(invoice);
        });
      }
    }
    if (!perm.stockWithApprove && !perm.stockWithCheck) {
      let sellRef = await firebase.firestore().collection("invoicesSell").get();
      let purchaseRef = await firebase
        .firestore()
        .collection("invoicesPurchase")
        .get();
      if (sellRef.size > 0) {
        const sellData = sellRef.docs.map((doc) => {
          return {
            invoiceId: doc.id,
            ...doc.data(),
          };
        });
        await sellData.forEach(async (invoice) => {
          await addStockData(invoice);
        });
      }
      if (purchaseRef.size > 0) {
        const purchaseData = purchaseRef.docs.map((doc) => {
          return {
            invoiceId: doc.id,
            ...doc.data(),
          };
        });
        await purchaseData.forEach(async (invoice) => {
          await addStockData(invoice);
        });
      }
    }
  }
};
const reverseActions = async (stock) => {
  let delQuery = firebase.firestore().collection("generalLedger");
  const delRef = await delQuery.get();
  if (delRef.size > 0) {
    const data = delRef.docs.map((doc) => doc.id);
    await data.forEach(async (doc) => {
      delQuery.doc(doc).delete();
    });
  }

  let stockRef = await firebase.firestore().collection("StockReport").get();
  if (stockRef.size > 0) {
    const stockData = stockRef.docs.map((doc) => {
      return {
        docId: doc.id,
        ...doc.data(),
      };
    });
    await stockData.forEach(async (doc) => {
      await firebase
        .firestore()
        .collection("StockReport")
        .doc(doc.docId)
        .update({
          receivedQty: 0,
          issuedQty: 0,
        });
    });
  }
};
const createLogoListForUsers = async () => {
  let query = firebase.firestore().collection("routes");
  let checkQuery = await query.get();

  if (checkQuery.size > 0) {
    let routesList = {};
    checkQuery.docs.map((doc) => {
      let data = doc.data();
      let newLogoAdd = {
        title: data.title,
        href: data.href,
        no: data.no,
        show: false,
      };
      routesList = { ...routesList, [doc.id]: newLogoAdd };
    });
    await firebase
      .firestore()
      .collection("gobalsettings")
      .doc("showLogoSetting")
      .set(routesList);
    return routesList;
  }
};
