import React, { useEffect, useState } from "react";

import Layout from "../../components/Layout";
import ShowModal from "../../subcom/ShowModal";

import * as itemStockActions from "../../action/itemStockAction";
import * as invoiceActions from "../../action/invoiceAction";
import * as itemActions from "../../action/itemAction";
import { Table, Spinner, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import LinkCom from "../../components/general/LinkCom";
import ItemLedgerSearch from "../../components/general/ItemLedgerSearch";
import OffcanvasCom from "../../subcom/OffcanvasCom";
import { loadProductionNote } from "../../action/productionCom";
const ItemLedger = (props) => {
  const itemId = props.match.params.id;
  const sd = props.match.params.sd;
  const ed = props.match.params.ed;
  const dispatch = useDispatch();
  const stockRed = useSelector((state) => state.stock);
  const itemRed = useSelector((state) => state.item);
  const invoiceRed = useSelector((state) => state.invoice);
  const globalRed = useSelector((state) => state.global);
  const [itemList, setItemList] = useState([]);
  const [itemLedger, setItemLedger] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stockList, setStockList] = useState([]);
  const [totalData, setTotalData] = useState({
    totalRecieved: 0,
    totalIssured: 0,
    totalBalanceInStock: 0,
  });
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [rDate, setRDate] = useState({
    start: "",
    end: "",
  });
  const noteRed = useSelector((state) => state.note);
  const [sItem, setSItem] = useState({ id: "", name: "" });
  
  const [stockReportRight, setStockReportRight] = useState({});
  useEffect(() => {
    dispatch(itemStockActions.loadStockReport());
    dispatch(itemActions.loadItems());
    dispatch(invoiceActions.loadInvoiceAll());
    dispatch(loadProductionNote());
  }, []);
  useEffect(() => {
    setStockReportRight(props.rights);
  }, []);

  useEffect(() => {
    setLoading(stockRed.loading);
    setLoading(itemRed.loading);
    setItemList(itemRed.item);
    setStockList(stockRed.stock);
  }, [stockRed, itemRed]);
  useEffect(() => {
    if (itemId && sd && ed) {
      setRDate({ start: sd, end: ed });

      if (itemList.length > 0 && stockList.length > 0) {
        onItemSelected(itemId);
        loadData();
      }
    } else {
      if (globalRed.date !== "") {
        let endDate = new Date().toISOString().slice(0, 10);
        let endFormat = moment(endDate).format("YYYY-MM-DD");
        setRDate({ start: globalRed.date, end: endFormat });
      }
    }
  }, [globalRed, itemId, sd, ed, itemList, stockList, invoiceRed]);

  const loadData = async () => {
    let data = [];
    let tRec = 0,
      tIss = 0,
      tBalance = 0;
    let invoices = invoiceRed.invoices;
    if (globalRed.updateStockWithGRN || globalRed.updateStockWithDC) {
      invoices = invoices.filter((f) => {
        if (f.type !== "Sell" && f.type !== "Purchase") {
          return f;
        }
      });

      if (!globalRed.updateStockWithGRN) {
        invoices = invoices.filter((f) => {
          if (f.type === "Delivery Challan") {
            return f;
          }
        });
      }
      if (!globalRed.updateStockWithDC) {
        invoices = invoices.filter((f) => {
          if (f.type === "Good Delivery Note") {
            return f;
          }
        });
      }
    } else {
      invoices = invoices.filter((f) => {
        if (f.type !== "Delivery Challan" && f.type !== "Good Receipt Note") {
          return f;
        }
      });
    }
    if (rDate.start !== "" && rDate.end !== "" && sItem.id !== "") {
      let s = new Date(rDate.start).toISOString().slice(0, 10);
      let e = new Date(rDate.end).toISOString().slice(0, 10);
      if (globalRed.stockWithApprove) {
        invoices = invoices.filter((f) => f.approve === true);
      }
      if (globalRed.stockWithCheck) {
        invoices = invoices.filter((f) => f.check === true);
      }
      let otherVouchers = [...noteRed.productionNote];
      invoices = [...invoices, ...otherVouchers];
      const sortByDate = invoices.sort((a, b) => {
        let dbDate, dbDate2;
        if (typeof a.date === "string") {
          dbDate = a.date;
        }
        if (typeof a.date === "object") {
          dbDate = new Date(a.date.toDate());
        }
        if (typeof b.date === "string") {
          dbDate = a.date;
        }
        if (typeof b.date === "object") {
          dbDate = new Date(b.date.toDate());
        }

        return dbDate - dbDate2;
      });
      let balanceInStock = 0;
      const fin = stockList.find((f) => f.docId === sItem.id);
      let getOpeningBalance = 0;
      if (fin && fin.balanceInStock) {
        getOpeningBalance = parseInt(fin.balanceInStock);
      }
      balanceInStock = balanceInStock + getOpeningBalance;
      data.push({
        docId: "",
        name: "",
        receivedQty: 0,
        issuedQty: 0,
        balanceInStock: balanceInStock,
        narration: "Opening Balance",
      });

      invoices.map((f) => {
        let receivedQty, issuedQty, date, narration;
        let check;
        if (typeof f.date === "string") {
          check = f.date;
        } else {
          let dbDate = new Date(f.date.toDate());
          check = new Date(dbDate).toISOString().slice(0, 10);
        }
        if (check < s) {
          if (
            f.type === "Sell" ||
            f.type === "Delivery Challan" ||
            f.type === "Purchase Return"
          ) {
            f.items.map((item) => {
              if (item.id === sItem.id) {
                date = f.date;
                balanceInStock =
                  parseInt(balanceInStock) - parseInt(item.quantity);
              }
            });
          } else if (
            f.type === "Purchase" ||
            f.type === "Good Receipt Note" ||
            f.type === "Sell Return"
          ) {
            f.items.map((item) => {
              if (item.id === sItem.id) {
                date = f.date;
                balanceInStock =
                  parseInt(balanceInStock) + parseInt(item.quantity);
              }
            });
          }
          if (f.type === "Production Note") {
            f.items.map((item) => {
              if (item.id === sItem.id) {
                balanceInStock =
                  parseInt(balanceInStock) - parseInt(item.quantity);
              }
            });
          }
          if (f.type === "Production Note") {
            if (f.receipyItemId === sItem.id) {
              balanceInStock =
                parseInt(balanceInStock) + parseInt(f.forQuantity);
            }
          }
        }

        if (check >= s && check <= e) {
          if (
            f.type === "Sell" ||
            f.type === "Delivery Challan" ||
            f.type === "Purchase Return"
          ) {
            narration = `${f.type} from ${f.party}`;
            f.items.map((item) => {
              if (item.id === sItem.id) {
                receivedQty = 0;
                date = f.date;
                issuedQty = item.quantity;
                balanceInStock =
                  parseInt(balanceInStock) - parseInt(item.quantity);
              }
            });
          } else if (
            f.type === "Purchase" ||
            f.type === "Good Receipt Note" ||
            f.type === "Sell Return"
          ) {
            narration = `${f.type} from ${f.party}`;
            f.items.map((item) => {
              if (item.id === sItem.id) {
                receivedQty = item.quantity;
                issuedQty = 0;
                date = f.date;
                balanceInStock =
                  parseInt(balanceInStock) + parseInt(item.quantity);
              }
            });
          }
          if (f.type === "Production Note") {
            narration = `${f.type} from ${f.party}`;
            f.items.map((item) => {
              if (item.id === sItem.id) {
                receivedQty = 0;
                date = f.date;
                issuedQty = item.quantity;
                balanceInStock =
                  parseInt(balanceInStock) - parseInt(item.quantity);
              }
            });
          }
          if (f.type === "Production Note") {
            narration = `${f.type} from ${f.party ? f.party : ""}`;
            f.items.map((item) => {
              if (item.id === sItem.id) {
                receivedQty = parseInt(f.forQuantity);
                issuedQty = 0;
                date = f.date;
                balanceInStock =
                  parseInt(balanceInStock) + parseInt(f.forQuantity);
              }
            });
          }
        }

        if (receivedQty) {
          data.push({
            docId: fin.docId,
            name: fin.name,
            invoiceId: f.invoiceId ? f.invoiceId : f.id,
            receivedQty,
            issuedQty: 0,
            balanceInStock: balanceInStock,
            narration,
            check,
          });
          tRec = tRec + receivedQty;
        }
        if (issuedQty) {
          data.push({
            docId: fin.docId,
            name: fin.name,
            receivedQty: 0,
            invoiceId: f.invoiceId ? f.invoiceId : f.id,
            issuedQty,
            balanceInStock: balanceInStock,
            narration,
            check,
          });
          tIss = tIss + issuedQty;
        }
        setItemLedger(data);
        setTotalData({
          totalRecieved: tRec,
          totalIssured: tIss,
        });
      });
    }
  };

  const onItemSelected = (id) => {
    const select = itemList.find((f) => f.docId === id);
    setSItem({ id: select.docId, name: select.name });
  };
  const handleStartDate = (e) => {
    const val = e.target.value;
    setRDate({ start: val, end: "" });
  };
  const handleEndDate = (e) => {
    const val = e.target.value;
    setRDate({ start: rDate.start, end: val });
  };

  const resetAllValues = () => {
    setItemLedger([]);
    dispatch(itemStockActions.loadStockReport());
    dispatch(itemActions.loadItems());
    dispatch(invoiceActions.loadInvoiceAll());
    setSItem({ id: "", name: "" });
    setRDate({ start: "", end: "" });
  };
  const handlePrintOption = () => {
    localStorage.setItem(
      "printItemLadgerReport",
      JSON.stringify({
        rDate,
        itemLedger,
        totalData,
      })
    );
  };

  if (!loading) {
    return (
      <Layout>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "20px",
            padding: "3px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <OffcanvasCom
              show={showOffCanvas}
              handleOffcanvas={() => setShowOffCanvas(!showOffCanvas)}
            >
              <ItemLedgerSearch
                itemList={itemList}
                label={sItem.name}
                selectedItem={(id) => onItemSelected(id)}
                handleStartDate={handleStartDate}
                handleEndDate={handleEndDate}
                start={rDate.start}
                end={rDate.end}
                loadData={loadData}
              />
              {itemLedger.length > 0 &&
                Object.keys(stockReportRight).length > 0 &&
                stockReportRight.print && (
                  <Button onClick={handlePrintOption} style={{ width: "100%" }}>
                    <Link
                      to={`/printitemledger`}
                      target="_blank"
                      style={{ color: "white", textDecoration: "none" }}
                    >
                      Print
                    </Link>
                  </Button>
                )}
            </OffcanvasCom>

            <h5 onClick={resetAllValues}>Item Ledger</h5>
          </div>
        </div>

        {itemLedger.length > 0 ? (
          <Table striped bordered hover size='sm' style={{ textAlign: "center" ,fontSize:"14px"}}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Voucher No</th>
                <th>Particular</th>
                <th>Received Quantity</th>
                <th>Issued Quantity</th>
                <th>Balance in Stock</th>
                {/* <th>Operation</th> */}
              </tr>
            </thead>
            <tbody>
              {itemLedger.map((st, index) => {
                return (
                  <tr key={index}>
                    <td> {st.check}</td>
                    <td style={{ textDecoration: "none", color: "black" }}>
                      {st.invoiceId && <LinkCom vNo={st.invoiceId} />}
                    </td>
                    <td style={{ textAlign: "start", color: "black" }}>
                      {st.narration}
                    </td>
                    <td>{st.receivedQty > 0 && st.receivedQty}</td>
                    <td>{st.issuedQty > 0 && st.issuedQty}</td>
                    <td>{st.balanceInStock}</td>
                    {/* <td onClick={() => showStockModalHandler(st)}>edit</td> */}
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <th>Total</th>
                <th> {totalData.totalRecieved}</th>
                <th> {totalData.totalIssured}</th>
                <th>{itemLedger[itemLedger.length - 1].balanceInStock}</th>
              </tr>
            </tbody>
          </Table>
        ) : null}
      </Layout>
    );
  } else {
    return (
      <Layout>
        {" "}
        {loading && (
          <ShowModal show={loading}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner animation="border" role="status" />
            </div>
          </ShowModal>
        )}
      </Layout>
    );
  }
};

export default ItemLedger;
