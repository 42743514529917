import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button, FloatingLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as trialBalanceActions from "../../action/trialBalance";
import CustomDropDown from "../../subcom/CustomDropDown";
import "../../Css/btnStyle.css";
import Layout from "../../components/Layout";
import TrialBalanceTable from "../../components/trialbalance/TrialBalanceTable";
import moment from "moment";
import OffcanvasCom from "../../subcom/OffcanvasCom";
const TrailBalance = (props) => {
  const globalRed = useSelector((state) => state.global);
  let types = [
    { docId: 0, title: "All" },
    { docId: 1, title: "oe" },
    { docId: 2, title: "lb" },
    { docId: 3, title: "sp" },
    { docId: 4, title: "ct" },
    { docId: 5, title: "oa" },
    { docId: 6, title: "in" },
    { docId: 7, title: "ex" },
  ];
  const dispatch = useDispatch();
  const tbRed = useSelector((state) => state.tb);
  const [levels, setLevels] = useState([]);
  const [filterZero, setFilterZero] = useState(false);
  const [options, setOptions] = useState({
    level1: true,
    level2: true,
    level3: true,
  });
  const [sAccountType, setSAccountType] = useState({ docId: 0, title: "all" });
  const [show, setShow] = useState("all");
  const [rDate, setRDate] = useState({
    start: "",
    end: "",
  });
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  
  const [reportRight, setReportRight] = useState({});
  useEffect(() => {
    setReportRight(props.rights);
  }, []);
  useEffect(() => {
    document.title = "Trial Balance";
  }, []);
  useEffect(() => {
    setLevels([]);
  }, [rDate]);
  useEffect(() => {
    setLevels(tbRed.levels);
  }, [tbRed]);
  useEffect(() => {
    if (globalRed.date !== "") {
      let endDate = new Date().toISOString().slice(0, 10);
      let endFormat = moment(endDate).format("YYYY-MM-DD");
      setRDate({ start: globalRed.date, end: endFormat });
    }
  }, [globalRed]);
  const handleFilterZero = () => {
    setRDate(rDate);
    setFilterZero((check) => {
      if (check) {
        setLevels(tbRed.levels);
        return false;
      } else {
        filterZeroFunction();
        return true;
      }
    });
  };
  const filterZeroFunction = () => {
    let level1Array = [];
    tbRed.levels.forEach((level1) => {
      let level2Array = [];
      if (level1.children.length > 0) {
        level1.children.forEach((level2) => {
          let level3Array = [];
          if (level2.children.length > 0) {
            level2.children.forEach((level3) => {
              let credit = level3.obdebit + level3.debit;
              let debit = level3.obcredit + level3.credit;
              if (Math.abs(credit) > 0 || Math.abs(debit) > 0) {
                level3Array.push(level3);
              }
            });
          }
          if (level3Array.length > 0) {
            level2Array.push({ ...level2, children: level3Array });
          }
        });
      }
      if (level2Array.length > 0) {
        level1Array.push({ ...level1, children: level2Array });
      }
    });
    setLevels(level1Array);
  };
  const handleStartDate = (e) => {
    const val = e.target.value;
    setRDate({ start: val, end: "" });
  };
  const handleEndDate = (e) => {
    const val = e.target.value;
    setRDate({ start: rDate.start, end: val });
  };

  const selectedAccount = (docId) => {
    const filterType = types.find((f) => f.docId === docId);
    if (docId === 0) {
      setLevels(tbRed.levels);
    } else {
      setLevels(tbRed.levels.filter((f) => f.type === filterType.title));
    }
    setSAccountType({ docId: filterType.docId, title: filterType.title });
  };
  const handleReportChangeType = (val) => {
    setShow(val);
  };
  const onLevelShowChange = (e) => {
    const val = e.target.value;

    if (val === "level1Show") {
      setOptions((option) => {
        return { ...option, level1: !option.level1 };
      });
    }
    if (val === "level2Show") {
      setOptions((option) => {
        return { ...option, level2: !option.level2 };
      });
    }
    if (val === "level3Show") {
      setOptions((option) => {
        return { ...option, level3: !option.level3 };
      });
    }
    if (filterZero) {
      setFilterZero(false);
      setLevels(tbRed.levels);
    }
  };
  const loadData = () => {
    if (rDate.start !== "" && rDate.end !== "") {
      setRDate(rDate);
      dispatch(trialBalanceActions.getAllLevel(rDate));
    } else {
      alert("please select date range");
    }
    setShowOffCanvas(!showOffCanvas);
  };
  const handlePrintInfo = () => {
    localStorage.setItem(
      "trialBalance",
      JSON.stringify({
        levels,
        show,
        options,
        sAccountType,
      })
    );
  };
  if (tbRed.loading) {
    return <div>loading</div>;
  } else {
    return (
      <Layout>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "5px",
            width: "30vw",
          }}
        >
          <OffcanvasCom
            show={showOffCanvas}
            handleOffcanvas={() => setShowOffCanvas(!showOffCanvas)}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <FloatingLabel controlId="floatingSelect" label="Start Date">
                <Form.Control
                  name="startDate"
                  type="date"
                  onChange={handleStartDate}
                  placeholder="please select date"
                  value={rDate.start}
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingSelect" label="End Date">
                <Form.Control
                  name="endDate"
                  type="date"
                  onChange={handleEndDate}
                  placeholder="please select date"
                  value={rDate.end}
                />
              </FloatingLabel>

              <Button
                onClick={loadData}
                style={{ width: "100%", height: "100%", padding: "10px" }}
              >
                Search
              </Button>
            </div>
            {levels.length > 0 && rDate.start !== "" && rDate.end !== "" && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Form.Check
                    name="level1Show"
                    type="checkbox"
                    onChange={onLevelShowChange}
                    value={"level1Show"}
                    label="Level 1"
                    checked={options.level1 ? true : false}
                  />
                  <Form.Check
                    name="level2Show"
                    type="checkbox"
                    onChange={onLevelShowChange}
                    label="Level 2 "
                    value={"level2Show"}
                    checked={options.level2 ? true : false}
                  />
                  <Form.Check
                    name="level3Show"
                    type="checkbox"
                    value={"level3Show"}
                    onChange={onLevelShowChange}
                    label="Level 3 "
                    checked={options.level3 ? true : false}
                  />
                  {levels.length > 0 && (
                    <Form.Check
                      name="filterZero"
                      type="checkbox"
                      value={filterZero}
                      onChange={handleFilterZero}
                      label="Filter Zeros"
                      checked={filterZero}
                    />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Form.Check
                    type="radio"
                    label="Opening Balance"
                    value="openingBalance"
                    name="reportType"
                    checked={show === "openingBalance" ? true : false}
                    onChange={(e) => handleReportChangeType(e.target.value)}
                  />
                  <Form.Check
                    type="radio"
                    label="During Balance"
                    value="duringBalance"
                    name="reportType"
                    checked={show === "duringBalance" ? true : false}
                    onChange={(e) => handleReportChangeType(e.target.value)}
                  />
                  <Form.Check
                    type="radio"
                    label="Closing Balance"
                    value="closingBalance"
                    name="reportType"
                    checked={show === "closingBalance" ? true : false}
                    onChange={(e) => handleReportChangeType(e.target.value)}
                  />
                  <Form.Check
                    type="radio"
                    label="All "
                    value="all"
                    name="reportType"
                    checked={show === "all" ? true : false}
                    onChange={(e) => handleReportChangeType(e.target.value)}
                  />
                  {types.length > 0 && (
                    <div style={{ width: "100%" }}>
                      <CustomDropDown
                        list={types}
                        label={sAccountType.title.toUpperCase()}
                        selectedItem={(id) => selectedAccount(id)}
                      />
                    </div>
                  )}
                  {reportRight && reportRight.print ? (
                    <Button
                      onClick={handlePrintInfo}
                      style={{ width: "100%", marginTop: "10px" }}
                    >
                      <Link
                        to={`/printtrialbalance`}
                        target="_blank"
                        style={{
                          width: "100px",
                          height: "100%",
                          padding: "10px",
                          color: "white",
                          textDecoration: "none",
                        }}
                      >
                        Print
                      </Link>
                    </Button>
                  ) : null}
                </div>
              </>
            )}
          </OffcanvasCom>
          <h3> Trial Balance</h3>
        </div>

        {levels.length > 0 && rDate.start !== "" && rDate.end !== "" && (
          <div style={{ width: "100%", height: "500px", overflow: "auto" }}>
            <TrialBalanceTable
              levels={levels}
              options={options}
              show={show}
              rDate={rDate}
            />
          </div>
        )}
      </Layout>
    );
  }
};

export default TrailBalance;
