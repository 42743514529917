import React from 'react'
import { BiSort } from 'react-icons/bi'
const TableHeading = (props) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
        }}
            onClick={() => props.clickHandler()}
        >
            <BiSort size={22} color="blue" />
            <span>{props.heading}</span>
        </div>
    )
}

export default TableHeading
