import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap';
const PrintJv = (props) => {
    const { vNo } = props;
    let type = '';
    if (vNo) {
        let part = vNo.split('-');
        console.log({ part })
        if (part[0] === 'br') {
            type = 'Bank Receipt';

        }
        if (part[0] === 'bp') {
            type = 'Bank Payment';
        }
    }

    return (
        <div>

            <Table striped bordered hover style={{ textAlign: "center" }}>
                <thead>
                    <tr>
                        <th style={{ width: "50px" }}>#</th>
                        <th style={{ width: "250px" }} >Particular</th>
                        <th style={{ width: "100px" }}>Adj Voucher</th>
                        {
                            (type !== '' && type === 'Bank Payment' || type === 'Bank Receipt') ?
                                <>
                                    <th style={{ width: "80px" }}>Check No</th>
                                    <th style={{ width: "80px" }}>Check date</th>
                                </>
                                : null
                        }
                        <th style={{ width: "100px" }}>Dr. Amount</th>
                        <th style={{ width: "100px" }}>Cr. Amount</th>



                    </tr>
                </thead>
                <tbody>
                    {
                        props.jv.map((item, index) => (
                            <tr key={index}>
                                <td >{index+1}</td>
                                <td>
                                    {
                                        item.party

                                    }
                                    <br />
                                    {
                                        item.lineNarration
                                    }
                                </td>
                                <td >
                                    {
                                        item.voucherAdj !== 0 && item.voucherAdj
                                    }
                                </td>
                                {
                                    (type !== '' && type === 'Bank Payment' || type === 'Bank Receipt') ?
                                        <>
                                            <td >
                                                {
                                                    item.checkNo
                                                }


                                            </td>
                                            <td  >
                                                {item.checkDate}

                                            </td>
                                        </>
                                        : null
                                }
                                <td >
                                    {
                                        item.accDebit

                                    }


                                </td>

                                <td >
                                    {
                                        item.accCredit

                                    }
                                </td>


                            </tr>

                        ))
                    }




                </tbody>
            </Table >

        </div>
    )
}

export default PrintJv
