import React from "react";
import { Table } from "react-bootstrap";
import CustomDropDown from "../../subcom/CustomDropDown";
import { Link } from "react-router-dom";
import Input2 from "../../subcom/Input2";

const ProductionHeader = (props) => {
  const { date, party, itemData, edit, invoiceType } = props;

  return (
    <Table striped bordered hover style={{ textAlign: "center" }}>
      <thead>
        <tr>
          <th style={{width:"150px"}}>Date</th>

          <th>
            Party Name
            {invoiceType === "Purchase" ? (
              <Link to="/chartofaccount/supplier">+</Link>
            ) : (
              <Link to="/chartofaccount/client">+</Link>
            )}
          </th>
          <th>For QTY</th>
          <th>Caption</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ width: "80px" }}>
            {!edit ? (
              date
            ) : (
              <Input2
                name="date"
                type="date"
                onChange={props.handleDateChange}
                value={date}
              />
            )}
          </td>
          <td
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              width: "100%",
              textAlign: "center",
            }}
          >
            {!edit ? (
              party
            ) : (
              <div>
                {itemData.length > 0 && (
                  <CustomDropDown
                    list={itemData}
                    label={party}
                    selectedItem={props.selectedItemForReceipy}
                  />
                )}
              </div>
            )}
          </td>
          <td>
            {!edit ? (
              props.forQuantity
            ) : (
              <Input2
                name="voucherId"
                type="number"
                onChange={props.handleForQtyChange}
                value={props.forQuantity}
              />
            )}
          </td>
          <td>
            {!edit ? (
              props.caption
            ) : (
              <Input2
                name="voucherId"
                type="text"
                onChange={props.handleForCaptionChange}
                value={props.caption}
              />
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default ProductionHeader;
