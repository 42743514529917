import React, { useEffect, useState } from "react";
import TrialBalanceTable from "../../components/trialbalance/TrialBalanceTable";
import SlipHeader from "../../components/SlipHeader.js/SlipHeader";
import { loadcompanyData } from "../../action/generalMethod";
import { useSelector } from "react-redux";
const PrintTrialBalance = (props) => {
  const [companyData, setCompanyData] = useState({});
  const globalRed = useSelector((state) => state.global);
  const [showLogo, setShowLogo] = useState(false);
  useEffect(() => {
    document.title = "Trial Balance Print";
  }, []);
  const [reportData, setReportData] = useState();
  useEffect(async () => {
    let data = localStorage.getItem("trialBalance")
      ? localStorage.getItem("trialBalance")
      : null;
    if (data) {
      const parseData = JSON.parse(data);
      setReportData(parseData);
    }
    const cData = await loadcompanyData();
    setCompanyData(cData);
    if (globalRed.showLogo && globalRed.showLogo.length > 0) {
      let showOrNot = globalRed.showLogo;
      setShowLogo(showOrNot[2].children[0].children[2].show);
    }
  }, [globalRed]);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  useEffect(async () => {
    if (reportData) {
      await delay(4000);
      window.print();
    }
  }, [reportData]);

  if (reportData) {
    const { levels, options, sAccountType, show } = reportData;
    let filterLevels;
    if (sAccountType.docId === 0) {
      filterLevels = levels;
    } else {
      filterLevels = levels.filter((f) => f.type === sAccountType.title);
    }

    return (
      <div style={{ margin: "20px", backgroundColor: "white" }}>
        <SlipHeader
          companyData={companyData}
          data={""}
          vNo={""}
          slipType={"Trial Balance"}
          showLogo={showLogo}
        />

        <TrialBalanceTable
          print={"print"}
          levels={filterLevels}
          options={options}
          show={show}
        />
      </div>
    );
  } else {
    return <div>loading</div>;
  }
};

export default PrintTrialBalance;
